import GoogleLogo from "../assets/images/socials/1534129544.png";
import FacebookLogo from "../assets/images/socials/facebook_med.png";
import LinkedInLogo from "../assets/images/socials/free-linkedin-icon-130-thumb.png";

export const GOOGLE_AUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth";
export const GOOGLE_SCOPE =
  "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

export const FACEBOOK_AUTH_URL = "https://www.facebook.com/v13.0/dialog/oauth";
export const FACEBOOK_SCOPE = "public_profile,email";

export const LINKEDIN_AUTH_URL =
  "https://www.linkedin.com/oauth/v2/authorization";
export const LINKEDIN_SCOPE = "r_liteprofile r_emailaddress";

export const PROVIDER = {
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  LINKEDIN: "LINKEDIN"
};

export const PROVIDER_NAME = {
  [PROVIDER.GOOGLE]: "Google",
  [PROVIDER.FACEBOOK]: "Facebook",
  [PROVIDER.LINKEDIN]: "LinkedIn"
};

export const PROVIDER_ICON = {
  [PROVIDER.GOOGLE]: GoogleLogo,
  [PROVIDER.FACEBOOK]: FacebookLogo,
  [PROVIDER.LINKEDIN]: LinkedInLogo
};