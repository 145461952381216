import React, { useEffect, useState } from "react";

import ProjectCard from "../molecules/ProjectCard";

import { getRelatedProjects } from "../../services/ProjectService.js";

import scrollTrigger from "../../util/ScrollReveal";

import "../../styles/components/molecules/RelatedProjects.scss";

function RelatedProjects({ role, projectObject, user }) {
  const [projectMatch, setProjectMatch] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    getRelatedProjects(projectObject.projectId).then((resultList) => {
      setProjectMatch(resultList);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (projectMatch.length > 0) {
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, 500);
    }
  }, [projectMatch]);

  function storeFavorites(projectId) {
    let favoriteList = [];
    const favoriteItems = localStorage.getItem("favorites");

    // Use list from local storage if it exists
    if (favoriteItems != null) {
      favoriteList = JSON.parse(favoriteItems);
    }

    // Add new favorite project to list and local storage
    if (favoriteList.indexOf(projectId) === -1) {
      favoriteList.push(projectId);
    } else {
      favoriteList.splice(favoriteList.indexOf(projectId), 1);
    }

    localStorage.setItem("favorites", JSON.stringify(favoriteList));
  }

  return (
    <>
      {projectMatch.length !== 0 ? 
        <div
          className="related-projects-overview"
          style={role === "admin" ? { display: "none" } : { display: "flex" }}
        >
          <div className="related-projects-overview__container">
            <h1 className="related-projects-overview__title">Related Projects</h1>
            <div className="related-projects-overview__content-container">
              {!isLoading && projectMatch.map((elem, i) => (
                <ProjectCard
                  key={`project-${i}`}
                  parent="related"
                  imageUrl={elem.imageUrl}
                  projectId={elem.projectId}
                  title={elem.title}
                  boosted={elem.boosted}
                  userImageUrl={elem.userImageUrl}
                  designerName={elem.designerName}
                  onClick={(projectId) => { storeFavorites(projectId); } }
                  uuid={elem.uuid}
                  user={user}
                  favoriteCount={elem.favoriteCount}
                  ownerProfileId={elem.userUUID}
                />
              ))}
            </div>
          </div>
        </div>
        : 
        null
      }
    </>
  );
}

export default RelatedProjects;
