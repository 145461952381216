import React from "react";
import Button from "./Button";

import "../../styles/components/atoms/ErrorNotification.scss";

class ErrorNotification extends React.Component {
  render() {
    return (
      <div className='error-info'>
        <div className="error-info__container">
          <h2>Whoops, sorry! An unexpected error occured..</h2>
          <div className="error-info__btn-container">
            <Button type="black" customClassName="error-info__btn" title="Contact Us" onClick={() => {  window.location = "mailto:support@megosu.com"; }}/>
            <Button type="black" customClassName="error-info__btn" title="Go Back" onClick={() => { window.location.assign(process.env.REACT_APP_WEBAPP_BASE_URL); console.clear(); }}/>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorNotification;