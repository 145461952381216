import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import SubscriptionForm from "../components/molecules/SubscriptionForm";
import SubscriptionTestimonials from "../components/molecules/SubscriptionTestimonials";
import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";
import scrollTrigger from "../util/ScrollReveal";

import {
  getCurrentUserRole,
  updateAccessToken,
  getCurrentUser
} from "../services/AuthService";

import feature1 from "../assets/images/subscription/feature1.jpg";
import feature2 from "../assets/images/subscription/feature2.jpg";
import feature3 from "../assets/images/subscription/feature3.jpg";
import feature4 from "../assets/images/subscription/feature4.jpg";

import { 
  GCS_ASSET_BRAUN_LOGO, GCS_ASSET_HERMAN_MILLER_LOGO, GCS_ASSET_MADECOM_LOGO, 
  GCS_ASSET_OBJECT_EMBASSY_LOGO, GCS_ASSET_VANMOOF_LOGO, GCS_ASSET_VT_WONEN_LOGO 
}  from "../constants/gcs-assets-paths";

import "../styles/pages/SubscriptionPage.scss";

const SubscriptionPage = () => {
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);

  useEffect(async () => {
    const email = getCurrentUser();

    if (email) {
      const currentRole = new URLSearchParams(window.location.search).get("currentRole");
      await updateAccessToken();
      const updatedRole = getCurrentUserRole();
    
      if (currentRole) {
        if (updatedRole !== currentRole) {
          history.push({
            pathname: "/",
            search: "?paymentConfirmation=" + updatedRole
          });
        }
        else {
          history.push({
            pathname: "/pricing"
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className="subscription">
      <DocumentHead
        title="Subscriptions | Megosu"
        metaDescription="Level up your career with Megosu. Whether you're looking to build your portfolio, find your next client or collaborate with new parties:
        We've got you covered!"
        showChat={true}
      />
      <div className="subscription__feature-container reveal">
        <figure className="subscription__feature-image-container">
          <div className="subscription__feature-image-wrapper">
            <img src={feature1} alt="female walking" className="reveal"/>
          </div>
          <figcaption className="reveal">&copy; Ashley Willard </figcaption>
        </figure>
        <div className="reveal">
          <h1 className="subscription__feature-title">
           Level up your career with Megosu. Whether you&apos;re looking to build your portfolio,
           find your next client or collaborate with new parties: <br></br> We&apos;ve got you covered!
          </h1>
          <p className="subscription__feature-subtext">
            With a Pro or Pitch plan you get the tools you need to boost your career opportunities.
          </p>
        </div>
      </div>
      
      <h2 id="pickaplan" className="subscription__title reveal">
        Pick a Plan
      </h2>
      <SubscriptionForm reveal={true} />

      <SubscriptionTestimonials reveal={true}  />

      <div className="subscription__feature-container reveal">
        <div>
          <h3 className="subscription__feature-title">Propose your design to companies</h3>
          <p className="subscription__feature-subtext">
          Over the years we&apos;ve learned that designers like to get their designs produced. 
          But many designers have a limited- mainly regional- network. 
          With Megosu we provide you with a safe network of companies.
          </p>
          <HashLink smooth to="/pitch">
            <Button type="white" title="Pitch" customClassName="subscription__feature-button"/>
          </HashLink>
        </div>
        <figure className="subscription__feature-image-container">
          <div className="subscription__feature-image-wrapper">
            <img src={feature2} alt="man reading book"/>
          </div>
          <figcaption>&copy; Marie Gerhardt </figcaption>
        </figure>
      </div>

      <div className="subscription__feature-container reveal">
        <figure className="subscription__feature-image-container">
          <div className="subscription__feature-image-wrapper">
            <img src={feature3} alt="Ivan Pérez looking at his design"/>
          </div>
          <figcaption>&copy; Ivan Pérez Jové </figcaption>
        </figure>
        <div>
          <h3 className="subscription__feature-title">Boost your portfolio</h3>
          <p className="subscription__feature-subtext">
          Go professional with a suite of features to accelerate your design career. 
          With a Pro Plan you&apos;re able to present your complete portfolio and have boosted reach. 
          Upload unlimited projects, add more images and videos to your projects and link your webshop.
          </p>
          <HashLink smooth to="/pricing#pickaplan">
            <Button type="white" title="Go Pro" customClassName="subscription__feature-button"/>
          </HashLink>
        </div>
      </div>

      <div className="subscription__feature-container reveal">
        <div>
          <h3 className="subscription__feature-title">Discover designers & their projects </h3>
          <p className="subscription__feature-subtext">
          Are you looking for a designer? With Megosu you&apos;ll get access to an exclusive group of talented designers. 
          The business filters help you to find the right person. Get in touch with us if you want find a designer or a project. 
          Megosu&apos;s team also curates, consults and recruits.
          </p>
          <Button type="white" title="Become a partner" onClick={() => { window.open("mailto:partners@megosu.com"); }} customClassName="subscription__feature-button--less-width"/>
        </div>
        <figure className="subscription__feature-image-container">
          <div className="subscription__feature-image-wrapper">
            <img src={feature4} alt="people reading books"/>
          </div>
          <figcaption>&copy; Alexandre Picciotto </figcaption>
        </figure>
      </div>

      <div className="subscription__contact-container reveal">
        <h3 className="subscription__contact-title">Businesses who trust us</h3>
        <div className="subscription__image-container">
          <img className="subscription__company-image" src={GCS_ASSET_BRAUN_LOGO} alt="braun logo" />
          <img className="subscription__company-image" src={GCS_ASSET_HERMAN_MILLER_LOGO} alt="herman miller logo"/>
          <img className="subscription__company-image" src={GCS_ASSET_VANMOOF_LOGO} alt="vanmoof logo"/>
          <img className="subscription__company-image" src={GCS_ASSET_MADECOM_LOGO} alt="madecom logo"/>
          <img className="subscription__company-image" src={GCS_ASSET_VT_WONEN_LOGO} alt="vtwonen logo" />
          <img className="subscription__company-image" src={GCS_ASSET_OBJECT_EMBASSY_LOGO} alt="object embassy logo"/>
        </div>
        <p className="subscription__contact-subtext">
          Contact our team if you want multiple accounts or to learn about great features for partnering businesses.
        </p>
        <Button type="black" title="Contact" onClick={() => window.location.assign("mailto:partners@megosu.com")}/>
      </div>
    </div>
  );
};

export default SubscriptionPage;



