import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useLocation, NavLink } from "react-router-dom";
import { VscClose } from "@react-icons/all-files/vsc/VscClose";

import NavBar from "../../components/molecules/NavBar";
import Button from "../atoms/Button";
import PopUp from "../molecules/PopUp";

import { getCurrentUser } from "../../services/AuthService";
import { getNotifications } from "../../services/ChatService";
import { getProjectTitleAndCountByProfileId } from "../../services/ProjectService";
import { logout } from "../../services/LoginService";
import { getSiteAnnouncement } from "../../services/SiteService";

import "../../styles/components/organisms/Header.scss";

import megosu from "../../assets/images/MegosuOutlinetext.svg";
import profilePlaceHolder from "../../assets/images/ProfilePlaceholder.png";
import popupProImage from "../../assets/images/popupImages/transitions/popupTransitionPro.m4v";
import preTransitionPopupProImage from "../../assets/images/popupImages/PopupProAccount.png";

import { ThemeContext } from "../../contexts/theme-context";

function Header({ user, setBarHidden }) {
  const SCROLL_TRIGGER_INDEX = 500;

  const history = useHistory();
  const location = useLocation();
  const [click, setIsClicked] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [role, setRole] = useState("");
  const [currentUser, setCurrentUser] = useState(false);
  const [proUpgradePopup, setProUpgradePopup] = useState(false);
  const [amountOfProjects, setAmountOfProjects] = useState(0);
  const [uploadUpgradePopup, setUploadUpgradePopup] = useState(false);
  const [userId, setUserId] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(getCurrentUser() ? true : false); 
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 
  const [accountSetupPopupVisible, setAccountSetupPopupVisible] = useState(false);
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showAnnouncementBar, setShowAnnouncementBar] = useState(false);
  const [announcement, setAnnouncement] = useState([]);
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (localStorage.getItem("megosu-theme") !== null) {
      setTheme(localStorage.getItem("megosu-theme"));
    }

    const email = getCurrentUser();
    setCurrentUser(email);

    if (user) {
      setRole(user.role.name);
      setUserImage(user.profile.imageUrl);

      getProjectTitleAndCountByProfileId(user.profile.profileId).then((res) => {
        setAmountOfProjects(res[1]);
      });

      getNotifications(user.userId).then((notifications) => {
        if (notifications) {
          const notifcationIds = [];
      
          for (let i = 0; i < notifications.length; i++) {
            notifcationIds.push(notifications[i].id);
          }

          setNotifications(notifcationIds);
        }
      });

      setUserId(user.uuid);
    }

    getSiteAnnouncement().then((res) => {
      setAnnouncement(res);
    });
  }, [location, user]);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollHeader);

    if (sessionStorage.getItem("hideAnnouncementBar") !== "true") {
      setShowAnnouncementBar(true);
    } else {
      setBarHidden(true);     
      setShowAnnouncementBar(false);
    }

    return () => {
      window.removeEventListener("scroll", handleScrollHeader);
    };
  }, []);

  const handleMenuClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsClicked(!click);
  };

  function handleUpload() {
    if (amountOfProjects >= 5 && role === "free-user") {
      setUploadUpgradePopup(true);
    } else {
      history.push({
        pathname: "/upload-project",
      });
    }
  }

  const homePageScrollHandler = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      sessionStorage.removeItem("scrollPosition");
    }
  };

  const handleLogInClick = () => {
    if (window.location.pathname !== "/set-up") {
      setLoginPopupVisible(true);
    } else {
      setAccountSetupPopupVisible(true);
    }
  };

  const handleUploadClick = () => {
    if (window.location.pathname !== "/set-up") {
      setSignUpPopUpVisible(true);
    } else {
      setAccountSetupPopupVisible(true);
    }
  };

  function handleSignOut() {
    logout();
    setIsClicked(false);
    history.push({
      pathname: "/",
    });
    window.location.reload();
  }

  function handleClickProfileMenu(currentProfileTab) {
    sessionStorage.setItem("currentProfileTab", JSON.stringify({"tab": currentProfileTab, "id": userId}));
    history.push({
      pathname: "/profile/" + userId
    });
  }

  const handleScrollHeader = () => {
    if (sessionStorage.getItem("hideAnnouncementBar") === "true") {
      return;
    }

    if (window.scrollY > SCROLL_TRIGGER_INDEX) {
      setShowAnnouncementBar(false);
    } else if (window.scrollY < SCROLL_TRIGGER_INDEX) {
      setShowAnnouncementBar(true);
      setBarHidden(false);
    } 

    return;
  };

  function handleCloseNotificationBar() {
    sessionStorage.setItem("hideAnnouncementBar", true);
    setShowAnnouncementBar(false);
    setBarHidden(true);
  }

  const handleThemeChange = () => {
    const isCurrentDark = theme === "dark";
    setTheme(isCurrentDark ? "light" : "dark");
    localStorage.setItem("megosu-theme", isCurrentDark ? "light" : "dark");
  };

  return (
    <div className={!showAnnouncementBar ? "header__container header__container--hide" : "header__container"}>
      {announcement && (
        <div 
          className={"header__bar header__bar--visible"}
        >
          <p className="header__bar-text" onClick={() => { window.location.replace(announcement.url); }}>
            {announcement.title}
          </p>
          <VscClose
            className="header__bar-close-icon"
            onClick={() => { handleCloseNotificationBar(); }}
          />
        </div>
      )}
      
      <div className="header">
        <div className="header__logo-container">
          <div onClick={homePageScrollHandler}>
            <Link to="/">
              <img className="header__logo" src={megosu} alt="header-logo"/>
            </Link>
          </div>
        </div>

        <div className="header__theme-option-container">
          <label className="header__theme-option-switch">
            <input type="checkbox" className="header__theme-option-checkbox" onChange={handleThemeChange} checked={theme === "dark"}/>
            <span className="header__theme-option-slider round"></span>
          </label>
        </div>

        <div className="header__links-container">
          <ul className="header__links">
            <li className="header__links-item">
              <div onClick={homePageScrollHandler}>
                <Link to="/">
                  Explore
                </Link>
              </div>
            </li>
            <li className="header__links-item">
              <div onClick={homePageScrollHandler}>
                <Link to="/pitch">
                    Pitch
                </Link>
              </div>
            </li>
            <li className="header__links-item">
              <Link to={{ pathname: `${role ? "https://community.megosu.com/users/sign_in" : "/sign-up"}`}} target={role && "_blank"}>
                  Community
              </Link>
            </li>
            <li className="header__links-item">
              <div onClick={homePageScrollHandler}>
                <Link to="/tools">
                  Tools
                </Link>
              </div>
            </li>
            <li 
              className="header__links-item"
              onMouseEnter={() => { setShowAboutDropdown(true); }}
              onMouseLeave={() => { setShowAboutDropdown(false); }}
            >
              <div onClick={homePageScrollHandler} className="header__about-dropdown-content">
                <Link to={"/about"}>
                    About
                </Link>
              </div>
            </li>
            <div
              className={`${showAboutDropdown ? "header__about-dropdown header__about-dropdown--show" : "header__about-dropdown"}`}
              id="about-dropdown" 
              onMouseEnter={() => { setShowAboutDropdown(true); }} 
              onMouseLeave={() => { setShowAboutDropdown(false); }}
            >
              <NavLink className="header__about-dropdown-item" to="/about">About us</NavLink>
              <NavLink className="header__about-dropdown-item" to="/pricing">Pricing</NavLink>
              <NavLink className="header__about-dropdown-item" to="/success-stories/moooi">Success stories</NavLink>
              <NavLink className="header__about-dropdown-item" to="/testimonials">Testimonials</NavLink>
              <NavLink className="header__about-dropdown-item" to="/faq">FAQ</NavLink>
            </div>
            {role === "admin" && (
              <>
                <li className="header__links-item">
                  <a href="/admin">
                    Admin
                  </a>
                </li>
                <li className="header__links-item">
                  <a onClick={() => { handleSignOut(); }}>
                    Logout
                  </a>
                </li>
              </>
            )}  
          </ul>
        </div>

        <div className="header__button-group-container">
          {!currentUser ? (
            <div className="header__buttons-container">
              <div className="header__login" onClick={() => { handleLogInClick(); }}>
                Log In
              </div>
              <div className="header__registration" onClick={() => { handleUploadClick(); }}>
                Upload
              </div>
            </div>
          ) : (
            <div
              className="header__buttons-container"
              style={
                role === "admin" ? { display: "none" } : { display: "flex" }
              }
            >
              <>
                <div className="header__upload-btn">
                  <Button
                    type="black"
                    className="header__upload"
                    onClick={handleUpload}
                    title="Upload"
                  />
                </div>
              </>
            </div>
          )}
          {currentUser && role !== "admin" && (
            <>
              <div 
                onClick={homePageScrollHandler} 
                className="header__navbar-dropdown-content"
                onMouseEnter={() => { setShowProfileDropdown(true); }}
                onMouseLeave={() => { setShowProfileDropdown(false); }}
              >
                <NavLink to={userId && "/profile/" + userId} className="project-card__link" aria-label="Go to profile">
                  <div
                    className="header__user-image-container"
                    style={{
                      backgroundImage: userImage
                        ? `url(${process.env.REACT_APP_GCS_IMAGE_URL + userImage})`
                        : `url(${profilePlaceHolder})`,
                    }}
                  >
                    {notifications.length > 0 && <span className="header__user-image-notification"/>}
                  </div>
                </NavLink>
              </div>
              <div 
                className={ 
                  showProfileDropdown ? "header__profile-dropdown--show" : "header__profile-dropdown"
                }
                id="profile-dropdown" 
                onMouseEnter={() => { setShowProfileDropdown(true); }} 
                onMouseLeave={() => { setShowProfileDropdown(false); }}
              >
                <Link className="header__profile-dropdown-item" to="#" onClick={() => { handleClickProfileMenu("projectsTab"); }}>Projects</Link>
                <Link className="header__profile-dropdown-item" to="#" onClick={() => { handleClickProfileMenu("saves"); }}>Saves</Link>
                <Link className="header__profile-dropdown-item" to="#" onClick={() => { history.push({ pathname: "/chat" }); }}>
                  {notifications.length > 0 && <span className="header__profile-dropdown-chat-notification"/>}
                  Chats
                </Link>
                <Link className="header__profile-dropdown-item" to="#" onClick={() => { history.push({ pathname: "/settings" }); }}>Settings</Link>
                <Link className="header__profile-dropdown-item" to="#" onClick={() => { handleSignOut(); }}>Log out</Link>                  
              </div>
            </>
          )}
          {location.pathname !== "/payment" && (
            <div className={`header__nav-icon navbar-transition ${click && "open"}`} onClick={(e) => { handleMenuClick(e); }}>
              <span className="navbar-transition"></span>
              <span className="navbar-transition"></span>
              <span className="navbar-transition"></span>
            </div>
          )}
        </div>
      </div>

      <PopUp
        visibility={proUpgradePopup}
        handleOnClickClose={() => setProUpgradePopup(false)}
        popupType="community"
        overlay={true}
        firstButtonType="black"
        firstButtonTitle="Go Pro"
        firstButtonAction={() => { setProUpgradePopup(false); history.push("/pricing"); }}
      />

      <PopUp
        visibility={accountSetupPopupVisible}
        overlay={true}
        handleOnClickClose={() => setAccountSetupPopupVisible(false)}
        popupTitle="Finish your profile first"
        popupText="It just takes 2 minutes to fill out all the missing fields- then you can use Megosu to the fullest."
        firstButtonTitle="Complete Profile"
        firstButtonType="white"
        firstButtonAction={() => setAccountSetupPopupVisible(false)}
      />

      <PopUp
        visibility={uploadUpgradePopup}
        overlay={true}
        handleOnClickClose={() => setUploadUpgradePopup(false)}
        src={popupProImage}
        preTransitionImage={preTransitionPopupProImage}
        transition={true}
        popupTitle="Unlock more uploads!"
        popupText="Congrats! You’ve uploaded 5 projects. Go Pro to upload as many projects as you like."
        firstButtonTitle="Go Pro"
        firstButtonType="black"
        firstButtonAction={() => {
          history.push({ pathname: "/pricing" });
          setUploadUpgradePopup(false);
        }}
      />

      <PopUp 
        visibility={signUpPopUpVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => { setSignUpPopUpVisible(false); }}
        popupType="sign-up" 
      />

      <PopUp 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />

      <NavBar
        isClicked={click}
        setIsClicked={setIsClicked}
        currentUser={currentUser}
        initialRole={role}
        notificationBarVisible={showAnnouncementBar}
      />
    </div>
  );
}

export default Header;
