import React, { useEffect, useState } from "react";
import $ from "jquery";

import readmore from "../../assets/files/Read More - Megosu.pdf";

import Button from "../atoms/Button";
import Spinner from "../atoms/Spinner";

import { createCheckoutSession, createCustomerPortalSession } from "../../services/CheckoutService";
import { getCurrentUser, getCurrentUserByEmail } from "../../services/AuthService";

import "../../styles/components/molecules/SubscriptionCard.scss";

function SubscriptionCard(
  { title,
    setPlanTitle,
    setDowngradePopup,
    setSelectedPlan,
    selectedPlan,
    currentRole,
    description,
    features,
    prices,
    planDuration,
    purchasedPlan,
    paymentStatus,
    setPendingPopup,
    setSignUpPopup
  }) {

  const [isLoading, setLoading] = useState(false);
  const [paymentPending, setPaymentPending] = useState(false);

  useEffect(() => {    
    if (paymentStatus !== undefined && purchasedPlan !== undefined) {
      if (paymentStatus == "unpaid" && purchasedPlan == title) {
        setPaymentPending(true);
      }
    }
  });

  function subscribe(title, termPlan) {
    if (paymentStatus !== undefined && paymentStatus == "unpaid") {
      setPendingPopup(true);
      return;
    }
    
    setPlanTitle(title);

    getCurrentUserByEmail(getCurrentUser()).then(user => {
      if (currentRole === "free-user" && title === "free") {
        setDowngradePopup(false);
      } else if (title === "free" || currentRole === "business-user" && title === "pro") {
        setDowngradePopup(true);
      }
      else if (user.customerSubscription && user.customerSubscription.stripeSubscriptionId) {
        setLoading(true);
        createCustomerPortalSession(document.URL).then((res) =>  {
          setLoading(false);
        });
      }
      else {
        setLoading(true);
        createCheckoutSession(title, termPlan).then((res) => {
          setLoading(false);
        });
      }
    });
  }

  const changeCurrentPlanButtonText = (text) => {
    setTimeout(() => {
      $(".subscription-card__current-plan-button").text(text);
    }, 100);
  };

  return (
    <div
      className={"subscription-card " + (title === "pro" ? "subscription-card--recommended " : "") + (paymentPending && "subscription-card--pending") +
      (selectedPlan && selectedPlan.includes(title) && !paymentPending ? "subscription-card--active" : "") + (title === "free" ? " subscription-card__free-card" : "")} 
      onClick={() => { 
        if (currentRole) {
          if (title === "partners") {
            window.location.assign("mailto:partners@megosu.com");          
          } else {
            if (title !== "pitch") {
              setSelectedPlan(title); 
              subscribe(title, planDuration);
            }
          }
        } else { 
          setSignUpPopup(true);
        }
      }}>

      <div className="subscription-card__subscription-title-block">
        <h4 className={title === "pitch" ? "subscription-card__title-pro" : "subscription-card__title"}>
          {!isLoading ? title : "Forwarding..."}
          {!isLoading && title === "pitch" ? (
            <span className="subscription-card__pitch-beta-icon" onClick={() => { window.open(readmore, "_blank"); }}>Beta</span>
          ) : null}
        </h4>
        <p className={`subscription-card__description ${title === "pitch" && "subscription-card__description--no-padding-top"}`}>{description}</p>
      </div>
      {!isLoading ? (
        <>
          <div className="subscription-card__price">
            <h4>€{planDuration ? prices[1] : prices[0]}<span>/mo</span></h4>
            <p className="subscription-card__billing">{(title === "pro" || title === "pitch") && (planDuration ? "Billed Yearly" : "Billed Monthly")}</p>
          </div>
          <div 
            onMouseEnter={() => { if (title !== "pitch") { changeCurrentPlanButtonText("Manage Plan"); }}}
            onMouseLeave={() => { if (title !== "pitch") { changeCurrentPlanButtonText("Current Plan"); }}}
          >
            <Button
              title={title === "pitch" ? "Sold Out" : (paymentPending ?  "Pending" : (currentRole && currentRole.includes(title) ? "Current Plan" : "Get Started"))}
              type={title === "free" ? (paymentStatus == "unpaid" ? "black" : "white") : "black"}
              customClassName={`${paymentPending ? "subscription-card__button-pending" : (paymentStatus == "unpaid" && "subscription-card__button-disabled")} ${currentRole && currentRole.includes(title) && "subscription-card__current-plan-button"}`}
            />    
          </div>
        </>
      ) : (
        <>
          <div className="subscription-card__loading-spinner">
            <Spinner type="megosu" />
          </div>
        </>
      )}
      <ul className={title === "pro" || title === "pitch" ? "subscription-card__list subscription-card__list--business" : "subscription-card__list"}>
        {features.map((elem, i) => (
          <li key={i} className="subscription-card__list-item">
            <span>{elem}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SubscriptionCard;
