import React, { useEffect } from "react";

import "../../styles/components/atoms/Input.scss";

function Input({
  placeholder,
  label,
  id,
  required,
  name,
  setValue,
  type,
  maxLength,
  onChange,
  disabled,
  accept,
  onInput
}) {
  return (
    <div className="input__container">
      <label
        className="input__title"
        style={label ? { visibility: "visible" } : { visibility: "hidden" }}
      >
        {label}
        {required ? "*" : ""}
      </label>
      <input
        className="input"
        id={id}
        placeholder={placeholder ? placeholder : null}
        type={type}
        name={name}
        value={setValue}
        maxLength={maxLength}
        onChange={onChange ? (e) => onChange(e) : null}
        required={required && true}
        disabled={disabled}
        accept={accept ? accept : null}
        onInput={onInput ? (e) => onInput(e) : null}
      />
    </div>
  );
}

export default Input;
