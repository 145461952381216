import React from "react";
import { Link } from "react-router-dom";

import RegistrationForm from "../components/molecules/RegistrationForm";
import DocumentHead from "../components/atoms/DocumentHead";

import "../styles/pages/RegistrationPage.scss";

const RegistrationPage = () => {
  return (
    <div className="registration__container">
      <DocumentHead
        title="Sign Up | Megosu"
        metaDescription="Join Megosu now!"
      />
      <div className="registration">
        <div className="registration__form-container">
          <span className="registration__registration-link">
            Already a member? &nbsp;
            <Link className="registration__login-link" to="/login">
              Log in
            </Link>
          </span>
          <h1 className="registration__title">Sign Up</h1>
          <RegistrationForm />
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
