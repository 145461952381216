import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/atoms/Button";
import Popup from "./PopUp";
import Spinner from "../atoms/Spinner";

import { login, loginOAuth, loginVerify2fa } from "../../services/LoginService";
import { getUser } from "../../services/UserService";
import { getCurrentUser } from "../../services/AuthService";
import { getFavoritesInLocalStorage } from "../../services/FavoriteService";

import { getProvidersUrls } from "../../util/OAuth2";

import { PROVIDER, PROVIDER_ICON } from "../../constants/auth";

import "../../styles/components/molecules/LoginForm.scss";

const Login = () => {
  const [invisible, setInvisible] = useState(true);
  const [socialLoginErrorVisible, setSocialLoginErrorVisible] = useState(false);
  const [resetPopUpVisible, setResetPopUpVisible] = useState(false);
  const [ssoLoginLoader, setSsoLoginLoader] = useState(true);
  const [show2faPrompt, setShow2faPrompt] = useState(false);
  const [twoFactorAuthErrorVisible, setTwoFactorAuthErrorVisible] = useState(false);
  const history = useHistory();

  const providerUrls = getProvidersUrls();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Required.")
      .email("Please fill in a valid email (example@megosu.com)."),
    password: Yup.string()
      .required("Required.")
  });

  const { register, handleSubmit, formState } = useForm({
    defaultValues: { email: "", password: ""},
    resolver: yupResolver(loginSchema)
  });

  useEffect(() => {
    if (getCurrentUser()) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const type = params.get("type");

      if (type === "oauth2") {
        const state = params.get("state");
        const redirectUri = params.get("redirect_uri");
        const clientId = params.get("client_id");
        const responseType = params.get("response_type");

        loginOAuth(state, redirectUri, clientId, responseType);
      }
      else {
        setSsoLoginLoader(false);
        if (history.location.pathname === "/login") {
          history.push({
            pathname: "/",
          });
        } else {
          window.location.reload();
        }
      }
    }
    else {
      setSsoLoginLoader(false);
    }
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const type = params.get("type");

    if (formState.errors && Object.keys(formState.errors).length === 0) {
      // Check if login is via third-party
      if (type === "oauth2") {

        login(data.email, data.password)
          .then(async (loginResponse) => {
            const state = params.get("state");
            const redirectUri = params.get("redirect_uri");
            const clientId = params.get("client_id");
            const responseType = params.get("response_type");

            if (loginResponse.ok) {
              const token = await localStorage.getItem("currentUser");
              if (token !== null) {
                loginOAuth(
                  state,
                  redirectUri,
                  clientId,
                  responseType
                ).catch((e) => {
                  console.log("Error occured when trying to login via Megosu!" + e);
                });
              } else {
                console.log("Token is missing for Request Header");
              }
            }
          });
      } else {
        login(data.email, data.password)
          .then(async (loginResponse) => {
            await getUser(data.email)
              .then(async (user) => {
                if (loginResponse.status === 200) {
                  getFavoritesInLocalStorage(user.profile.profileId).then((res) => {
                    if (user.role.name === "admin") {
                      history.push({
                        pathname: "/admin",
                      });
                      window.location.reload();
                    } else {
                      if (history.location.pathname === "/login") {
                        history.push({
                          pathname: "/",
                        });
                      }
                      window.location.reload();
                    }
                  }).catch((e) => {
                    console.log("Error occured when retrieving user favorites.");
                  });
                } else if (loginResponse.status === 403) {
                  setSocialLoginErrorVisible(true);
                  setInvisible(true);
                } else if (loginResponse.status === 202) {
                  setShow2faPrompt(true);
                }
              })
              .catch((e) => {
                console.log("Error occured when retrieving user! " + e);
              });
          })
          .catch((error) => {
            console.log("Login failed!", error);
            setInvisible(false);
            setSocialLoginErrorVisible(false);
          });
      }
    }
  };

  function handleKeyPress2faForm(event) {
    event.preventDefault();
    
    const form = event.target.form;
    // get the current focused input
    const index = [...form].indexOf(event.target);

    if (event.key === "Backspace") {
      // if the user is using the backspace key, clear the input and focus on the next in previous input if possible
      event.target.value = "";
      
      if (index - 1 > -1) {
        form[index - 1].focus();
      }

      return;
    }

    // only accept number keys
    var validkeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    if (validkeys.indexOf(event.key) < 0) return;
    event.target.value = event.key;

    // focus on the next input after inputting the value
    form[index + 1].focus();
  }

  function handle2faCodeSubmit() {
    const values = [];
    for (let i = 1; i < 7; i++) {
      // get the values from the seperate inputs
      values.push(document.getElementsByName(`pincode-${i}`)[0].value);
    }

    // join the values together to get the entered code
    const code = values.join("");
    const loginUser = JSON.parse(sessionStorage.getItem("credentials"));

    loginVerify2fa(loginUser, code).then(async (loginResponse) => {
      // verify the 2fa code
      await getUser(loginUser.email)
        .then(async (user) => {
          if (loginResponse.status === 200) {
            // login and redirect the user if the response was 200
            getFavoritesInLocalStorage(user.profile.profileId).then((res) => {
              if (user.role.name === "admin") {
                history.push({
                  pathname: "/admin",
                });
                window.location.reload();
              } else {
                if (history.location.pathname === "/login") {
                  history.push({
                    pathname: "/",
                  });
                }
                window.location.reload();
              }
            }).catch((e) => {
              console.log("Error occured when retrieving user favorites.");
            });
          } else if (loginResponse.status === 400) {
            // display error if the code was invalid 
            setTwoFactorAuthErrorVisible(true);
            setInvisible(true);
          } 
        })
        .catch((e) => {
          console.log("Error occured when retrieving user! " + e);
        });
    });
  }

  return (
    <div className="login-form">
      {ssoLoginLoader ? <Spinner type="big" /> : (
        <>
          <div className={invisible ? "invisible error-message" : "error-message"}>
            <p>Invalid email address or password.</p>
          </div>

          <div className={socialLoginErrorVisible ? "error-message" : "invisible error-message"}>
            <p>Your email is connected with an account through<br></br>social-login, please continue with your Social Provider below</p>
          </div>

          <div className={twoFactorAuthErrorVisible ? "error-message" : "invisible error-message"}>
            <p>Invalid or Expired Code.</p>
          </div>

          {show2faPrompt ? (
            <>
              <form id="login-form__2fa-form" autoComplete="off"> 
                <h2 className="login-form__2fa-title">Two-Factor Authentication</h2>
                <div className="login-form__2fa-group login-form__2fa-pincode">
                  <label>Enter the 6-digit code from your authenticator application</label>
                  <input onKeyDown={(e) => { handleKeyPress2faForm(e); }} type="text" name="pincode-1" maxLength="1" pattern="[\d]*" tabIndex="1" placeholder="·" autoComplete="off"/>
                  <input onKeyDown={(e) => { handleKeyPress2faForm(e); }} type="text" name="pincode-2" maxLength="1" pattern="[\d]*" tabIndex="2" placeholder="·" autoComplete="off"/>
                  <input onKeyDown={(e) => { handleKeyPress2faForm(e); }} type="text" name="pincode-3" maxLength="1" pattern="[\d]*" tabIndex="3" placeholder="·" autoComplete="off"/>
                  <input onKeyDown={(e) => { handleKeyPress2faForm(e); }} type="text" name="pincode-4" maxLength="1" pattern="[\d]*" tabIndex="4" placeholder="·" autoComplete="off"/>
                  <input onKeyDown={(e) => { handleKeyPress2faForm(e); }} type="text" name="pincode-5" maxLength="1" pattern="[\d]*" tabIndex="5" placeholder="·" autoComplete="off"/>
                  <input onKeyDown={(e) => { handleKeyPress2faForm(e); }} type="text" name="pincode-6" maxLength="1" pattern="[\d]*" tabIndex="6" placeholder="·" autoComplete="off"/>
                </div>
                <div className="login-form__2fa-buttons">
                  <Button
                    type="black"
                    title={"Continue"}
                    onClick={(e) => { e.preventDefault(); handle2faCodeSubmit(); }}
                  />
                </div>
              </form>
            </>
          ) : (
            <>
              <form className="login-form__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="login-form__input-container">
                  <div className="login-form__label-container">
                    <label className="login-form__label">Email</label>
                    {formState.errors && formState.errors?.email && (
                      <div className="form-error">{formState.errors.email.message}</div>
                    )}
                  </div>
                  <input
                    className={
                      formState.errors && formState.errors?.email
                        ? "input-error login-form__input"
                        : "login-form__input"
                    }
                    name="email"
                    {...register("email")}
                  />
                </div>

                <div className="login-form__input-container">
                  <div className="login-form__label-container">
                    <label className="login-form__label">Password</label>
                    {formState.errors && formState.errors?.password && (
                      <div className="form-error">{formState.errors.password.message}</div>
                    )}
                  </div>
                  <input
                    className={
                      formState.errors && formState.errors?.password
                        ? "input-error login-form-input"
                        : "login-form__input"
                    }
                    name="password"
                    type="password"
                    {...register("password")}
                  />
                </div>
    
                <div>
                  <span className="login-form__reset-link">
                    <a onClick={() => setResetPopUpVisible(true)}>Forgot password?</a>
                  </span>
                </div>

                <div className="login-form__button-container">
                  <Button type="pink" title="Log In" customClassName="button--pink-form"/>
                </div>
              </form>

              <h3 className="login-form__social-login"><span>Or continue with</span></h3>
              <div className="login-form__social-grid-container">
                {Object.keys(PROVIDER).map((provider, i) => (
                  <a key={i} onClick={() => {window.location.replace(`${providerUrls[provider]}`);}}>
                    <img src={PROVIDER_ICON[provider]}></img>
                  </a>
                ))}
              </div>
            </>
          )}

          <Popup
            visibility={resetPopUpVisible}
            overlay={true}
            handleOnClickClose={() => setResetPopUpVisible(false)}
            popupType="reset-password"
          />
        </>
      )}
    </div> 
  );
};

export default Login;
