import { useHistory } from "react-router-dom";

import "../../styles/components/molecules/FileCard.scss";

function FileCard({
  file,
  currentUser,
  setUpgradePopup,
  setSignUpPopup
}) {
  const history = useHistory();

  function openFile() {
    if (!currentUser) {
      setSignUpPopup(true);
      return;
    }

    if (file.availability === "Pro") {
      if (currentUser.role.name !== "pro-user" && currentUser.role.name !== "pitch-user") {
        setUpgradePopup(true);
        return;
      }
    }

    if (file.availability === "Pitch") {
      if (currentUser.role.name !== "pitch-user") {
        setUpgradePopup(true);
        return;
      }
    }

    if (file.fileUrl === null) {
      window.open(file.webUrl, "_blank");
    } else {
      window.open(file.fileUrl, "_blank");
    }
  }
  
  return (
    <div className="file-card">
      <div className="file-card__content-container" onClick={() => { openFile(); }}>
        <div className="file-card__img-container">
          <div className="file-card__img">
            <img src={file.firstCoverUrl} className="file-card__img"/>
          </div>
          <div className="file-card__img-hover">
            <img src={file.secondCoverUrl} className="file-card__img-hover"/>
          </div>
        </div>
      </div>
        
      <div className="file-card__title-container">
        <h5 onClick={() => { openFile(); }} className="file-card__title">{file.title}<br/></h5>
        {file.availability !== "Free" && (
          <h6 className="file-card__boosted" onClick={() => { history.push("/pricing"); }}>{file.availability}</h6>
        )}
      </div>
    </div>
  );
}

export default FileCard;
