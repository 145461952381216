import React, { useEffect } from "react";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import PropTypes from "prop-types";

import "../../styles/components/atoms/Spinner.scss";
function LoadingSpinner({ loadingMessage, type }) {
  return (
    <div className="spinner__container">
      {type === "big" && <AiOutlineLoading3Quarters className="spinner__icon" />}
      {type === "small" && <AiOutlineLoading3Quarters className="spinner__icon--small" />}
      {type === "megosu" && (
        //Todo Animate svg from imported image instead of inline svg
        <svg
          className="spinner__icon--megosu"
          id="Laag_1"
          data-name="Laag 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 173.54 192.2"
        >
          <path
            className="cls-1"
            d="M225,309.74c18.7-11,38.5,2.2,67.1-12.1-28.6-14.3-48.4-1.1-67.1-12.1-12.65-7.15-15.95-23.1-9.35-35.2,7.15-12.1,22.55-17.05,35.2-9.35,18.7,10.45,17.05,33.55,44,51.7-2.2-31.9-23.1-42.35-23.1-63.8a25.85,25.85,0,0,1,51.7,0c0,21.45-20.9,31.9-23.1,63.8,26.95-17.6,25.3-41.25,44-51.7,12.65-7.7,28.05-2.75,35.2,9.35,6.6,12.1,3.3,28-9.35,35.2-18.7,11-38.5-2.2-66.55,12.1,28.05,14.3,47.85,1.1,66.55,12.1,12.65,7.15,15.95,23.1,9.35,35.2C372.44,357,357,362,344.39,354.29c-18.7-10.45-17.05-34.1-44-51.7,2.2,31.9,23.1,42.35,23.1,63.8a25.85,25.85,0,0,1-51.7,0c0-21.45,20.9-31.9,23.1-63.8-26.95,18.15-25.3,41.25-44,51.7-12.65,7.7-28,2.75-35.2-9.35C209.09,332.84,212.39,316.89,225,309.74Z"
            transform="translate(-210.87 -201.54)"
          />
        </svg>
      )}
      {loadingMessage ? <h4 className="spinner__loading-message">{loadingMessage}</h4> : null}
    </div>
  );
}

LoadingSpinner.propTypes = {
  loadingMessage: PropTypes.string,
  type: PropTypes.string,
};

export default LoadingSpinner;
