import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { BsBookmark } from "@react-icons/all-files/bs/BsBookmark";
import { BsBookmarkFill } from "@react-icons/all-files/bs/BsBookmarkFill";

import Button from "../atoms/Button";
import HorizontalRule from "../atoms/HorizontalRule";
import PopUp from "./PopUp";
import UserDetailCard from "./UserDetailCard";

import {
  getFavoriteProjectsByProfileId,
  getFavoritesByProfileId,
  getFavoritesCountByProjectId,
  removeFavorite,
  saveFavorite,
} from "../../services/FavoriteService";
import { getChats } from "../../services/ChatService";
import { getFavoriteUsersByProjectId } from "../../services/FavoriteService";
import { getCurrentUser } from "../../services/AuthService";
import { getProjectById, getProjectDetailsById } from "../../services/ProjectService";

import popupProImage from "../../../src/assets/images/popupImages/transitions/popupTransitionPro.m4v";
import popupFreeImage from "../../../src/assets/images/popupImages/transitions/popupTransitionFree.m4v";
import preTransitionPopupFreeImage from "../../../src/assets/images/popupImages/PopupFreeAccount.png";
import preTransitionPopupProImage from "../../../src/assets/images/popupImages/PopupProAccount.png";

import "../../styles/components/molecules/ProjectDetailsCard.scss";

function ProjectDetailsCard({
  teamMembers,
  teamMembersLink,
  projectUploader,
  currentUserIsProjectOwner,
  user,
  profileObject,
  primaryProjectImage,
  projectObject
}) {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();
  const [role, setRole] = useState("");
  const [profile] = useState(profileObject);
  const [projectDetails, setProjectDetails] = useState({});
  const [project] = useState(projectObject); 
  const [projectTags, setProjectTags] = useState([]);
  const [favorite, setFavorite] = useState(false);
  const [favoriteCounts, setFavoriteCounts] = useState([]);
  const [contactPopUpVisible, setContactPopUpVisible] = useState(false);
  const [upgradePopUpVisible, setUpgradePopUpVisible] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [loginPopUpVisible, setLoginPopUpVisible] = useState(false);
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 
  const [chatExists, setChatExists] = useState(false);
  const [goToChat, setGoToChat] = useState();
  const [savedProjectUsers, setSavedProjectUsers] = useState([]);
  const [savedProjectUsersLoading, setSavedProjectUsersLoading] = useState(false);
  const [projectCardPopUpVisible, setProjectCardPopUpVisible] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(getCurrentUser() ? true : false); 
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (user) {
      setCurrentUser(user);
      getFavoritesByProfileId(user.profile.profileId).then((res) => {
        getUserFavorites(res);
      });

      setRole(user.role.name);
      getChats(user.userId).then((chats) => {
        for (const chat of chats) {
          if (chat.senderId == user.userId && chat.recipientId == profile.user.userId) {
            setChatExists(true);
            setGoToChat(chat.chatRoomId);
            return;
          }
        }
      });
    }

    setUserImage(projectUploader.imageUrl);
    getProjectDetailsById(project.uuid).then((projectDetails) => { 
      setProjectDetails(projectDetails);
      getProjectTags(projectDetails);
  
      getFavoritesCountByProjectId(project.projectId).then((projectGroups) => {
        setFavoriteCounts(projectGroups);
      });
      setLoading(false);
    });
  }, [user]);

  const getFavoriteUsers = () => {
    setSavedProjectUsersLoading(true);

    getFavoriteUsersByProjectId(project.projectId).then((users) => {
      if (user.role.name === "free-user") {
        users.slice(0, 3);
      }

      setSavedProjectUsers(users);
      setSavedProjectUsersLoading(false);
    });
  };

  const getUserFavorites = (favoritesList) => {
    if (favoritesList && favoritesList.find((elem) => elem.id.projectId === project.projectId)) {
      setFavorite(true);
    } else {
      setFavorite(false);
    }
  };

  function getProjectTags(project) {
    const categories = ["material", "product", "production", "space", "topic"];

    const projectTags = [];

    categories.forEach((category) => {
      project[category].map(function (elem) {
        return (elem.category = category);
      });
      projectTags.push(project[category]);
    });

    const tagsString = projectTags.reduce((acc, val) => acc.concat(val), []);
    const tempTagsHolder = [];

    tagsString.forEach((element) => {
      if (element) {
        tempTagsHolder.push({
          name: element["name"],
          category: element["category"],
        });
      }
    });
    if (tempTagsHolder.length > 1) {
      tempTagsHolder.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
    setProjectTags(tempTagsHolder);
  }

  function storeFavorites(projectId) {
    let favoriteList = [];
    const favoriteItems = localStorage.getItem("favorites");

    // Use list from local storage if it exists
    if (favoriteItems != null) {
      favoriteList = JSON.parse(favoriteItems);
    }

    // Add new favorite project to list and local storage
    if (favoriteList.indexOf(projectId) === -1) {
      favoriteList.push(projectId);
    } else {
      favoriteList.splice(favoriteList.indexOf(projectId), 1);
    }

    localStorage.setItem("favorites", JSON.stringify(favoriteList));
  }

  function handleBookmarkClick() {
    if (currentUser === undefined) {
      setLoginPopUpVisible(true);
    } else if (favorite) {
      setFavorite(false);

      if (currentUser.userId) {
        removeFavorite(currentUser.userId, project.projectId).then(() => {
          console.log("Removed favorite");
        });

        if (favoriteCounts.length === 0) {
          setFavoriteCounts("");
        } else {
          setFavoriteCounts(parseInt(favoriteCounts) - 1);
        }
      }
    } else {
      setFavorite(true);

      if (currentUser.userId) {
        saveFavorite(currentUser.userId, project.projectId).then(() => {
          console.log("Saved favorite");
        });

        if (favoriteCounts === undefined || favoriteCounts.length === 0) {
          setFavoriteCounts(1);
        } else {
          setFavoriteCounts(parseInt(favoriteCounts) + 1);
        }
      }
    }
  }

  const handleEditProject = () => {
    getProjectById(project.uuid).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          history.push({
            pathname: "/edit-project/" + project.uuid,
            state: data,
          });
        });
      }
    });
  };

  const uploaderData = {
    designerId: projectUploader.user.userId,
    designerFirstName: projectUploader.firstName,
    designerLastName: projectUploader.lastName,
    designerMail: projectUploader.user.email,
    designerAvatar: projectUploader.imageUrl
      ? process.env.REACT_APP_GCS_IMAGE_URL + projectUploader.imageUrl
      : null,
    projectName: projectDetails.title,
    projectImage: process.env.REACT_APP_GCS_IMAGE_URL + primaryProjectImage,
    currentPage: "projectPage",
  };


  //TODO Make classes of the styling that is being changed
  let lastWindowTop;

  document.addEventListener("scroll", () => {
    if (!document.querySelector(".project-details-card")) {
      return;
    }
    const projectCardSelector = document.querySelector(".project-details-card");
    const windowTop = window.scrollY;
    const windowHeight = window.innerHeight;

    const projectCardTop = projectCardSelector.offsetTop;
    const projectCardHeight = projectCardSelector.offsetHeight;
    
    if (windowHeight <= projectCardHeight) {
      // Only needed for screens smaller than the details
      if (windowTop < lastWindowTop) {
        // Scrolling up
        if (windowTop <= projectCardTop) {
          // Window has passed top of card
          projectCardSelector.style.position = "sticky";
          projectCardSelector.style.top = "0";
          projectCardSelector.style.marginTop = "unset";
          projectCardSelector.style.bottom = "unset";
        } else {
          projectCardSelector.style.position = "relative";
        }
      } else {
        const windowBottom = windowTop + windowHeight;
        const projectCardBottom = projectCardTop + projectCardHeight;
        // Scrolling down
        projectCardSelector.style.bottom = "0";
        projectCardSelector.style.top = "unset";

        if (projectCardTop <= windowTop) {
          // Scrolling down before scrolled to top of element
          projectCardSelector.style.position = "sticky";
        }
        if (windowBottom >= projectCardBottom) {
          // Bottom of window has passed bottom of card
          projectCardSelector.style.marginTop = "auto";
        } else {
          projectCardSelector.style.marginTop = (projectCardTop - 62) + "px";
        }
      }
    }

    lastWindowTop = windowTop;
  });

  function handleContactClick() {
    if (currentUser === undefined) {
      setSignUpPopUpVisible(true);
    } else {
      if (chatExists) {
        sessionStorage.setItem("goToChat", goToChat);
        history.push("/chat");
      } else {
        setContactPopUpVisible(true);
      }
    }
  }

  const handleFavoriteClick = () => {
    if (userIsLoggedIn) {
      handleBookmarkClick();
      storeFavorites(projectDetails.projectId);
    } else {
      setSignUpPopUpVisible(true);
    }
  };

  return (
    <div className="project-details-card">
      <div className="project-details-card__container">
        <div className="project-details-card__info-container">
          <div className="project-details-card__subinfo-container">
            <h6 className="project-details-card__title">
              {project.title}&nbsp;<span className="project-details-card__designYear">{project.designYear}</span>
            </h6>
            <span className="project-details-card__sub-title">
              {project.marketAvailability
                ? "Available for market"
                : "Unavailable for market"}
            </span>
          </div>

          <div className="project-details-card__save-btn-container">
            <span
              className={
                "project-details-card__save-count project-card__save-count-" +
                project.projectId
              }
              onClick={() => {
                if (project && user) {
                  if (profile.profileId === user.profile.profileId) {
                    setProjectCardPopUpVisible(true); 
                    getFavoriteUsers();
                  }
                }
              }}
              style={project && user && user !== undefined && (profile.profileId === user.profile.profileId) ?
                {fontWeight: "400", cursor: "pointer"} : {fontWeight: "300"}}
            >
              {favoriteCounts}
            </span>
            <div
              className={
                "project-details-card__bookmark--filled project-card__bookmark-" +
                project.projectId +
                "--filled"
              }
              onClick={() => { handleFavoriteClick(); }}
              style={favorite ? { display: "flex" } : { display: "none" }}
            >
              <BsBookmarkFill className="bookmark-filled-icon" />
            </div>
            <div
              className={
                "project-details-card__bookmark project-card__bookmark-" +
                project.projectId
              }
              onClick={() => { handleFavoriteClick(); }}
              style={favorite ? { display: "none" } : { display: "flex" }}
            >
              <BsBookmark />
            </div>
          </div>
        </div>
        
        {!loading && (
          <>
            {projectDetails.deliverables && projectDetails.deliverables.length > 0 &&
            <div className="project-details-card__project-info-container">
              <h6 className="project-details-card__sub-title">Deliverables</h6>
              <span className="project-details-card__info-description">
                {projectDetails.deliverables.map((e, i) => (
                  <>
                    {i === (projectDetails.deliverables.length - 1) ? (
                      e.name + ". "
                    ) : (
                      e.name + ", "
                    )}
                  </>
                ))}
              </span>
            </div>
            }
  
            <div className="project-details-card__info-container project-details-card__metrics">
              {(project.length !== 0 || project.width !== 0 || project.height !== 0) && (
                <div className="project-details-card__project-info-container">
                  <h6 className="project-details-card__sub-title">Size</h6>
                  <span className="project-details-card__info-description">
                    {project.length + " x " + project.width + " x " + project.height + " cm"}
                  </span>
                </div>
              )}
  
  
              {project.weight !== 0 && (
                <div className="project-details-card__project-info-container">
                  <h6 className="project-details-card__sub-title">Weight</h6>
                  <span className="project-details-card__info-description">
                    {project.weight + " kg"}
                  </span>
                </div>
              )}
            </div>
  
            {teamMembersLink.length > 0 && (
              <div className="project-details-card__project-info-container">
                <h6 className="project-details-card__sub-title">Team member(s)</h6>
                {teamMembers.map((member, i) => (
                  <span className="project-details-card__info-description" key={i}>
                    <span 
                      className={`${teamMembersLink[i] != "" && "project-details-card__info-description--link"}`}
                      onClick={() => (teamMembersLink[i] != "") && history.push("/profile/" + teamMembersLink[i])}
                    >
                      {member}
                    </span>
                    {(i < teamMembers.length - 1) && ", "}
                  </span>
                ))}
              </div>
            )}

            <div className="project-details-card__project-info-container">
              <h6 className="project-details-card__sub-title">Tags</h6>
              <div className="tag-element">
                {Object.values(projectTags).map((tag, idx) => {
                  return (
                    <Button
                      key={`btn-${tag}-${idx}`}
                      title={
                        tag.name === "Other"
                          ? tag.name + " " + tag.category + "s"
                          : tag.name
                      }
                      type="outline"
                      onClick={() =>
                        history.push({
                          pathname: "/",
                          state: { tag: tag.name, filterCategory: tag.category },
                        })
                      }
                    />
                  );
                })}
              </div>
            </div>
          </>        
        )}
        <HorizontalRule />
        {(projectUploader) && (
          <UserDetailCard
            name={profile.firstName + " " + profile.lastName}
            city={profile.city}
            country={profile.country.name}
            profileImage={userImage}
            role={profile.user.role.name}
            uuid={profile.user.uuid}
          />
        )}
        <div className="project-details-card__button-container">
          <>
            {projectUploader && 
            projectUploader.user.role.name && 
            (projectUploader.user.role.name === "pro-user" || projectUploader.user.role.name === "business-user" || projectUploader.user.role.name === "pitch-user") 
            && project.marketUrl && (
              <Button
                title="Buy ↗"
                type="pink"
                onClick={() => { window.open(project.marketUrl, "_blank"); }}
              />
            )}
            {currentUserIsProjectOwner ? (
              <>
                <Button
                  title="Pitch"
                  type="black"
                  onClick={() => history.push({ pathname: "/pitch" })}
                />
                <Button
                  title="Edit Project"
                  type="white"
                  onClick={() => { handleEditProject(); }} 
                />
              </>
            ) :
              <Button
                title="Contact"
                type="black"
                onClick={() => { userIsLoggedIn ? handleContactClick() : setSignUpPopUpVisible(true); }}
              />
            }
          </>
        </div>

        <PopUp
          visibility={projectCardPopUpVisible}
          overlay={true}
          handleOnClickClose={() => setProjectCardPopUpVisible(false)}
          popupType="showSavedProjectsUsers"
          popupTitle="People who saved your project"
          firstButtonTitle="Go Pro"
          firstButtonType="black"
          firstButtonAction={() => history.push({ pathname: "/pricing" })}
          role={role}
          savedProjectUsers={savedProjectUsers}
          savedProjectUsersLoading={savedProjectUsersLoading}
        />
      
        <PopUp
          visibility={contactPopUpVisible}
          handleOnClickClose={() => setContactPopUpVisible(false)}
          overlay={true}
          popupType="contact"
          props={uploaderData}
          firstButtonAction={() => { setChatExists(true); }}
        />

        <PopUp
          visibility={upgradePopUpVisible}
          overlay={true}
          handleOnClickClose={() => setUpgradePopUpVisible(false)}
          src={popupProImage}
          preTransitionImage={preTransitionPopupProImage}
          transition={true}
          popupTitle="Unlock this feature!"
          popupText="Projects are boosted, ask feedback, access to the private community & get in touch with clients."
          firstButtonTitle="Go Pro"
          firstButtonType="black"
          firstButtonAction={() => history.push({ pathname: "/pricing" })}
        />

        <PopUp
          visibility={loginPopUpVisible}
          overlay={true}
          handleOnClickClose={() => setLoginPopUpVisible(false)}
          src={popupFreeImage}
          preTransitionImage={preTransitionPopupFreeImage}
          transition={true}
          popupTitle="Start your account \n to save this project."
          firstButtonTitle="Sign up for free"
          firstButtonType="black"
          firstButtonAction={() => setSignUpPopUpVisible(true) }
        />

        <PopUp 
          visibility={signUpPopUpVisible}
          overlay={true}
          firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
          handleOnClickClose={() => setSignUpPopUpVisible(false) }
          popupType="sign-up" 
        />

        <PopUp 
          visibility={loginPopupVisible}
          overlay={true}
          firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
          handleOnClickClose={() => { setLoginPopupVisible(false); }}
          popupType="login" 
        />
      </div>
    </div>
  );
}

export default ProjectDetailsCard;
