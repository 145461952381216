import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import ProjectCard from "../components/molecules/ProjectCard";
import Spinner from "../components/atoms/Spinner";
import DocumentHead from "../components/atoms/DocumentHead";
import AdminHeaderForm from "../components/molecules/AdminHeaderForm";
import AdminOverviewForm from "../components/molecules/AdminOverviewForm";
import AdminCompanyForm from "../components/molecules/AdminCompanyForm";
import AdminFileForm from "../components/molecules/AdminFileForm";
import AdminAdvertisementForm from "../components/molecules/AdminAdvertisementForm";
import AdminStatisticsOverview from "../components/molecules/AdminStatisticsOverview";
import AdminUniversityForm from "../components/molecules/AdminUniversityForm";
import Button from "../components/atoms/Button";

import { getUnverifiedProjects } from "../services/AdminService";
import { deleteSiteAnnouncement, deleteToolCategory, getSiteAnnouncement, getUniversities, saveSiteAnnouncement, saveToolCategory, saveUniversity, saveUniversityList } from "../services/SiteService";
import { getHeaders } from "../services/HeaderService";

import scrollTrigger from "../util/ScrollReveal";

import "../styles/pages/AdminPage.scss";

const listItems = ["Statistics", "Projects", "Headers Logged In", "Headers Logged Out", "Companies", "Notification Bar", "Tools", "Banner Card", "Universities"];

const initialNotificationBarValues = {
  title: "",
  url: ""
};

const toolCategoryValues = {
  name: "",
};

const AdminPage = ({ user }) => {
  const PROJECTS_PER_PAGE = 20;
  const [projects, setProjects] = useState(null);
  const [items, setItems] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [indexOfFirstProject, setIndexOfFirstProject] = useState(0);
  const [indexOfLastProject, setIndexOfLastProject] = useState(PROJECTS_PER_PAGE);
  const [currentTab, setCurrentTab] = useState("Statistics");
  const HEADERS = [1, 2, 3, 4, 5, 6];
  const [headerLoggedInItems, setHeaderLoggedInItems] = useState([]);
  const [headerLoggedOutItems, setHeaderLoggedOutItems] = useState([]);
  const [notificationBarValues, setNotificationBarValues] = useState([]);
  const [currentToolCategoryValues, setCurrentToolCategoryValues] = useState(null);
  const [editingNotificationBar, setEditingNotificationBar] = useState(false);

  const [currentCompany, setCurrentCompany] = useState(null);
  const [companyFormIsOpen, setCompanyFormIsOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [fileFormIsOpen, setFileFormIsOpen] = useState(false);
  const [currentToolCategory, setCurrentToolCategory] = useState(null);
  const [toolCategoryFormIsOpen, setToolCategoryFormIsOpen] = useState(false);
  const [currentAdvertisement, setCurrentAdvertisement] = useState(null);
  const [advertisementFormIsOpen, setAdvertisementFormIsOpen] = useState(false);
  const [advertisementPositions, setAdvertisementPositions] = useState([]);
  const [currentUniversity, setCurrentUniversity] = useState(null);
  const [universityFormIsOpen, setUniversityFormIsOpen] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("adminTab") !== null) {     
      setCurrentTab(sessionStorage.getItem("adminTab"));
      // Remove adminTab afterwards to avoid going to "Companies" tab every time
      sessionStorage.removeItem("adminTab");
    }
  }, []);

  useEffect(() => {
    sessionStorage.clear();

    if (user) {
      setCurrentUser(user);
      getUnverifiedProjects().then((projectsList) => {
        setProjects(projectsList);
        setItems(projectsList.slice(indexOfFirstProject, indexOfLastProject));
      });

      getHeaders().then((res) => {
        const listLoggedIn = res.filter(p => p.type === "loggedIn");
        const listLoggedOut = res.filter(p => p.type === "loggedOut");
        setHeaderLoggedInItems(listLoggedIn);
        setHeaderLoggedOutItems(listLoggedOut);
      });
    }
  }, [user]);

  useEffect(() => {
    if (projects) {
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, 500);
    }
  }, [projects, items]);

  useEffect(() => {
    if (currentTab == "Notification Bar") {
      getSiteAnnouncement().then((res) => {
        if (res == null) {
          setNotificationBarValues(initialNotificationBarValues);
        } else {
          setNotificationBarValues(res);
        }
      });
    }
  }, [currentTab]);

  // Toggle between list items
  function handleOnClick(tabName) {
    setCurrentTab(tabName);
    setTimeout(() => { scrollTrigger(".reveal"); }, [200]);
  }

  function fetchMoreData() {
    const lastIndex = indexOfLastProject + PROJECTS_PER_PAGE;
    const firstIndex = lastIndex - PROJECTS_PER_PAGE;
    let getNextProjects;

    if (lastIndex > projects.length) {
      getNextProjects = projects.slice(firstIndex, projects.length);
    } else {
      getNextProjects = projects.slice(firstIndex, lastIndex);
    }

    setIndexOfFirstProject(firstIndex);
    setIndexOfLastProject(lastIndex);
    setItems(items.concat(getNextProjects));
  }

  function getNextProjectUrls(i) {
    try {
      const urls = [];
      for (let j=i; j < items.length - 1; j++) {
        const projectUrl = "/project-details/" + items[j + 1].projectId;
        urls.push(projectUrl);
      }

      if(urls.length === 0) {
        urls.push("/admin");
      }

      sessionStorage.setItem("nextAdminProjects", JSON.stringify(urls));
    } catch (e) {
      sessionStorage.setItem("nextAdminProjects", "/admin");
      console.log(e);
    }
  }

  function storeFavorites(projectId) {
    const favoriteItems = localStorage.getItem("favorites");

    // Use list from local storage if it exists
    if (favoriteItems != null) {
      favoriteList = JSON.parse(favoriteItems);
    }

    // Add new favorite project to list and local storage
    if (favoriteList.indexOf(projectId) === -1) {
      favoriteList.push(projectId);
    } else {
      favoriteList.splice(favoriteList.indexOf(projectId), 1);
    }

    localStorage.setItem("favorites", JSON.stringify(favoriteList));
  }

  function handleOnChangeNotificationBar(e) {
    setEditingNotificationBar(true);
    const { name, value } = e.target;

    switch (name) {
      default:
        setNotificationBarValues({
          ...notificationBarValues,
          [name]: value,
        });
        break;
    }
  }

  function saveNotificationBar() {
    saveSiteAnnouncement(notificationBarValues).then((res) => {
      alert("Announcement saved and will be displayed from now on.");
      setNotificationBarValues(res);
    });
  }

  function deleteNotificationBar() {
    deleteSiteAnnouncement(notificationBarValues.id).then((res) => {
      alert("Announcement was deleted and won't be shown anymore.");
      setNotificationBarValues(initialNotificationBarValues);
    });
  }

  function processSaveToolCategory() {
    if (currentToolCategory !== null) {
      currentToolCategory.name = currentToolCategoryValues;

      saveToolCategory(currentToolCategory).then((res) => {
        alert("[OK] - Tool Category was saved.");
      });
    } else {
      saveToolCategory({name: currentToolCategoryValues}).then((res) => {
        alert("[OK] - Tool Category was saved.");
      });
    }
  }

  function processDeleteToolCategory() {
    deleteToolCategory(currentToolCategory.toolId).then((res) => {
      if (res.ok) {
        alert("[OK] - Category was deleted.");
      } else {
        alert("[ERR] - Something went wrong whilst trying to delete this category.");
      }
    });
  }

  return (
    <div className="admin">
      <DocumentHead
        title={"Admin Panel | Megosu"}
        metaDescription=""
      />
      <ul className="admin__list">
        <div className="admin__list-container">
          {listItems.map((elem, i) => (
            <li
              key={i}
              className={`admin__list-item ${currentTab === elem && "admin__list-item--active"} admin__list-item-` + i}
              onClick={(e) => { handleOnClick(elem); }}
            >
              <a>{elem}</a>
            </li>
          ))}
        </div>
      </ul>

      {currentTab == "Statistics" ? (
        <div className="admin__main-content reveal">
          {currentUser && (
            <AdminStatisticsOverview currentUser={currentUser} changeTab={handleOnClick}></AdminStatisticsOverview>
          )}
        </div>
      ) : null}

      {currentTab == "Projects" ? (
        <>
          <div className="admin__main-content">
            {projects && items ? (
              <>
                <InfiniteScroll
                  dataLength={items.length}
                  next={fetchMoreData}
                  hasMore={items.length < projects.length}
                  style={{
                    padding: "0 3rem",
                    overflowY: "hidden",
                    display: "grid",
                  }}
                >
                  <div className="admin__list-content">
                    {items.map((elem, i) => (
                      <div key={i} onClick={() => { getNextProjectUrls(i);}}>
                        <ProjectCard
                          key={i}
                          title={elem.title}
                          parent="admin"
                          index={i}
                          boosted={elem.boosted}
                          imageUrl={elem.imageUrl}
                          userImageUrl={elem.userImageUrl}
                          designerName={elem.designerName}
                          projectId={elem.projectId}
                          onClick={(projectId) => storeFavorites(projectId)}
                          uuid={elem.uuid}
                          user={currentUser}
                          favoriteCount={elem.favoriteCount}
                          ownerProfileId={elem.userUUID}
                        />
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              </>
            ) : (
              <Spinner type="megosu" />
            )}
          </div>
        </>
      ) : null}
      {currentTab == "Headers Logged In" ? (
        <div className="admin__main-content reveal">
          <h2>Header Logged In Configuration</h2>
          <>
            {headerLoggedInItems && (
              <>
                {Object.values(HEADERS).map((elem, i) => (
                  <div key={i}>
                    <AdminHeaderForm number={elem} header={headerLoggedInItems[i]} type="loggedIn"/>
                  </div>
                ))}
              </>          
            )}
          </>
        </div>

    
      ): null}

      {currentTab == "Headers Logged Out" ? (
        <div className="admin__main-content reveal">
          <h2>Headers Logged Out Configuration</h2>
          <>
            {headerLoggedOutItems && (
              <>
                {Object.values(HEADERS).map((elem, i) => (
                  <div key={i}>
                    <AdminHeaderForm number={elem} header={headerLoggedOutItems[i]} type="loggedOut"/>
                  </div>
                ))}
              </>          
            )}
          </>
        </div>
      ) : null}

      {currentTab == "Companies" ? (
        <div className="admin__main-content reveal">
          {!companyFormIsOpen ? (
            <AdminOverviewForm 
              type="Company"
              title="Company Database"
              addButtonTitle="Company"
              setSelection={setCurrentCompany} 
              setIsDetailOpen={setCompanyFormIsOpen}
            />
          ) : (
            <>
              <Button customClassName={"admin__back-button"} title="&#8592; Back" type="white" onClick={() => { setCompanyFormIsOpen(false); }}/>
              <AdminCompanyForm currentCompany={currentCompany}/>
            </>
          )}
        </div>
      ) : null}

      {currentTab == "Banner Card" ? (
        <div className="admin__main-content reveal">
          {!advertisementFormIsOpen ? (
            <AdminOverviewForm 
              type="Advertisements"
              title="Banner Cards"
              addButtonTitle="Banner"
              setSelection={setCurrentAdvertisement} 
              setIsDetailOpen={setAdvertisementFormIsOpen}
              setAdvertisementPositions={setAdvertisementPositions}
            />
          ) : (
            <>
              <Button customClassName={"admin__back-button"} title="&#8592; Back" type="white" onClick={() => { setAdvertisementFormIsOpen(false); setCurrentAdvertisement(null); }}/>
              <AdminAdvertisementForm currentAdvertisement={currentAdvertisement} advertisementPositions={advertisementPositions}/>
            </>
          )}
        </div>
      ) : null}

      {currentTab == "Notification Bar" ? (
        <div className="admin__main-content reveal">
          <h2>Notification Bar</h2>

          <div className="admin__notification-bar-container">
            <label>Title</label>
            <input 
              name="title"
              required={true} 
              placeholder="Title" 
              type="text"
              defaultValue={notificationBarValues.title}
              onChange={(e) => handleOnChangeNotificationBar(e)}
            />
          </div>

          <div className="admin__notification-bar-container">
            <label>URL</label>
            <input
              name="url"
              required={true} 
              placeholder="URL" 
              type="url"
              defaultValue={notificationBarValues.url}
              onChange={(e) => handleOnChangeNotificationBar(e)}
            />
          </div>

          <div className="admin__notification-bar-buttons">
            <Button onClick={() => { saveNotificationBar(); }} type="pink" title="Save"/>
            <Button onClick={() => { deleteNotificationBar(); }} type="white" title="Delete"/>
          </div>
        </div>
      ) : null}

      {currentTab == "Tools" ? (
        <div className="admin__main-content reveal">
          {!fileFormIsOpen && !toolCategoryFormIsOpen ? (
            <>
              <AdminOverviewForm 
                type="ToolCategories"
                title="Tools Categories"
                addButtonTitle="Category"
                setSelection={setCurrentToolCategory} 
                setIsDetailOpen={setToolCategoryFormIsOpen}
              />
              
              <AdminOverviewForm 
                type="Files"
                title="Tools Database"
                addButtonTitle="Tool"
                setSelection={setCurrentFile} 
                setIsDetailOpen={setFileFormIsOpen}
              />
            </>
          ) : (
            <>
              {fileFormIsOpen ? (
                <>
                  <Button customClassName={"admin__back-button"} title="&#8592; Back" type="white" onClick={() => { setFileFormIsOpen(false); setCurrentFile(null); }}/>
                  <AdminFileForm currentFile={currentFile}/>
                </>
              ) : (
                <>
                  <Button customClassName={"admin__back-button"} title="&#8592; Back" type="white" onClick={() => { setToolCategoryFormIsOpen(false); setCurrentToolCategory(null); }}/>

                  <div className="admin__main-content">
                    <h2>Tool Category</h2>

                    <div className="admin__notification-bar-container">
                      <label>Name</label>
                      <input 
                        name="name"
                        required={true} 
                        placeholder="Name" 
                        type="text"
                        defaultValue={currentToolCategory && currentToolCategory.name}
                        onChange={(e) => setCurrentToolCategoryValues(e.target.value)}
                      />
                    </div>

                    <div className="admin__notification-bar-buttons">
                      <Button onClick={() => { processSaveToolCategory(); }} type="pink" title="Save"/>
                      <Button onClick={() => { processDeleteToolCategory(); }} type="white" title="Delete"/>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      ) : null}

      {currentTab == "Universities" ? (
        <div className="admin__main-content reveal">
          {!universityFormIsOpen ? (
            <AdminOverviewForm 
              type="Universities"
              title="Universities Database"
              addButtonTitle="University"
              setSelection={setCurrentUniversity} 
              setIsDetailOpen={setUniversityFormIsOpen}
            />
          ) : (
            <>
              <Button customClassName={"admin__back-button"} title="&#8592; Back" type="white" onClick={() => { setUniversityFormIsOpen(false); setCurrentUniversity(null); }}/>
              <AdminUniversityForm currentUniversity={currentUniversity}/>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AdminPage;
