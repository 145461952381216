export function convertCreatedAtDate(dateTimestamp) {
  const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Ap",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateTimestamp);

  const dateString =
    shortMonthNames[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear();
  return dateString;
}

const exportedFunctions = { convertCreatedAtDate };

export default exportedFunctions;
