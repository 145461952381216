import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DocumentHead from "../components/atoms/DocumentHead";
import AccountSetupForm from "../components/molecules/AccountSetupForm";

import { RouterPrompt } from "../util/RouterPrompt";

import { getCurrentUser } from "../services/AuthService";
import { getUser } from "../services/UserService";

import "../styles/pages/EditProfilePage.scss";

const initialAuthUserValues = {
  email: "",
  userId: null,
};

const EditAccountPage = ({ user, setCurrentUser }) => {
  const [authUser, setAuthUser] = useState(initialAuthUserValues);
  const [userDetails, setUserDetails] = useState(null);
  const [allowLeave, setAllowLeave] = useState(true);
  const history = useHistory();
  const [id, setID] = useState("");


  useEffect(()  => {
    if (user) {
      setAuthUser({
        email: user.email,
        userId: user.userId,
        role: user.role.name,
        authType: user.authType
      });

      setID(user.uuid);

      if (user.profile.dateOfBirth) {
        setUserDetails({
          ...user.profile,
          dateOfBirthDD: +user.profile.dateOfBirth.split("-")[2],
          dateOfBirthMM: +user.profile.dateOfBirth.split("-")[1],
          dateOfBirthYYYY: +user.profile.dateOfBirth.split("-")[0]
        });
      } else {
        setUserDetails({
          ...user.profile,
        });
      }
    }
  }, [user]);

  function handleOnSubmit() {
    const email = getCurrentUser();

    if (email) {
      getUser(email).then((user) => {
        if (user) {
          setCurrentUser(user);
        }
      });
    }
    
    history.push({
      pathname: "/profile/" + id
    });
  }

  return (
    <div className="account-edit">
      <DocumentHead
        title={"Edit your Profile on Megosu"}
        metaDescription="Edit your profile."
      />
      <AccountSetupForm
        title={"Edit Profile"}
        authUser={authUser && authUser}
        userDetails={userDetails}
        onSubmit={(e) => handleOnSubmit(e)}
        type="edit"
        setAllowLeave={setAllowLeave}
      />
      <RouterPrompt
        when={!allowLeave}
        title="Are you sure you want to leave this page?"
        text="No data will be saved. Therefore the changes you made to your profile will be lost."
        cancelText="Cancel"
        confirmText="Confirm"
      />
    </div>
  );
};

export default EditAccountPage;
