import { useCallback, useEffect, useState } from "react";
import { BsImageFill } from "@react-icons/all-files/bs/BsImageFill";
import { IoIosCloseCircle } from "@react-icons/all-files/io/IoIosCloseCircle";

import PopUp from "../molecules/PopUp";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../util/cropImage";

import CheckIcon from "../../assets/images/CheckIcon.png";

import "../../styles/components/atoms/ImageUploader.scss";
import "../../styles/components/molecules/UploadProjectForm.scss";


function ImageUploader({ simplified, index, currentImage, changeImages, removable, multiple, size, usesCropper, proProjectImages, imageIsPresent, isOldSafari, allowWebP, setShowCompressWarning }) {
  const [imageURI, setImageURI] = useState(null);
  const [invalidImage, setInvalidimage] = useState("");
  const [active, setActive] = useState(false);
  const [backupImageURI, setBackupImageURI] = useState(null);
  const [initialPosition, setInitialPosition] = useState(null);
  const [visibleControls, setVisibleControls] = useState(true);
  const [visibleOverlay, setVisibleOverlay] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(0);
  const [isCropped, setIsCropped] = useState(false);
  const [userCanCrop, setUserCanCrop] = useState(false);
  const [imageIsVertical, setImageIsVertical] = useState(false);
  const MAX_MB_NO_COMPRESSION = 0.06;

  const containerCursorStyle = {
    "cursor": "default",
  };

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  
  useEffect(() => {
    if (currentImage) {
      setImageURI(currentImage);
      setBackupImageURI(currentImage);
      imageIsPresent && imageIsPresent(true);
      usesCropper && isImageVertical(currentImage);
    }
  }, [currentImage]);
  
  useEffect(() => {
    if (!initialPosition && croppedAreaPixels && croppedAreaPixels !== 0 && imageURI ) {
      setInitialPosition(croppedAreaPixels);
    }

    if (!userCanCrop && !isCropped && initialPosition && JSON.stringify(initialPosition) !== JSON.stringify(croppedAreaPixels)) {
      setUserCanCrop(true);
    }
  }, [initialPosition, croppedAreaPixels]);
  

  function resetImage(event) {
    event.preventDefault();
  
    // Setting our image to the image we backed up
    setImageURI(backupImageURI);

    // Resetting crop/zoom values
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setCroppedAreaPixels(null);
    setInitialPosition(null);
    setUserCanCrop(false);
    setIsCropped(false);
    setVisibleOverlay(false);

    currentImage = backupImageURI;
    const succesOverlay = document.getElementById("success-overlay-" + index);
    
    succesOverlay.classList.remove("image-uploader__circle-container-show");
    succesOverlay.classList.remove("image-uploader__circle-container-cursor");
  } 

  async function cropImage(event) {
    event.preventDefault();

    const input = document.querySelectorAll(".image-uploader__input")[index];
    setUserCanCrop(false);

    try {
      // Try to crop our image
      const croppedImage = await getCroppedImg(
        imageURI,
        croppedAreaPixels
      ).then((image) => {
        setImageURI(image);
        currentImage = image; 
            
        const file = dataURLtoFile(image, "filename.png");
        const originalFile = dataURLtoFile(backupImageURI, "backup.png");
        // Change our input's file to the cropped version
        const container = new DataTransfer(); 
        container.items.add(file);
        container.items.add(originalFile);
        input.files = container.files;

        // Resetting crop values
        setZoom(1);
        setCrop({ x: 0, y: 0 });
        setCroppedAreaPixels(0);
        setIsCropped(true);
        setUserCanCrop(false);
        setVisibleOverlay(true);

        const succesOverlay = document.getElementById("success-overlay-" + index);

        succesOverlay.classList.add("image-uploader__circle-container-show");

        setTimeout(() => {
          succesOverlay.classList.remove("image-uploader__circle-container-show");
          succesOverlay.classList.add("image-uploader__circle-container-cursor");
          setVisibleOverlay(false);
        }, 1500);
      });

    } catch (e) {
      console.error(e);
    }
  }

  // Convert data URL to file
  function dataURLtoFile(dataurl, filename) {
    // Using a regex to extract the data values into an array so we can convert it into a file
    // fields extract include datat type, params etc.
    const arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]);
    let n = bstr.length; 
    const u8arr = new Uint8Array(n);

    // Adding the UTF-16 code units of the characters to our 8 bit array
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    // Create file from array
    return new File([u8arr], filename, {type:mime});
  }

  function handleFileInput(event, inputIndex) {
    const inputFiles = event.target.files;
    let imageArray = [];
    imageArray = [];
    const promiseArray = [];

    const maxAmountOfImages = 8 - inputIndex;

    if (inputFiles.length > maxAmountOfImages) {
      setInvalidimage("You have selected too many Images");
      setActive(true);
    } else {
      for (let i = 0; i < inputFiles.length; i++) {

        if (event.target.files && event.target.files[i]) {
          promiseArray.push(new Promise((resolve, reject) => {

            event.target.isRemove = false;
            const reader = new FileReader();
            reader.onload = function (e) {
              const imageFile = e.target.result;
              const allowedMimes = ["jpg", "jpeg", "png"];

              if (allowWebP) {
                allowedMimes.push("webp");
              }

              const checkType = imageFile.split("image/").pop().split(";")[0];
              const maxMb = 5;
              const mb = inputFiles[i].size / 1024 / 1024;

              if (!imageFile) {
                setInvalidimage("Please select an Image");
                setActive(true);
              } else if (!allowedMimes.includes(checkType)) {
                setInvalidimage("Please select an image of type: jpg or png.");
                unSelect(event, index);
                setActive(true);
              } else if (mb > maxMb) {
                setInvalidimage(
                  "File size (" +
                  mb.toFixed(2) +
                  "MB) is too big, please select an image with a maximum file size of 5MB."
                );
                unSelect(event, index);
                setActive(true);
              } else {
                if (multiple) {
                  const dataTransfer = new DataTransfer();
                  dataTransfer.items.add(inputFiles[i]);

                  const input = document.querySelectorAll(".image-uploader__input")[inputIndex + i];

                  input.files = dataTransfer.files;

                  imageArray[i] = [imageFile, inputIndex];

                  setTimeout(() => setVisibleControls(false), 1500);
                  resolve();
                } else {
                  setImageURI(imageFile);
                  setBackupImageURI(imageFile);
                  setZoom(1);
                  imageIsPresent && imageIsPresent(true);

                  currentImage = imageFile;
                  isImageVertical(imageFile);

                  setTimeout(() => setVisibleControls(false), 1500);
                }
              }
            };

            reader.readAsDataURL(event.target.files[i]);
          }));
        }
      }
      if (multiple) {
        Promise.all(promiseArray).then(function () {
          if (imageArray.length > 1) {
            changeImages(imageArray);
          } else {
            setImageURI(imageArray[0][0]);
          }
        });
      }
    }
  }

  function unSelect(event, index) {
    event.preventDefault();

    const formElement = document.getElementsByClassName("image-uploader__form");
    const inputElement = document.getElementsByClassName("image-uploader__input image-uploader__input-" + index);
    inputElement[0].isRemove = imageURI;

    if (imageURI && imageURI.startsWith("data:")) {
      inputElement[0].isRemove = false;
      formElement[index].reset();
    }

    if (simplified) {
      formElement[index].reset();
    }

    setImageURI(null);
    setUserCanCrop(false);
    setIsCropped(false);
    setInitialPosition(null);
    imageIsPresent && imageIsPresent(false);
  }

  function isImageVertical(image) {
    var img = new Image();
    img.src = image;

    img.onload = function() {
      if (img.width >= img.height){
        setImageIsVertical(true);
      } else {
        setImageIsVertical(false);
      }
    };
  }

  return (
    <>
      <PopUp
        visibility={active}
        overlay={true}
        handleOnClickClose={() => setActive(false)}
        popupTitle="Oops!"
        popupText={invalidImage}
        firstButtonTitle="I understand"
        firstButtonAction={() => setActive(false)}
      />

      <form className="image-uploader__form" encType="multipart/form-data">
        <div
          className={
            simplified
              ? "image-uploader image-uploader--simplified"
              : "image-uploader"
          }
        >
          <label
            className={
              imageURI
                ? "image-uploader__input-container image-uploader__input-container--active"
                : 
                !isOldSafari 
                  ? "image-uploader__input-container"
                  : "image-uploader__input-container image-uploader__input-container-aspect-ratio"
            }   
          >
            <div 
              className={
                !imageURI || simplified
                  ? "image-uploader__placeholder-container" 
                  : "image-uploader__placeholder-container image-uploader__placeholder-cursor"
              }
            >
              <input
                className={
                  "image-uploader__input image-uploader__input-" + index
                }
                name="image"
                type="file"
                accept="image/*"
                multiple={multiple}
                onClick={(e) => { usesCropper && imageURI && e.preventDefault(); }}
                onInput={(e) => {  handleFileInput(e, index); }}
              />
              <span
                className="image-uploader__input-text"
                style={simplified ? { display: "none" } : { display: "block" }}
              >
                max 5mb
              </span>
              {proProjectImages ? <h6 className="image-uploader__boosted-img">Pro</h6>
                : null}
              <div className="image-uploader__img-placeholder">
                <BsImageFill />
              </div>
              <span
                className="image-uploader__input-text"
                style={simplified ? { display: "none" } : { display: "block" }}
              >
                {allowWebP ? "JPEG, PNG, WebP" : "JPEG or PNG"} <br /> {!size ? "1000x1000" : size}
              </span>
            </div>
            <div className="image-uploader__img-container">
              {imageURI && usesCropper && (
                <>
                  <Cropper
                    image={imageURI}
                    crop={crop}
                    zoom={zoom}
                    aspect={7 / 8}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    restrictPosition={true}
                    disableAutomaticStylesInjection={true}
                    zoomWithScroll={false}
                    style={String(imageURI).includes("google") && {containerStyle: containerCursorStyle}}
                    objectFit={imageIsVertical ? "vertical-cover" : "horizontal-cover"}
                  />
                  {!String(imageURI).includes("google") && !isCropped &&
                  <div className={visibleControls ? "controls controls-start" : "controls"}>
                    <span 
                      className="minus" 
                      onClick={() => {
                        if (zoom > 1) {
                          setZoom((prevZoom) => Math.round(prevZoom * 10) / 10 - 0.1);
                        }
                      }}
                    >
                      -
                    </span>
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoom(e.target.value);
                      }}
                      className="zoom-range"
                    />
                    <span 
                      className="plus" 
                      onClick={() => {
                        if (zoom < 3) {
                          setZoom((prevZoom) => Math.round(prevZoom * 10) / 10 + 0.1);
                        }
                      }}
                    >
                      +
                    </span>
                  </div>
                  }
                  {visibleOverlay && (
                    <div id={"success-overlay-"+index} className="image-uploader__circle-container">
                      <img className="image-uploader__circle" src={CheckIcon}/>
                    </div>
                  )}
                </>
              )}

              {imageURI && !usesCropper && (
                <img
                  className="image-uploader__img"
                  src={imageURI}
                  alt="upload"
                />
              )}
            </div>
          </label>
          {removable && imageURI && (
            <IoIosCloseCircle
              onClick={(e) => {
                unSelect(e, index); //can pass arguments this.btnTapped(foo, bar);
              }}
            />
          )}
        </div>
      </form>
      {usesCropper && (
        <div className="image-uploader__button-container">
          {userCanCrop && !String(imageURI).includes("google") ? 
            <button className="image-uploader__save-button" onClick={(event) => cropImage(event)}>Save</button> 
            : 
            isCropped && <button className="image-uploader__reset-button" onClick={(event) => resetImage(event)}>Reset</button> 
          }
        </div> 
      )}
    </>
  );
}

export default ImageUploader;
