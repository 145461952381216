import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BiChevronLeft } from "@react-icons/all-files/bi/BiChevronLeft";
import { fromBlob } from "image-resize-compress";
import { compress } from "jpegasus";

import $ from "jquery";

import LabelSet from "./LabelSet";
import PopUp from "./PopUp";
import Spinner from "../atoms/Spinner";

import { getFilterTypes, getProjectTitleAndCountByProfileId, saveProject, updateProject } from "../../services/ProjectService";
import { deleteProjectImage, uploadProjectImage } from "../../services/ImageService";
import { amountOfUploadsCheck } from "../../util/PopUpChecks";
import { getCurrentUserByEmail } from "../../services/AuthService";

import "../../styles/components/molecules/PostUploadProjectForm.scss";

import popupProImage from "../../assets/images/popupImages/transitions/popupTransitionPro.m4v";
import preTransitionPopupProImage from "../../assets/images/popupImages/PopupProAccount.png";

function PostUploadProjectForm({
  project,
  upload,
  onUpload,
  setProjectId,
  proceededFromEditPage,
  currentProject,
  proceed,
  hasProceeded,
  setAllowLeave,
  currentUser,
  imageFiles
}) {
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [labels, setLabels] = useState(null);
  const history = useHistory();
  const [upgradePopUpVisible, setUpgradePopUpVisible] = useState(false);

  useEffect(() => {
    getFilterTypes().then((result) => {
      const labelsSet = result;
      setLabels(labelsSet);
      setLoading(false);
    });
  }, []);

  async function uploaderProjectImage(projectForm, projectId, edit) {
    // Upload images
    const imageRequests = [];
    
    for (let i = 0; i < imageFiles.length; i++) {
      if (imageFiles[i].files != null && imageFiles[i].files.length >= 1) {
        let fileSizeMb;

        if (imageFiles[i].files.length === 2) { 
          fileSizeMb = imageFiles[i].files[1].size / 1024 / 1024;
        } else {
          fileSizeMb = imageFiles[i].files[0].size / 1024 / 1024;
        }

        // Compress max image size
        let compressedFile;

        if (imageFiles[i].files.length === 2) {
          compressedFile = imageFiles[i].files[1];
        } else {
          compressedFile = imageFiles[i].files[0];
        }

        // append image
        const formData = new FormData();
        const file = new File([compressedFile], "image-" + imageFiles[i].index);
        formData.append("image", file);

        const smallImage = imageFiles[i].files[0];
        
        // append the compressed image
        const compressedData = new FormData();
        const smallImageFile = new File([smallImage], "compressedimage-" + imageFiles[i].index);
        formData.append("compressedImage", smallImageFile);

        imageRequests.push(
          uploadProjectImage(
            formData,
            projectId,
            imageFiles[i].index,
            compressedData
          ).then(() => {
            console.log("image uploaded!");
          })
        );
      } else if (imageFiles[i].input.isRemove) {
        const imagePath = imageFiles[i].input.isRemove
          .split(process.env.REACT_APP_GCS_IMAGE_URL)
          .pop();

        imageRequests.push(
          deleteProjectImage(imagePath).then(() => {
            console.log("image removed from project!");
          })
        );
      } else {
        console.log("No files found");
      }
    }

    // Wait for all requests to complete before redirecting
    Promise.all(imageRequests).then(function () {
      // If edit then redirect directly to project page else return confirmation screen
      if (edit) {
        history.push("/project-details/" + currentProject.uuid);
        amountOfUploadsCheck();
        location.reload();
      } else {
        amountOfUploadsCheck();
        onUpload(true);
      }
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setAllowLeave(true);

    const labelElem = document.getElementsByClassName("labelset__input");
    const labels = [];

    for (let i = 0; i < labelElem.length; i++) {
      if (labelElem[i].checked === true) {
        labels[labelElem[i].name.toLowerCase()] =
          labels[labelElem[i].name.toLowerCase()] || [];
        labels[labelElem[i].name.toLowerCase()].push({
          id: parseInt(labelElem[i].value),
          name: labelElem[i].id,
        });
      }

      if (labels[labelElem[i].name] === undefined) {
        labels[labelElem[i].name] = [];
      }
    }

    const projectForm = { ...project, ...labels };

    if (proceededFromEditPage) {
      if (currentProject.project !== undefined) {
        currentProject = currentProject.project;
      }
      updateProject(currentProject.projectId, projectForm, true).then(
        (result) => {
          console.log("Project Updated", currentProject.projectId);
          uploaderProjectImage(projectForm, currentProject.projectId, true);
        }
      );
    } else {
      saveProject(projectForm).then((projectId) => {
        if (projectId) {
          setProjectId(projectId);
          console.log("Project Uploaded", projectId);

          getCurrentUserByEmail(currentUser).then((user) => {
            getProjectTitleAndCountByProfileId(user.profile.profileId);
            uploaderProjectImage(projectForm, projectId, false);
          });
        } else {
          setFormLoading(false);
          setUpgradePopUpVisible(true);
        }
      });
    }
    // Shows LoadingSpinner
    setFormLoading(true);
  }

  return (
    <div
      className="post-upload-form"
      style={proceed && !upload ? { display: "flex" } : { display: "none" }}
    >
      <div className="post-upload-form-container">
        <div
          className="post-upload-form__go-back"
          onClick={() => hasProceeded(false)}
        >
          <BiChevronLeft />
          <span>Go Back</span>
        </div>
        <div className="post-upload-form__title-container">
          <h4 className="post-upload-form__title">Almost there!</h4>
          <span className="post-upload-form__subtitle">
            Just select the items that match your design best, <br />
            so we can match you with our filtering system.
          </span>
        </div>
        {!loading && (
          <form
            className="post-upload-form__form"
            onSubmit={(e) => handleSubmit(e)}
          >
            {Object.keys(labels).map((elem, i) => (
              <LabelSet
                key={i}
                currentProject={proceededFromEditPage && currentProject}
                loading={loading}
                index={elem}
                category={labels[elem]}
              />
            ))}

            <div className="post-upload-form__button-container">
              {formLoading ? (
                <div className="upload-form__loading-spinner">
                  <Spinner type="megosu" />
                </div>
              ) : (
                <button type="submit" className="post-upload-form__submit">
                  {proceededFromEditPage ? "Save" : "Upload"}
                </button>
              )}
            </div>
          </form>
        )}
      </div>

      <PopUp
        visibility={upgradePopUpVisible}
        overlay={true}
        handleOnClickClose={() => history.push("/")}
        src={popupProImage}
        preTransitionImage={preTransitionPopupProImage}
        transition={true}
        popupTitle="Unlock more uploads!"
        popupText="Congrats! You’ve uploaded 5 projects. Go Pro to upload as many projects as you like."
        firstButtonTitle="Go Pro"
        firstButtonType="black"
        firstButtonAction={() => { history.push({ pathname: "/pricing" }); }}
      />
    </div>
  );
}

export default PostUploadProjectForm;
