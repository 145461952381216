import React from "react";

import "../../styles/components/atoms/Toggle.scss";

function Toggle({ value, isChecked, checked, role }) {
  function handleOnChange(e) {
    if (e.target.checked) {
      isChecked(true);
    } else {
      isChecked(false);
    }
  }

  return (
    <div className="toggle">
      <label className="toggle__switch">
        <input
          type="checkbox"
          value={value}
          onChange={(e) => {
            handleOnChange(e);
          }}
          checked={role ? ((role === "business-user" || role === "pitch-user") && checked) : checked}
        />
        <span className="toggle__slider toggle__slider--round"></span>
      </label>
    </div>
  );
}

export default Toggle;
