import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { createCustomerPortalSession, getPaymentStatus } from "../../services/CheckoutService";

import SubscriptionCard from "./SubscriptionCard";
import Toggle from "../atoms/Toggle";
import PopUp from "./PopUp";
import Button from "../atoms/Button";

import {
  freeFeatures,
  proFeatures,
  freePrices,
  proPrices,
  pitchPrices,
  pitchFeatures,
  partnerFeatures
} from "../../assets/data/DataList";
import { getCurrentUserRole } from "../../services/AuthService";
import { cancelSubscription } from "../../services/CheckoutService";

import "../../styles/components/molecules/SubscriptionForm.scss";

import popupImagePro from "../../assets/images/popupImages/transitions/popupTransitionPro.m4v";
import preTransitionPopupProImage from "../../assets/images/popupImages/PopupProAccount.png";

const SubscriptionForm = ({ reveal }) => {
  const [index, setIndex] = useState(0);
  const [durationPlan, setDurationPlan] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const [downgradePopup, setDowngradePopup] = useState(false);
  const [signUpPopupVisible, setSignUpPopupVisible] = useState(false); 
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 
  const [planTitle, setPlanTitle] = useState("");

  const history = useHistory();

  const subscriptions = ["free", "pro", "pitch"];
  const descriptions = [
    "Build your portfolio, save projects and chat with designers.",
    "Exhibit your portfolio professionally and boost your career opportunities.",
    "Propose your designs to your favorite companies and start partnerships.",
  ];
  const featuresList = [freeFeatures, proFeatures, pitchFeatures];
  const priceList = [freePrices, proPrices, pitchPrices];
  const [paymentStatus, setPaymentStatus] = useState("");
  const [purchasedPlan, setPurchasedPlan] = useState("");
  const [pendingPaymentPopup, setPendingPaymentPopup] = useState(false);

  useEffect(() => {
    const userRole = getCurrentUserRole();
    
    if (userRole) {
      setUserRole(userRole);
      setSelectedPlan(userRole);

      if (userRole === "pro-user" || userRole === "business-user" || userRole === "pitch-user") {
        getPaymentStatus().then((res) => {
          if (res === undefined) return;
          setPaymentStatus(res.status);
          setPurchasedPlan(res.planName);
        });
      }
    }
  }, []);

  function endSubscription() {
    cancelSubscription().then(async () => {
      setDowngradePopup(false);
      history.push({
        pathname: "/",
        search: "?paymentConfirmation=free-user"
      });
    });
  }

  return (
    <div>
      <div className={`subscription-form ${reveal && "reveal"}`}>
        <div className="subscription-form__toggle">
          <span>Monthly</span>
          <Toggle
            isChecked={() => {
              setDurationPlan(!durationPlan);
            }}
            value="termplan"
            checked={durationPlan ? true : false}
          />
          <span>Yearly</span>
          <span>33% off!</span>
        </div>

        <div className="subscription-form__card-container">
          {subscriptions.map((elem, i) => ( 
            <div key={i} className="subscription-form__sub-container"> 
              <SubscriptionCard
                key={i}
                index={i === index + 1}
                currentRole = {userRole}
                setDowngradePopup={setDowngradePopup}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
                title={elem.toString().toLowerCase()}
                setPlanTitle={setPlanTitle}
                description={descriptions[i]}
                features={featuresList[i]}
                planDuration={durationPlan}
                prices={priceList[i]}
                onClick={(title, checked) => {
                  handleSubmit(title, checked, activeRole);
                }}
                purchasedPlan={purchasedPlan}
                paymentStatus={paymentStatus}
                setPendingPopup={setPendingPaymentPopup}
                setSignUpPopup={setSignUpPopupVisible}
              />
            </div>
          ))}
        </div>

        <div className="subscription-form__partners">
          <div className="subscription-form__partners-container" onClick={() => { window.open("mailto:partners@megosu.com"); }}>
            <div className="subscription-form__partners-column">
              <h2 className="subscription-form__partners-title">Partners</h2>
              <p className="subscription-form__partners-paragraph">Contact our team to learn about great features for partnering businesses.</p>
              <Button
                type="black"
                title={"Custom pricing"}
                onClick={() => { window.open("mailto:partners@megosu.com"); }}
              />
            </div>
            <div className="subscription-form__partners-column">
              <ul className="subscription-form__partners-features-list">
                {partnerFeatures.map((elem, i) => (
                  <li key={i}>{elem}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <PopUp 
        visibility={signUpPopupVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopupVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => { setSignUpPopupVisible(false); }}
        popupType="sign-up" 
      />

      <PopUp 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopupVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />

      <PopUp
        visibility={downgradePopup}
        overlay={true}
        handleOnClickClose={() => setDowngradePopup(false)}
        src={popupImagePro}
        preTransitionImage={preTransitionPopupProImage}
        transition={true}
        popupTitle="Are you sure?"
        popupText="Important: Your paid plan will be cancelled immediately, without a refund of the remaining billing cycle."
        firstButtonTitle="No, I'm not"
        firstButtonAction={() => setDowngradePopup(false)}
        secondButtonTitle="Yes, I'm sure"
        secondButtonAction={() => {
          if (planTitle == "free") {
            endSubscription();
          } else if (planTitle == "pro") {
            createCustomerPortalSession(document.URL);
          }
        }}
      />

      <PopUp
        visibility={pendingPaymentPopup}
        overlay={true}
        handleOnClickClose={() => setPendingPaymentPopup(false)}
        transition={true}
        popupTitle="Your payment is pending."
        popupText="Payment methods other than creditcards (e.g. SEPA - iDeal) can take up to 14 business days to process your payment. You can already enjoy your paid plan now!"
      />
    </div>
  );
};

export default SubscriptionForm;
