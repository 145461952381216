import "../../styles/components/molecules/HowItWorksTestimonials.scss";

const HowItWorksTestimonials = ({title, testimonials, isTablet}) => {
  return (
    <div className="how-it-works-testimonials reveal">
      <h3 className="how-it-works-testimonials__review-title">{title}</h3>
      <div className="how-it-works-testimonials__reviews">
        {testimonials && testimonials.map((item, i) => 
          (
            <div className="how-it-works-testimonials__review-card" key={i}>
              <h4 className="how-it-works-testimonials__review-text">
                {item.text}
              </h4>
              <div className="how-it-works-testimonials__designer">
                <img className="how-it-works-testimonials__review-image" src={item.source} />
                <div className="how-it-works-testimonials__review-job-container">
                  <h5 className="how-it-works-testimonials__review-name">{item.designerName}</h5>
                  <p className="how-it-works-testimonials__review-job-title">{item.jobTitle}</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default HowItWorksTestimonials;
