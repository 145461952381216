import React, { useEffect, useState } from "react";
import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill";

import { getFilterTypes } from "../../services/ProjectService";

import "../../styles/components/molecules/ProjectFilter.scss";

const ProjectFilter = ({
  selectedTags,
  addRemoveFilter,
  filterType,
  projectFilter,
  setProjectFilter,
  designerFilter,
  setDesignerFilter
}) => {
  const [materialFilter, setMaterialFilter] = useState(true);
  const [productFilter, setProductFilter] = useState(false);
  const [productionFilter, setProductionFilter] = useState(false);
  const [spaceFilter, setSpaceFilter] = useState(false);
  const [topicFilter, setTopicFilter] = useState(false);
  const [tags, setTags] = useState(null);
  const [reversedIcon, setReversedIcon] = useState(false);

  const INACTIVE_FILTER_FONT_WEIGHT = "300";
  const ACTIVE_FILTER_FONT_WEIGHT = "400";

  useEffect(() => {
    getFilterTypes().then((r) => setTags(r));
  }, []);

  const categoryContent = (category, mobile) => {
    return (<ul className={mobile ? "project-filter__dropdown--btn-dropdown" : "project-filter__dropdown"}>
      {tags &&
        Object.values(
          tags[category].map((item, i) => (<li
            key={`filter-${category}-${i}`}
            className="project-filter__dropdown-tag"
            onClick={(e) => addRemoveFilter(item["name"], category)}
          >
            <span
              style={{
                fontWeight: selectedTags[category].includes(item["name"])
                  ? ACTIVE_FILTER_FONT_WEIGHT
                  : INACTIVE_FILTER_FONT_WEIGHT,
              }}
              className="project-filter__dropdown-tag-count"
            >
              {item["name"]}
            </span>
          </li>
          ))
        )}
    </ul>);
  };

  return (
    <>
      {filterType === "mobile" ? (
        <>
          <div
            className={`${designerFilter && "project-filter__btn--disabled"} project-filter__btn`}
            onClick={() => {
              setProjectFilter(!projectFilter);
              setDesignerFilter(false);
            }}
            onMouseEnter={() => { setReversedIcon(true); }}
            onMouseLeave={() => { setReversedIcon(false); }}
          >
            Project Filters
            <BsFillCaretDownFill 
              className={`${projectFilter && "designer-filter__bsx-icon--disabled"} designer-filter__bsx-icon ${reversedIcon && "designer-filter__bsx-icon--reversed"} ${projectFilter && "designer-filter__bsx-icon--reversed-mobile"}`} 
            />
          </div>
          <div 
            className={`${projectFilter && "project-filter__dropdown-content--active"} project-filter__dropdown-content`}
            onMouseEnter={() => { setReversedIcon(true); }}
            onMouseLeave={() => { setReversedIcon(false); }}
          >
            <div className="project-filter__categories">
              <label
                className="project-filter__label-list"
                style={{
                  fontWeight: materialFilter
                    ? ACTIVE_FILTER_FONT_WEIGHT
                    : INACTIVE_FILTER_FONT_WEIGHT,
                }}
                onClick={() => {
                  setMaterialFilter(true);
                  setProductFilter(false);
                  setProductionFilter(false);
                  setSpaceFilter(false);
                  setTopicFilter(false);
                }}
              >
                  Material
              </label>
              <label
                className="project-filter__label-list"
                style={{
                  fontWeight: productFilter
                    ? ACTIVE_FILTER_FONT_WEIGHT
                    : INACTIVE_FILTER_FONT_WEIGHT,
                }}
                onClick={() => {
                  setProductFilter(true);
                  setMaterialFilter(false);
                  setProductionFilter(false);
                  setSpaceFilter(false);
                  setTopicFilter(false);
                }}
              >
                  Product
              </label>
              <label
                className="project-filter__label-list"
                style={{
                  fontWeight: productionFilter
                    ? ACTIVE_FILTER_FONT_WEIGHT
                    : INACTIVE_FILTER_FONT_WEIGHT,
                }}
                onClick={() => {
                  setMaterialFilter(false);
                  setProductFilter(false);
                  setProductionFilter(true);
                  setSpaceFilter(false);
                  setTopicFilter(false);
                }}
              >
                  Production
              </label>
              <label
                className="project-filter__label-list"
                style={{
                  fontWeight: spaceFilter
                    ? ACTIVE_FILTER_FONT_WEIGHT
                    : INACTIVE_FILTER_FONT_WEIGHT,
                }}
                onClick={() => {
                  setMaterialFilter(false);
                  setProductFilter(false);
                  setProductionFilter(false);
                  setSpaceFilter(true);
                  setTopicFilter(false);
                }}
              >
                  Space
              </label>
              <label
                className="project-filter__label-list"
                style={{
                  fontWeight: topicFilter
                    ? ACTIVE_FILTER_FONT_WEIGHT
                    : INACTIVE_FILTER_FONT_WEIGHT,
                }}
                onClick={() => {
                  setMaterialFilter(false);
                  setProductFilter(false);
                  setProductionFilter(false);
                  setSpaceFilter(false);
                  setTopicFilter(true);
                }}
              >
                  Topic
              </label>
            </div>

            <div className="filter__project-filter-category-content">
              {materialFilter && categoryContent("material", true)}
              {productFilter && categoryContent("product", true)}
              {productionFilter && categoryContent("production", true)}
              {spaceFilter && categoryContent("space", true)}
              {topicFilter && categoryContent("topic", true)}
            </div>
          </div>
        </>
      ) : (
        <div className="project-filter">
          <div className="project-filter__list-item">
            <label className="project-filter__label-list">Material</label>
            {categoryContent("material", false)}
          </div>
          <div className="project-filter__list-item">
            <label className="project-filter__label-list">Product</label>
            {categoryContent("product", false)}
          </div>
          <div className="project-filter__list-item">
            <label className="project-filter__label-list">Production</label>
            {categoryContent("production", false)}
          </div>
          <div className="project-filter__list-item">
            <label className="project-filter__label-list">Space</label>
            {categoryContent("space", false)}
          </div>
          <div className="project-filter__list-item">
            <label className="project-filter__label-list">Topic</label>
            {categoryContent("topic", false)}
          </div>     
        </div>
      )}
    </>
  );
};

export default ProjectFilter;
