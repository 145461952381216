import { AuthHeader } from "../util/AuthHeader";

export async function getChats(userId) {
  const getUserUrl = process.env.REACT_APP_API_BASE_URL + "/users/" + userId + "/chats";
  
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
  
  return await fetch(getUserUrl, options)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    });
}

export async function findChatMessages(currentUserId, contactUserId) {
  const getUserUrl = process.env.REACT_APP_API_BASE_URL + "/messages/" + currentUserId + "/" + contactUserId;
    
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
    
  return await fetch(getUserUrl, options)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    });
}

export async function findChatMessage(id) {
  const getUserUrl = process.env.REACT_APP_API_BASE_URL + "/messages/" + id;
      
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
      
  return await fetch(getUserUrl, options)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    });
}

export async function deleteChat(userId, senderId, recipientId) {
  const deleteChatUrl = process.env.REACT_APP_API_BASE_URL + "/users/" + userId + "/" + senderId + "/" + recipientId;
      
  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
    },
  };
      
  return await fetch(deleteChatUrl, options)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    });
}

export async function getNotifications(userId) {
  const notificationsUrl = process.env.REACT_APP_API_BASE_URL + "/notifications/" + userId;

  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
      
  return await fetch(notificationsUrl, options)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    });
}

export async function removeNotification(chatRoomId) {
  const notificationsUrl = process.env.REACT_APP_API_BASE_URL + "/notifications/remove/" + chatRoomId;

  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
    },
  };
      
  return fetch(notificationsUrl, options).then((response) => {
    if (response.ok) {
      console.log("Notification removed");
    }
  });
}

export async function getChatMessageId(senderId, recipientId) {
  const chatMessageIdUrl = process.env.REACT_APP_API_BASE_URL + "/chat-message/" + senderId + "/" + recipientId;

  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
      
  return fetch(chatMessageIdUrl, options).then(async (response) => {
    if (response.ok) {
      return await response.json();
    }
  });
}