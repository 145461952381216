import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { compress } from "jpegasus";

import { getChats, findChatMessages, deleteChat, getNotifications, removeNotification, getChatMessageId } from "../services/ChatService";
import { uploadChatMessageAttachment } from "../services/ImageService";

import ChatOverview from "../components/molecules/ChatOverview";
import ChatDetail from "../components/molecules/ChatDetail";
import Popup from "../components/molecules/PopUp";

import scrollTrigger from "../util/ScrollReveal";
import { RouterPrompt } from "../util/RouterPrompt";

import "../styles/pages/ChatPage.scss";

let stompClient;
const ChatPage = ({ user }) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const [chatInfo, setChatInfo] = useState({});
  const [userId, setUserId] = useState(null);
  const [text, setText] = useState("");
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [noResultsPopUpActive, setNoResultsPopUpActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allowLeave, setAllowLeave] = useState(true);
  const isMobile = width <= 768;
  const isTablet = width <= 1200;
  const [hasResized, setHasResized] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (selectedChat) {
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, 500);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      if (user.userId) {
        setUserId(user.userId);
      } else {
        setUserId(user.uuid);
      }
    }

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [user]);

  useEffect(() => {
    userId && connect();
  }, [userId]);

  useEffect(() => {
    if (messages) {
      setMessages( messages.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.timestamp) - new Date(b.timestamp);
      }));
    }
  }, [messages]);

  useEffect(() => {
    if (text.length > 0) {
      setAllowLeave(false);
    } else {
      setAllowLeave(true);
    }
  }, [text]);

  const setSelectedChats = (chat) => {
    setSelectedChat(chat);
    sessionStorage.setItem("selectedChat", JSON.stringify(chat));

    if (notifications.includes(chat.chatRoomId)) {
      removeNotification(chat.chatRoomId).then(() => {
        setNotifications(notifications.splice(notifications.indexOf(chat.chatRoomId), 1));
        loadContacts();
      });
    }

    findChatMessages(chat.recipientId, userId).then((msgs) => {
      setMessages(msgs);

      if (!msgs || msgs.length === 0) {
        findChatMessages(userId, JSON.parse(sessionStorage.getItem("selectedChat")).recipientId).then((messages) => {
          setMessages(messages);
        });
      }
    });
  };

  const handleWindowSizeChange = () => {
    setHasResized(true);
    setWidth(window.innerWidth);
  };

  const connect = () => {
    const Socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL + "/ws"}`);
    stompClient = Stomp.over(Socket);
    stompClient.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    stompClient.subscribe(
      "/user/" + userId + "/queue/messages",
      onMessageReceived
    );

    loadContacts();
  };

  const onError = (err) => {
    console.log(err);
  };

  const onMessageReceived = (msg) => {
    const notification = JSON.parse(msg.body);
    loadContacts();
  };

  const sendMessage = (msg, images, files) => {   
    if (msg.trim() !== "") {
      // setup our message
      const message = {
        senderId: userId,
        recipientId: selectedChat.recipientId,
        senderName: currentUser.profile.firstName + " " + currentUser.profile.lastName,
        recipientName: selectedChat.recipientName,
        content: msg,
        timestamp: new Date(),
      };

      if (images === 0 || files === 0) {
      // sending our message
        stompClient.send("/app/chat", {}, JSON.stringify(message));

        // setting our new messages in the chat
        const newMessages = [...messages];
        newMessages.push(message);
        setMessages(newMessages);

        setTimeout(() => {
        // wait for the backend to be done processing

          getChats(userId).then((chats) => {
          // update messsage overview with new messages
            if (chats) {
              setContacts(chats);
            }
          });

          refreshChat(userId);
          setText("");   
          setChatInfo({});
          setLoading(false);
        }, 30);
      } else {
      // sending our message
        stompClient.send("/app/chat", {}, JSON.stringify(message));

        setTimeout(() => {
          // wait for the backend to be done processing the text message
          getChatMessageId(userId, selectedChat.recipientId).then((res) => {
            setChatInfo({chatMessageId: res, chatRoomId: selectedChat.id});
          
            images.forEach(async (elem, i) => {
              // if we have images, read each URI to a file and compress the image
              const file = dataURLtoFile(elem.URI, "filename.png");
    
              const compressedFile = await compress(file, {
                maxHeight: 1000,
                maxWidth: 1000,
                quality: 0.7,
              });
    
              const formData = new FormData();
              const actualFile = new File([compressedFile], "image-" + i);
              formData.append("file", actualFile);
              // specify the content-type of the file (jpeg)
              formData.append("contentType", elem.URI.substring(elem.URI.indexOf(":") + 1, elem.URI.indexOf(";")));
    
              // upload the image
              return uploadChatMessageAttachment(formData, selectedChat.id, res, i).then((res) => {
                console.log("image uploaded!");
              });
            }); 

            files.forEach((elem, i) => {
              // if we have files, read the URIs to a file and add the right extension to the file name (.pdf/.pptx/.docx)
              const file = dataURLtoFile(elem.URI, "filename." + elem.URI.substring(elem.URI.indexOf("/") + 1, elem.URI.indexOf(";")));

              const formData = new FormData();
              const actualFile = new File([file], elem.name);
              formData.append("file", actualFile);
              formData.append("contentType", elem.URI.substring(elem.URI.indexOf(":") + 1, elem.URI.indexOf(";")));
    
              // upload the file
              return uploadChatMessageAttachment(formData, selectedChat.id, res, i).then((res) => {
                console.log("file uploaded!");
              });
            });
            
            setTimeout(() => {
              refreshChat(userId);
              setText("");   
              setChatInfo({});
              setLoading(false);

              // clearing the input files
              document.getElementById("attachmentInput").value = "";
              document.getElementById("imageInput").value = "";
            }, 1000);
          });
        }, 200);
      }
    }
  };

  // Convert data URL to file
  function dataURLtoFile(dataurl, filename) {
    // Using a regex to extract the data values into an array so we can convert it into a file
    // fields extract include datat type, params etc.
    const arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]);
    let n = bstr.length; 
    const u8arr = new Uint8Array(n);
  
    // Adding the UTF-16 code units of the characters to our 8 bit array
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    // Create file from array
    return new File([u8arr], filename, {type:mime});
  }

  const refreshChat = (userId) => {
    getChats(userId).then((chats) => {
      if (chats) {
        setContacts(chats);
      }
    });
    findChatMessages(userId, selectedChat.recipientId).then((messages) => {
      setMessages(messages);
    });
  };

  const deleteChats = () => {
    deleteChat(userId, selectedChat.senderId, selectedChat.recipientId).then((res) => {
      loadContacts();
      sessionStorage.removeItem("selectedChat");
    });
  };

  const loadContacts = () => {
    if (userId) {
      getChats(userId).then((chats) => {
        // load all chats of this user
        if (chats) {
          setContacts(chats);

          getNotifications(userId).then((notifcations) => {
            if (notifcations) {
              const notifcationIds = [];
  
              for (let i=0; i<notifcations.length; i++) {
                notifcationIds.push(notifcations[i].id);
              }
  
              setNotifications(notifcationIds);
            }
          });

          if (sessionStorage.getItem("goToChat") != null) {
            // if user is redirected via contact button
            for (const chat of chats) {
              if (chat.chatRoomId == sessionStorage.getItem("goToChat")) {
                sessionStorage.removeItem("goToChat");
                setSelectedChats(chat);
              } 
            }
          } else if (sessionStorage.getItem("selectedChat") != null) {
            // if we have selected a chat, keep that chat as our selected that
            setSelectedChats(JSON.parse(sessionStorage.getItem("selectedChat")));
            findChatMessages(JSON.parse(sessionStorage.getItem("selectedChat")).recipientId, userId).then((msgs) => {
              if (!msgs || msgs.length === 0) {
                findChatMessages(userId, JSON.parse(sessionStorage.getItem("selectedChat")).recipientId).then((messages) => {
                  setMessages(messages);
                });
              } else {
                setMessages(msgs);
              }
            });
          } else {
            if (chats.length > 0) {
              // by default set the first chat as the opened chat if we don't have a selected chat
              setSelectedChats(chats[0]);
              sessionStorage.setItem("selectedChat", JSON.stringify(chats[0]));

              // find the chat messages with the first chat that is opened
              findChatMessages(chats[0].recipientId, userId).then((msgs) => {
                setMessages(msgs);
              });
            } else {
              // if we have no chats, we can't have a selected chat
              setSelectedChat(null);
              setNoResultsPopUpActive(true);
            }
          }
        }
      });
    }
  };

  function handleNoResultClose() {
    setNoResultsPopUpActive(false); 
    history.push("/");

    // disconnect our client on leaving the chat page
    stompClient.disconnect();
  }

  return (
    <div className={!isMobile ? "chat-page chat-page__background" : "chat-page"}>
      <div className="chat-page__container">
        {(!isMobile || (isMobile && !selectedChat)) && (
          <ChatOverview 
            setSelectedChat={setSelectedChats}
            contacts={contacts}
            setContacts={setContacts}
            setMessages={setMessages}
            notifications={notifications}
            currentUser={currentUser}
            isMobile={isMobile}
            hasResizedWindow={hasResized} 
          />
        )}
        {((!isMobile && selectedChat) || (isMobile && selectedChat)) && (
          <ChatDetail 
            setSelectedChat={setSelectedChat} 
            selectedChat={selectedChat} 
            isMobile={isMobile} 
            text={text} 
            setText={setText}
            sendMessage={sendMessage}
            chatMessages={messages}
            deleteChat={deleteChats}
            refreshChat={refreshChat}
            chatInfo={chatInfo}
            setChatInfo={setChatInfo}
            setLoading={setLoading}
            loading={loading}
            hasResizedWindow={hasResized} 
          />
        )}
        {!isTablet && <div className={selectedChat ? "chat-page__tips-download-container" : "chat-page__tips-download-container-no-chat"}/>}
      </div>
      <Popup
        visibility={noResultsPopUpActive}
        overlay={true}
        handleOnClickClose={() => { handleNoResultClose(); }}
        popupTitle="It looks like you don't have any chats open."
        popupText="Explore to discover new designers!"
        firstButtonTitle="Explore"
        firstButtonType="black"
        firstButtonAction={() => { handleNoResultClose(); }}
      />

      <RouterPrompt
        when={!allowLeave}
        title="Are you sure you want to leave this page?"
        text="Your message will be deleted if you leave."
        cancelText="Cancel"
        confirmText="Confirm"
      />
    </div>
  );
  
};

export default ChatPage;

