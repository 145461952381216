import React from "react";

import "../../styles/components/molecules/ChatOverview.scss";

import profilePlaceholder from "../../assets/images/ProfilePlaceholder.png";

const ChatOverview = ({ setSelectedChat, contacts, notifications, isMobile, hasResizedWindow }) => {
  return (
    <div className={isMobile || hasResizedWindow ? "chat-overview" : "chat-overview reveal"}>
      {contacts.length != 0 && (
        <div className="chat-overview__container">
          <h1 className="chat-overview__title">Chats</h1>
          <ul className="chat-overview__chats">
            <>
              {(contacts.map((contact, i) => (
                <li key={`chat-overview__chat-with-${contact.recipientId}-${i}`}
                  onClick={() => { setSelectedChat(contact); }}>
                  <div className="chat-overview__user-info-container">     
                    <div
                      className="header__user-image-container"
                      style={{
                        backgroundImage: contact && contact.recipientImageUrl ? "url(" + process.env.REACT_APP_GCS_IMAGE_URL + contact.recipientImageUrl + ")" : `url(${profilePlaceholder})`
                      }}
                    >
                      {notifications.includes(contact.chatRoomId) && <div className="header__user-image-notification" />}
                    </div>
                    <div className="chat-overview__user-text-container">   
                      <div className="chat-overview__chat-header-container">
                        <span className="chat-overview__user-name">
                          {contact.recipientName}
                        </span>
                        <div className="chat-overview__message-date">
                          {contact.recentDate && (
                            <>
                              {new Intl.DateTimeFormat("en-GB", { 
                                month: "long", 
                                day: "2-digit",
                                year: "numeric", 
                              }).format(new Date(contact.recentDate))}
                            </>
                          )}
                        </div>
                      </div>  
                      <div className={!notifications.includes(contact.chatRoomId) ? "chat-overview__user-message" : "chat-overview__user-message chat-overview__user-message-thick"}>
                        {contact.recentMessage}
                      </div>
                    </div>
                  </div>
                </li> 
              )))} 
            </>         
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChatOverview;