import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import ErrorPage from "./components/atoms/ErrorNotification.jsx";

Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_HONEYBADGER_ENVIRONMENT,
  enableUncaught: false
});

ReactDOM.hydrate(
  <React.StrictMode>
    <HoneybadgerErrorBoundary  honeybadger={Honeybadger} ErrorComponent={ErrorPage}>
      <App />
    </HoneybadgerErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
