import "../../styles/components/molecules/Partners.scss";

import { 
  GCS_ASSET_BRAUN_LOGO, GCS_ASSET_HERMAN_MILLER_LOGO, GCS_ASSET_KEYSHOT_LOGO, GCS_ASSET_MADECOM_LOGO, 
  GCS_ASSET_PICTORIGHT_FONDS_LOGO, GCS_ASSET_VANMOOF_LOGO, GCS_ASSET_VT_WONEN_LOGO, GCS_ASSET_WACOM_LOGO 
} from "../../constants/gcs-assets-paths";

function Partners({ reveal }) {
  return (      
    <div className={`${reveal && "reveal"}`}>
      <h2 className="partner-images__title">Partners who trust us</h2>
      <div className="partner-images__image-container">
        <img className="partner-images__company-image" src={GCS_ASSET_WACOM_LOGO} alt="wacom logo" />
        <img className="partner-images__company-image" src={GCS_ASSET_BRAUN_LOGO} alt="braun logo" />
        <img className="partner-images__company-image" src={GCS_ASSET_KEYSHOT_LOGO} alt="keyshot logo"/>
        <img className="partner-images__company-image" src={GCS_ASSET_MADECOM_LOGO} alt="madecom logo"/>
        <img className="partner-images__company-image" src={GCS_ASSET_VANMOOF_LOGO} alt="vanmoof logo"/>
        <img className="partner-images__company-image" src={GCS_ASSET_HERMAN_MILLER_LOGO} alt="herman miller logo"/>
        <img className="partner-images__company-image" src={GCS_ASSET_PICTORIGHT_FONDS_LOGO} alt="picto rights fonds logo" />
        <img className="partner-images__company-image" src={GCS_ASSET_VT_WONEN_LOGO} alt="vtwonen logo"/>
      </div>
    </div>);
}

export default Partners;