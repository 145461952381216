import { AuthHeader } from "../util/AuthHeader";

export function saveSiteAnnouncement(announcement) {
  const saveAnnouncementUrl = process.env.REACT_APP_API_BASE_URL + "/site/announcement/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(announcement),
  };
    
  return fetch(saveAnnouncementUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}
  
export function getSiteAnnouncement() {
  const getAnnouncementUrl = process.env.REACT_APP_API_BASE_URL + "/site/announcement";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(getAnnouncementUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}
  
export function deleteSiteAnnouncement(id) {
  const deleteAnnouncementUrl = process.env.REACT_APP_API_BASE_URL + "/site/announcement/delete/" + id;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(deleteAnnouncementUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function saveSiteFile(siteFile) {
  const saveFileUrl = process.env.REACT_APP_API_BASE_URL + "/site/files/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(siteFile),
  };
    
  return fetch(saveFileUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}
  
export function getSiteFiles() {
  const getFilesUrl = process.env.REACT_APP_API_BASE_URL + "/site/files";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(getFilesUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function deleteSiteFile(id) {
  const deleteFileUrl = process.env.REACT_APP_API_BASE_URL + `/site/files/${id}/delete`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(deleteFileUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function getAdminMainStatistics() {
  const getStaticsMainUrl = process.env.REACT_APP_API_BASE_URL + "/site/statistics/main";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(getStaticsMainUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function getAdminProjectGoalStatistics() {
  const getProjectGoalStatistics = process.env.REACT_APP_API_BASE_URL + "/site/statistics/project-goals";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(getProjectGoalStatistics, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function getAdminProfileGoalStatistics() {
  const getProfileStatistics = process.env.REACT_APP_API_BASE_URL + "/site/statistics/profile-goals";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(getProfileStatistics, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function getAdminRoleStatistics() {
  const getRoleStatistics = process.env.REACT_APP_API_BASE_URL + "/site/statistics/roles";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(getRoleStatistics, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function getUniversities() {
  const getUniversitiesUrl = process.env.REACT_APP_API_BASE_URL + "/site/universities";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
    
  return fetch(getUniversitiesUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function saveUniversity(university) {
  const getUniversitiesUrl = process.env.REACT_APP_API_BASE_URL + "/site/universities/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(university),
  };
    
  return fetch(getUniversitiesUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function deleteUniversity(id) {
  const deleteUniversityUrl = process.env.REACT_APP_API_BASE_URL + `/site/universities/${id}/delete`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };
    
  return fetch(deleteUniversityUrl, options)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function getToolCategories() {
  const getToolCategoriesUrl = process.env.REACT_APP_API_BASE_URL + "/site/tool-categories";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
    
  return fetch(getToolCategoriesUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function saveToolCategory(toolCategory) {
  const saveToolCategoryUrl = process.env.REACT_APP_API_BASE_URL + "/site/tool-categories/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(toolCategory),
  };
    
  return fetch(saveToolCategoryUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}

export function deleteToolCategory(id) {
  const deleteToolCategoryUrl = process.env.REACT_APP_API_BASE_URL + `/site/tool-categories/${id}/delete`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };
  
  return fetch(deleteToolCategoryUrl, options)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("An Error Occured");
    });
}