import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "../../styles/components/molecules/SuccessStory.scss";
import Button from "../atoms/Button";

function SuccessStory({ 
  headerImage, 
  headerCaption, 
  companyName, 
  firstInfoHeaderText, 
  secondInfoHeaderText, 
  firstInfoHeaderImage, 
  secondInfoHeaderImage,
  infoTextHTML,
  infoUnderTitle,
  firstSmallImages,
  secondSmallImages,
  firstSmallImage,
  secondSmallImage,
  thirdSmallImage,
  fourthSmallImage,
  fifthSmallImage,
  sixthSmallImage,
  seventhSmallImage,
  eigthSmallImage,
  firstSmallImageCaption,
  secondSmallImageCaption,
  thirdSmallImageCaption,
  fourthSmallImageCaption,
  fifthSmallImageCaption,
  sixthSmallImageCaption,
  seventhSmallImageCaption,
  eigthSmallImageCaption,
  regularVideos,
  autoPlayVideos,
  loopVideos,
  firstRegular,
  secondRegular,
  thirdRegular,
  firstRegularCaption,
  secondRegularCaption,
  thirdRegularCaption,
  firstRegularTextHTML,
  secondRegularTextHTML,
  thirdRegularTextHTML,
  middleTitleHTML,
  preButtonQuestion,
  firstButtonTitle,
  secondButtonTitle,
  firstButtonAction,
  secondButtonAction,
  underButtonText,
  firstOtherStory,
  firstOtherStoryStatic,
  secondOtherStory,
  secondOtherStoryStatic,
  firstOtherStoryCaption,
  secondOtherStoryCaption,
  setCurrentStory
}) {
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const sendEmail = () => { window.location = "mailto:partners@megosu.com"; };

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  const onButtonClick = (action) => {
    if (action) {
      if (action !== "contact") {
        history.push(action);
      } else {
        sendEmail();
      }
    }
  };

  return (
    <div className="success-story">
      <div className="success-story__header reveal">
        <div
          style={{
            backgroundImage: `url("${headerImage}")`
          }}
          className="success-story__header-image"
        />
        <p className="success-story__header-caption">{headerCaption}<span className="success-story__name--bold">{companyName}</span></p>
      </div>

      <div className="success-story__info reveal">
        <div className="success-story__info__header">
          {firstInfoHeaderText ? (
            <p className="success-story__info__header__text">{firstInfoHeaderText}</p>
          ) : (
            <div className="success-story__info__header__image-wrapper">
              <div className={`success-story__info__header__image ${companyName === "MADE.com" && "success-story__info-header__image-megosu-logo"}`} style={{ backgroundImage: `url("${firstInfoHeaderImage}")`}} />
            </div>
          )}
          <p className="success-story__info__header__x">X</p>
          {secondInfoHeaderText ? (
            <p className="success-story__info__header__text">{secondInfoHeaderText}</p>
          ) : (
            <div className="success-story__info__header__image-wrapper">
              <div className={`success-story__info__header__image ${companyName === "Kartent" && "success-story__info-header__image-kartent-logo"}`} style={{ backgroundImage: `url("${secondInfoHeaderImage}")`}} />
            </div>
          )}
        </div>
        <p className="success-story__info__text" dangerouslySetInnerHTML={{ __html: infoTextHTML }}/>
        <h1 className="success-story__title">{infoUnderTitle}</h1>
      </div>

      {firstSmallImages && (
        <div className="success-story__small-images-container reveal">
          <div>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <img className="success-story__media-wrapper--small" src={firstSmallImage}/>
              </div>
              {firstSmallImageCaption && (<figcaption>{firstSmallImageCaption}</figcaption>)}
            </figure>
          </div>
          <div>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <img className="success-story__media-wrapper--small" src={secondSmallImage}/>
              </div>
              {secondSmallImageCaption && (<figcaption>{secondSmallImageCaption}</figcaption>)}
            </figure>
          </div>
          <div>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <img className="success-story__media-wrapper--small" src={thirdSmallImage}/>
              </div>
              {thirdSmallImageCaption && (<figcaption>{thirdSmallImageCaption}</figcaption>)}
            </figure>
          </div>
          <div>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <img className="success-story__media-wrapper--small" src={fourthSmallImage}/>
              </div>
              {fourthSmallImageCaption && (<figcaption>{fourthSmallImageCaption}</figcaption>)}
            </figure>
          </div>
        </div>
      )}

      {firstRegular && (
        <div className="success-story__regular-container reveal">
          <figure className="success-story__media-container">
            <div className="success-story__media-wrapper">
              {!regularVideos.includes("1") ? (
                <img className="success-story__media-wrapper--regular" src={firstRegular}/>
              ) : (
                <video 
                  className="success-story__media-wrapper--regular" 
                  {...(autoPlayVideos.includes("1") && !isMobile && {autoPlay: true})} 
                  {...(loopVideos.includes("1") && {loop: true})} 
                  width="100%" 
                  height="100%" 
                  controls 
                  controlsList="nodownload" 
                  preload="metadata"
                >
                  <source src={firstRegular + "#t=0.001"} type="video/mp4" />
                </video>
              )}
            </div>
            {firstRegularCaption && (<figcaption>{firstRegularCaption}</figcaption>)}
          </figure>
          <p className="success-story__regular-container__text" dangerouslySetInnerHTML={{ __html: firstRegularTextHTML }} />
        </div>
      )}

      {secondRegular && (
        <div className="success-story__regular-container reveal">
          <figure className="success-story__media-container">
            <div className="success-story__media-wrapper">
              {!regularVideos.includes("2") ? (
                <img className="success-story__media-wrapper--regular" src={secondRegular}/>
              ) : (
                <video 
                  className="success-story__media-wrapper--regular" 
                  {...(autoPlayVideos.includes("2") && !isMobile && {autoPlay: true})} 
                  {...(loopVideos.includes("2") && {loop: true})} 
                  width="100%" 
                  height="100%" 
                  controls 
                  controlsList="nodownload" 
                  preload="metadata"
                  playsinline
                >
                  <source src={secondRegular + "#t=0.001"} type="video/mp4" />
                </video>
              )}
            </div>
            {secondRegularCaption && (<figcaption>{secondRegularCaption}</figcaption>)}
          </figure>
          <p className="success-story__regular-container__text" dangerouslySetInnerHTML={{ __html: secondRegularTextHTML }} />
        </div>
      )}

      {middleTitleHTML && (
        <div>
          <h1 className={isMobile ? "success-story__title success-story__title--left reveal" : "success-story__title reveal"} dangerouslySetInnerHTML={{ __html: middleTitleHTML }}/>
        </div>
      )}

      {secondSmallImages && (
        <div className="success-story__small-images-container reveal">
          <div>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <img className="success-story__media-wrapper--small" src={fifthSmallImage}/>
              </div>
              {fifthSmallImageCaption && (<figcaption>{fifthSmallImageCaption}</figcaption>)}
            </figure>
          </div>
          <div>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <img className="success-story__media-wrapper--small" src={sixthSmallImage}/>
              </div>
              {sixthSmallImageCaption && (<figcaption>{sixthSmallImageCaption}</figcaption>)}
            </figure>
          </div>
          <div>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <img className="success-story__media-wrapper--small" src={seventhSmallImage}/>
              </div>
              {seventhSmallImageCaption && (<figcaption>{seventhSmallImageCaption}</figcaption>)}
            </figure>
          </div>
          <div>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <img className="success-story__media-wrapper--small" src={eigthSmallImage}/>
              </div>
              {eigthSmallImageCaption && (<figcaption>{eigthSmallImageCaption}</figcaption>)}
            </figure>
          </div>
        </div>
      )}

      {thirdRegular && (
        <div className="success-story__regular-container reveal">
          <figure className="success-story__media-container">
            <div className="success-story__media-wrapper">
              {!regularVideos.includes("3") ? (
                <img className="success-story__media-wrapper--regular" src={thirdRegular}/>
              ) : (
                <video 
                  className="success-story__media-wrapper--regular" 
                  {...(autoPlayVideos.includes("3") && !isMobile && {autoPlay: true})} 
                  {...(loopVideos.includes("3") && {loop: true})} 
                  width="100%" 
                  height="100%" 
                  controls 
                  controlsList="nodownload" 
                  preload="metadata"
                >
                  <source src={thirdRegular + "#t=0.001"} type="video/mp4" />
                </video>
              )}
            </div>
            {thirdRegularCaption && (<figcaption>{thirdRegularCaption}</figcaption>)}
          </figure>
          <p className="success-story__regular-container__text" dangerouslySetInnerHTML={{ __html: thirdRegularTextHTML }}/>
        </div>
      )}
      <div className="reveal">
        <h1 className="success-story__title success-story__question">{preButtonQuestion}</h1>
        <div className="success-story__button-container">
          <Button 
            type="black"
            title={firstButtonTitle}
            onClick={() => { onButtonClick(firstButtonAction); }}
          />
          {secondButtonTitle && (
            <>            
              <p className="success-story__or">or</p>
              <Button 
                type="black"
                title={secondButtonTitle}
                onClick={() => { onButtonClick(secondButtonAction); }}
              />
            </>
          )}
        </div>
        <p className="success-story__inspirational-text">{underButtonText}</p>
      </div>

      <div className="reveal">
        <p className="success-story__more-stories-title">More Success Stories</p>
        <div className="success-story__more-stories">
          <div className="success-story__other-story" onClick={() => { setCurrentStory(firstOtherStoryCaption); }}>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <>
                  {isMobile ? (
                    <img className="success-story__media-wrapper--small" src={firstOtherStoryStatic}></img>
                  ) : (
                    <video loop {...(!isMobile && {autoPlay: true})} className="success-story__media-wrapper--small">
                      <source src={firstOtherStory + "#t=0.001"} type="video/mp4" />
                    </video>
                  )}
                </>
              </div>
              {firstOtherStoryCaption && (<figcaption className="success-story__other-story-caption">{firstOtherStoryCaption}</figcaption>)}
            </figure>
          </div>
          <div className="success-story__other-story" onClick={() => { setCurrentStory(secondOtherStoryCaption); }}>
            <figure className="success-story__media-container">
              <div className="success-story__media-wrapper">
                <>
                  {isMobile ? (
                    <img className="success-story__media-wrapper--small" src={secondOtherStoryStatic}></img>
                  ) : (
                    <video loop {...(!isMobile && {autoPlay: true})} className="success-story__media-wrapper--small">
                      <source src={secondOtherStory + "#t=0.001"} type="video/mp4" />
                    </video>
                  )}
                </>
              </div>
              {secondOtherStoryCaption && (<figcaption className="success-story__other-story-caption">{secondOtherStoryCaption}</figcaption>)}
            </figure>
          </div>
        </div>
      </div>

    </div> 
  );
}

export default SuccessStory;