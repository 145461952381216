import { useEffect } from "react";

import DocumentHead from "../components/atoms/DocumentHead";
import Partners from "../components/molecules/Partners";

import scrollTrigger from "../util/ScrollReveal";

import { designerProfiles } from "../assets/data/DataList";

import { GCS_ASSET_ABOUT_IMAGE, GCS_ASSET_ABOUT_THUMBNAIL, GCS_ASSET_ABOUT_VIDEO } from "../constants/gcs-assets-paths";

import "../styles/pages/AboutPage.scss";

const Aboutpage = () => {

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);

  return (
    <div className="about">
      <DocumentHead
        title="About Us | Megosu"
        metaDescription="Our mission is to unleash the potential of great product design through collaboration with design brands."
        showChat={true}
      />
      <h1 className="about__title reveal">About Us</h1>
      <div className="about__feature-image-container reveal">
        <img
          className="about__feature-image"
          src={GCS_ASSET_ABOUT_IMAGE}
          alt="megosu founders"
        />
      </div>

      <div className="about__block reveal">
        <h2 className="about__subtitle">
          Megosu, where designers & brands collaborate
        </h2>
        <p className="about__text">
        Megosu noticed a big ask from both independent designers and brands to connect with one another, we strive to provide just that. Megosu connects designers with companies that are seeking new product ideas, designers to hire or daily inspiration.        </p>
        <p className="about__text">
          <span className="about__text--bold">Our mission</span> is to unleash the potential of great product design through collaboration.
        </p>
      </div>

      <div className="about__block reveal">
        <h2 className="about__subtitle">
          Learn about Megosu in just 60 seconds.
        </h2>
        <div className="about__video-wrapper">
          <video width="100%" height="100%" poster={GCS_ASSET_ABOUT_THUMBNAIL} controls controlsList="nodownload" preload="metadata">
            <source src={GCS_ASSET_ABOUT_VIDEO} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="about__block reveal">
        <p className="about__text">
          How we&apos;re helping you
        </p>
        <ul>
          <li className="about__list-item">
            <span className="about__list-item--bold">Find refreshing designers and their projects</span><br/>
            Be inspired by thousands of projects.
          </li>
          <li className="about__list-item">
            <span className="about__list-item--bold">Access our international network</span><br/>
            Boost your career oppurtunities aside from your local network.
          </li>
          <li className="about__list-item">
            <span className="about__list-item--bold">Collaborate with convenience</span><br/>
            On your own terms- under our guidance.
          </li>
        </ul>
      </div>

      <div className="about__block reveal">
        <h2 className="about__subtitle">
          Why we started
        </h2>
        <p className="about__text">
          While studying ProductDesign, Guido & Max- founders of Megosu- learned that most design disappears under a layer of dust. With so much unleashed potential we were sure there would be a platform for these &apos;dusted&apos; design projects. But there wasn&apos;t... 
        </p>
        <p className="about__text">
          So instead of just sitting back (and relax), we decided it was time to create this platform ourselves, Megosu.
        </p>
      </div>

      <div className="about__block reveal">
        <h2 className="about__subtitle">Designer profiles</h2>
        <div className="about__designer-profiles">
          {designerProfiles.map((elem, i) => (
            <>
              <div key={i} className="about__designer">
                <h2 className="about__designer-title">Meet <span>{elem.name}</span></h2>
                <div>
                  <video 
                    className="admin-advertisement-form__visual" 
                    src={process.env.REACT_APP_GCS_IMAGE_URL + elem.videoPath + "#t=0.001"}
                    type="video/mp4" 
                    controls 
                    controlsList="nodownload"
                    preload="metadata"
                  />
                </div>
              </div>
            </>
          ))}
        </div>
       
      </div>

      <Partners/>
    </div>
  );
};

export default Aboutpage;
