export function nullFilter(array) {
  return array.filter((item) => {
    return item != null;
  });
}

export function filterQueryBuilder(filterObject) {
  const propsArray = [];
  let queryBuilder = "";
  let searchQuery = "";

  for (const prop in filterObject) {
    const obj = filterObject[prop];

    if (obj.length !== 0) {
      propsArray.push(prop);

      if (propsArray.length > 1) {
        queryBuilder = queryBuilder.concat("&");
      }

      queryBuilder = queryBuilder.concat(prop + "=").concat(obj);
    }

    searchQuery = `?${queryBuilder}`;
  }

  return searchQuery;
}

const exportedFunctions = { nullFilter, filterQueryBuilder };

export default exportedFunctions;
