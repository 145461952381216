import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";

import PopUp from "../components/molecules/PopUp";

/* Can be used for custom prompt on navigation */
export function RouterPrompt(props) {
  const { when, title, confirmText, cancelText, text } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const unblockRef = useRef();

  // Show prompt on when
  // History.block is used to block navigation away from the current page
  // History block's returned boolean decides whether or not to navigate
  useEffect(() => {
    unblockRef.current = history.block((prompt) => {
      if (when) {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    }); 
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [history, when]);

  // Confirm navigation
  function handleConfirm() {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  }

  // Cancel navigation
  function handleCancel() {
    setShowPrompt(false);
  }

  return showPrompt ? (
    <PopUp
      visibility={showPrompt}
      overlay={true}
      handleOnClickClose={handleCancel}
      popupTitle={title}
      popupText={text}
      firstButtonTitle={cancelText}
      firstButtonAction={handleCancel}
      secondButtonTitle={confirmText}
      secondButtonAction={handleConfirm}
    />
  ) : null;
}