import { AuthHeader } from "../util/AuthHeader";

const controller = new AbortController();
const signal = controller.signal;

export function getAdvertisements(sorted) {
  sorted = `?sorted=${sorted}`;

  const getAdvertisements = process.env.REACT_APP_API_BASE_URL + `/ads${sorted}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  };
    
  return fetch(getAdvertisements, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getAdvertisementsCount() {
  const getAdvertisementsCount = process.env.REACT_APP_API_BASE_URL + "/ads/count";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  };
    
  return fetch(getAdvertisementsCount, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function updateAdvertisement(advertisementId, advertisement) {
  const updateAdvertisementUrl = process.env.REACT_APP_API_BASE_URL + `/ads/${advertisementId}/update`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(advertisement),
  };
  
  return fetch(updateAdvertisementUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function saveAdvertisement(advertisement) {
  const saveAdvertisementUrl = process.env.REACT_APP_API_BASE_URL + "/ads/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(advertisement),
  };
    
  return fetch(saveAdvertisementUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function deleteAdvertisement(advertisementId) {
  const deleteAdvertisementUrl = process.env.REACT_APP_API_BASE_URL + `/ads/${advertisementId}/delete`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };
  
  return fetch(deleteAdvertisementUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

const exportedFunctions = {
  getAdvertisements,
  updateAdvertisement,
  saveAdvertisement,
  deleteAdvertisement,
  getAdvertisementsCount
};
export default exportedFunctions;
