import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import DOMPurify from "dompurify";
import $ from "jquery";
import { HashLink } from "react-router-hash-link";

import { BsInfoCircleFill } from "@react-icons/all-files/bs/BsInfoCircleFill";
import { MdChat } from "@react-icons/all-files/md/MdChat";
import { FaShare } from "@react-icons/all-files/fa/FaShare";
import { BsBookmark } from "@react-icons/all-files/bs/BsBookmark";
import { BsBookmarkFill } from "@react-icons/all-files/bs/BsBookmarkFill";

import CommentSection from "./CommentSection";
import Button from "../atoms/Button";
import Popup from "./PopUp";

import { getFavoritesCountByProjectId, getFavoriteUsersByProjectId, removeFavorite, saveFavorite } from "../../services/FavoriteService";
import { getProjectById, getProjectViewsCount } from "../../services/ProjectService";

import { convertCreatedAtDate } from "../../util/Converters";

import "../../styles/components/molecules/ProjectDetailsOverview.scss";
import { useHistory } from "react-router-dom";


function ProjectDetailsOverview({ role, projectObject, modal, showModal, feedbackDate, feedbackText, projectOwnerRole, currentUserIsProjectOwner, currentUser, profileObject}) {
  const history = useHistory();
  const [project] = useState(projectObject);
  const [profile] = useState(profileObject);
  const cleanedDescription = DOMPurify.sanitize(projectObject.description);
  const [projectImages, setProjectImages] = useState([]);
  const [sharePopUpVisible, setSharePopUpVisible] = useState(false);
  const [savedProjectUsers, setSavedProjectUsers] = useState([]);
  const [savedProjectUsersLoading, setSavedProjectUsersLoading] = useState(false);
  const [projectCardPopUpVisible, setProjectCardPopUpVisible] = useState(false);
  const [favoriteCounts, setFavoriteCounts] = useState([]);
  const [favorite, setFavorite] = useState(false);
  const [signUpPopupVisible, setSignUpPopupVisible] = useState(false); 
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 
  const [projectViewsCount, setProjectViewsCount] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    if (project.imageUrl) {
      const splitProjectImages = project.imageUrl.map((a) => a.url);
      splitProjectImages.sort();
      if (splitProjectImages.length > 4 && projectOwnerRole.name === "free-user") {
        setProjectImages(splitProjectImages.slice(0,5));
      } else {
        setProjectImages(splitProjectImages);
      }
    }
    
    setTimeout(() => {
      const projectDetailOverview = $(".project-details-overview")[0];
      projectDetailOverview && projectDetailOverview.classList.remove("reveal", "active");
    }, 2000);
  }, []);

  useEffect(() => {
    if (project.description) {
      const description = document.getElementsByClassName("project-details-overview__project-description")[0];
      const anchors = description.getElementsByTagName("a");
      if (anchors) {
        for (let i = 0; i < anchors.length; i++) {
          anchors[i].setAttribute("class", "project-details-overview__disabled-link");
        }
      }
    }

    getFavoritesCountByProjectId(project.projectId).then((projectGroups) => {
      setFavoriteCounts(projectGroups);
    });

    getUserFavorites(JSON.parse(localStorage.getItem("favorites")));

    if (currentUserIsProjectOwner) {
      getProjectViewsCount(project.uuid).then((res) => {
        setProjectViewsCount(res);
      });
    }
  }, [project]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  function handleOnClick(e) {
    e.preventDefault();
    showModal(!modal);
  }

  const getFavoriteUsers = () => {
    setSavedProjectUsersLoading(true);

    getFavoriteUsersByProjectId(project.projectId).then((users) => {
      if (role === "free-user") {
        users.slice(0, 3);
      }

      setSavedProjectUsers(users);
      setSavedProjectUsersLoading(false);
    });
  };

  const getUserFavorites = (favoritesList) => {
    if (favoritesList && favoritesList.find((elem) => elem === project.projectId)) {
      setFavorite(true);
    } else {
      setFavorite(false);
    }
  };

  function handleBookmarkClick() {
    if (!currentUser) {
      setLoginPopupVisible(true);
    } else if (favorite) {
      setFavorite(false);

      if (currentUser.userId) {
        removeFavorite(currentUser.userId, project.projectId).then(() => {
          console.log("Removed favorite");
        });

        if (favoriteCounts.length === 0) {
          setFavoriteCounts("");
        } else {
          setFavoriteCounts(parseInt(favoriteCounts) - 1);
        }
      }

      storeFavorites(project.projectId);
    } else {
      setFavorite(true);

      if (currentUser.userId) {
        saveFavorite(currentUser.userId, project.projectId).then(() => {
          console.log("Saved favorite");
        });

        if (favoriteCounts === undefined || favoriteCounts.length === 0) {
          setFavoriteCounts(1);
        } else {
          setFavoriteCounts(parseInt(favoriteCounts) + 1);
        }
      }

      storeFavorites(project.projectId);
    }
  }

  function storeFavorites(projectId) {
    let favoriteList = [];
    const favoriteItems = localStorage.getItem("favorites");

    // Use list from local storage if it exists
    if (favoriteItems != null) {
      favoriteList = JSON.parse(favoriteItems);
    }

    // Add new favorite project to list and local storage
    if (favoriteList.indexOf(projectId) === -1) {
      favoriteList.push(projectId);
    } else {
      favoriteList.splice(favoriteList.indexOf(projectId), 1);
    }

    localStorage.setItem("favorites", JSON.stringify(favoriteList));
  }

  const scrollWithOffset = (el, yOffset) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const handleAdminEditProject = () => {
    getProjectById(project.uuid).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          history.push({ 
            pathname: "/edit-project/" + project.uuid, 
            state: data
          });
        });
      }
    });
  };

  return (
    <article 
      aria-label={`${project.title} by ${profileObject.firstName} ${profileObject.lastName} on Megosu | Product Design Platform`} 
      title={`${project.title} by ${profileObject.firstName} ${profileObject.lastName} on Megosu | Product Design Platform`}
    >
      <div className="project-details-overview reveal">
        {isMobile ? (
          <>
            <div className="project-details-overview__details-container project-details-overview__details-container--mobile">
              <div>
                <h6 className="project-details-overview__details-title">
                  {project.title}&nbsp;<span className="project-details-overview__details-title--design-year">{project.designYear}</span>
                </h6>
                <span className="project-details-overview__details-subtitle">
                  {project.marketAvailability
                    ? "Available for market"
                    : "Unavailable for market"}
                </span>
              </div>

              <div className="project-details-overview__details-save-btn-container">
                <div className="project-details-overview__bookmark-container">
                  <span
                    className={
                      "project-details-overview__details-save-count project-details-overview__details-save-count-" +
              project.projectId
                    }
                    onClick={() => {
                      if (project && currentUser) {
                        if (project.profile.profileId === currentUser.profile.profileId) {
                          setProjectCardPopUpVisible(true); 
                          getFavoriteUsers();
                        }
                      }
                    }}
                    style={project && currentUser && currentUser !== undefined && (profile.profileId === currentUser.profile.profileId) ?
                      {fontWeight: "400", cursor: "pointer"} : {fontWeight: "300"}}
                  >
                    {favoriteCounts}
                  </span>
                  <div className={"project-details-overview__details-bookmark--filled project-details-overview__details-bookmark-" + project.projectId + "--filled"}
                    onClick={() => { handleBookmarkClick(); }}
                    style={favorite ? { display: "flex" } : { display: "none" }}
                  >
                    <BsBookmarkFill className="bookmark-filled-icon" />
                  </div>
                  <div className={
                    "project-details-overview__details-bookmark"
                  }>
                    <div
                      className={
                        "project-details-overview__details-bookmark project-details-overview__details-bookmark-" +
              project.projectId
                      }
                      onClick={() => { handleBookmarkClick(); }}
                      style={favorite ? { display: "none" } : { display: "flex" }}
                    >
                      <BsBookmark />
                    </div>
                  </div>
                </div>

                <div className={currentUserIsProjectOwner ? 
                  "project-details-overview__side-menu--mobile project-details-overview__side-menu--mobile project-details-overview__side-menu--owner" : "project-details-overview__side-menu--mobile"
                }>
                  <div className="project-details-overview__chat-icon">
                    <HashLink smooth to="#comment-section"  scroll={el => scrollWithOffset(el, -85)}>
                      <MdChat className="project-details-overview__side-menu--menu-items--mobile"/>
                    </HashLink>
                  </div>
                  <div onClick={() => { setSharePopUpVisible(true); }}>
                    <FaShare className="project-details-overview__side-menu--menu-items--mobile"/>
                  </div>
                  {currentUserIsProjectOwner && (
                    <div onClick={() => {
                      setProjectCardPopUpVisible(true); 
                      getFavoriteUsers(); 
                    }}>
                      <BsInfoCircleFill className="project-details-overview__side-menu--menu-items--mobile"/>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        
        ) : null}
      
        <div className="project-details-overview__container">
          <div
            className="project-details-overview__content-container"
            style={
              role === "admin" ? { paddingTop: "1rem" } : { paddingTop: "4rem" }
            }
          >
            {role === "admin" && (
              <div className="project-details-overview__actions-container">
                <>
                  <div className="project-details-overview__feedback">
                    <div className="project-details-overview__feedback-row">
                      <div className="project-details-overview__feedback-row-text">
                        <b>Last feedback date: {feedbackDate ? feedbackDate.split("T")[0] + " ": "Project has not received feedback yet"}</b>
                        <b>Feedback rating: {project.adminRating + "/10"}</b>
                      </div>
                      <div className="project-details-overview__feedback-row-buttons">
                        <Button
                          title="Review Project"
                          type="pink"
                          onClick={(e) => handleOnClick(e)}
                        />
                        <Button
                          title="Edit Project"
                          type="black"
                          onClick={() => { handleAdminEditProject(); }}
                        />
                      </div>
                    </div>
                    <p>{feedbackText}</p>
                  </div>
                </>
              </div>
            )}

            {!isMobile && (
              <div className="project-details-overview__side-menu">
                <div className="project-details-overview__chat-icon">
                  <HashLink smooth to="#comment-section" scroll={el => scrollWithOffset(el, -85)}  aria-label="Go to the comment section">
                    <MdChat className="project-details-overview__side-menu--menu-items"/>
                  </HashLink>
                </div>
                <div onClick={() => { setSharePopUpVisible(true); }}>
                  <FaShare className="project-details-overview__side-menu--menu-items"/>
                </div>
                {currentUserIsProjectOwner && (
                  <div onClick={() => {setProjectCardPopUpVisible(true); 
                    getFavoriteUsers(); } }>
                    <BsInfoCircleFill className="project-details-overview__side-menu--menu-items"/>
                  </div>
                )}
              </div>
            )}

            <div className="project-details-overview__info-container">
              <div className="feature-img-container">
                {projectImages[0] && (
                  <img
                    src={process.env.REACT_APP_GCS_IMAGE_URL + projectImages[0]}
                    className="project-details-overview__feature-img"
                    alt={project.imageAltText}
                  />
                )}
              </div>
              <span className="project-details-overview__info_description">
                <time dateTime={new Date(project.createdAt).toISOString().slice(0, 10)}>
                   Uploaded on {convertCreatedAtDate(project.createdAt)}
                </time>
              </span>

              {!isMobile && (
                <span className="project-details-overview__title">
                  {project.title}
                </span>
              )}
              <div className="project-details-overview__project-description">
                <p dangerouslySetInnerHTML={{ __html: cleanedDescription }}/>
              </div>

              {projectImages.length > 1 &&
              projectImages
                .slice(1)
                .map((image, id) => (
                  <img
                    key={id}
                    src={process.env.REACT_APP_GCS_IMAGE_URL + image}
                    alt={project.imageAltText}
                    className="project-details-overview__img-secondary reveal"
                  />
                ))}
              {project.videoLink && (profile.user.role.name === "pro-user" || profile.user.role.name === "business-user" || profile.user.role.name === "pitch-user") ? 
                <div className="project-details-overview__video-wrapper">
                  <ReactPlayer
                    className="project-details-overview__featured-video"
                    url={project.videoLink}
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
                :
                null
              }

              {project.sketchFabUrl && (profile.user.role.name === "pro-user" || profile.user.role.name === "business-user" || profile.user.role.name === "pitch-user") && (
                <div className="project-details-overview__sketchfab-embed-wrapper"> 
                  <iframe 
                    title="AR Prototype" 
                    allowFullScreen 
                    mozallowfullscreen="true" 
                    webkitallowfullscreen="true" 
                    allow="autoplay; fullscreen; xr-spatial-tracking" 
                    xr-spatial-tracking
                    execution-while-out-of-viewport
                    execution-while-not-rendered 
                    web-share 
                    src={project.sketchFabUrl}>
                  </iframe>             
                </div>
              )}
              
            </div>
          </div>
        </div>
        <CommentSection 
          isProjectOwner={currentUserIsProjectOwner}
          user={currentUser}
          projectId={project.projectId}
        />
      </div>

      <Popup 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopupVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />

      <Popup 
        visibility={signUpPopupVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopupVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => { setSignUpPopupVisible(false); }}
        popupType="sign-up" 
      />

      <Popup
        visibility={projectCardPopUpVisible}
        overlay={true}
        handleOnClickClose={() => setProjectCardPopUpVisible(false)}
        popupType={"showSavedProjectsUsers"}
        popupTitle={"People who saved your project"}
        firstButtonTitle="Go Pro"
        firstButtonType="black"
        firstButtonAction={() => { history.push({ pathname: "/pricing" }); }}
        role={role}
        savedProjectUsers={savedProjectUsers}
        savedProjectUsersLoading={savedProjectUsersLoading}
        data={projectViewsCount}
      />

      <Popup
        visibility={sharePopUpVisible}
        overlay={true}
        handleOnClickClose={() => { setSharePopUpVisible(false); }}
        popupType={"share"}
        popupTitle={"Share this project with others"}
        projectTitle={project.title}
        projectId={project.uuid}
        projectImageUrl={process.env.REACT_APP_GCS_IMAGE_URL + projectImages[0]}
      />
    </article>
  );
}

export default ProjectDetailsOverview;
