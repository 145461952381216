import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DocumentHead from "../components/atoms/DocumentHead";
import AccountSetupForm from "../components/molecules/AccountSetupForm";
import { RouterPrompt } from "../util/RouterPrompt";

import { authenticateNewUser } from "../services/AuthService";
import { getUser } from "../services/UserService";

import "../styles/pages/AccountSetupPage.scss";

const initialAuthUserValues = {
  email: "",
  role: "",
  userId: null,
};

const AccountSetupPage = () => {
  const [authUser, setAuthUser] = useState(initialAuthUserValues);
  const [allowLeave, setAllowLeave] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (sessionStorage.getItem("credentials") !== null) {
      const email = JSON.parse(sessionStorage.getItem("credentials"))["email"];

      getUser(email).then((user) => {
        setAuthUser({
          email: user.email,
          role: user.role.name,
          userId: user.userId,
          authType: user.authType
        });
      });
    } else {
      history.push("/");
    }
  }, []);

  async function handleOnSubmit(e) {
    const email = JSON.parse(sessionStorage.getItem("credentials"))["email"];
    const password = JSON.parse(sessionStorage.getItem("credentials"))[
      "password"
    ];

    await authenticateNewUser(email, password).then(async () => {
      sessionStorage.removeItem("credentials");

      await getUser(email)
        .then(async (user) => {
          if (user) {
            const previousURL = sessionStorage.getItem("previousURL");
            if (previousURL !== undefined && previousURL !== null && previousURL !== "/sign-up") {
              sessionStorage.removeItem("previousURL");
              history.push({ pathname: previousURL });
            } else {
              history.push({ pathname: "/" });
            }
          }
        })
        .catch((e) => {
          console.log("Error occured when retrieving user! " + e);
        });
    });
  }

  return (
    <div id="account-setup" className="account-setup">
      <DocumentHead
        title={"Set Up your account on Megosu"}
        metaDescription=""
      />
      <AccountSetupForm
        title={"Setup Account"}
        authUser={authUser && authUser}
        onSubmit={(e) => handleOnSubmit(e)}
        setAllowLeave={setAllowLeave}
        type="setup"
      />
      <RouterPrompt
        when={!allowLeave}
        title="Finish your profile first"
        text="It just takes 2 minutes to fill out all the missing fields- then you can use Megosu to the fullest."
        cancelText="Complete Profile"
      />
    </div>
  );
};

export default AccountSetupPage;
