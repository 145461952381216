import { AuthHeader } from "../util/AuthHeader";

export function saveProfile(profile) {
  const email = JSON.parse(sessionStorage.getItem("credentials"))["email"];
  const password = JSON.parse(sessionStorage.getItem("credentials"))["password"];
  
  const saveProfileUrl = process.env.REACT_APP_API_BASE_URL + "/users/profile/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:  "Basic " + btoa(email + ":" + password),
    },
    body: JSON.stringify(profile),
  };
  
  return fetch(saveProfileUrl, options)
    .then((response) => {
      return response;
    });
}

export function updateProfile(profile) {
  const updateProfileUrl = process.env.REACT_APP_API_BASE_URL + "/users/profile";
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(profile),
  };

  return fetch(updateProfileUrl, options);
}

export function getProfileById(id) {
  const getProfileUrl = process.env.REACT_APP_API_BASE_URL + `/users/${id}/profile`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProfileUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((uploader) => {
      return uploader;
    });
}

export function getProfilesByName(name) {
  if (name == "") {
    name = null;
  }

  const getProfilesUrl = process.env.REACT_APP_API_BASE_URL + `/users/${name}/profiles`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProfilesUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((profiles) => {
      return profiles;
    });
}

export function getCountryList() {
  const countryListUrl = process.env.REACT_APP_API_BASE_URL + "/users/countries";

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(countryListUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function updateProfileSocials(socials) {
  const updateSocialUrl = process.env.REACT_APP_API_BASE_URL + "/users/profile/update-socials";

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(socials),
  };

  return fetch(updateSocialUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

const exportedFunctions = {
  saveProfile,
  updateProfile,
  getProfilesByName,
  getProfileById,
  updateProfileSocials
};

export default exportedFunctions;
