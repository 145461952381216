/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import SockJS from "sockjs-client";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { BiErrorCircle } from "@react-icons/all-files/bi/BiErrorCircle";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";

import Spinner from "../atoms/Spinner";
import Button from "../atoms/Button";
import Registration from "./RegistrationForm";
import Login from "./LoginForm";

import { getCurrentUser, getCurrentUserByEmail } from "../../services/AuthService";

import "../../styles/components/molecules/FormPopup.scss";

import improveYourUploadFile from "../../assets/files/improve_your_upload.pdf";
import profilePlaceHolder from "../../assets/images/ProfilePlaceholder.png";
import { sendResetPasswordMail } from "../../services/MailService";

const FormPopup = ({ props, popupType, handleOnClickClose, handleOnChangeAccountSetup, profileValues, firstButtonAction, secondButtonAction }) => {
  let stompClient;
  
  const [currentUser, setCurrentUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profileImageUrl: "",
    projectImageUrl: "",
  });

  const [contactSubject, setContactSubject] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendingStatus, setSendingStatus] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [currentPage] = useState(popupType === "contact" && props.currentPage);
  const [currentUsers, setCurrentUsers] = useState("");
  const [members, setMembers] = useState([""]);
  const [membersError, setMembersError] = useState([""]);

  const resetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required("Field can't be empty.")
      .email("Please fill in a valid email (example@megosu.com).")
  });

  const contactUserSchema = Yup.object().shape({
    message: Yup.string()
      .required("Message can't be empty.")
  });

  const { register: registerPasswordReset, handleSubmit: handlePasswordReset, formState: passwordResetState, setError: setPasswordResetError } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(resetPasswordSchema)
  });

  const { register: registerContact, handleSubmit: handleContact, formState: contactState, getValues: getContactValue } = useForm({
    defaultValues: { message: "" },
    resolver: yupResolver(contactUserSchema)
  });

  const history = useHistory();

  useEffect(() => {
    const email = getCurrentUser();
    if (email) {
      setCurrentUsers(email);
      getCurrentUserByEmail(email).then((user) => {
        if (user) {
          setCurrentUser(user);
        }
      });
    }

    if (popupType === "contact") {
      const designerFullName =
        props.designerFirstName + " " + props.designerLastName;
      const message = `Start a conversation with ${designerFullName}`;

      setContactMessage(message);
    }
  }, [
    profileValues,
    popupType === "contact" && props.designerFirstName,
    popupType === "contact" && props.designerLastName
  ]);

  function sendMessageToDesigner(data, event) {
    event.preventDefault();
    let mailSubject = contactSubject;

    if (mailSubject === "") {
      mailSubject = "You received a message!";
    }

    const Socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL + "/ws"}`);
    stompClient = Stomp.over(Socket);
    stompClient.connect({}, onConnected);
  }

  const onConnected = () => {
    // when our client is connected, we can send the message from the form
    const message = {
      senderId: currentUser.userId,
      recipientId: props.designerId,
      senderName: currentUser.profile.firstName + " " + currentUser.profile.lastName,
      recipientName: props.designerFirstName + " " + props.designerLastName,
      content: getContactValue("message"),
      timestamp: new Date(),
    };
    stompClient.send("/app/chat", {}, JSON.stringify(message));
    setSendingStatus(true);
    firstButtonAction();

    // disconnect our client again
    stompClient.disconnect();
  };

  function handleResetPassword(data, event) {
    event.preventDefault();
    const email = data.email;

    setLoading(true);
    sendResetPasswordMail(email)
      .then((response) => {
        if (response.ok) {
          handleOnClickClose();
        } else {
          if (response.status === 404) {
            setPasswordResetError("email", { type: "custom", message: "This email does not exist!"});
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Request failed" + error);
      });
  }

  const validateMembers = () => {
    let error = false;

    members.map((member, i) => {
      if (member === "") {
        membersError[i] = "Enter an email";
        error = true;
      } else if (!member.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        membersError[i] = "Enter a valid email";
        error = true;
      } else {
        membersError[i] = "";
      }
    });

    setMembersError([...membersError]);

    if (!error) firstButtonAction(members);
  };

  return (
    <>
      <div className="form-popup">
        {popupType === "contact" ? (
          <div className="form-popup--contact">
            {loading && (
              <div className="form-popup--contact__spinner">
                <Spinner type="megosu"/>
                <h4>Sending your message ...</h4>
              </div>
            )}
            {!loading && !sendingStatus && !errorStatus && (
              <form onSubmit={handleContact(sendMessageToDesigner)}>
                <div className="form-popup--contact__top-container">
                  <div className="user-container">
                    {props.designerAvatar ? (
                      <img
                        src={props.designerAvatar}
                        className="user-avatar"
                        width="93"
                        height="93"
                        alt="avatar"
                      />
                    ) : (
                      <img
                        src={profilePlaceHolder}
                        className="user-avatar"
                        width="93"
                        height="93"
                        alt="avatar"
                      />
                    )}
                    <span className="user-message__title">
                      Message&nbsp;{props.designerFirstName}
                    </span>
                  </div>
                </div>

                <div className="form-popup--contact__middle-container">
                  {contactState.errors && contactState.errors?.message && (
                    <span>{contactState.errors.message.message}</span>
                    )}
                    <br/>
                  <textarea
                    className={contactState.errors && contactState.errors?.message
                      ? "contact-message-textarea form-popup--contact__input--error"
                      : "contact-message-textarea"
                    }
                    placeholder={contactMessage}
                    type="textarea"
                    {...registerContact("message")}
                  />
                </div>

                <div className="form-popup--contact__bottom-container">
                  <Button
                    title="Cancel"
                    type="white"
                    onClick={handleOnClickClose}
                  />
                  <Button
                    title="Send"
                    type="pink"
                    onClick={() => { sendMessageToDesigner; firstButtonAction; }}
                  />
                </div>
              </form>
            )}
            {sendingStatus && (
              <div className="form-popup--contact__success-message-container">
                <h5 className="title">Send!</h5>
                <span className="sub-text">
                  We will notify you when you’ve got  a response.
                </span>
                <br></br>
                <Button
                    title="Go to chat"
                    type="pink"
                    onClick={() => { history.push("/chat"); }}
                  />
              </div>
            )}
            {errorStatus && (
              <div className="form-popup--contact__error-message-container">
                <BiErrorCircle className="icon icon__error" />
                <h5 className="title">Message not delivered</h5>
                <span className="sub-text">
                  There was a problem delivering your message to{" "}
                  {props.designerFirstName}&nbsp;
                  {props.designerLastName}.
                </span>
              </div>
            )}
          </div>
        ) : popupType === "invite-team-members" ? (
          <div className="form-popup--invite-team-member">
            <h1 className="form-popup--invite-team-member__title">Invite New Members</h1>
            <p className="form-popup--invite-team-member__text">
              Send an invitation link via email to invite your team members.
            </p>

            <form>
              {members.map((member, i) => (
                <>
                  {membersError[i] && (
                    <span className="form-popup--team-member__error">{membersError[i]}</span>
                  )}
                  <div key={i} className="form-popup--team-member">
                    <div className="form-popup--team-member__select">
                      <div className={`form-popup--team-member__input ${membersError[i] && "form-popup--team-member__input--error"}`}>
                        <input
                          className="upload-form__input"
                          value={members[i]}
                          placeholder="example@email.com"
                          name="members"
                          maxLength={25}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            if(e.key === "Enter"){
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            members[i] = e.target.value;
                            setMembers([...members]);
                          } } 
                        />
                        <FaPlus
                          className="form-popup--team-member__discard-icon"
                          color="black"
                          onClick={() => {
                            if (members.length > 1) {
                              setMembers([...members.slice(0, i), ...members.slice(i + 1, members.length)]);
                              setMembersError([...members.slice(0, i), ...members.slice(i + 1, members.length)]);
                            } else {
                              members[i] = "";
                              membersError[i] = "";
                              setMembers([...members]);
                              setMembersError([...membersError]);
                            }
                          } } />
                      </div>
                    </div>
                    {i == members.length - 1 && members.length < 5 && (
                      <Button
                        title={<FaPlus color="black" />}
                        type="grey"
                        onClick={() => { 
                          setMembers(members => [...members, ""]);
                          setMembersError(membersError => [...membersError, ""]);
                        } } />
                    )}
                  </div>
                </>
              ))
              }

              <div className="form-popup--invite-team-member__button-container">
                <Button
                  type="black"
                  title="Send invitation"
                  onClick={(e) => { 
                    e.preventDefault();
                    validateMembers();
                  }}
                />
              </div>
            </form>
            
          </div>
        ) : popupType === "uploaded-project" ? (
          <div className="form-popup--project-uploaded">
            <h1 className="form-popup--project-uploaded__title">Your project has been uploaded succesfully!🎉</h1>
            <p className="form-popup--project-uploaded__text">
              Like to receive some tips on how to improve your upload?
              <br/>
              <a className="form-popup--project-uploaded__designer-guide" onClick={() => { window.open(improveYourUploadFile, "_blank"); }}>Download our guide.</a>
            </p>
            
            <div className="form-popup--project-uploaded__button-container">
              <Button
                type="pink"
                title="Upload another project"
                onClick={secondButtonAction}
              />
              <Button
                type="white"
                title="View my project"
                onClick={firstButtonAction}
              />
            </div>
          </div>
        ) : popupType === "sign-up" ? (
          <div className="form-popup--registration">
            <span>
              Already a member? &nbsp;
              <a onClick={firstButtonAction}>
                Log in
              </a>
            </span>
            <h1 className="form-popup--registration__title">Sign Up</h1>
            <Registration />
          </div>
        ) : popupType === "login" ? (
          <div className="form-popup--login">
            <span className="form-popup--login__sign-up">
              Don&apos;t have an account? &nbsp; <a onClick={firstButtonAction}>Sign Up</a>
            </span>
            <h4 className="form-popup--login__title">Log In</h4>
            <Login />
          </div>
        ) : (
          <form className="form-popup--reset-password" onSubmit={handlePasswordReset(handleResetPassword)}>
            <h2 className="form-popup--reset-password__title">Reset Password</h2>
            <p className="form-popup--reset-password__text">
              Enter your email and we&apos;ll send you a recovery link. Please also check your spam or junk folder.
            </p>
            <div className="form-popup--reset-password__input-container">
              <div className="form-popup--reset-password__error-container">
                <label>Email</label>
                {passwordResetState.errors && passwordResetState.errors?.email && (
                    <span>{passwordResetState.errors.email.message}</span>
                  )}
              </div>
              <input
                className={
                  passwordResetState.errors && passwordResetState.errors?.email
                    ? "form-popup--reset-password__input--error"
                    : "form-popup--reset-password__input"
                }
                type="text"
                placeholder="example@megosu.com"
                {...registerPasswordReset("email")}
              />
            </div>
            <div className="form-popup--reset-password__button-container">
              {loading ? (
                <Spinner type="megosu" />
              ) : (
                <>
                  <Button
                    type="white"
                    title="Cancel"
                    onClick={handleOnClickClose}
                  />
                  <Button
                    type="pink"
                    title="Send"
                    onClick={() => { handleResetPassword; }}
                  />
                </>
              )}
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default FormPopup;
