import { AuthHeader } from "../util/AuthHeader";

export function saveFavorite(userId, projectId) {
  const saveFavoriteUrl = process.env.REACT_APP_API_BASE_URL + "/users/profile/favorites";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify({ "id": {"projectId": projectId}, "project" : {"projectId": projectId }}),
  };

  return fetch(saveFavoriteUrl, options).then((response) => {
    if (response.ok) {
      return response.text();
    }
  });
}

export function getFavoritesIdsByProfileId(profileId) {
  const getFavoritesByProfileId =
    process.env.REACT_APP_API_BASE_URL + "/users/profile/" + profileId + "/favorite-ids";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getFavoritesByProfileId, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
}

export function getFavoritesByProfileId(profileId) {
  const getFavoritesByProfileId =
    process.env.REACT_APP_API_BASE_URL + "/users/profile/" + profileId + "/favorites";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getFavoritesByProfileId, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
}

export function getFavoriteProjectsByProfileId(profileId, fromIndex, toIndex) {
  let getFavoritesByProfileId = "";

  if (fromIndex !== undefined && toIndex !== undefined) {
    fromIndex = `?fromIndex=${fromIndex}`;
    toIndex = `&toIndex=${toIndex}`;

    getFavoritesByProfileId = process.env.REACT_APP_API_BASE_URL + "/users/profile/" + profileId + `/favorite-projects${fromIndex}${toIndex}`;
  } else {
    getFavoritesByProfileId = process.env.REACT_APP_API_BASE_URL + "/users/profile/" + profileId + "/favorite-projects";
  }
 
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getFavoritesByProfileId, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
}

export function getFavoritesInLocalStorage(profileId) {
  const getFavoritesByProfileIdUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/profile/" + profileId + "/favorite-ids";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getFavoritesByProfileIdUrl, options)
    .then((response) => {
      response.text().then((res) => {
        if (response.ok) {
          localStorage.setItem("favorites", res);
        } else {
          localStorage.setItem("favorites", JSON.stringify([]));
        }
      });
    })
    .then((data) => {
      return data;
    });
}

export function getFavoritesCountByProjectId(projectId) {
  const getFavoritesCountUrl =
    process.env.REACT_APP_API_BASE_URL + "/projects/" + projectId + "/favorites/count";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getFavoritesCountUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
}

export function getFavoriteUsersByProjectId(projectId) {
  const getFavoritesCountUrl =
    process.env.REACT_APP_API_BASE_URL + "/projects/" + projectId + "/favorites/users";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getFavoritesCountUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
}

export function removeFavorite(userId, projectId) {
  const removeFavoriteUrl = process.env.REACT_APP_API_BASE_URL + "/users/profile/favorites";
  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ "id": {"projectId": projectId}, "project" : {"projectId": projectId }}),
  };

  return fetch(removeFavoriteUrl, options).then((response) => {
    if (response.ok) {
      return response.text();
    }
  });
}
