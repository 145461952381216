import { GCS_ABOUT_PATH, GCS_PARTNERS_PATH, GCS_PITCH_PATH } from "./gcs-paths";

// about
export const GCS_ASSET_ABOUT_IMAGE = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_ABOUT_PATH}/About.png`;
export const GCS_ASSET_ABOUT_VIDEO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_ABOUT_PATH}/aboutVideo.mp4`;
export const GCS_ASSET_ABOUT_THUMBNAIL = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_ABOUT_PATH}/aboutVideoThumbnail.png`;

// pitch page
export const GCS_ASSET_PITCH_P1 = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/p1.m4v`;
export const GCS_ASSET_PITCH_P2 = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/p2.m4v`;
export const GCS_ASSET_PITCH_P3 = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/p3.m4v`;
export const GCS_ASSET_PITCH_P4 = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/p4.m4v`;
export const GCS_ASSET_PITCH_COVER_1 = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/Cover1.jpeg`;
export const GCS_ASSET_PITCH_COVER_2 = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/Cover2.jpeg`;
export const GCS_ASSET_PITCH_COVER_3 = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/Cover3.jpeg`;
export const GCS_ASSET_PITCH_COVER_4 = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/Cover4.jpeg`;
export const GCS_ASSET_PITCH_COVER_BECOME_A_PARTNER = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PITCH_PATH}/becomeAPartner.png`;

// partners
export const GCS_ASSET_WACOM_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/Wacom.svg`;
export const GCS_ASSET_BRAUN_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/Braun.svg`;
export const GCS_ASSET_KEYSHOT_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/Keyshot.svg`;
export const GCS_ASSET_MADECOM_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/MadeCom.svg`;
export const GCS_ASSET_VANMOOF_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/VanMoof.svg`;
export const GCS_ASSET_HERMAN_MILLER_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/HermanMiller.svg`;
export const GCS_ASSET_PICTORIGHT_FONDS_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/pictorightfonds.svg`;
export const GCS_ASSET_VT_WONEN_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/Vtwonen.svg`;
export const GCS_ASSET_OBJECT_EMBASSY_LOGO = process.env.REACT_APP_GCS_IMAGE_URL + `${GCS_PARTNERS_PATH}/Object Embassy.svg`;
