import React from "react";
import { Redirect, Route } from "react-router-dom";

import { getCurrentUserRole } from "../services/AuthService";

export const GuardedRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getCurrentUserRole() != null ? (
        <Component {...props} user={user} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);
