import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { RouterPrompt } from "../util/RouterPrompt";

import PopUp from "../components/molecules/PopUp";
import UploadProjectForm from "../components/molecules/UploadProjectForm";
import PostUploadProjectForm from "../components/molecules/PostUploadProjectForm";
import DocumentHead from "../components/atoms/DocumentHead";

import { getCurrentUser } from "../services/AuthService";
import { getProjectById } from "../services/ProjectService";

import "../styles/pages/UploadProjectPage.scss";

const UploadProjectPage = () => {
  const [proceed, hasProceeded] = useState(false);
  const [project, setProject] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [upload, hasUploaded] = useState(false);
  const [allowLeave, setAllowLeave] = useState(true);
  const [imageFiles, setImageFiles] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!getCurrentUser()) {
      history.push("/sign-up");
    }
  }, []);

  function navigateToProject(id) {
    getProjectById(id).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          history.push({
            pathname: `/project-details/${data.uuid}`,
          });
        });
      }
    });
  }

  return (
    <div
      className="upload-project"
      style={upload ? { height: "100vh" } : { height: "auto" }}
    >
      <DocumentHead
        title="Upload Project | Megosu"
        metaDescription="Upload your designs."
      />
      <UploadProjectForm
        proceed={proceed}
        hasProceeded={hasProceeded}
        type="upload"
        setProject={(e) => setProject(e)}
        setAllowLeave={setAllowLeave}
        setImageFiles={setImageFiles}
      />
      <PostUploadProjectForm
        proceed={proceed}
        hasProceeded={hasProceeded}
        project={project}
        upload={upload}
        onUpload={hasUploaded}
        setProjectId={(e) => setProjectId(e)}
        setAllowLeave={setAllowLeave}
        currentUser={getCurrentUser()}
        imageFiles={imageFiles}
      />
      <RouterPrompt
        when={!allowLeave}
        title="Are you sure you want to leave this page?"
        text="No data will be saved. Therefore the changes you made to your project will be lost."
        cancelText="Cancel"
        confirmText="Confirm"
      />
      <div className="uploaded-project">
        <PopUp
          overlay={false}
          visibility={upload}
          popupType="uploaded-project"
          handleOnClickClose={() => { history.push({ pathname: "/" }); }}
          firstButtonAction={(e) => {
            e.preventDefault();
            navigateToProject(projectId);
          }}
          secondButtonAction={() => { window.location.reload(); }}
        />
      </div>
    </div>
  );
};

export default UploadProjectPage;
