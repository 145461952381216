import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";

import { brands } from "../assets/data/DataList";

import scrollTrigger from "../util/ScrollReveal";

import "../styles/pages/BrandsPage.scss";

const BrandsPage = () => {
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const isTablet = width <= 1200;
  const ctaDesktopUrl = "brands/cta-megosu/Pitch%20Your%20Design%20-%20Megosu.png";
  const ctaMobileUrl = "brands/cta-megosu/Megosu.001.jpeg";

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className="brands reveal">
      <DocumentHead
        title="Testimonials | Megosu"
        metaDescription="Our mission is to unleash the potential of great product design through collaboration with design brands."
        showChat={true}
      />

      <div className="brands__title-container">
        <h2 className="brands__title">Some testimonials from<br/> our design brands</h2>
        <p className="brands__subtitle">Find out all brands <Link to="/pitch">here</Link></p>
      </div>

      <div className="brands__logos-intros">
        {brands.map((elem, i) => (
          <div className="brands__brand-image-container" key={i}>
            <img src={process.env.REACT_APP_GCS_IMAGE_URL + elem.logoImageUrl}></img>
          </div>
        ))}
      </div>

      <div className="brands__banners">
        {brands.map((elem, i) => (
          <img
            className="brands__banner-image"
            key={i}
            src={process.env.REACT_APP_GCS_IMAGE_URL + (!isTablet ? elem.desktopImageUrl : elem.mobileImageUrl)}
          >
          </img>
        ))}
      </div>

      <div className="brands__call-to-action">
        <img className={!isTablet ? "brands__call-to-action-image" : "brands__call-to-action-image brands__call-to-action-image--tablet"} src={process.env.REACT_APP_GCS_IMAGE_URL + (!isTablet ? ctaDesktopUrl : ctaMobileUrl)}></img>
        <div className={!isTablet ? "brands__image-overlay-container" : "brands__image-overlay-container brands__image-overlay-container--tablet"}>
          <h2 className={!isTablet ? "brands__image-overlay-title" : "brands__image-overlay-title brands__image-overlay-title--tablet"}>Pitch your designs to<br/> your favorite companies</h2>
          <Button 
            type="pink" 
            customClassName="brands__pitch-button" 
            title="Pitch now"
            onClick={() => { history.push("/pitch"); }}
          />
        </div>
      </div>
     
    </div>
  );
};

export default BrandsPage;
