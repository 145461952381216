import { AuthHeader } from "../util/AuthHeader";

export function createCheckoutSession(type, yearly) {
  const createCheckoutSessionUrl = process.env.REACT_APP_API_BASE_URL + `/create-checkout-session?type=${type}&yearly=${yearly}`;
  const options = {
    method: "POST",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(createCheckoutSessionUrl, options)
    .then((response) => {
      console.log(response.status);
      if (response.status === 303) {

        response.text().then(redirectUrl => {
          window.location.href = redirectUrl;
        });
      }
    });
}

export function createCustomerPortalSession(returnUrl) {
  const createCheckoutSessionUrl = process.env.REACT_APP_API_BASE_URL + `/create-customer-portal-session?returnUrl=${returnUrl}`;
  const options = {
    method: "POST",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(createCheckoutSessionUrl, options)
    .then((response) => {
      console.log(response.status);
      if (response.status === 303) {
        response.text().then(redirectUrl => {
          window.location.href = redirectUrl;
        });
      }
    });
}

export function cancelSubscription() {
  const cancelSubscriptionUrl = process.env.REACT_APP_API_BASE_URL + "/cancel-subscription";
  const options = {
    method: "POST",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(cancelSubscriptionUrl, options)
    .then((response) => {
      console.log(response.status);
    });
}

export function deleteSubscription() {
  const deleteSubscriptionUrl = process.env.REACT_APP_API_BASE_URL + "/delete-subscription";
  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(deleteSubscriptionUrl, options)
    .then((response) => {
      return response;
    });
}

export function getPaymentStatus() {
  const paymentStatusUrl = process.env.REACT_APP_API_BASE_URL + "/payment-status";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(paymentStatusUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getSubscriptionStatus() {
  const subStatusUrl = process.env.REACT_APP_API_BASE_URL + "/subscription-status";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(subStatusUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}