import { AuthHeader } from "../util/AuthHeader";

export function getHeaders() {
  const getHeadersUrl = process.env.REACT_APP_API_BASE_URL + "/headers";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
  
  return fetch(getHeadersUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
}

export function getHeadersByType(userIsLoggedIn) {
  let getHeadersUrl = "";

  if (userIsLoggedIn) {
    getHeadersUrl = process.env.REACT_APP_API_BASE_URL + "/headers/loggedIn";
  } else {
    getHeadersUrl = process.env.REACT_APP_API_BASE_URL + "/headers/loggedOut";
  }
  
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };
  
  return fetch(getHeadersUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
}
  