import React, { useEffect, useState } from "react";

import { FiUsers } from "@react-icons/all-files/fi/FiUsers";
import { FiUploadCloud } from "@react-icons/all-files/fi/FiUploadCloud";
import { MdWork } from "@react-icons/all-files/md/MdWork";

import { getAdminMainStatistics, getAdminProfileGoalStatistics, getAdminProjectGoalStatistics, getAdminRoleStatistics } from "../../services/SiteService";

import "../../styles/components/molecules/AdminStatisticsOverview.scss";

function AdminStatisticsOverview({
  currentUser,
  changeTab
}) {
  const [mainData, setMainData] = useState([]);
  const [projectGoalsData, setProjectGoalsData] = useState([]);
  const [profileGoalsData, setProfileGoalsData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    getAdminMainStatistics().then((res) => {
      setMainData(res);
    });

    getAdminProjectGoalStatistics().then((res) => {
      setProjectGoalsData(res);
    });

    getAdminProfileGoalStatistics().then((res) => {
      setProfileGoalsData(res);
    });

    getAdminRoleStatistics().then((res) => {
      setRoleData(res);
    });
  }, []);

  return (
    <>
      <div className="admin-statistics">
        <h2>Welcome back, {currentUser.profile.firstName}</h2>

        <div className="admin-statistics__card-container">
          <div className="admin-statistics__card">
            <div className="admin-statistics__card-column">
              <span className="admin-statistics__card-icon admin-statistics__card-icon--pink"><FiUsers/></span>
            </div>

            <div className="admin-statistics__card-column">
              <div className="admin-statistics__card-content">
                <h2 className="admin-statistics__card-number admin-statistics__card-icon--pink">{mainData["Users"]}</h2>
                <p className="admin-statistics__card-metric-name admin-statistics__card-icon--pink">{Object.keys(mainData).filter(elem => elem === "Users")}</p>
              </div>
            </div>
          </div>

          <div className="admin-statistics__card" onClick={() => { changeTab("Projects"); }}>
            <div className="admin-statistics__card-column">
              <span className="admin-statistics__card-icon"><FiUploadCloud/></span>
            </div>

            <div className="admin-statistics__card-column">
              <div className="admin-statistics__card-content">
                <h2 className="admin-statistics__card-number">{mainData["Uploads"]}</h2>
                <p className="admin-statistics__card-metric-name">{Object.keys(mainData).filter(elem => elem === "Uploads")}</p>
              </div>
            </div>
          </div>

          <div className="admin-statistics__card" onClick={() => { changeTab("Companies"); }}>
            <div className="admin-statistics__card-column">
              <span className="admin-statistics__card-icon"><MdWork/></span>
            </div>

            <div className="admin-statistics__card-column">
              <div className="admin-statistics__card-content">
                <h2 className="admin-statistics__card-number">{mainData["Companies"]}</h2>
                <p className="admin-statistics__card-metric-name">{Object.keys(mainData).filter(elem => elem === "Companies")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="admin-statistics__container">
          <div className="admin-statistics__box">
            <h2 className="admin-statistics__box-title">User Demographics</h2>
          
            {roleData && Object.keys(roleData).map((elem, i) => (
              <div key={i} className="admin-statistics__table">
                <div className="admin-statistics__table-row">
                  <p>{Object.keys(roleData)[i]}</p>
                  <span>{Object.values(roleData)[i]}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="admin-statistics__box">
            <h2 className="admin-statistics__box-title">Project Goals</h2>
          
            {projectGoalsData && Object.keys(projectGoalsData).map((elem, i) => (
              <div key={i} className="admin-statistics__table">
                <div className="admin-statistics__table-row">
                  <p>{Object.keys(projectGoalsData)[i]}</p>
                  <span>{Object.values(projectGoalsData)[i]}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="admin-statistics__box">
            <h2 className="admin-statistics__box-title">I&apos;m here to..</h2>
          
            {profileGoalsData && Object.keys(profileGoalsData).map((elem, i) => (
              <div key={i} className="admin-statistics__table">
                <div className="admin-statistics__table-row">
                  <p>{Object.keys(profileGoalsData)[i]}</p>
                  <span>{Object.values(profileGoalsData)[i]}</span>
                </div>
              </div>
            ))}
          </div>

          <br></br>

          <div className="admin-statistics__box">
            <h2 className="admin-statistics__box-title">Uploads Breakdown</h2>
          
            <div className="admin-statistics__table">
              <div className="admin-statistics__table-row">
                <p>Public & Archived:</p>
                <span>{mainData["Uploads"]}</span>
              </div>
            </div>
            <div className="admin-statistics__table">
              <div className="admin-statistics__table-row">
                <p>Public:</p>
                <span>{mainData["PublicUploads"]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminStatisticsOverview;