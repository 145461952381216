import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { withRouter } from "react-router";
import smoothscroll from "smoothscroll-polyfill";
import { HelmetProvider } from "react-helmet-async";

import "./App.scss";
import "./util/FetchInterceptor";
import Header from "./components/organisms/Header";
import Footer from "./components/organisms/Footer";

import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import HomePage from "./pages/HomePage";
import EditProjectPage from "./pages/EditProjectPage";
import EditAccountPage from "./pages/EditAccountPage";
import AccountSetupPage from "./pages/AccountSetupPage";
import UploadProjectPage from "./pages/UploadProjectPage";
import ProfilePage from "./pages/ProfilePage";
import AboutPage from "./pages/AboutPage";
import SuccessPage from "./pages/SuccessStoriesPage";
import GuidelinesPage from "./pages/GuidelinesPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import FaqPage from "./pages/FaqPage";
import ProjectDetailPage from "./pages/ProjectDetailPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NotFoundPage from "./pages/NotFoundPage";
import PartnersPage from "./pages/PartnersPage";
import AdminPage from "./pages/AdminPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import ChangeEmailConfirmationPage from "./pages/ChangeEmailConfirmationPage";
import ChatPage from "./pages/ChatPage";
import OAuthLoadingPage from "./pages/OAuthLoadingPage";
import HowItWorksPage from "./pages/HowItWorksPage";
import PitchPage from "./pages/PitchPage";
import BrandsPage from "./pages/BrandsPage";
import PdfRedirectPage from "./pages/PdfRedirectPage";
import FilesPage from "./pages/FilesPage";

import { getCurrentUser } from "./services/AuthService";
import { getUser } from "./services/UserService";
import { GuardedRoute } from "./util/GuardedRoute";

import DocumentHead from "./components/atoms/DocumentHead";
import { ThemeContext } from "./contexts/theme-context";

const Main = withRouter(({ location }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [role, setRole] = useState(null);
  const { pathname } = useLocation();
  const [notificationBarHidden, setNotificationBarHidden] = useState(false);
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    /* Polyfill for smooth scroll behaviour on Safari */
    smoothscroll.polyfill();
    window.scrollTo(0, 0);

    if (!currentUser) {
      const email = getCurrentUser();

      if (email) {
        getUser(email).then((user) => {
          if (user) {
            setRole(user.role.name);
            setCurrentUser(user);
          }
        });
      }
    }
  }, [pathname]);

  return (
    <div className="App">
      <DocumentHead
        title="Megosu - Product Design Platform"
        metaDescription="The leading and most inspiring platform for talented product designers worldwide."
      />
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <div className={`theme-${theme}`}>
          <Header user={currentUser} key={currentUser} setBarHidden={setNotificationBarHidden}/>

          <div className={!notificationBarHidden ? "App__content" : "App__content App__content--no-bar"}>
            <Switch>
              <Route path="/admin">
                {role === "admin" ? (<AdminPage user={currentUser} />) : (<NotFoundPage/>)}
              </Route>
              <Route exact path="/">
                <HomePage currentUser={currentUser} />
              </Route>
              <Route path="/login" component={LoginPage} />
              <Route path="/sign-up" component={RegistrationPage} />
              <Route path="/set-up" component={AccountSetupPage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <GuardedRoute
                path="/upload-project"
                component={UploadProjectPage}
              />
              <GuardedRoute
                path="/settings"
                component={() => <EditAccountPage user={currentUser} setCurrentUser={setCurrentUser}/>}
              />
              <Route 
                path="/profile/:id" 
                component={() => <ProfilePage currentUser={currentUser}/>}
              />
              <GuardedRoute
                path="/edit-project/:id"
                component={EditProjectPage}
              />
              <GuardedRoute
                path="/chat"
                component={ChatPage}
                user={currentUser}
              />
              <Route
                path="/project-details/:id"
                render={({ match }) => <ProjectDetailPage key={match.params.id} user={currentUser} />}
              />
              <Route path="/how-it-works" 
                component={() => <HowItWorksPage currentUser={currentUser}/>} 
              />
              <Route path="/pitch">
                <PitchPage currentUser={currentUser} />
              </Route>
              <Route path="/partners" component={PartnersPage} />
              <Route path="/success-stories/:id" component={SuccessPage} />
              <Route path="/guidelines" component={GuidelinesPage} />
              <Route path="/terms" component={TermsPage} />
              <Route path="/policy" component={PrivacyPolicyPage} />
              <Route path="/faq" component={FaqPage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/pricing" component={SubscriptionPage} />
              <Route path="/testimonials" component={BrandsPage}/>
              <Route path="/tools" component={FilesPage}/>
              <Route component={ChangeEmailConfirmationPage} path="/email-confirmation" />
              <Route path="/oauth" component={OAuthLoadingPage}/>
              <GuardedRoute path="/admin" component={AdminPage} />
              <Route path={"/open-pdf"} component={PdfRedirectPage}/>
              <Route component={NotFoundPage} />
            </Switch>
          </div>
          {
            location.pathname !== "/login" &&
  location.pathname !== "/admin" &&
  location.pathname !== "/sign-up" &&
  location.pathname !== "/set-up" &&
  location.pathname !== "/email-confirmation" &&
  location.pathname !== "/settings" &&
  location.pathname !== "/upload-project" &&
  location.pathname !== "/reset-password" &&
  location.pathname !== "/chat" && 
  location.pathname.indexOf("/edit-project") ? (
                <Footer user={currentUser} />
              ) : null /* Solution for issue with 0 at bottom of page */
          }
        </div>
      </ThemeContext.Provider>
    </div>
  );
});

const App = () => (
  <HelmetProvider>
    <Router getUserConfirmation={() => {/* Empty callback to block the default browser prompt */}}>
      <Main />
    </Router>
  </HelmetProvider>
);

export default App;
