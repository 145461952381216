import "../../styles/components/atoms/Checkbox.scss";

function Checkbox(props) {
  
  return <label className="checkbox">
    <input
      name={props.name}
      id={props.id}
      index={props.index}
      value={props.value}
      type="checkbox"
      className={props.fieldName}
      onClick={props.onHandle}
      onChange={props.onChange}
      checked={props.checked}
    />
    <span className="checkmark"/>
  </label>;

}

export default Checkbox;