import React, { useRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Button from "../atoms/Button";
import PopUp from "./PopUp";

import Illustration from "../../assets/images/SidebarImage-v2.webp";

import "../../styles/components/molecules/NavBar.scss";

function NavBar({ isClicked, setIsClicked, currentUser, initialRole, notificationBarVisible}) {
  const history = useHistory();
  const ref = useRef(null);

  const [staticArrow, setStaticArrow] = useState(false);
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 
  const [accountSetupPopupVisible, setAccountSetupPopupVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    const handleClickOutside = (event) => {
      let navbarTransition = event.target?.className;
  
      if (!navbarTransition) {
        navbarTransition = "";
      }
  
      if (ref.current && !ref.current.contains(event.target) && (typeof navbarTransition == "string") && !navbarTransition.includes("navbar-transition")) {
        setIsClicked(false);
      }
    };
  
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    document.addEventListener("click", handleClickOutside, true);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
  // Prevent being able to scroll page behind nav while in mobile nav
    if (isMobile) {
      if (isClicked) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    }
  }, [isMobile]);

  const handleUpgrade = () => {
    history.push({
      pathname: "/pricing",
    });
    setIsClicked(false);
  };

  const handleLogInClick = () => {
    if (window.location.pathname !== "/set-up") {
      setLoginPopupVisible(true);
    } else {
      setAccountSetupPopupVisible(true);
    }
  };

  const handleMobileNavigationChange = () => {
    if (isMobile) {
      setIsClicked(false);
    } else {
      return;
    }
  };

  const handleOnClickLink = () => {
    handleMobileNavigationChange();
    setIsClicked(false);
  };

  const onClickCommunity = () => {
    if (!currentUser) {
      setSignUpPopUpVisible(true);
    } 

    setIsClicked(false);
  };

  const homePageScrollHandler = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      sessionStorage.removeItem("scrollPosition");
    }
  };

  const handleExploreClick = () => {
    handleMobileNavigationChange(); 
    setIsClicked(false);
    homePageScrollHandler();
  };

  return (
    <>
      <div
        ref={ref}
        className={notificationBarVisible ? "navbar navbar--transform-up" : "navbar"}
        style={
          isClicked
            ? { transform: "translateX(0px)" }
            : { transform: "translateX(100%)" }
        }
      >
        <div className="navbar__content">
          <div>
            {!isMobile && (
              <div className="navbar__img-container">
                <img
                  src={Illustration}
                  className="navbar__feature-img"
                  alt="navigation"
                />
              </div>
            )}
            <ul 
              className="navbar__list"
              onMouseEnter={() => { setStaticArrow(true); }}
              onMouseLeave={() => { setStaticArrow(false); }}
            >
              <li className={`navbar__list-item ${!staticArrow && "navbar__item-static-arrow"}`}>
                <Link
                  to="/"
                  onClick={handleExploreClick}
                  className="navbar__list-item-top"
                >
                    Start Exploring
                </Link>
              </li>
              <li className="navbar__list-item">
                <Link
                  to="/pitch"
                  onClick={handleOnClickLink}
                  className="navbar__list-item-top"
                >
                  Pitch Design<h6 className="form__social-menu-boosted">Beta</h6>
                </Link>
              </li>
              <li className="navbar__list-item">
                {initialRole === "pro-user" || initialRole === "business-user" || initialRole === "pitch-user" || initialRole === "free-user" ? (
                  <a
                    href="https://community.megosu.com/users/sign_in"
                    target="_blank"
                    rel="noreferrer"
                    className="navbar__list-item-top"
                  >
                  Community
                  </a>
                ) : (
                  <a className="navbar__list-item-top" onClick={onClickCommunity}>
                    Community
                  </a>
                )}
              </li>
              <li className="navbar__list-item">
                <Link
                  to="/tools"
                  onClick={handleOnClickLink}
                  className="navbar__list-item-top"
                >
                  Tools
                </Link>
              </li>
              <li className="navbar__list-item navbar__list-item-bottom-first">
                <Link className="navbar__list-item-bottom" to="/about" onClick={handleOnClickLink}>
                About
                </Link>
              </li>
              <li className="navbar__list-item">
                <Link className="navbar__list-item-bottom" to="/pricing" onClick={handleOnClickLink}>
                Pricing
                </Link>
              </li>
              <li className="navbar__list-item">
                <Link className="navbar__list-item-bottom" to="/success-stories/moooi" onClick={handleOnClickLink}>
                Success Stories
                </Link>
              </li>
              <li className="navbar__list-item">
                <Link className="navbar__list-item-bottom" to="/testimonials" onClick={handleOnClickLink}>
                Testimonials
                </Link>
              </li>
              <li className="navbar__list-item">
                <Link className="navbar__list-item-bottom" to="/faq" onClick={handleOnClickLink}>
                FAQ
                </Link>
              </li>
            </ul>
          </div>

          <div className="navbar__buttons">
            <div>
              {currentUser ? (
                <Button
                  type="pink"
                  onClick={handleUpgrade}
                  className="navbar-buttons__second"
                  customClassName="button--navbar-top"
                  title="Upgrade"
                />
              ) : (
                <Button
                  type="pink"
                  onClick={() => {
                    history.push("/sign-up");
                    setIsClicked(false);
                  }}
                  className="navbar-buttons__second"
                  customClassName="button--navbar-top"
                  title="Upload"
                />
              )}
              <Button
                type="white"
                onClick={currentUser ? () => { history.push({ pathname: "/upload-project" }); setIsClicked(false); } : () => { handleLogInClick(); setIsClicked(false);}}
                customClassName="button--navbar-bottom"
                className="navbar-buttons__second"
                title={currentUser ? "Upload" : "Login"}
              />
            </div>
          </div>
        </div>
      </div>

      <PopUp
        visibility={accountSetupPopupVisible}
        overlay={true}
        handleOnClickClose={() => setAccountSetupPopupVisible(false)}
        popupTitle="Finish your profile first"
        popupText="It just takes 2 minutes to fill out all the missing fields- then you can use Megosu to the fullest."
        firstButtonTitle="Complete Profile"
        firstButtonType="white"
        firstButtonAction={() => setAccountSetupPopupVisible(false)}
      />

      <PopUp 
        visibility={signUpPopUpVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => setSignUpPopUpVisible(false)}
        popupType="sign-up" 
      />

      <PopUp 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />
    </>
  );
}

export default NavBar;
