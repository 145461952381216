import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { IoLocationSharp } from "@react-icons/all-files/io5/IoLocationSharp";
import { FiPaperclip } from "@react-icons/all-files/fi/FiPaperclip";
import { FiImage } from "@react-icons/all-files/fi/FiImage";
import { IoIosCloseCircle } from "@react-icons/all-files/io/IoIosCloseCircle";

import Button from "../atoms/Button";
import Popup from "./PopUp";
import LoadingSpinner from "../atoms/Spinner";

import profilePlaceholder from "../../assets/images/ProfilePlaceholder.png";

import { getUserById } from "../../services/UserService";
import { downloadChatAttachment } from "../../services/ImageService";

import "../../styles/components/molecules/ChatDetail.scss";

const ChatDetail = ({ selectedChat, setSelectedChat, isMobile, text, setText, sendMessage, chatMessages, deleteChat, setLoading, loading, hasResizedWindow }) => {
  const DATE_OPTIONS = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
  const [user, setUser] = useState(null);
  const [deletePopUpActive, setActiveDeletePopUp] = useState(false);
  const [previewImageFiles, setPreviewImageFiles] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [invalidImage, setInvalidimage] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const history = useHistory();

  useEffect(() => {
    scrollToLastMessage();

    getUserById(selectedChat.recipientId).then(user => {
      if (user) {
        setUser(user);
      }
    });

    getUserById(selectedChat.senderId).then(user => {
      if (user) {
        scrollToLastMessage();
      }
    });

    if (chatMessages.length > 0) {
      setLoadingMessages(false);
    }

    setText("");
  }, [selectedChat, chatMessages]);

  const scrollToLastMessage = () => {
    const messages = document.getElementsByClassName("chat-detail__messages")[0];
    messages.scrollTop = messages.scrollHeight;
  };

  const navigateToProfile = (senderId) => {
    if (senderId == user.userId) { 
      history.push("/profile/" + selectedChat.recipientId + "/");
    } else {
      history.push("/profile/" + selectedChat.senderId + "/"); 
    }
  };

  const messageUserImage = (senderId) => {
    if (senderId == user.userId) { 
      return selectedChat && selectedChat.recipientImageUrl ? "url(" + process.env.REACT_APP_GCS_IMAGE_URL + selectedChat.recipientImageUrl + ")" : `url(${profilePlaceholder})`;
    } else {
      return selectedChat && selectedChat.senderImageUrl ? "url(" + process.env.REACT_APP_GCS_IMAGE_URL + selectedChat.senderImageUrl + ")" : `url(${profilePlaceholder})`;
    }
  };
  const handleFileInput = (event, type) => {
    const inputFile = event.target.files[0];

    if (inputFile) {
      event.target.isRemove = false;
      const reader = new FileReader();
      if (type === "image") {
        reader.onload = function (e) {
          const imageFile = e.target.result;

          const allowedMimes = ["jpg", "jpeg", "png"];
          const checkType = imageFile.split("image/").pop().split(";")[0];
          const maxMb = 5;
          const mb = inputFile.size / 1024 / 1024;

          if (!imageFile) {
            setInvalidimage("Please select an Image");
          } else if (!allowedMimes.includes(checkType)) {
            setInvalidimage("Please select an image of type: jpg or png.");
          } else if (mb > maxMb) {
            setInvalidimage(`File size (${mb.toFixed(2)}MB) is too big, please select an image with a maximum file size of ${maxMb}MB.`);
          }
          setPreviewImageFiles([...previewImageFiles, {name: inputFile.name, URI: imageFile, type: "image"}]);
        };
        reader.readAsDataURL(inputFile);


      } else {
        reader.onload = function (e) {  
          const uploadFile = e.target.result;
          const allowedMimes = ["data:application/pdf", "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document", "data:application/vnd.openxmlformats-officedocument.presentationml.presentation"];
          const checkType = uploadFile.split("data/").pop().split(";")[0];
          const maxMb = 5;
          const mb = inputFile.size / 1024 / 1024;

          if (!uploadFile) {
            setInvalidimage("Please select a file");
          } else if (!allowedMimes.includes(checkType)) {
            setInvalidimage("Please select a file of type: .pdf, .docx or .pptx.");
          } else if (mb > maxMb) {
            setInvalidimage(`File size (${mb.toFixed(2)}MB) is too big, please select a file with a maximum file size of ${maxMb}MB.`);
          }
          setPreviewFiles([...previewFiles, {name: inputFile.name, URI: uploadFile, type: "file"}]);
        };

        reader.readAsDataURL(inputFile);
      }
    }
  };

  const unSelect = (name, type) => {
    if (type === "image") {
      let currentPreview = previewImageFiles;
      currentPreview = currentPreview.filter((file) => file.name !== name);
      setPreviewImageFiles(currentPreview);

      document.getElementById("imageInput").value = "";
      
    } else if (type === "file") {
      let currentPreview = previewFiles;
      currentPreview = currentPreview.filter((file) => file.name !== name);
      setPreviewFiles(currentPreview);

      document.getElementById("attachmentInput").value = "";
    }
  };

  const handleSend = () => {
    if (text === "") {
      setInvalidimage("Please type a message.");
      return;
    }

    if (previewImageFiles.length === 0 && previewFiles.length === 0) {
      sendMessage(text, 0); 
      setText("");
    } else {
      setLoading(true);
      sendMessage(text, previewImageFiles, previewFiles); 
      setPreviewImageFiles([]);
      setPreviewFiles([]);
    }
  };

  const downloadFile = (url) => {
    downloadChatAttachment(url).then((res) => {
      // retrieve the blob via the backend from the GCS
      let blobUrl = "";
      if (String(url).includes("image")) {
        // if we are downloading an image, explicitly assign the file type to ensure the file is downloaded correctly
        var blob = new Blob([res], {type: "image/png"});
        blobUrl = window.URL.createObjectURL(blob);
      } else {
        // if our file is not an image, the OS of the client should detect the file type automatically
        blobUrl = window.URL.createObjectURL(res);
      }
      
      // trigger the client's window to download the file
      const a = document.createElement("a");
      a.download = url.replace(/^.*[\\/]/, "");
      a.href = blobUrl;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };

  return (
    <div className={hasResizedWindow ? "chat-detail" : "chat-detail reveal"}>
      <div className="chat-detail__container">
        {isMobile && (
          <div className="chat-detail__mobile-button-container">
            <Button type="white" customClassName="button--white-form" title="&#8592;  Chats" onClick={() => { setSelectedChat(null); }}/> 
            <Button type="white" customClassName="button--white-form" title="Delete" onClick={() => { setActiveDeletePopUp(true); }}/>
          </div>
        )}
        <div className="chat-detail__title-info-container">
          <div className="chat-detail__recipient-info-container">
            <div
              id="head-image-preview"
              className="header__user-image-container"
              style={{
                backgroundImage: selectedChat && selectedChat.recipientImageUrl ? "url(" + process.env.REACT_APP_GCS_IMAGE_URL + selectedChat.recipientImageUrl + ")" : `url(${profilePlaceholder})`,
                cursor: "pointer"
              }}
              onClick={() => { history.push("/profile/" + user.uuid + "/"); }}
            />
            <div className="chat-detail__text-info-container">
              <Link to={user ? "/profile/" + user.uuid : "/profile/" + selectedChat.recipientId}>
                <h1 className="chat-detail__text-header">
                  {selectedChat && " " + selectedChat.recipientName}
                </h1>
              </Link>
              <span className="chat-detail__info-location">
                <IoLocationSharp />
                {user &&  (
                  <>
                    {user.profile.city},&nbsp;{user.profile.country.name}
                  </>
                )
                }
              </span>
            </div>
          </div>
          {!isMobile && <div className="chat-detail__button-container"><Button type="white" customClassName="button--white-form" title="Delete" onClick={() => { setActiveDeletePopUp(true); }}/></div>} 
        </div>
        <ul className="chat-detail__messages">
          {!loadingMessages ? (
            <>
              {chatMessages && chatMessages.map((message, i) => (
                <li key={`chat-detail__message-${i}`}>
                  <>
                    {user ? (
                      <>
                        <div className="chat-detail__message-container">
                          <div className="chat-overview__info-container">
                            <div className="chat-overview__user-info-container">     
                              <div
                                id="profile-image"
                                className="header__user-image-container"
                                style={{ backgroundImage: messageUserImage(message.senderId) }}
                                onClick={() => { navigateToProfile(message.senderId); }}
                              /> 
                         
                              <div className="chat-overview__user-text-container">     
                                <span 
                                  className="chat-overview__user-name" 
                                  onClick={() => { navigateToProfile(message.senderId); }}
                                >
                                  {message.senderName}
                                </span>
                                <div className="chat-detail__date-container">
                                  {(
                                    <>
                                      {new Intl.DateTimeFormat("en-GB", { 
                                        month: "long", 
                                        day: "2-digit",
                                        year: "numeric", 
                                      }).format(new Date(message.timestamp))}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-detail__message-text">{message.content}</div>

                          <div className="chat-detail__content-container">
                            {message.attachments && message.attachments.length > 0 && message.attachments.map((elem, i) => (
                              <>
                                {String(elem.url).includes("image") && (
                                  <div className="chat-detail__content-image" onClick={() => { downloadFile(elem.url); }}>
                                    <img key={i} src={process.env.REACT_APP_GCS_IMAGE_URL + elem.url}></img>
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                          {message.attachments && message.attachments.length > 0 && message.attachments.map((elem, i) => (
                            <>
                              {!String(elem.url).includes("image") && (
                                <div onClick={() => { downloadFile(elem.url); }} key={i} className="chat-detail__preview-other-file chat-detail__preview-other-file--downloadable">
                                  <p className="chat-detail__preview-other-file-text"><FiPaperclip/> {String(elem.url).substring(elem.url.lastIndexOf("/") + 1, elem.url.length)}</p>
                                </div>
                              )}
                            </>
                          ))}  
                        </div>                                   
                      </>
                    ) : null}
                  </>
                </li>
              ))}
            </>
          ) : (
            <LoadingSpinner type="megosu"/>
          )}
        </ul>

        <div className="chat-detail__send-message">
          <textarea
            className="chat-detail__message-input"
            maxLength={5000}
            placeholder="Type a message"
            value={text}
            disabled={loading}
            onChange={(e) => setText(e.target.value)}
          />
          <div className="chat-detail__preview-files">
            <div className="chat-detail__preview-image-files">
              {previewImageFiles && previewImageFiles.map((file, i) => (
                <div key={i} className={`chat-detail__preview-image-file chat-detail__preview-image-file-${i}`}>
                  <IoIosCloseCircle onClick={() => { unSelect(file.name, "image"); }} />
                  <img src={file.URI} />
                </div>
              ))}
            </div>
          </div>  

          <div className="chat-detail__preview-files">
            <div className="chat-detail__preview-other-files">
              {previewFiles && previewFiles.map((file, i) => (
                <div key={i} className={`chat-detail__preview-other-file chat-detail__preview-other-file-${i}`}>
                  <IoIosCloseCircle onClick={() => { unSelect(file.name, "file"); }} />
                  <FiPaperclip/>
                  <p className="chat-detail__preview-other-file-text">{file.name}</p>
                </div>
              ))}
            </div>
          </div>
           
          <div className="chat-detail__buttons">
            <div className="chat-detail__icons">
              <form encType="multipart/form-data">
                <label htmlFor="attachmentInput" className="chat-detail__label-input"><FiPaperclip /></label>
                <input 
                  id="attachmentInput"
                  type="file" 
                  name="upload" 
                  className="chat-detail__input"
                  accept=".pdf,.docx,.pptx"
                  onInput={(e) => {  handleFileInput(e, "file"); }}
                />
              </form>
              <form encType="multipart/form-data">
                <label htmlFor="imageInput" className="chat-detail__label-input"><FiImage /></label>
                <input 
                  id="imageInput"
                  type="file" 
                  name="upload" 
                  className="chat-detail__input"
                  accept="image/*" 
                  onInput={(e) => {  handleFileInput(e, "image"); }}
                />
              </form>
            </div>
            {invalidImage && (
              <div className="form-error">{invalidImage}</div>
            )}
            <Button type="pink" onClick={() => { handleSend(); }} title={!loading ? "Reply" : "Sending.."} />
          </div>
        </div>
      </div>

      <Popup
        visibility={deletePopUpActive}
        overlay={true}
        handleOnClickClose={() => { setActiveDeletePopUp(false); }}
        popupTitle="Are you sure you want to delete this chat?"
        popupText="All messages will be saved. You can always start your chat again"
        firstButtonTitle="Keep"
        firstButtonAction={() => { setActiveDeletePopUp(false); }}
        secondButtonTitle="Delete"
        secondButtonAction={() => { deleteChat(); setActiveDeletePopUp(false); }}
      />
    </div>
  );
};
  
export default ChatDetail;