import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill";

import Button from "../components/atoms/Button";
import Faq from "react-faq-component";
import DocumentHead from "../components/atoms/DocumentHead";

import { getCurrentUser } from "../services/AuthService";

import "../styles/pages/FaqPage.scss";

import FeatureImage from "../assets/images/Faq.jpg";

import scrollTrigger from "../util/ScrollReveal";

const FaqPage = () => {
  const [currentUser, setCurrentUser] = useState(false);

  useEffect(() => {
    const email = getCurrentUser();
    setCurrentUser(email);
  });

  const sendEmail = (email) => {
    window.location = "mailto:" + email;
  };

  const config = {
    arrowIcon: <BsFillCaretDownFill className="checked-dropdown__caret-icon" />,
  };

  const faqQuestions = [
    {
      FaqContainertitle: "Megosu?",
      rows: [
        {
          title: "Why explore via Megosu?",
          content: <><p className='faq__text-container'>Have you ever wondered how you’ll find design that’s already ten year old, let alone design from the past century? Or do you know where to find that next talent out of Singapore, or that senior designer from Denmark? And what platform are the big designbrands checking every single day? </p><p className='faq__text-container--spacing'>At Megosu we bring the whole design community together- and by doing so we create fruitful partnerships that never would have happened without us. But what makes us unique? Well, that’s our filter system in which you’ll use criteria that are relevant for you. So just in a matter of seconds you’ll find what you’re looking for whether that being seating made of metal from an experienced designer or design that’s made from biomaterials by designers from the United States. Find design and start collaborating faster than ever before!</p></>
        },
        {
          title: "What’s Megosu’s mission?",
          content: <><p className='faq__text-container'>Designers are the most creative beings around, they work their magic on all kinds of topics. Unfortunately most of their incredible designs disappear under a layer of dust. It’s just hard to find the right people to further develop your design. But that changes now. With Megosu we created one place to find all the designs- and designers. With Megosu we’ll help you to connect with our worldwide community of designers and to put projects into the market, hire designers and to enrich our world through design. Read <Link to='/about'>Our Story</Link> here.</p></>
        },
        {
          title: "Could you share your plans for the future?",
          content: <><p className='faq__text-container'>You know the saying dream big or go home? It’s something we live by. To put it mildly, we believe designers are here to change the world for the better. Together we’ll change the industry to be more innovative & sustainable. In the near future we like to help numerous designers to find great collaborations with incredible businesses- and the companies in turn to lots of inspiring projects and great designers working for them. So to summarize our go big or go home dream: Megosu to be the go-to place for product designers. </p></>
        },
        {
          title: "Why would I upload my projects at Megosu?",
          content: <><p className='faq__text-container'>Let’s be fair, everyone wants to have a place to show their designs, right? It’s even better that here at Megosu we attract thousands and thousands to have a look at your work- the designers, designlovers and well-acknowledged design brands! So if you’re ready to connect and create fruitful partnerships? <Link to= {currentUser ? "/upload-project" : "/login"}>Start uploading!</Link></p></>
        },
        {
          title: "Who’s behind the platform?",
          content: <><p className='faq__text-container'>Learn more about us and our team via our <Link to='/about'>About page</Link>.</p></>
        }
      ]
    },
    {
      FaqContainertitle: "Designs",
      rows: [
        {
          title: "What happens after I’ve uploaded my project?",
          content: <><p className='faq__text-container'>Congrats! You’re part of Megosu and your design is already online and can be discovered by others! What now? Well, You can upload more projects or start exploring everything at Megosu. </p><p className='faq__text-container--spacing'>We’ll review every project that’s been uploaded and if it aligns with our <Link to='/guidelines'>Community Guidelines</Link> you’ll receive a confirmation that your work is officially part of our platform. </p></>
        },
        {
          title: "How do you guarantee the highest quality of designs?",
          content: <><p className='faq__text-container'>Fair question, since the quality of design projects varies a lot. At Megosu we want to reward those with outstanding work and we like to challenge others to improve. Hence is why we have a curation process and we reward every upload with a rating that will result in a higher or lower position within the database. Henceforth the higher ranked projects will automatically be easier to find. But don’t you worry now, other projects can still be found with relative ease- but they’ll just appear lower on an explore page.</p></>
        },
        {
          title: "Could you elaborate on your curation process?",
          content: <><p className='faq__text-container'>Of course we can! Curating design is difficult, because every single project is different and made by someone with a different kind of skillset. Still we’ve come up with three pillars of how we curate design.</p><ul className='faq__list--spacing'><li className='faq__list-item--ordered'>Is it innovative? What’s the purpose of the project and will it benefit the user or world? Is it new?</li><li className='faq__list-item--ordered'>How sustainable is the project, is it manufacturable at scale and is it inclusive in its use?</li><li className='faq__list-item--ordered'>Are the aesthetics appealing to whoever’s gonna use it. Design should be timeless, to be longlasting and useful in the longterm.</li></ul><p className='faq__text-container--spacing'>These three pillars are of the utmost importance to us- besides aesthetically pleasing visuals, process material and great research. After our curation process your design will either be accepted, rejected or you’re given feedback on how to improve your upload. Most projects will make an appearance on our platform, because we believe that all design (and their designers) have the potential to evolve in something that’ll better the world.</p></>
        },
        {
          title: "How do I get featured on your Social Media channels?",
          content: <><p className='faq__text-container'>Well, that’s easy. Just upload your work on Megosu. That’s it- plain and simple. We have a look at every single project that’s featured and by uploading it onto the platform you’ll automatically have the chance of being featured, you don’t have to do anything extra! Just sit back and relax! :)</p></>
        },
        {
          title: "How do I update my projects?",
          content: <><p className='faq__text-container'>Easy! Just login to your account then click on &quot;Profile&quot;. From here you can see a list of all your projects. Just select the project you would like to edit and just simply click on the thumbnail. At the left you’ll now find a button stating &quot;Edit Project&quot;.</p></>
        },
        {
          title: "Can I see who saved my project?",
          content: <><p className='faq__text-container'>No, it’s private for our users.</p></>
        },
        {
          title: "I wanna improve my chances of being discovered by top notch companies. How should I move forward?",
          content: <><p className='faq__text-container'>First and foremost it’s important to design the best projects you can? Done? Then visualize your design the best way you can. Megosu is an aesthetic platform and you’ve got to compete against the best designers in the world- and your visuals will make for the first impression.</p><p className='faq__text-container--spacing'>PS. Did you know that by selecting our <Link to='/pricing'>Pro account</Link> you are 3x more likely to be discovered by potential clients since you’ll boost your projects? That’s huge!</p></>
        },
        {
          title: "What kind of media can I upload?",
          content: <><p className='faq__text-container'>Images: jpg, jpeg, png<br/>Multimedia: can be added via the embed option.</p><p className='faq__text-container--spacing'>Each of your images should be kept preferably at around 100kb. Images should be saved at 72dpi.</p><p className='faq__text-container--spacing'>Images can be uploaded at any size, although the first two images are portrayed in the explore page and should look great in a 7:8 aspect ratio. </p><p className='faq__text-container--spacing'>In order to keep our website as fast as possible we’ll put on an automatic image compressor.</p></>
        },
        {
          title: "What happens with my designer rights?",
          content: <><p className='faq__text-container'>Your project is and always remains yours- uploading it onto Megosu gives you the possibility to extend your network and to hopefully start new collaborations through your project.</p></>
        },
        {
          title: "I’m affraid people will steal my idea, can you protect my idea?",
          content: <><p className='faq__text-container'>We will always try to delete people who are on the platform for the wrong reasons- this is also stated in our <Link to="/terms">Terms of Service</Link>. Please <Link to="#" onClick={() => sendEmail("support@megosu.com")}>contact us</Link> for support if you became aware of suspicious behaviour, so we can try to provide a solution. Please note that we cannot guarantee total safety, unfortunately there are still too many people who like to take advantage of the creativity of designers. But by uploading it onto our platform you’ll also have the possibilty to meet incredible people from within the design industry, people that you might never have met if you didn’t put your work out there. It’s always a risk showing your work to people, but your work exists to make the world a little more beautiful and help shape it for the bettter- and that requires courage and faith in the humanity of people. </p></>
        },
        {
          title: "My upload was rejected, why?",
          content: <><p className="faq__text-container">The most frequent reason for a rejection is that your project is not focussed on Product/Industrial Design. We’re a platform focussing on this type of projects- hence is why all projects must have some sort of physical existence (we’re not digitally focussed). Another common reason for rejection is that you’ve uploaded a project that’s not really finished (e.g. just a sketch) or that you’ve violated our code of conduct.</p></>
        }
      ]
    },
    {
      FaqContainertitle: "Collaborate",
      rows: [
        {
          title: "Am I allowed to reach out to as many designers as I want?",
          content: <><p className='faq__text-container'>Yes you are! We strive to provide for as many collaborations as possible. But please don’t spam everyone with the same message, for guidelines on this you can check out our <Link to='/guidelines'>Community Guidelines</Link>.</p></>
        },
        {
          title: "What happens after I get in touch with a designer?",
          content: <><p className='faq__text-container'>First the designer reviews whether or not your request or question is interesting. If so you’re free to go and to explore how you can start collaborating. We’re happy to assist you to give you guidelines on how to collaborate in the best way. </p></>
        },
        {
          title: "Is Megosu in any way involved in the collaboration between designers and companies?",
          content: <><p className='faq__text-container'>It’s up to you and the designer(s) to find the best conditions to start your partnership. We are always available to share our vision and help you out during this process. Just <Link to="#" onClick={() => sendEmail("support@megosu.com")}>send us a message</Link> and explain your wishes and our team will get back to you.</p></>
        },
        {
          title: "Can I have unlimited partnerships?",
          content: <p className='faq__text-container'>Of course! As a matter of fact, that’s what we hope to provide for as many people as possible. So yes, start collaborating and change the world for the better!</p>
        },
        {
          title: "What are the benefits of the community?",
          content: <><p className="faq__text-container">
          In the community you’ll meet likeminded designers- designers who are eager to learn and to help one another sharing their experiences, skills and viewing. Besides you’ll find lots of great tools, tips & tricks, courses, portfolio tips and much much more. Curious to know what members got to say? <Link to="/pricing">Have a look at some testimonials</Link>.
          </p></>
        },
        {
          title: "Collaborate with Megosu?",
          content: <><p className="faq__text-container">
          Are you a brand, university or just an individual. No matter who you are we can help you! In the past we’ve worked with big brands such as Wacom, Herman Miller, Braun, Keyshot, Made.com, VTWonen and many more. Maybe you’ll be next? <Link to="#" onClick={() => sendEmail("partners@megosu.com")}>contact us</Link> with your idea of a potential partnership.
          </p></>
        },
      ],
    },
    {
      FaqContainertitle: "Profile",
      rows: [
        {
          title: "Is my profile visible for everyone?",
          content: <><p className="faq__text-container">
          Both designers and designlovers have a personal profile. But your account will only be visible to others until you’ve uploaded a project, since it can only be reached via the <Link to="/">Explore page</Link>. But you can share the link with others, so they can check out your profile!
          </p></>
        },
        {
          title: "Can others see my saves?",
          content: <><p className="faq__text-container">
          No, they’re private. We’ve opted for this to give you ultimate safety in saving projects for future reference and to connect privately with the designers of your interest. 
          </p></>
        },
        {
          title: "How do I know how many people have viewed my profile?",
          content: <><p className="faq__text-container">
          At the moment this service isn’t available. It’s on our list of things we like to add to the platform in the future. Stay tuned!          </p></>
        }
      ]
    },
    {
      FaqContainertitle: "Account & Billing",
      rows: [
        {
          title: "I can’t log in to my account, What should I do?",
          content: <><p className="faq__text-container">
            You can have your login information resent to you via email by using the ‘forget your password’ functionality. If that doesn&apos;t work then <Link to="#" onClick={() => sendEmail("support@megosu.com")}>shoot us a message</Link> and our helpful staff will take care of you.
          </p></>
        },
        {
          title: "How can I reset my password or email address?",
          content: <><p className="faq__text-container">
            Simply login to your account then click on the “Profile” button. Hereafter you’ll select “Edit Profile”. Here you can change your password and the email address associated with the account.
          </p></>
        },
        {
          title: "I don’t remember my password and the email address I used to sign up is inaccessible. Help!",
          content: <><p className="faq__text-container">
            You will have to <Link to="#" onClick={() => sendEmail("support@megosu.com")}>shoot us a message</Link> so we can reset your email address to your current email address. Please include what address you used to sign up with and the address you would like to change it to. Your login information then can be sent to you via email.
          </p></>
        },
        {
          title: "How do I unlock more features?",
          content: <><p className="faq__text-container">
            Easy! Just go over to our <Link to="/pricing">Pick a Plan</Link> page and either select the Pro or Premium account.
          </p></>
        },
        {
          title: "How do I change my subscription?",
          content: <><p className="faq__text-container">
            If you want to change your subscription of your account you can easily find it by going over to our <Link to="/pricing">Pricing</Link> section and then change your subscription to the model of your preference.
          </p></>
        },
        {
          title: "Were can I find my billing information?",
          content: <><p className="faq__text-container">
            Please visit your profile and go to ‘Edit Profile’. Hereafter you’ll select- at the bottom of the page- ‘Subscription & Billing’. At this portal you’ll find everything you need, inlcuding invoices.
          </p></>
        },
      ],
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);

  return (
    <div className="faq">
      <DocumentHead
        title="FAQ | Megosu"
        metaDescription="Our most frequently asked questions answered for you!"
      />
      <h1 className="faq__title reveal">FAQ</h1>
      <div className="faq__feature-image-container">
        <img className="faq__feature-image" src={FeatureImage} alt="partners" />
        <p className="faq__feature-image-copyright reveal">© Judy Kong</p>
      </div>
      {faqQuestions.map((faqQuestion, i) => (
        <div key={i} className="reveal">
          <h2 className="faq__subtitle">{faqQuestion.FaqContainertitle}</h2>
          <Faq data={faqQuestion} config={config} />
        </div>
      ))}
      <h2 className="faq__subtitle reveal">Other</h2>
      <div className="faq__container reveal">
        <h2 className="faq__container-title">
          Are there any rules I need to abide?
        </h2>
        <p className="faq__text-container">
          We don’t like to call them rules, but we’ve got some guidelines on how to behave (respectfully!). For this exquisite list you can check out our <Link to="/guidelines">Community Guidelines</Link>. Like to get more information? Check out our <Link to="/terms">Terms of Service</Link> or <Link to="/policy">Privacy Policy</Link>.
        </p>
      </div>
      <h2 className="faq__subtitle--spacing reveal">Got more questions?</h2>
      <Button
        customClassName="reveal button--black"
        type="black"
        title="Contact Support"
        onClick={() => sendEmail("support@megosu.com")}
      ></Button>
    </div>
  );
};

export default FaqPage;
