import React, { useEffect, useState } from "react";

import Button from "../atoms/Button";

import { getAllSortedCompanies } from "../../services/CompanyService";
import { getSiteFiles, getToolCategories, getUniversities } from "../../services/SiteService";
import { getAdvertisements } from "../../services/AdvertisementService";

import "../../styles/components/molecules/AdminOverviewForm.scss";

function AdminOverviewForm({
  type,
  title,
  addButtonTitle,
  setSelection,
  setIsDetailOpen,
  setAdvertisementPositions
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    switch (type) {
      case "Company" : {
        getAllSortedCompanies(true).then((companiesList) => {
          setData(companiesList);
        });
        break;
      }
  
      case "Files" : {
        getSiteFiles().then((files) => {
          setData(files);
        });
        break;
      }

      case "Advertisements" : {
        getAdvertisements(true).then((advertisements) => {
          setData(advertisements);

          // Fill the advertisementPositions list to pass to the advertisementForm component
          const advertisementPositions = [];
          for (let i = 0; i < advertisements.length; i++) {
            advertisementPositions.push(advertisements[i].position);
          }

          setAdvertisementPositions(advertisementPositions);
        });

        break;
      }

      case "Universities" : {
        getUniversities().then((universities) => {
          setData(universities);
        });

        break;
      }

      case "ToolCategories" : {
        getToolCategories().then((categories) => {
          setData(categories);
        });
      }
    }

  }, []);

  return (
    <>
      <div className="admin-overview-form__container">
        <div className="admin-overview-form__header">
          <h3>{title}</h3>
          <Button
            title={`+ New ${addButtonTitle}`}
            onClick={() => { setIsDetailOpen(true); }}
            type="pink"
            customStyle={{marginBottom:  "0.625rem", marginLeft: "13rem", height: "2rem", fontWeight: "500"}}
          />
        </div>
        {data && data.length >= 1 ? (
          data.map((elem) => (
            <>
              <div className="admin-overview-form__items">
                <Button
                  title="Edit"
                  onClick={() => { setSelection(elem); setIsDetailOpen(true); }}
                  type="white"
                  customStyle={{marginBottom:  "0.625rem", width: "7rem", height: "2rem", fontWeight: "500"}}
                />
                {type === "Advertisements" ? (
                  <p className="admin-overview-form__items-name">{elem.title} (Position: {elem.position})</p>
                ) : (
                  <p className="admin-overview-form__items-name">{type === "Universities" ? (elem.city + " - " + elem.name) : (elem.name || elem.title)}</p>
                )}
              </div>
            </>
          ))
        ) : (
          <h2 className="admin-overview-form__noresults">
            There were no items found.
          </h2>
        )}
      </div>
    </>
  );
}

export default AdminOverviewForm;