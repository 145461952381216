import React, { useEffect, useState, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { BsBookmark } from "@react-icons/all-files/bs/BsBookmark";
import { BsBookmarkFill } from "@react-icons/all-files/bs/BsBookmarkFill";
import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";

import PopUp from "./PopUp";
import Button from "../atoms/Button";

import { removeFavorite, saveFavorite } from "../../services/FavoriteService";
import { getFavoriteUsersByProjectId } from "../../services/FavoriteService";
import { archiveProject, getProjectById } from "../../services/ProjectService";

import "../../styles/components/molecules/ProjectCard.scss";

import placeholder from "../../assets/images/ProjectPlaceholder.jpeg";
import profilePlaceHolder from "../../assets/images/ProfilePlaceholder.png";
import popupProImage from "../../assets/images/popupImages/transitions/popupTransitionPro.m4v";
import preTransitionPopupProImage from "../../assets/images/popupImages/PopupProAccount.png";

function ProjectCard({
  parent,
  index,
  projectId,
  title,
  boosted,
  selectedSortingField,
  forSale,
  onClick,
  handleScroll,
  imageUrl,
  userImageUrl,
  designerName,
  uuid,
  user,
  manualFavoriteSaveFunction,
  favoriteCount,
  ownerProfileId,
  archived,
  marketUrl
}) {
  let clickedDots = false;

  const history = useHistory();
  const [click, isClicked] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const [favoriteCounts, setFavoriteCounts] = useState([]);
  const [projectImages, setProjectImages] = useState("");
  const [projectCardPopUpVisible, setProjectCardPopUpVisible] = useState(false);
  const [role, setRole] = useState("");
  const [savedProjectUsersLoading, setSavedProjectUsersLoading] = useState(false);
  const [savedProjectUsers, setSavedProjectUsers] = useState([]);
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 
  const [menuVisible, setMenuVisible] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [sharePopUpVisible, setSharePopUpVisible] = useState(false);
  const [originalImages, setOriginalImages] = useState([]);
  const [proUpgradePopup, setProUpgradePopup] = useState(false);
  const [reversedIcon, setReversedIcon] = useState(false);
  const [showPitchHistory, setShowPitchHistory] = useState(false);

  const imageRef = useRef(null);
  
  useEffect(() => {
    if (imageUrl) {
      const projectImageList = imageUrl.map((a) => a.compressedUrl); 
      projectImageList.sort();
      setProjectImages(projectImageList);
      imageRef.current.src = process.env.REACT_APP_GCS_IMAGE_URL + projectImageList[0];
      
      const originalImageList = imageUrl.map((a) => a.url);
      originalImageList.sort();
      setOriginalImages(originalImageList);
    }

    if (archived === true) {
      setIsArchived(true);
    }

    setFavoriteCounts(favoriteCount);
  }, [projectId]);

  useEffect(() => {
    if (user) {
      setRole(user.role.name);
      setCurrentUser(user);
      setProfileId(user.profile.profileId);
      getUserFavorites(JSON.parse(localStorage.getItem("favorites")));
    }
  }, [user, projectId]);

  const getFavoriteUsers = () => {
    setSavedProjectUsersLoading(true);

    getFavoriteUsersByProjectId(projectId).then((users) => {
      if (user.role.name === "free-user") {
        users.slice(0, 3);
      }

      setSavedProjectUsers(users);
      setSavedProjectUsersLoading(false);
    });
  };

  const getUserFavorites = (favoritesList) => {
    if (favoritesList == null) { 
      setFavorite(false);
    }
    
    if (favoritesList && favoritesList.find((elem) => elem === projectId)) {
      setFavorite(true);
    } else {
      setFavorite(false);
    }
  };


  function showMenu(e) {
    clickedDots = true;
    if (menuVisible == true) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  }

  const onClickSaveCount = () => {
    if (currentUser && ownerProfileId === currentUser.uuid) {
      setProjectCardPopUpVisible(true); 
      getFavoriteUsers();
    }
  };

  function showProject() {
    if (handleScroll && !clickedDots) {
      handleScroll();
      clickedDots = false;
    }
  }

  function handleArchiveClick() {
    let action = "";

    if (isArchived) {
      action = "unarchive";

      if (sessionStorage.getItem("uploadLimitReached") === "true") {
        setProUpgradePopup(true);
      } else {
        archiveProject(projectId, action).then((res) => {
          setIsArchived(false);
          setMenuVisible(false);
        });
      }
    } else {
      action = "archive";

      archiveProject(projectId, action).then((res) => {
        setIsArchived(true);
        setMenuVisible(false);
      });
    }

  }

  // Toggle bookmark based on favorite state
  function handleBookmarkClick() {
    if (currentUser === undefined) {
      setSignUpPopUpVisible(true);
    } else if (favorite) {
      setFavorite(false);

      if (profileId) {
        removeFavorite(profileId, projectId).then(() => {
          console.log("Removed favorite");
        });

        if (favoriteCounts.length === 0) {
          setFavoriteCounts("");
        } else {
          setFavoriteCounts(parseInt(favoriteCounts) - 1);
        }
      }
    } else {
      setFavorite(true);

      if (profileId) {

        /**
         * function may only fire if a parent component (ex. ProfilePage) does not already handle the saveFavorite function. 
         * manualFavoriteSaveFunction is an optional parameter and defaults to undefined if not set in the parameters of the component.
         * The saveFavorite function will not fire when this parameter has been set to true in the component's parameter. 
        */ 
        if (manualFavoriteSaveFunction !== true) {
          saveFavorite(profileId, projectId).then(() => {
            console.log("Saved favorite");
          });
        }
        
        if (favoriteCounts === undefined || favoriteCounts.length === 0) {
          setFavoriteCounts(1);
        } else {
          setFavoriteCounts(parseInt(favoriteCounts) + 1);
        }
      }
    }
  }

  const handleEditProject = () => {
    getProjectById(uuid).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          history.push({
            pathname: "/edit-project/" + uuid,
            state: data,
          });
        });
      }
    });
  };

  return (
    <div>
      <div className={`project-card project-card-${index} reveal`}>
        <div className="project-card__img-container" onClick={() => { showProject(); }}>
          <NavLink to={"/project-details/" + uuid} className="project-card__link" aria-label="Go to Project">
            <div 
              className={isArchived ? "project-card__img project-card__archived" : "project-card__img"}          
            >
              <img
                src={placeholder}
                className={isArchived ? "project-card__img project-card__archived" : (menuVisible ? "project-card__img project-card__img-menu-open" : "project-card__img")}
                alt="project-card"
                id={"project-image-"+ projectId}
                ref={imageRef}
              />
            </div>
          </NavLink>
            
          {currentUser !== undefined && ownerProfileId === currentUser.uuid && (
            <div className="project-card__img-dot-items" onClick={(e) => { showMenu(); }} >
              <BsThreeDots className="project-card__img-menu-dots" />
            </div>
          )}

          {menuVisible && (
            <div className="project-card__img-btn-container">
              <Button
                className="form__submit"
                type="black"
                title="Pitch"
                onClick={() => history.push({ pathname: "/pitch" })}  
              />
              <Button
                className="form__submit"
                type="black"
                title="Edit"
                onClick={() => { handleEditProject(); }}  
              />
              <Button
                className="form__submit"
                type="black"
                title={isArchived ? "Unarchive" : "Archive"}
                onClick={(e) => { handleArchiveClick(); }}
              />
              {isArchived ? null :
                (
                  <Button
                    className="form__submit"
                    type="black"
                    title="Share"
                    onClick={(e) => { setSharePopUpVisible(true); }}
                  />
                )
              }
            </div>
          )}
         
          
          {projectImages[1] ? (
            <div className="project-card__img-hover">
     
              <NavLink to={"/project-details/" + uuid} className="project-card__link" aria-label="Go to project">
                <div 
                  className={isArchived ? "project-card__img project-card__archived" : (menuVisible ? "project-card__img project-card__img-menu-open" : "project-card__img")}
                >
                  <img
                    src={
                      imageUrl
                        ? process.env.REACT_APP_GCS_IMAGE_URL + projectImages[1]
                        : placeholder
                    }
                    className={isArchived ? "project-card__img project-card__archived" : "project-card__img"}
                    alt="project-card"
                  />
                </div>
              </NavLink>

              {currentUser !== undefined && ownerProfileId === currentUser.uuid && (
                <div className="project-card__img-dot-items">
                  <BsThreeDots onClick={(e) => { showMenu(); }} className="project-card__img-menu-dots" />
                </div>
              )}

              {parent !== "profile-projects" && (
                <div className="project-card__designer" onClick={() => { history.push("/profile/" + ownerProfileId);}}>
                  <div className="project-card__opacity-filter"
                  />
                  <div
                    className="project-card__user-image-container"
                    style={{
                      backgroundImage: userImageUrl
                        ? `url(${process.env.REACT_APP_GCS_IMAGE_URL + userImageUrl})`
                        : `url(${profilePlaceHolder})`,
                    }}
                  />
                  <p className="project-card__user-name">{designerName}</p>
                </div>
              )}

              {menuVisible && (
                <div className="project-card__img-btn-container">
                  <Button
                    className="form__submit"
                    type="black"
                    title="Edit"
                    onClick={() => { handleEditProject(); }}                  
                  />
                  <Button
                    className="form__submit"
                    type="black"
                    title={isArchived ? "Unarchive" : "Archive"}
                    onClick={(e) => { handleArchiveClick(); }}
                  />
                  {isArchived ? null :
                    (
                      <Button
                        className="form__submit"
                        type="black"
                        title={"Share"}
                        onClick={(e) => { setSharePopUpVisible(true); }}
                      />
                    )
                  }
                </div>
              )}
            </div>
          ) : null}

          <div className="project-card__modal-container">
            <div
              className="project-card__modal"
              style={click ? { display: "flex" } : { display: "none" }}
            >
            modal
            </div>
          </div>
        </div>
      </div>
        
      <div className={isArchived ? "project-card__description-container project-card__archived reveal" : "project-card__description-container reveal"}>
        <div className="project-card__content-container">
          <NavLink to={"/project-details/" + uuid} className="project-card__link" aria-label="Go to project">
            <h5 className="project-card__title">{title}<br/></h5>
          </NavLink>
          <div className="project-card__attributes-container">
            {selectedSortingField == "ForSale" && forSale && boosted
              ? <h6 className="project-card__boosted" onClick={() => { window.open(marketUrl, "_blank"); }}>Buy</h6> 
              : boosted 
                ? <h6 className="project-card__boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6> 
                : null}
            <div className="project-card__favorite-container">
              <div
                className="project-card__save-btn-container"
                style={
                  parent !== "admin" ? { display: "flex" } : { display: "none" }
                }
              >
              </div>
              <span className={"project-card__save-count project-card__save-count-" + projectId}
                onClick={() => { onClickSaveCount(); }}
                style={currentUser !== undefined && (ownerProfileId === currentUser.uuid) ?
                  {fontWeight: "400", cursor: "pointer"} : {fontWeight: "300"}}
              >
                {favoriteCounts == 0 ? "" : favoriteCounts}
              </span>
              <div
                className={
                  "project-card__bookmark--filled project-card__bookmark-" +
                projectId +
                "--filled"
                }
                onClick={() => {
                  onClick(projectId);
                  handleBookmarkClick();
                }}
                style={favorite ? { display: "flex" } : { display: "none" }}
              >
                <BsBookmarkFill className="bookmark-filled-icon" />
              </div>
              <div
                className={
                  "project-card__bookmark project-card__bookmark-" + projectId
                }
                onClick={() => {
                  onClick(projectId);
                  handleBookmarkClick();
                }}
                style={favorite ? { display: "none" } : { display: "flex" }}
              >
                <BsBookmark />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <PopUp
        visibility={projectCardPopUpVisible}
        overlay={true}
        handleOnClickClose={() => setProjectCardPopUpVisible(false)}
        popupType="showSavedProjectsUsers"
        popupTitle="People who saved your project"
        firstButtonTitle="Go Pro"
        firstButtonType="black"
        firstButtonAction={() => history.push({ pathname: "/pricing" })}
        role={role}
        savedProjectUsers={savedProjectUsers}
        savedProjectUsersLoading={savedProjectUsersLoading}
      />

      <PopUp 
        visibility={signUpPopUpVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => setSignUpPopUpVisible(false)}
        popupType="sign-up" 
      />

      <PopUp 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />

      <PopUp
        visibility={sharePopUpVisible}
        overlay={true}
        handleOnClickClose={() => setSharePopUpVisible(false)}
        popupType="share"
        popupTitle="Share this project with others"
        projectTitle={title}
        projectId={uuid}
        projectImageUrl={process.env.REACT_APP_GCS_IMAGE_URL + originalImages[0]}
        projectShareUrl={process.env.REACT_APP_WEBAPP_BASE_URL + "/project-details/" + uuid}
      />

      <PopUp
        visibility={proUpgradePopup}
        overlay={true}
        handleOnClickClose={ () => setProUpgradePopup(false)}
        src={popupProImage}
        preTransitionImage={preTransitionPopupProImage}
        transition={true}
        popupTitle="Upgrade to publish unlimited projects!"
        popupText="Boost your projects, add your shop, get access to our private community and unlock many more features."
        firstButtonTitle="Go Pro"
        firstButtonType="black"
        firstButtonAction={ () => {
          history.push({ pathname: "/pricing" });
          setProUpgradePopup(false);
        }}
      />
    </div>
  );
}

export default ProjectCard;
