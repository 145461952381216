import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";

import Button from "../atoms/Button";
import Popup from "./PopUp";

import profilePlaceholder from "../../assets/images/ProfilePlaceholder.png";

import { getCurrentUser } from "../../services/AuthService";

import "../../styles/components/molecules/CommentSection.scss";
import { 
  getCommentsByProjectId, 
  saveComment, 
  removeComment 
} from "../../services/CommentService";

function CommentSection({ isProjectOwner, user, projectId }) {
  const [comments, setComments] = useState(null);
  const [deletePopUpVisible, setDeletePopupVisible] = useState(false); 
  const [signUpPopupVisible, setSignUpPopupVisible] = useState(false); 
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 
  const [deleteCommentIndex, setDeleteCommentIndex] = useState(-1); 
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(getCurrentUser() ? true : false); 
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const history = useHistory();
  const MORE_PROJECTS = 20;
  const INITAL_PROJECTS = 5;

  const commentSchema = Yup.object().shape({
    text: Yup.string().max(2000, "Comment can't be longer than 2000 characters")
  });

  const { register, handleSubmit, formState, getValues, setValue } = useForm({
    defaultValues: { text: ""},
    resolver: yupResolver(commentSchema)
  });

  useEffect(() => {
    // Keep the comment for a user that wanted to comment but had to sign up
    const comment = JSON.parse(sessionStorage.getItem("comment"));
    
    if (comment && comment.location === history.location.pathname) {
      setValue("text", comment.content);
      sessionStorage.removeItem("comment");
    }
  }, []);

  useEffect(() => {
    if (projectId) {
      getCommentsByProjectId(projectId, page, INITAL_PROJECTS).then(async (res) => {
        if (res.ok) {
          const commentList = await res.json();

          commentList.sort(function(x, y){
            const date1 =  new Date(x.timestamp);
            const date2 = new Date(y.timestamp);
            return date2 - date1 ;
          });

          if (res.status === 200) {
            setHasMore(false);
          }

          setComments(commentList);
        }
      });
    }
  }, [projectId]);

  const onSubmit = (data, event) => {
    event.preventDefault();

    if (userIsLoggedIn) {
      const text = data.text;
      const profileId = user.profile.profileId;
      const email = getCurrentUser();

      saveComment(profileId, projectId, text, email).then((response) => {
        if (response) {
          setValue("text", "");
        
          const commentList = comments.slice();
          commentList.push(response);
          commentList.sort(function(x, y){
            const date1 =  new Date(x.timestamp);
            const date2 = new Date(y.timestamp);
            return date2 - date1;
          });
          setComments(commentList);
        }
      });
    } else {
      sessionStorage.setItem("comment", JSON.stringify({content: getValues("text"), location: history.location.pathname})); 
      setSignUpPopupVisible(true); 
    } 
  };

  const deleteComment = () => {
    if (deleteCommentIndex !== -1) {
      const comment = comments[deleteCommentIndex];

      removeComment(comment.id).then((response) => {
        comments.splice(deleteCommentIndex, 1);
        setComments(comments);

        setDeletePopupVisible(false);
      });
    }
  };

  const loadMoreComments = () => {
    const newPage = page + 1;
    
    getCommentsByProjectId(projectId, page, MORE_PROJECTS).then(async (response) => {
      if (response.ok) {
        let commentList = await response.json();
        
        if (newPage > 1) {
          commentList = comments.concat(commentList);
        }

        commentList.sort(function(x, y){
          const date1 =  new Date(x.timestamp);
          const date2 = new Date(y.timestamp);
          return date2 - date1;
        });

        setComments(commentList);

        setPage(newPage);

        if (response.status === 200) {
          setHasMore(false);
        }
      }
    });
  };

  return (
    <div className="comment-section" id="comment-section"> 
      <h2 className="comment-section__title">Comments</h2>

      {comments && comments.length > 0 ? (
        <ul className="comment-section__comments">
          {comments.map((comment, i) => (
            <li className="comment-section__comments-item" key={`comment-section__comments-item-${i}`}>
              <div className="comment-section__user-image-container">
                <div
                  className="comment-section__user-image"
                  style={{
                    backgroundImage: comment && comment.imageUrl ? "url(" + process.env.REACT_APP_GCS_IMAGE_URL + comment.imageUrl + ")" : `url(${profilePlaceholder})`}}
                  onClick={() => { history.push("/profile/" + comment.userId + "/"); }}
                /> 
              </div>
              <div className="comment-section__info-container">
                <div className="comment-section__comment-header">
                  <div className="comment-section__designer-info">
                    <p className="comment-section__comment-name" onClick={() => { history.push("/profile/" + comment.userId + "/"); }}>{comment.designerName}</p>
                    <span className="comment-section__comment-date">
                      &nbsp;   
                      {comment.timestamp && (
                        <> 
                          {new Intl.DateTimeFormat("en-GB", { 
                            month: "short", 
                            day: "numeric",
                          }).format(new Date(comment.timestamp))}
                        </>
                      )}
                    </span>
                  </div>
                  {(isProjectOwner || (user && user.profile.profileId === comment.profileId)) && (
                    <div className="comment-section__delete-icon" onClick={() => { setDeleteCommentIndex(i); setDeletePopupVisible(true); }}>
                      <svg width="10" height="9" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.4569" y1="7.48276" x2="7.17118" y2="1.48276" stroke="#E7E7E7" strokeWidth="1.5"/>
                        <line x1="1.51724" y1="1.4569" x2="7.51724" y2="7.17118" stroke="#E7E7E7" strokeWidth="1.5"/>
                      </svg>
                    </div>
                  )}
                </div>
                <div className="comment-section__comment-content">
                  {comment.content}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="comment-section__no-comments"><p className="comment-section__no-comments-text">Share your thoughts</p></div>
      )}

      {comments && hasMore && <a className="comment-section__show-more-comments" onClick={() => { loadMoreComments(); } }>Show More Comments</a>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="comment-section__post-comment">
          {formState.errors && formState.errors?.text && (
            <div className="form-error">{formState.errors.text.message}</div>
          )}
          <textarea
            className={
              formState.errors && formState.errors?.text
                ? "input-error comment-section__message-input"
                : "comment-section__message-input"
            }
            maxLength={2001}
            {...register("text")}
            aria-label="Write a comment"
          />
          <Button type="pink" title="Post" />
        </div>
      </form>

      <Popup
        visibility={deletePopUpVisible}
        overlay={true}
        handleOnClickClose={() => { setDeletePopupVisible(false); setDeleteCommentIndex(-1); }}
        popupTitle="Are you sure you want to delete this comment?"
        firstButtonTitle="Keep"
        firstButtonAction={() => setDeletePopupVisible(false)}
        secondButtonTitle="Delete"
        secondButtonAction={deleteComment}
      />
      <Popup 
        visibility={signUpPopupVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopupVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => { setSignUpPopupVisible(false); sessionStorage.removeItem("comment"); }}
        popupType="sign-up" 
      />
      <Popup 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopupVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); sessionStorage.removeItem("comment"); }}
        popupType="login" 
      />
    </div>
  );
}


export default CommentSection;