import { AuthHeader, AuthHeaderRefresh } from "../util/AuthHeader";

export function authenticateNewUser(username, password) {
  const authenticateNewUserUrl = process.env.REACT_APP_API_BASE_URL + "/authenticate";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  };

  return fetch(authenticateNewUserUrl, options).then((response) => {
    response.json().then((tokens) => {
      localStorage.setItem("currentUser", tokens["access_token"]);
      localStorage.setItem("refreshToken", tokens["refresh_token"]);
      localStorage.setItem("emailVerified", "true");
    });
  });
}

export function setRole(role, email) {
  const postRole = process.env.REACT_APP_API_BASE_URL + "/users/roles/" + role;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  };

  return fetch(postRole, options);
}

export function getRole(id) {
  const getRole = process.env.REACT_APP_API_BASE_URL + "/users/" + id + "/roles";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getRole, options)
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    });
}

export function getCurrentUser() {
  const token = localStorage.getItem("currentUser");

  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64)).sub;
  }
}

export function getCurrentUserRole() {
  const token = localStorage.getItem("currentUser");

  if (token) {
    return JSON.parse(atob(token.split(".")[1])).role;
  }
}

export function getCurrentUserByEmail(email) {
  const getCurrentUser = process.env.REACT_APP_API_BASE_URL + "/users/" + email;
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  if (email) {
    return fetch(getCurrentUser, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log("Request for current user failed!");
        }
      })
      .then((user) => {
        return user;
      });
  }
}

export function updateAccessToken() {
  const refreshTokenUrl = process.env.REACT_APP_API_BASE_URL + "/token";
  const options = {
    method: "POST",
    headers: {
      Authorization: AuthHeaderRefresh(),
    },
  };

  return fetch(refreshTokenUrl, options).then(async (res) => {
    const tokens = JSON.parse(await res.text());
    localStorage.setItem("currentUser", tokens["access_token"]);
    localStorage.setItem("refreshToken", tokens["refresh_token"]);
  });
}

export function get2faSetupQRCode() {
  const get2faSetupQRCodeUrl = process.env.REACT_APP_API_BASE_URL + "/2fa/setup/qr-request";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(get2faSetupQRCodeUrl, options)
    .then((response) => {
      return response;
    });
}

export function verify2faSetup(code) {
  const verify2faSetupUrl = process.env.REACT_APP_API_BASE_URL + "/2fa/setup/verify";
  const options = {
    method: "POST",
    headers: {
      Authorization: AuthHeader(),
    },
    body: code,
  };

  return fetch(verify2faSetupUrl, options)
    .then((response) => {
      return response;
    });
}

export function disable2faForUser(code) {
  const disable2faUrl = process.env.REACT_APP_API_BASE_URL + "/2fa/disable";
  const options = {
    method: "POST",
    headers: {
      Authorization: AuthHeader(),
    },
    body: code,
  };

  return fetch(disable2faUrl, options)
    .then((response) => {
      return response;
    });
}

const exportedFunctions = {
  authenticateNewUser,
  setRole,
  getCurrentUser,
  getCurrentUserByEmail,
  getCurrentUserRole,
};

export default exportedFunctions;
