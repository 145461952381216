import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import $ from "jquery";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FaPlus } from "@react-icons/all-files/fa/FaPlus";

import CheckedDropdown from "./CheckedDropdown";
import ImageUploader from "../atoms/ImageUploader";
import PopUp from "../molecules/PopUp";
import Button from "../atoms/Button";
import Spinner from "../atoms/Spinner";
import UserDetailCard from "../molecules/UserDetailCard";

import { deleteProject, getDeliverablesList, getGoalList, getProfileByProjectId, getProjectTitleAndCountByProfileId } from "../../services/ProjectService";
import { backUpProjectImages } from "../../services/ImageService";
import { getUserById } from "../../services/UserService";
import { getProfilesByName } from "../../services/ProfileService";
import { sendTeamMateInvitation } from "../../services/MailService";

import { marketOptions } from "../../assets/data/DataList";
import { projectValidation, scrollToFirstError, preventNonNumericalInput, URLRegex, projectURLRegex, alphanumericRegex } from "../../util/Validations";
import { BrowserVersion } from "../../util/BrowserVersion";

import "../../styles/components/molecules/UploadProjectForm.scss";

const initialValues = {
  title: "",
  designYear: "",
  videoLink: "",
  hasFirstCoverImage: false,
  hasSecondCoverImage: false,
  description: "",
  length: "",
  width: "",
  height: "",
  weight: "",
  team: [],
  deliverables: [],
  dreamClient: "",
  projectGoals: [],
  marketUrl: "",
  sketchFabUrl: "",
  marketAvailability: "",
  verified: 0,
};

function UploadProjectForm({
  proceed,
  hasProceeded,
  setProject,
  type,
  currentProject,
  setAllowLeave,
  setImageFiles
}) {
  const [values, setValues] = useState(initialValues);
  const [members, setMembers] = useState([""]);
  const [membersLink, setMembersLink] = useState([]);
  const [inviteMembers, setInviteMembers] = useState(false);
  const [active, setActive] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editValues, setEditValues] = useState(false);
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [errors, setErrors] = useState({});
  const [projectGoals, setProjectGoals] = useState([]);
  const [projectDeliverables, setProjectDeliverables] = useState([]);
  const [goals, setGoals] = useState([]);
  const [deliverables, setDeliverables] = useState([]);
  const [editorInstance, setEditorInstance] = useState(null);
  const [charactersAmount, setCharactersAmount] = useState(0);
  const [isFirefox, setIsFirefox] = useState(false);
  const [isOldSafari, setIsOldSafari] = useState(false);
  const [firstCoverIsPresent, setFirstCoverIsPresent] = useState(false);
  const [secondCoverIsPresent, setSecondCoverIsPresent] = useState(false);
  const [teamMembersLoading, setTeamMembersLoading] = useState(false);
  const [searchTeamMembers, setSearchTeamMembers] = useState([]);
  const [timeOut, setTimeOut] = useState();
  const [inviteMembersConfirm, setInviteMembersConfirm] = useState(false);
  const [showCompressWarning, setShowCompressWarning] = useState(true);

  const history = useHistory();
  const { id } = useParams();

  const MAX_IMAGE_UPLOAD_COUNT = 8;
  const currentYear = new Date().getFullYear();

  const BLACK = "#292929";

  const projectSchema = Yup.object().shape({
    title: Yup.string()
      .required("Required.")
      .matches(alphanumericRegex, "Letters, numbers and dashes allowed."),
    designYear: Yup.number()
      .transform((value, originalValue) => typeof originalValue === "string" && originalValue === "" ? undefined : value)
      .required("Required.")
      .min(1900, "Invalid date.")
      .max(currentYear, "Invalid date."),
    videoLink: Yup.string()
      .nullable()
      .default(undefined)
      .transform((value) => value === "" ? null : value)
      .matches(projectURLRegex, "We only accept Youtube or Vimeo."),
    marketUrl: Yup.string()
      .nullable()
      .default(undefined)
      .transform((value) => value === "" ? null : value)
      .matches(URLRegex, "Link is not valid."),
    sketchFabUrl: Yup.string()
      .nullable()
      .default(undefined)
      .transform((value) => value === "" ? null : value)
      .matches(URLRegex, "Link is not valid."),
  });

  const { register, handleSubmit, formState, reset, setValue, getValues } = useForm({
    defaultValues: { title: "", designYear: "", videoLink: "", marketUrl: "", sketchFabUrl: ""},
    resolver: yupResolver(projectSchema)
  });

  useEffect(() => {
    setIsFirefox(typeof InstallTrigger !== "undefined");

    const browser = BrowserVersion();
    if (browser.name === "Safari" && Number(browser.version) < 14.1) {
      setIsOldSafari(true);
    }

    getGoalList().then((goals) => {
      setProjectGoals(goals.map(el => el.name));
    });

    getDeliverablesList().then((deliverables) => {
      setProjectDeliverables(deliverables.map(el => el.name));
    });
  }, []);

  useEffect(() => {
    if (currentProject && currentProject.imageUrl) {
      const splitProjectImages = new Array(MAX_IMAGE_UPLOAD_COUNT).fill(undefined);

      currentProject.imageUrl.map(
        (a) => {
          (splitProjectImages[a.url.split(currentProject.title.replaceAll(" ", "-").toLowerCase()).pop().split("-")[1]] =
            process.env.REACT_APP_GCS_IMAGE_URL + a.url
          );
        }
      );
      setImages([splitProjectImages][0]);
    }

    if (currentProject) {
      if (currentProject.teamMembers) {
        const projectMembers = currentProject.teamMembers.split(",");
        projectMembers.map((userId, i) => {
          getUserById(userId).then(user => {
            if (user !== null) {
              members[i] = "@" + user.profile.firstName + " " + user.profile.lastName;
              membersLink[i] = user.uuid;
            } else {
              members[i] = userId;
              membersLink[i] = "";
            }

            setMembers([...members]);
            setMembersLink([...membersLink]);
          });
        });
      }

      setValues(currentProject);
      reset(currentProject);
    }

    setLoading(false);
  }, [currentProject, proceed]);

  useEffect(() => {
    if (editValues) {
      setValues(values);
    }

    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      hasProceeded(true);
    }
  }, [editValues, errors]);

  useEffect(() => {
    if (editorInstance && currentProject) {
      editorInstance.setData(currentProject.description);
    }
  }, [editorInstance]);

  useEffect(() => {
    if ((type === "upload" && values !== initialValues) || (!type && JSON.stringify(currentProject) !== JSON.stringify(values))) {
      setAllowLeave(false);
    } else {
      setAllowLeave(true);
    }
  }, [values]);

  const handleDelete = (e) => {
    e.preventDefault();

    backUpProjectImages(currentProject.projectId).then(() => {
      getProfileByProjectId(values.projectId).then((profile) => {
        deleteProject(currentProject.projectId).then((projectId) => {
          if (projectId) {
            console.log(`Project ${currentProject.projectId} Deleted.`);
          }

          getProjectTitleAndCountByProfileId(profile.profileId).then((stats) => {          
            history.push({
              pathname: "/profile/" + profile.user.uuid
            });
          });
        });
      });
    });
  };

  function handleOnSubmit(data, event) {
    event.preventDefault();

    const deliverableObjects = deliverables.map((deliverable) => {
      return { id: (projectDeliverables.indexOf(deliverable) + 1), name: deliverable };
    });
    const goalObjects = goals.map((goal) => {
      return { id: (projectGoals.indexOf(goal) + 1), name: goal };
    });

    values.deliverables = deliverableObjects;
    values.projectGoals = goalObjects;
    // Remove empty team members
    values.teamMembers = [];
    members.map((member, i) => {
      values.teamMembers[i] = (membersLink[i] && membersLink[i] != "") ? membersLink[i] : member;
    });
    values.teamMembers = values.teamMembers.filter(member => { return member !== ""; }).toString();
    values.hasFirstCoverImage = firstCoverIsPresent;
    values.hasSecondCoverImage = secondCoverIsPresent;
    values.title = data.title;
    values.designYear = data.designYear;
    values.videoLink = data.videoLink;
    values.marketUrl = data.marketUrl;

    if (data.sketchFabUrl !== null && data.sketchFabUrl.includes("-")) {
      var indexOfLastChar = data.sketchFabUrl.lastIndexOf("-");
      values.sketchFabUrl = "https://sketchfab.com/models/" + data.sketchFabUrl.substring(indexOfLastChar + 1) + "/embed";
    } else if (values.sketchFabUrl !== "" && data.sketchFabUrl === null) {
      values.sketchFabUrl = data.sketchFabUrl;
    }

    if (values.videoLink && !values.videoLink.match(/^http?:\/\//i) && !values.videoLink.match(/^https?:\/\//i)) {
      // if the user gave a URL which does not include a protocol, append the http protocol by default
      values.videoLink = "http://" + values.videoLink;
    }

    // Error handling
    setErrors(projectValidation(values, charactersAmount));
    scrollToFirstError(errors);
    setDataIsCorrect(true);
    setEditValues(true);
    setProject(values);

    const imageFormInput = $(".image-uploader__input");
    const imageRequests = [];

    for (let i = 0; i < imageFormInput.length; i++) {
      imageRequests.push({ index: i, files: imageFormInput[i].files, input: imageFormInput[i] });
    }

    if (imageRequests.length > 0) {
      setImageFiles(imageRequests);
    }
  }

  const onError = () => {
    scrollToFirstError(formState.errors);
  };

  const handleOnChange = (e) => {
    let { name, value } = e.target;

    if (name === "marketAvailability") {
      name = "marketAvailability";
      const convertedValue = parseInt(value);
      value = Boolean(convertedValue);
    }

    if (name === "title" || name === "designYear" || name === "videoLink" || name === "marketUrl") {
      setValue(name, value);
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  const changeImages = (imageArray) => {
    const imageArrayCopy = images.slice();

    for (let i = 0; i < imageArray.length; i++) {
      imageArrayCopy[imageArray[i][1] + i] = imageArray[i][0];
    }
    setImages(imageArrayCopy);
  };

  const searchTeamMate = async (i) => {
    if (teamMembersLoading) {
      clearTimeout(timeOut);
    }
    setTeamMembersLoading(true);

    const dropdown = document.querySelectorAll(".upload-form__team-member-dropdown")[i];
    dropdown.classList.remove("upload-form__team-member-dropdown--hidden");

    setTimeOut(setTimeout(() => {
      let member = members[i];
      if (members[i][0] == "@") {
        member = member.split("@")[1];
      }

      // Get first 10 users that match the most with the name
      getProfilesByName(member).then(profiles => {
        setSearchTeamMembers(profiles);

        setTeamMembersLoading(false);
      });
    }, 500));
  };

  const selectTeamMember = (member, i) => {
    members[i] = "@" + member.firstName + " " + member.lastName;
    setMembers([...members]);
    membersLink[i] = member.user.uuid;
    setMembersLink([...membersLink]);

    document.querySelectorAll(".upload-form__team-member-input input")[i].classList.add("upload-form__input--disabled");
  };

  const inviteTeamMembers = (teamMembers) => {
    teamMembers.forEach(member => {
      sendTeamMateInvitation(member);
    });
    setInviteMembers(false);
    setInviteMembersConfirm(true);
  };

  return (
    <form
      className="upload-form"
      style={!proceed ? { display: "flex" } : { display: "none" }}
      onSubmit={handleSubmit(handleOnSubmit, onError)}
    >
      <div className="upload-form-container">
        <h4 className="upload-form__title">
          {type === "upload" ? "Upload Project" : "Edit Project"}
        </h4>

        <div className="upload-form__form">
          <div className="upload-form__content-container">
            <div className="upload-form__input-container">
              <div className="upload-form__error-container">
                <label className="upload-form__label">Project Title *</label>
                {formState.errors && formState.errors?.title && (
                  <span className="upload-form__error">{formState.errors.title.message}</span>
                )}
              </div>
              <input
                className={
                  formState.errors && formState.errors?.title
                    ? "upload-form__input--error"
                    : "upload-form__input"
                }
                name="title"
                maxLength={20}
                {...register("title")}
                defaultValue={values.title}
                onBlur={() => { getValues("title") !== values.title && setAllowLeave(false); }}
              />
            </div>
            <div className="upload-form__input-container">
              <div className="upload-form__error-container">
                <label className="upload-form__label">Designed in *</label>
                {formState.errors && formState.errors?.designYear && (
                  <span className="upload-form__error">{formState.errors.designYear.message}</span>
                )}
              </div>
              <input
                className={
                  formState.errors && formState.errors?.designYear
                    ? "upload-form__input--error"
                    : "upload-form__input"
                }
                placeholder="YYYY"
                name="designYear"
                onKeyPress={(e) => { preventNonNumericalInput(e); }}
                {...register("designYear")}
                defaultValue={values.designYear}
                onBlur={() => { getValues("designYear") !== values.designYear && setAllowLeave(false); }}
              />
            </div>
          </div>

          <div className={`upload-form__compressor-warn ${showCompressWarning && "upload-form__compressor-warn--show"}`}>
            <div className="upload-form__compressor-warn-container">
              <div className="upload-form__compressor-warn-icon"><svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
              <div className="upload-form__compressor-warn-text">
                <p className="upload-form__compressor-warn-text--bold">Upload notice</p>
                <p>After saving your project, it may take a minute for images to fully update!</p>
              </div>
            </div>
          </div>

          <div className="upload-form__content-container upload-form__cover-container">
            <div className="upload-form__input-container upload-form__cover-image">
              <div className="upload-form__error-container" name="hasFirstCover">
                <label className="upload-form__label">Cover Image 1*</label>
                {errors.hasFirstCover && (
                  <span className="upload-form__error">{errors.hasFirstCover}</span>
                )}
              </div>
              <ImageUploader
                key={0}
                index={0}
                currentImage={images[0]}
                size="Recommended 7:8"
                removable={true}
                usesCropper={true}
                multiple={false}
                imageIsPresent={setFirstCoverIsPresent}
                allowWebP={true}
                setShowCompressWarning={setShowCompressWarning}
              />
            </div>
            <div className="upload-form__input-container upload-form__cover-image">
              <div className="upload-form__error-container" name="hasSecondCover">
                <label className="upload-form__label">Cover Image 2*</label>
                {errors.hasSecondCover && (
                  <span className="upload-form__error">{errors.hasSecondCover}</span>
                )}
              </div>
              <ImageUploader
                key={1}
                index={1}
                currentImage={images[1]}
                removable={true}
                size="Recommended 7:8"
                usesCropper={true}
                multiple={false}
                imageIsPresent={setSecondCoverIsPresent}
                allowWebP={true}
                setShowCompressWarning={setShowCompressWarning}
              />
            </div>
          </div>
          <br></br>
          <div className="upload-form__input-container">
            <div className="upload-form__error-container">
              <label className="upload-form__label">More images</label>
              <label className="description-label max-upload-size">max 5mb</label>
            </div>
            <div className="upload-form__image-container">
              {[...Array(MAX_IMAGE_UPLOAD_COUNT)].map((elem, i) => {
                return i > 1 && (
                  <React.Fragment>
                    <ImageUploader
                      key={i + 2}
                      simplified={true}
                      index={i}
                      currentImage={images[i]}
                      changeImages={changeImages}
                      removable={true}
                      multiple={!isFirefox && !isOldSafari}
                      proProjectImages={(i > 4)}
                      allowWebP={true}
                      setShowCompressWarning={setShowCompressWarning}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          <div className="upload-form__input-container">
            <div className="upload-form__error-container">
              <div className="upload-form__title-container">
                <label className="upload-form__label">
                  Video Link
                </label>
                <h6 className="upload-form__boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
              </div>
              {formState.errors && formState.errors?.videoLink && (
                <span className="upload-form__error">{formState.errors.videoLink.message}</span>
              )}
            </div>
            <input
              className={
                formState.errors && formState.errors?.videoLink
                  ? "upload-form__input--error"
                  : "upload-form__input"
              }
              name="videoLink"
              placeholder="Youtube or Vimeo"
              {...register("videoLink")}
              defaultValue={values.videoLink}
              onBlur={() => { getValues("videoLink") !== values.videoLink && setAllowLeave(false); }}
            />
          </div>

          <div className="upload-form__input-container">
            <div className="upload-form__error-container">
              <label className="upload-form__label">Description *</label>
              {errors.description && (
                <span className="upload-form__error">{errors.description}</span>
              )}
              <label
                className={`${charactersAmount > 5000 && "description-label--error"} description-label`}
              >
                {charactersAmount} / 5000 characters
              </label>
            </div>
            <div className={errors.description && "upload-form__input--error"}>
              <CKEditor
                editor={DecoupledEditor}
                config={{
                  toolbar: ["bold", "italic", "underline", "|", "numberedList", "bulletedList"],
                  placeholder: "Please share a more in-depth story of the project. E.g. explain: What, How & Why.",
                  removePlugins: ["FontBackgroundColor", "FontColor", "Alignment", "Strikethrough", "BlockQuote", "Heading"],
                }}
                onReady={editor => {
                  setEditorInstance(editor);
                  editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  let characters = data.replace(/<[^>]*>|/g, "");
                  characters = characters.replace(/&nbsp;/g, " ");
                  characters = characters.replace(/\s/g, "");
                  const charactersAmount = characters.length;

                  setCharactersAmount(charactersAmount);
                  setValues({
                    ...values,
                    ["description"]: data,
                  });
                }}
              />
            </div>
            <div id="wordCount"></div>
          </div>

          <div className="upload-form__measurement-container">
            <div className="upload-form__input-container">
              <div className="upload-form__error-container">
                <label className="upload-form__label">Size</label>
              </div>
              <div className="upload-form-size-container">
                <div>
                  <input
                    className="upload-form__input"
                    name="length"
                    step={0.1}
                    min={0}
                    value={values.length || ""}
                    placeholder="cm"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value.length >= 5) {
                        e.target.value = e.target.value.slice(0, 5);
                      }
                      handleOnChange(e);
                    }}
                    onKeyPress={(e) => { preventNonNumericalInput(e); }}
                  />
                  <span>L</span>
                </div>
                <div>
                  <input
                    className="upload-form__input"
                    name="width"
                    step={0.1}
                    min={0}
                    value={values.width || ""}
                    placeholder="cm"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value.length >= 5) {
                        e.target.value = e.target.value.slice(0, 5);
                      }
                      handleOnChange(e);
                    }}
                    onKeyPress={(e) => { preventNonNumericalInput(e); }}
                  />
                  <span>D</span>
                </div>
                <div>
                  <input
                    className="upload-form__input"
                    name="height"
                    step={0.1}
                    min={0}
                    value={values.height || ""}
                    placeholder="cm"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value.length >= 5) {
                        e.target.value = e.target.value.slice(0, 5);
                      }
                      handleOnChange(e);
                    }}
                    onKeyPress={(e) => { preventNonNumericalInput(e); }}
                  />
                  <span>H</span>
                </div>
              </div>
            </div>
            <div className="upload-form__input-container">
              <label className="upload-form__label">Weight</label>
              <input
                className="upload-form__input"
                placeholder="kg"
                type="number"
                name="weight"
                step={0.1}
                min={0}
                value={values.weight || ""}
                onChange={(e) => {
                  if (e.target.value.length >= 5) {
                    e.target.value = e.target.value.slice(0, 5);
                  }
                  handleOnChange(e);
                }}
                onKeyPress={(e) => { preventNonNumericalInput(e); }}
              />
            </div>
          </div>

          <div className="upload-form__input-container">
            <label className="upload-form__label">Team member(s)</label>
            <div className="upload-form__team-container">
              {members.map((member, i) => (
                <>
                  <div key={i} className="upload-form__team-member">
                    <div className="upload-form__team-member-select">
                      <div className="upload-form__team-member-input">
                        <input
                          className={`upload-form__input ${(membersLink[i] && membersLink[i] != "") && "upload-form__input--disabled"}`}
                          value={members[i]}
                          placeholder="Type or select your team member"
                          name="team"
                          maxLength={25}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const dropdown = document.querySelectorAll(".upload-form__team-member-dropdown")[i];
                              dropdown.classList.add("upload-form__team-member-dropdown--hidden");
                            }
                          }}
                          onChange={(e) => {
                            members[i] = e.target.value;
                            setMembers([...members]);
                            searchTeamMate(i);
                          }}
                          onFocus={() => searchTeamMate(i)}
                          onBlur={() => {
                            const dropdown = document.querySelectorAll(".upload-form__team-member-dropdown")[i];
                            dropdown.classList.add("upload-form__team-member-dropdown--hidden");
                          }}
                        />
                        <FaPlus
                          className="upload-form__team-discard-icon"
                          color={BLACK}
                          onClick={() => {
                            if (members.length > 1) {
                              setMembers([...members.slice(0, i), ...members.slice(i + 1, members.length)]);
                              setMembersLink([...membersLink.slice(0, i), ...membersLink.slice(i + 1, membersLink.length)]);
                            } else {
                              members[i] = "";
                              membersLink[i] = "";
                              setMembers([...members]);
                              setMembersLink([...membersLink]);
                            }
                          }} />
                      </div>
                      <div className="upload-form__team-member-dropdown upload-form__team-member-dropdown--hidden">
                        <ul className="upload-form__team-member-list">
                          {(!teamMembersLoading) ?
                            searchTeamMembers.map((member, index) => (
                              <li
                                className="upload-form__team-member-list-item"
                                key={index}
                                onClick={() => selectTeamMember(member, i)}
                              >
                                <UserDetailCard
                                  name={member.firstName + " " + member.lastName}
                                  city={member.city}
                                  country={member.country.name}
                                  profileImage={member.imageUrl}
                                  role={member.user.role.name}
                                />
                              </li>
                            ))
                            :
                            <Spinner type="megosu" />
                          }
                        </ul>
                        <p className="upload-form__team-member-dropdown-text">
                          Can&apos;t find your teammate?<br />Invite your team member via email&nbsp;
                          <span
                            className="upload-form__team-member-dropdown-text--mail-link"
                            onClick={() => setInviteMembers(true)}
                          >
                            here
                          </span>.
                        </p>
                      </div>
                    </div>
                    {i == members.length - 1 && members.length < 5 && (
                      <Button
                        title={<FaPlus color={BLACK} />}
                        type="grey"
                        onClick={() => {
                          setMembers(members => [...members, ""]);
                          setMembersLink(membersLink => [...membersLink, ""]);
                        }} />
                    )}
                  </div>
                </>
              ))
              }
            </div>
          </div>
          <div className="form__input-container">
            <div className="upload-form__error-container">
              <label className="upload-form__label">Project Deliverables</label>
            </div>
            <CheckedDropdown
              dropdownList={projectDeliverables}
              checkedValue={values.deliverables}
              name="deliverables"
              singleCheck={false}
              setDeliverables={setDeliverables}
            />
          </div>

          <div className="form__input-container">
            <div className="upload-form__error-container">
              <label className="upload-form__label">Project Goal</label>
            </div>
            <CheckedDropdown
              dropdownList={projectGoals}
              checkedValue={values.projectGoals}
              singleCheck={false}
              name="projectGoals"
              setGoals={setGoals}
            />
          </div>

          <div className="upload-form__input-container">
            <label className="upload-form__label">
              Dream client for this project
            </label>
            <input
              className="upload-form__input"
              name="dreamClient"
              value={values.dreamClient}
              maxLength={20}
              onChange={handleOnChange}
              placeholder="e.g. IKEA, Philips, Vitra"
            />
          </div>

          <div className="upload-form__input-container">
            <div className="upload-form__error-container">
              <div className="upload-form__title-container">
                <label className="upload-form__label">
                  Link this project to a webshop
                </label>
                <h6 className="upload-form__boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
              </div>
              {formState.errors && formState.errors?.marketUrl && (
                <span className="upload-form__error">{formState.errors.marketUrl.message}</span>
              )}
            </div>
            <input
              className={
                errors.marketUrl
                  ? "upload-form__input--error"
                  : "upload-form__input"
              }
              name="marketUrl"
              placeholder="https://www.shop-example.com"
              {...register("marketUrl")}
              defaultValue={values.marketUrl}
              onBlur={() => { getValues("marketUrl") !== values.marketUrl && setAllowLeave(false); }}
            />
          </div>

          <div className="upload-form__input-container">
            <div className="upload-form__error-container">
              <div className="upload-form__title-container">
                <label className="upload-form__label">
                  Sketchfab 3D Prototype
                </label>
                <h6 className="upload-form__boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
              </div>
              {formState.errors && formState.errors?.sketchFabUrl && (
                <span className="upload-form__error">{formState.errors.sketchFabUrl.message}</span>
              )}
            </div>
            <input
              className={
                errors.sketchFabUrl
                  ? "upload-form__input--error"
                  : "upload-form__input"
              }
              name="sketchFabUrl"
              placeholder="https://sketchfab.com/3d-models/your-model"
              {...register("sketchFabUrl")}
              defaultValue={values.sketchFabUrl}
              onBlur={() => { getValues("sketchFabUrl") !== values.sketchFabUrl && setAllowLeave(false); }}
            />
          </div>

          <div className="upload-form__input-container">
            <div className="upload-form__label-container--market">
              <label className="upload-form__label">
                Availability for market *
              </label>
              <div className="upload-form__tooltip">
                <p>i</p>
                <div className="upload-form__tooltip-content">
                  <p>
                    In case you like to work with partners for this project
                    (e.g. Ikea) it&apos;s a great filter option for companies
                    that are on the lookout for new projects. If your design is
                    already sold, please select &apos;no&apos;.
                  </p>
                </div>
              </div>
            </div>
            <div className="upload-form__market-container">
              <div className="upload-form__error-container">
                {errors.marketAvailability && (
                  <span className="upload-form__error upload-form__availability-error">
                    {errors.marketAvailability}
                  </span>
                )}
              </div>
              {marketOptions.map((elem, i) => (
                <div key={i} className="upload-form__market-availability">
                  <label className="upload-form__custom-checkbox">
                    <input
                      type="checkbox"
                      className="upload-form__checkbox"
                      checked={
                        values.marketAvailability === true
                          ? i === 0
                          : values.marketAvailability === false
                            ? i === 1
                            : ""
                      }
                      name="marketAvailability"
                      value={i === 0 ? 1 : 0}
                      onChange={handleOnChange}
                    />
                    <span className={
                      values.marketAvailability && i === 0
                        ||
                        !values.marketAvailability && i === 1
                        ? "upload-form__checkmark upload-form__checked"
                        : "upload-form__checkmark"
                    } />
                  </label>
                  <span className="upload-form__availability-text">{elem}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="upload-form__button-container">
            {loading ? (
              <div className="upload-form__spinner">
                <Spinner type="megosu" />
              </div>
            ) : (
              <Button
                className="upload-form__submit"
                type="pink"
                customClassName="button--pink-form"
                title="Proceed"
                onClick={() => { handleOnSubmit; }}
              />
            )}
            {type === "upload" ? null : (
              <div>
                <div
                  className="upload-form__cancel"
                  onClick={() => {
                    history.push({
                      pathname: `/project-details/${id}`,
                    });
                  }}
                >
                  Cancel Edit Project
                </div>
                <div
                  className="upload-form__delete"
                  onClick={() => { setActive(true); }}
                >
                  Delete Project
                </div>
              </div>
            )}
          </div>
          <PopUp
            visibility={active}
            overlay={true}
            handleOnClickClose={() => { setActive(false); }}
            popupTitle="Are you sure you want to delete it?"
            popupText="No data will be saved. Therefore you can not revive your account after it has been deleted."
            firstButtonTitle="Delete"
            firstButtonAction={handleDelete}
            secondButtonTitle="Keep"
            secondButtonAction={() => { setActive(false); }}
          />
          <PopUp
            visibility={inviteMembers}
            overlay={true}
            handleOnClickClose={() => { setInviteMembers(false); }}
            popupType={"invite-team-members"}
            firstButtonAction={(members) => { inviteTeamMembers(members); }}
          />
          <PopUp
            visibility={inviteMembersConfirm}
            overlay={true}
            handleOnClickClose={() => { setInviteMembersConfirm(false); }}
            popupTitle="Your team members have been invited!"
          />
        </div>
      </div>
    </form>
  );
}

export default UploadProjectForm;
