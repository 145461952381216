import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { BsSearch } from "@react-icons/all-files/bs/BsSearch";
import { BsArrowCounterclockwise } from "@react-icons/all-files/bs/BsArrowCounterclockwise";
import  { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill";
import { VscGlobe } from "@react-icons/all-files/vsc/VscGlobe";
import { BsFilter } from "@react-icons/all-files/bs/BsFilter";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";

import popupImagePremium from "../../assets/images/popupImages/transitions/popupTransitionPremium.m4v";
import preTransitionPopupPremiumImage from "../../assets/images/popupImages/PopupPremiumAccount.png";

import PopUp from "./PopUp";
import CheckedDropdown from "./CheckedDropdown";
import Toggle from "../atoms/Toggle";

import { getCompanyFilterTypes, getFilteredCompanies, getCompanyCountries } from "../../services/CompanyService";
import { filterQueryBuilder } from "../../util/Filters";

import { companySizeList, payoutTypes } from "../../assets/data/DataList";

import "../../styles/components/molecules/CompanyFilter.scss";

function CompanyFilter({
  role,
  setCompanies,
  setIndexOfFirstCompany,
  setIndexOfLastCompany,
  results,
  setResults,
  setQuery,
  setFirstIndex,
  setLastIndex,
  setEndOfCompanies,
  setLoading,
  isMobile,
  selectedSortingField
}) {   
  const initialSelectedTagValues = {
    text: [],
    material: [],
    product: [],
    space: [],
    companySize: [],
    payout: [],
    externalExperience: [],
    location: []
  };
  
  const history = useHistory();
  const COMPANIES_PER_PAGE = 20;
  
  const [companyDetailFilter, setCompanyDetailFilter] = useState(false);
  const [tagFilter, setTagFilter] = useState(false);
  const [filtersActiveStatus, setFiltersActiveStatus] = useState(false);
  const [selectedTags, setSelectedTags] = useState(initialSelectedTagValues);
  const [removeLocation, setRemoveLocation] = useState("");
  const [resetAllLocationBoxes, setResetAllLocationBoxes] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const [materialFilter, setMaterialFilter] = useState(true);
  const [productFilter, setProductFilter] = useState(false);
  const [spaceFilter, setSpaceFilter] = useState(false);
  const [tags, setTags] = useState(null);
  const [reversedIcon, setReversedIcon] = useState(false);
  const [upgradePopup, setUpgradePopup] = useState(false);
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [preselectLocation, setPreselectLocation] = useState([]);

  const [companySize] = useState(companySizeList);
  const [payout] = useState(payoutTypes);
  const filterRef = useRef(null);

  const INACTIVE_FILTER_FONT_WEIGHT = "300";
  const ACTIVE_FILTER_FONT_WEIGHT = "400";

  useEffect(() => {
    getCompanyFilterTypes().then((r) => setTags(r));
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams !== {}) {
      const tagValues = Object.keys(initialSelectedTagValues);
      for (const [key, value] of urlSearchParams) {
        tagValues.map(item => {
          if (item === key) {
            if (item === "location") {
              const splitCountries = value.split(",");
              for (let i = 0; i < splitCountries.length; i++) {
                const countryObject = { name: splitCountries[i] };
                setPreselectLocation((item) => [...item, countryObject]);
              }
            }

            addRemoveFilter(value, key, true);
          }
        });
      }
    }
  }, []);

  useEffect(() => { 
    if (role === "pitch-user") {
      getCompanyCountries().then((countries) => { 
        countries.sort();
        setCountryList(countries);
      });
    }
  }, [role]);

  const categoryContent = (category, mobile) => {
    return (<ul className={mobile ? "company-filter__dropdown--btn-dropdown" : "company-filter__dropdown"}>
      {tags &&
        Object.values(
          tags[category].map((item, i) => (<li
            key={`company-filter-${category}-${i}`}
            className="company-filter__dropdown-tag"
            onClick={() => { !signUpOrUpgradePopup(role) ? addRemoveFilter(item["name"], category) : null; }}
          >
            <span
              style={{
                fontWeight: selectedTags[category].includes(item["name"])
                  ? ACTIVE_FILTER_FONT_WEIGHT
                  : INACTIVE_FILTER_FONT_WEIGHT,
              }}
              className="company-filter__dropdown-tag-count"
            >
              {item["name"]}
            </span>
          </li>
          ))
        )}
    </ul>);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!signUpOrUpgradePopup(role) && event.target[0].value !== "") {
      addRemoveFilter(event.target[0].value, "text", true);
    }

    event.target.reset();
  };

  function toggleExternalExperience(e) {
    if (e === false) {
      addRemoveFilter("", "reset");
    } else {
      addRemoveFilter("", "externalExperience", false);
    }
  }
  
  const addRemoveFilter = async (tag, filterCategory, searchOverwrite) => {
    if (loadingFilter === true) {
      // if our filter was still loading whilst the function was called,
      // cancel the current fetch request by firing an event which is listened to by the DOM through the CompanyService
      const evt = new Event("abortCompanyFilterFetch");
      document.dispatchEvent(evt);
      setLoadingFilter(false);
    }

    setLoading(true);
    setLoadingFilter(true);
    setIndexOfFirstCompany(0);
    setIndexOfLastCompany(COMPANIES_PER_PAGE);

    if (filterCategory === "externalExperience") {
      if (!searchOverwrite) {
        selectedTags[filterCategory] === false
          ? (selectedTags[filterCategory] = true)
          : (selectedTags[filterCategory] = true);
      }
    } else if (filterCategory === "reset") {
      setResetAllLocationBoxes(true);
      setFiltersActiveStatus(false);
      setSelectedTags(initialSelectedTagValues);

    } else {
      const selectedTagsCopy = { ...selectedTags };
      const indexOfSelectedFilter = tag !== "" && selectedTagsCopy[filterCategory].indexOf(tag);

      if (indexOfSelectedFilter > -1 || tag === "") {
        if (!searchOverwrite && tag !== "" && filterCategory !== "location") {
          selectedTagsCopy[filterCategory].splice(indexOfSelectedFilter, 1);
        } else if (filterCategory === "location") {
          setRemoveLocation(tag);
          selectedTagsCopy[filterCategory].splice(indexOfSelectedFilter, 1);
        } else {
          selectedTagsCopy[filterCategory].splice(0, selectedTagsCopy[filterCategory].length);
        }
      } else {
        if (tag.includes(",")) {
          const splitValue = tag.split(",");
          for (let i = 0; i < splitValue.length; i++) {
            selectedTagsCopy[filterCategory].push(splitValue[i]);
          }
        } else {
          selectedTagsCopy[filterCategory].push(tag);
        }
      }

      setSelectedTags(selectedTagsCopy);
      setFiltersActiveStatus(!Object.values(selectedTagsCopy).every(({ length }) => length ? false : true));
    }

    const queryUrl = filterCategory === "reset" ? filterQueryBuilder(initialSelectedTagValues) : filterQueryBuilder(selectedTags);
    const resultList = [];

    await getFilteredCompanies(queryUrl, 0, COMPANIES_PER_PAGE, selectedSortingField).then((filteredCompaniesList) => {
      if (filteredCompaniesList) {
        filteredCompaniesList.forEach((company) => {
          resultList.push(company.id);
        });

        setQuery(queryUrl);
        setFirstIndex(0);
        setLastIndex(COMPANIES_PER_PAGE);
        setIndexOfLastCompany(COMPANIES_PER_PAGE);
        
        if (filteredCompaniesList.length < COMPANIES_PER_PAGE) {
          setEndOfCompanies(true);
        } else {
          setEndOfCompanies(false);
        }
        setCompanies(filteredCompaniesList);
        setResults(filteredCompaniesList.length === 0 ? 0 : filteredCompaniesList[0].amountOfCompanies);
        setLoading(false);
        setLoadingFilter(false);
      }
    });

    history.push({
      pathname: "/pitch",
      search: "?" + new URLSearchParams(queryUrl),
    });
  };

  function signUpOrUpgradePopup(role) {
    if (role === null) {
      setSignUpPopUpVisible(true);
      return true;
    } else if (role !== "pitch-user") {
      setUpgradePopup(true);
      return true;
    }

    return false;
  }

  return (
    <div className="company-filter">
      <div className="company-filter__container">
        <div className="company-filter__search-status">
          <p>
            {(filtersActiveStatus && !loadingFilter) && `${results} companies with...`}
          </p>
          <p>
            {Object.keys(selectedTags).map((category, index) => {
              return Object.values(selectedTags[category]).map((tag, idx) => {
                return (
                  <span key={`tag-${tag}-${idx}`}>
                    {tag === "Other" ? tag + " " + category + "s" : tag}&nbsp;
                    <AiOutlineClose
                      className="company-filter__remove-icon"
                      onClick={() => {
                        addRemoveFilter(tag, category);
                      }}
                    />
                    &nbsp;
                  </span>
                );
              });
            })}
          </p>
        </div>

        <div className="company-filter-reset" onClick={(e) => { addRemoveFilter("", "reset"); }}>
          <label>
            <BsArrowCounterclockwise />
          </label>
        </div>

        <div className="company-filter__searchbar-container">
          <form onSubmit={onSubmit} className="company-filter__searchbar-form">
            <BsSearch className="company-filter__searchbar-icon" />
            <input
              type="search"
              placeholder="Search"
              minLength={1}
              maxLength={30}
              className="company-filter__searchbar-input"
              enterkeyhint="search"
            />
          </form>
        </div>

        {!isMobile && (
          <div className="company-filter-unordered-list company-filter__filter-tag-container">
            <>  
              <div className="company-filter">
                <div className="company-filter__list-item">
                  <label className="company-filter__label-list">Material</label>
                  {categoryContent("material", false)}
                </div>
                <div className="company-filter__list-item">
                  <label className="company-filter__label-list">Product</label>
                  {categoryContent("product", false)}
                </div>
                <div className="company-filter__list-item">
                  <label className="company-filter__label-list">Space</label>
                  {categoryContent("space", false)}
                </div>
              </div>
            </>
          </div>
        )}

        <div className="company-filter__dropdown-container">
          <div
            className="company-detail-filter"
            onClick={() => {
              setCompanyDetailFilter(!companyDetailFilter);
              setTagFilter(false);
            }}
            onMouseEnter={() => { setReversedIcon(true); !companyDetailFilter && filterRef?.current && filterRef?.current.click(); }}
            onMouseLeave={() => { setReversedIcon(false); }}
            ref={filterRef}
          >
            <div
              className={`${
                tagFilter && "company-detail-filter__btn--disabled"
              } company-detail-filter__btn`}
            >
              <BsFilter className="company-detail-filter__bsfilter-icon" />
                Company Filter
              <BsFillCaretDownFill 
                className={`${companyDetailFilter && "company-detail-filter__bsx-icon--disabled"} company-detail-filter__bsx-icon ${reversedIcon && "company-detail-filter__bsx-icon--reversed"} 
                ${companyDetailFilter && "company-detail-filter__bsx-icon--reversed-mobile"}`}
              />
            </div>
          </div>
          <div
            className={`company-detail-filter__dropdown-content ${companyDetailFilter && "company-detail-filter__dropdown-content--active"}`}
            onMouseEnter={() => { setReversedIcon(true); }}
            onMouseLeave={() => { setReversedIcon(false); }}
          >
            <div className="company-detail-filter__dropdown-category">
              <p>Company Size</p>
              <div className="company-detail-filter__dropdown-options">
                <ul>
                  <li
                    style={{
                      fontWeight:
                    selectedTags.companySize.length >= 1 ? "300" : "400",
                    }}
                    onClick={() => { !signUpOrUpgradePopup(role) ? selectedTags.companySize.length >= 1 && addRemoveFilter("", "companySize") : null; }}
                  >
                All
                  </li>
                  {companySize.map((item, i) => (
                    <li
                      key={`company-detail-filter-size-${i}`}
                      onClick={() => { !signUpOrUpgradePopup(role) ?  addRemoveFilter(item, "companySize") : null; }}
                    >
                      <span
                        style={{
                          fontWeight:
                        selectedTags.companySize.includes(item) && "400",
                        }}
                      >
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
                <div className="company-detail-filter__dropdown-tooltip">
                  <p>i</p>
                  <div className="company-detail-filter__dropdown-tooltip-content">
                    <p><span>Small</span> 1-50 employees</p>
                    <p><span>Medium</span> 51-200 employees</p>
                    <p><span>Large</span> 200+ employees</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="company-detail-filter__dropdown-category">
              <p>Payout</p>
              <div className="company-detail-filter__dropdown-options">
                <ul>
                  <li
                    style={{
                      fontWeight:
                    selectedTags.payout.length >= 1 ? "300" : "400",
                    }}
                    onClick={() => { !signUpOrUpgradePopup(role) ? selectedTags.payout.length >= 1 && addRemoveFilter("", "payout") : null; }}
                  >
                All
                  </li>
                  {payout.map((item, i) => (
                    <li
                      key={`company-detail-filter-size-${i}`}
                      onClick={() => { !signUpOrUpgradePopup(role) ? addRemoveFilter(item, "payout") : null; }}
                    >
                      <span
                        style={{
                          fontWeight:
                        selectedTags.payout.includes(item) && "400",
                        }}
                      >
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="company-detail-filter__dropdown-category">
              <p>Experience with external designers</p>
              <div className="company-detail-filter__dropdown-options">
                <Toggle
                  isChecked={(e) => { !signUpOrUpgradePopup(role) ? toggleExternalExperience(e) : null; }}
                  value="externalExperience"
                />
                <span>Experience with external designers</span>
              </div>
            </div>

            <div className="company-detail-filter__dropdown-category">
              <p>Location</p>
              <div className="company-detail-filter__dropdown-options">
                <VscGlobe className="company-detail-filter__globe-icon" />
                <CheckedDropdown
                  onClick={() => { signUpOrUpgradePopup(role) ? setReversedIcon(false) : null; }}
                  dropdownList={countryList !== [] && countryList}
                  name="country"
                  filterCategoryName="location"
                  className="countrydropdown"
                  dropdownType="filter"
                  searchable={true}
                  addRemoveFilter={addRemoveFilter}
                  removeLocation={removeLocation}
                  setRemoveLocation={setRemoveLocation}
                  resetAllLocationBoxes={resetAllLocationBoxes}
                  setResetAllLocationBoxes={setResetAllLocationBoxes}
                  checkedValue={preselectLocation !== [] && preselectLocation}
                />
              </div>
            </div>
          </div>

          <>
            {isMobile && (
              <>
                <div
                  className={`${companyDetailFilter && "company-filter__btn--disabled"} company-filter__btn`}
                  onClick={() => {
                    setTagFilter(!tagFilter);
                    setCompanyDetailFilter(false);
                  }}
                  onMouseEnter={() => { setReversedIcon(true); }}
                  onMouseLeave={() => { setReversedIcon(false); }}
                >
                Product Filter
                  <BsFillCaretDownFill 
                    className={`${tagFilter && "company-filter__bsx-icon--disabled"} company-filter__bsx-icon ${reversedIcon && "company-filter__bsx-icon--reversed"} ${tagFilter && "company-filter__bsx-icon--reversed-mobile"}`} 
                  />
                </div>
                <div 
                  className={`${tagFilter && "company-filter__dropdown-content--active"} company-filter__dropdown-content`}
                  onMouseEnter={() => { setReversedIcon(true); }}
                  onMouseLeave={() => { setReversedIcon(false); }}
                >
                  <div className="company-filter__categories">
                    <label
                      className="company-filter__label-list"
                      style={{
                        fontWeight: materialFilter
                          ? ACTIVE_FILTER_FONT_WEIGHT
                          : INACTIVE_FILTER_FONT_WEIGHT,
                      }}
                      onClick={() => {
                        setMaterialFilter(true);
                        setProductFilter(false);
                        setSpaceFilter(false);
                      }}
                    >
              Material
                    </label>
                    <label
                      className="company-filter__label-list"
                      style={{
                        fontWeight: productFilter
                          ? ACTIVE_FILTER_FONT_WEIGHT
                          : INACTIVE_FILTER_FONT_WEIGHT,
                      }}
                      onClick={() => {
                        setProductFilter(true);
                        setMaterialFilter(false);
                        setSpaceFilter(false);
                      }}
                    >
              Product
                    </label>
                    <label
                      className="company-filter__label-list"
                      style={{
                        fontWeight: spaceFilter
                          ? ACTIVE_FILTER_FONT_WEIGHT
                          : INACTIVE_FILTER_FONT_WEIGHT,
                      }}
                      onClick={() => {
                        setMaterialFilter(false);
                        setProductFilter(false);
                        setSpaceFilter(true);
                      }}
                    >
              Space
                    </label>
                  </div>

                  <div className="company-filter__company-filter-category-content">
                    {materialFilter && categoryContent("material", true)}
                    {productFilter && categoryContent("product", true)}
                    {spaceFilter && categoryContent("space", true)}
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>

      <PopUp
        visibility={upgradePopup}
        overlay={true}
        handleOnClickClose={() => setUpgradePopup(false)}
        src={popupImagePremium}
        preTransitionImage={preTransitionPopupPremiumImage}
        transition={true}
        popupTitle="Stay one step ahead!"
        popupText="Use advanced company filters, contact the right person & get access to all 400+ companies."
        firstButtonTitle="Get a Pitch Plan"
        firstButtonAction={() => { history.push("/pricing"); }}
        firstButtonType="black"
      />
      <PopUp 
        visibility={signUpPopUpVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => setSignUpPopUpVisible(false)}
        popupType="sign-up" 
      />
      <PopUp 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />
    </div>
  );
}

export default CompanyFilter;
