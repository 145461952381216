import { 
  FACEBOOK_AUTH_URL, 
  FACEBOOK_SCOPE, 
  GOOGLE_AUTH_URL, 
  GOOGLE_SCOPE, 
  LINKEDIN_AUTH_URL, 
  LINKEDIN_SCOPE, 
  PROVIDER 
} from "../constants/auth";

import { getLinkedInInformation } from "../services/LoginService";

const GOOGLE_TOKEN = "https://oauth2.googleapis.com/token";
const GOOGLE_USER_INFO = "https://www.googleapis.com/oauth2/v2/userinfo";

const FACEBOOK_TOKEN = "https://graph.facebook.com/v13.0/oauth/access_token";
const FACEBOOK_USER_INFO = "https://graph.facebook.com/v13.0/me?fields=id,first_name,middle_name,last_name,name,email,picture.width(250).height(250)";

const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  
  return `${base}?${query}`;
};
  
export const getProvidersUrls = () => ({
  [PROVIDER.GOOGLE]: getURLWithQueryParams(GOOGLE_AUTH_URL, {
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: `${process.env.REACT_APP_OAUTH2_URL}/oauth?provider=${PROVIDER.GOOGLE.toLowerCase()}`,
    scope: GOOGLE_SCOPE,
    response_type: "code",
    access_type: "offline",
    prompt: "consent"
  }),
  [PROVIDER.FACEBOOK]: getURLWithQueryParams(FACEBOOK_AUTH_URL, {
    client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
    redirect_uri: `${process.env.REACT_APP_OAUTH2_URL}/oauth?provider=${PROVIDER.FACEBOOK.toLowerCase()}`,
    scope: FACEBOOK_SCOPE,
    response_type: "code",
    auth_type: "rerequest",
    display: "popup"
  }),
  [PROVIDER.LINKEDIN]: getURLWithQueryParams(LINKEDIN_AUTH_URL, {
    response_type: "code",
    client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirect_uri: `${process.env.REACT_APP_OAUTH2_URL}/oauth?provider=${PROVIDER.LINKEDIN.toLowerCase()}`,
    scope: LINKEDIN_SCOPE
  })
});

export const queryToObject = queryString => {
  const pairsString =
      queryString[0] === "?" ? queryString.slice(1) : queryString;
  const pairs = pairsString
    .split("&")
    .map(str => str.split("=").map(decodeURIComponent));
  return pairs.reduce((acc, [key, value]) => {
    if (key) {
      acc[key] = value;
    }
  
    return acc;
  }, {});
};
  
const fetchJSON = (...args) => fetch(...args).then(r => r.json());

export const getValidatedWithGoogleUser = async (code, redirectUri) => {
  // obtain authorization token from Google
  const { access_token } = await fetchJSON(GOOGLE_TOKEN, {
    method: "POST",
    body: JSON.stringify({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: redirectUri,
      grant_type: "authorization_code",
      code
    })
  });
  // fetch user data from Google with the obtained token
  const userData = await fetchJSON(GOOGLE_USER_INFO, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });

  // return the user information
  return userData;
};

export const getValidatedWithFacebookUser = async (code, redirectUri) => {
  // obtain authorization token from Facebook
  const tokenUrl = getURLWithQueryParams(FACEBOOK_TOKEN, {
    client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
    client_secret: process.env.REACT_APP_FACEBOOK_CLIENT_SECRET,
    redirect_uri: redirectUri,
    code
  });

  // fetch user data from Facebook with the obtained token
  const { access_token } = await fetchJSON(tokenUrl);
  const dataUrl = FACEBOOK_USER_INFO + "&access_token=" + access_token;
  const userData = await fetchJSON(dataUrl);

  // return the user information
  return userData;
};

export const getValidatedWithLinkedinUser = async (code, redirectUri) => {
  const body = new URLSearchParams({
    grant_type: "authorization_code",
    code: code,
    redirect_uri: redirectUri,
    client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET
  });

  // send information for processing to the backend
  await getLinkedInInformation(body).then(async (res) => {
    // return user information provided by the backend  
    return res;
  });
};