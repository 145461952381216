import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import PopUp from "../components/molecules/PopUp";

import { verifyUser } from "../services/UserService";

import "../styles/pages/ChangeEmailConfirmation.scss";

const ChangeEmailConfirmationPage = () => {
  const history = useHistory();

  useEffect(() => { 
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      verifyUser(token);
    } else { 
      history.push("/");
    }
  });
  

  return (
    <div className="change-email-confirmation">
      <PopUp
        visibility={true}
        handleOnClickClose={() => history.push("/")}
        popupTitle="Your email has been verified!"
        firstButtonTitle="Go to Log In"
        firstButtonType="black"
        firstButtonAction={() => history.push("/login")}
      />
    </div>
  );
};

export default ChangeEmailConfirmationPage;
