import React, { useEffect, useState } from "react";
import $ from "jquery";
import { compress } from "jpegasus";

import Button from "../atoms/Button";
import ImageUploader from "../atoms/ImageUploader";
import CheckedDropdown from "./CheckedDropdown";
import Input from "../atoms/Input";
import Checkbox from "../atoms/Checkbox";
import LabelSet from "./LabelSet";

import { uploadCompanyImage } from "../../services/ImageService";
import { saveCompany } from "../../services/AdminService";
import { getCountryList } from "../../services/ProfileService";
import { updateCompany, deleteCompany, getCompanyFilterTypes, saveCompanyContacts } from "../../services/CompanyService";

import { companyValidation } from "../../util/Validations";
import { companyStatusOptions, companySizeOptions, companyPayoutOptions, companyWorkEnvironmentOptions, companyExperienceOtherDesignersOptions } from "../../assets/data/DataList";

import "../../styles/components/molecules/AdminCompanyForm.scss";
import "../../styles/pages/AdminPage.scss";

const initialValues = {
  name: "",
  country: "",
  contactPerson: "",
  contactEmail: "",
  status: "",
  companySize: "",
  payout: [],
  workEnvironment: [],
  experienceOtherDesigners: "",
  space: [],
  product: [],
  material: [],
  description: "",
  notes: "",
  websiteUrl: "",
  hasFirstImage: false,
  hasSecondImage: false,
  hasThirdImage: false,
  hasFourthImage: false,
  hasFifthImage: false
};

const initialContactValues = [
  {
    functionName: "",
    megosuProfileUrl: "",
    email: "",
    linkedInUrl: ""
  }, 
  {
    functionName: "",
    megosuProfileUrl: "",
    email: "",
    linkedInUrl: ""
  },
  {
    functionName: "",
    megosuProfileUrl: "",
    email: "",
    linkedInUrl: ""
  },
  {
    functionName: "",
    megosuProfileUrl: "",
    email: "",
    linkedInUrl: ""
  },
];

function AdminCompanyForm({ currentCompany }) {
  const AMOUNT_OF_CONTACTS = [1, 2, 3, 4];

  const [values, setValues] = useState(initialValues);
  const [contactValues, setContactValues] = useState(initialContactValues);
  const [company, setCompany] = useState(null);
  const [currentCompanyImageList, setCurrentCompanyImageList] = useState([]);
  const [editing, setEditing] = useState(false);
  const [images, setImages] = useState([]);
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [errors, setErrors] = useState({});
  const [firstImageIsPresent, setFirstImageIsPresent] = useState(false);
  const [secondImageIsPresent, setSecondImageIsPresent] = useState(false);
  const [thirdImageIsPresent, setThirdImageIsPresent] = useState(false);
  const [fourthImageIsPresent, setFourthImageIsPresent] = useState(false);
  const [fifthImageIsPresent, setFifthImageIsPresent] = useState(false);
  const [labels, setLabels] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyFilterTypes().then((result) => {
      const labelsSet = result;
      setLabels(labelsSet);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getCountryList().then((countries) => {
      setCountryList(countries.map((el) => el.name));
    });

    if (currentCompany !== null) {
      setCompany(currentCompany);
      // Set the values to the current company
      values.name = currentCompany.name;
      values.country = currentCompany.country;
      values.contactPerson = currentCompany.contactPerson;
      values.contactEmail = currentCompany.contactEmail;
      values.status = currentCompany.status;
      values.companySize = currentCompany.companySize;
      values.payout = currentCompany.payout.split(",");
      values.workEnvironment = currentCompany.workEnvironment.split(",");
      values.experienceOtherDesigners = currentCompany.experienceOtherDesigners;
      values.space = currentCompany.space;
      values.product = currentCompany.product;
      values.material = currentCompany.material;
      values.description = currentCompany.description;
      values.notes = currentCompany.notes;
      values.websiteUrl = currentCompany.websiteUrl;

      // Sort the images to put them in the correct order (logo is first image)
      const companyImageList = currentCompany.imageUrl.map((a) => a.compressedUrl); 
      companyImageList.sort();
      setCurrentCompanyImageList(companyImageList);

      if (currentCompany.contacts) {
        currentCompany.contacts.map((elem, i) => {
          contactValues[i] = elem;
        });
      }
    } else {
      setValues(initialValues);
      setContactValues(initialContactValues);
    }
  }, []);

  function handleOnChange(e) {
    setEditing(true);
    var { name, value } = e.target;

    if (name === "workEnvironment" || name === "payout") {
      if (values[`${name}`].includes(value)) {
        const temp = values[`${name}`];
        temp.splice(temp.indexOf(value), 1);

        value = temp;     
      } else {
        const temp = values[`${name}`];
        temp.push(value);

        value = temp;
      }
    } 

    switch (name) {
      default:
        setValues({
          ...values,
          [name]: value,
        });
        break;
    }
  }

  function handleOnChangeContact(e, i) {
    setEditing(true);
    var { name, value } = e.target;
    var temp = contactValues;

    temp[i][`${name}`] = value;
    setContactValues([...temp]);
  }

  const setCurrentCountry = (country) => {
    setCountry(country);
  };

  async function uploaderCompanyImage(companyId) {
    // Find the images in the ImageUploader input (should contain 5 company files)
    const imageFormInput = $(".image-uploader__input");
    const imageRequests = [];
    
    for (let i = 0; i < imageFormInput.length; i++) {
      if (imageFormInput[i].files != null && imageFormInput[i].files.length >= 1) {
        
        // Compress max image size
        const compressedFile = await compress(imageFormInput[i].files[0], {
          maxHeight: 1200,
          maxWidth: 1200,
          quality: 0.90,
        });

        const formData = new FormData();
        const file = new File([compressedFile], "image-" + i);
        formData.append("image", file);

        const smallImage = await compress(imageFormInput[i].files[0], {
          maxHeight: 800,
          maxWidth: 800,
          quality: 0.75,
        });

        const compressedData = new FormData();
        const smallImageFile = new File([smallImage], "compressedimage-" + i);
        formData.append("compressedImage", smallImageFile);

        imageRequests.push(
          uploadCompanyImage(
            formData,
            companyId,
            i,
            compressedData
          ).then(() => {
            console.log("image uploaded!");
          })
        );
      } else {
        console.log("No files found");
      }
    }
  }

  const onSubmit = () => {
    values.hasFirstImage = firstImageIsPresent;
    values.hasSecondImage = secondImageIsPresent;
    values.hasThirdImage = thirdImageIsPresent;
    values.hasFourthImage = fourthImageIsPresent;
    values.hasFifthImage = fifthImageIsPresent;
    values.payout = values.payout.join();
    values.workEnvironment = values.workEnvironment.join();
    
    // Set default values if no status, payout or workEnvironment is selected
    if (values.status === "") {
      values.status = companyStatusOptions[1];
    }
    if (values.payout === "") {
      values.payout = companyPayoutOptions[0];
    }
    if (values.workEnvironment === "") {
      values.workEnvironment = companyWorkEnvironmentOptions[0];
    }
    
    // Error handling
    const errorsValidation = companyValidation(values);
    if (Object.keys(errorsValidation).length === 0) {
      // Read the labels 
      const labelElem = document.getElementsByClassName("labelset__input");
      const labels = [];

      if (labelElem.length !== 0) {
        for (let i = 0; i < labelElem.length; i++) {
          if (labelElem[i].checked === true) {
            labels[labelElem[i].name.toLowerCase()] =
            labels[labelElem[i].name.toLowerCase()] || [];
            labels[labelElem[i].name.toLowerCase()].push({
              id: parseInt(labelElem[i].value),
            });
          }
  
          if (labels[labelElem[i].name] === undefined) {
            labels[labelElem[i].name] = [];
          }
        }
        values.space = labels.space;
        values.product = labels.product;
        values.material = labels.material;
      }

      if (currentCompany == null) {
        saveCompany(values).then((res) => {
          setValues(res);

          var companyId = res.id;
  
          uploaderCompanyImage(companyId).then(() => {
            const filteredContactValues = contactValues.filter((elem) => elem.functionName !== null && elem.functionName !== "");

            saveCompanyContacts(companyId, filteredContactValues).then(() => {
              window.alert("Company was saved succesfully!");
              sessionStorage.setItem("adminTab", "Companies");
            });
          });
        });
      } else {
        values.id = currentCompany.id;

        updateCompany(currentCompany.id, values).then((res) => {  
          var companyId = res;

          uploaderCompanyImage(res).then(() => {
            const filteredContactValues = contactValues.filter((elem) => elem.functionName !== null && elem.functionName !== "");

            saveCompanyContacts(companyId, filteredContactValues).then(() => {
              window.alert("Company was saved succesfully!");
              sessionStorage.setItem("adminTab", "Companies");
            });
          });
        });        
      }
      window.scrollTo(0, 0);
    } else {
      setErrors(companyValidation(values));
    }
  };

  const onDelete = () => {
    if (confirm("Are you sure you want to delete this company?")) {
      deleteCompany(currentCompany.id).then((res) => {
        window.alert("Company was deleted succesfully!");
        sessionStorage.setItem("adminTab", "Companies");
        window.location.reload();
      });       
    }
  };

  return (
    <div>
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <div className="admin-company-form__container">
          <div className="admin-company-form__content-container">
            <div className="company-form__content-container company-form__cover-container">
              <div className="company-form__input-container company-form__cover-image">
                <div className="admin-company-form__error-container" name="hasFirstImage">
                  <label className="company-form__label">Logo*</label>
                  {errors.hasFirstImage && (
                    <span className="company-form__error">{errors.hasFirstImage}</span>
                  )}
                </div>
                <ImageUploader
                  key={0}
                  index={0}
                  currentImage={currentCompany !== null ? (
                    currentCompanyImageList[0]
                      ? process.env.REACT_APP_GCS_IMAGE_URL +
                      currentCompanyImageList[0]
                      : undefined
                  )
                    : (
                      images[0]
                        ? process.env.REACT_APP_GCS_IMAGE_URL +
                    images[0]
                        : undefined
                    ) 
                  }
                  removable={true}
                  multiple={false}
                  simplified={true}
                  imageIsPresent={setFirstImageIsPresent}
                />
              </div>

              <div className="company-form__content-container company-form__cover-container">
                <div className="company-form__input-container company-form__cover-image">
                  <div className="admin-company-form__error-container" name="hasSecondImage">
                    <label className="company-form__label">Image 1*</label>
                    {errors.hasSecondImage && (
                      <span className="company-form__error">{errors.hasSecondImage}</span>
                    )}
                  </div>
                  <div className="admin-company-form__image-uploader">
                    <ImageUploader
                      key={1}
                      index={1}
                      currentImage={currentCompany !== null ? (
                        currentCompanyImageList[1]
                          ? process.env.REACT_APP_GCS_IMAGE_URL +
                      currentCompanyImageList[1]
                          : undefined
                      )
                        : (
                          images[1]
                            ? process.env.REACT_APP_GCS_IMAGE_URL +
                    images[1]
                            : undefined
                        ) 
                      }
                      removable={true}
                      multiple={false}
                      simplified={true}
                      imageIsPresent={setSecondImageIsPresent}
                    />
                  </div>
                </div>
              </div>
              <div className="company-form__content-container company-form__cover-container">
                <div className="company-form__input-container company-form__cover-image">
                  <div className="admin-company-form__error-container" name="hasThirdImage">
                    <label className="company-form__label">Image 2*</label>
                    {errors.hasThirdImage && (
                      <span className="company-form__error">{errors.hasThirdImage}</span>
                    )}
                  </div>
                  <div className="admin-company-form__image-uploader">
                    <ImageUploader
                      key={2}
                      index={2}
                      currentImage={currentCompany !== null ? (
                        currentCompanyImageList[2]
                          ? process.env.REACT_APP_GCS_IMAGE_URL +
                      currentCompanyImageList[2]
                          : undefined
                      )
                        : (
                          images[2]
                            ? process.env.REACT_APP_GCS_IMAGE_URL +
                    images[2]
                            : undefined
                        ) 
                      }
                      removable={true}
                      multiple={false}
                      simplified={true}
                      imageIsPresent={setThirdImageIsPresent}
                    />
                  </div>
                </div>
              </div>
              <div className="company-form__content-container company-form__cover-container">
                <div className="company-form__input-container company-form__cover-image">
                  <div className="admin-company-form__error-container" name="hasFourthImage">
                    <label className="company-form__label">Image 3*</label>
                    {errors.hasFourthImage && (
                      <span className="company-form__error">{errors.hasFourthImage}</span>
                    )}
                  </div>
                  <div className="admin-company-form__image-uploader">
                    <ImageUploader
                      key={3}
                      index={3}
                      currentImage={currentCompany !== null ? (
                        currentCompanyImageList[3]
                          ? process.env.REACT_APP_GCS_IMAGE_URL +
                      currentCompanyImageList[3]
                          : undefined
                      )
                        : (
                          images[3]
                            ? process.env.REACT_APP_GCS_IMAGE_URL +
                    images[3]
                            : undefined
                        ) 
                      }
                      removable={true}
                      multiple={false}
                      simplified={true}
                      imageIsPresent={setFourthImageIsPresent}
                    />
                  </div>
                </div>
              </div>
              <div className="company-form__content-container company-form__cover-container">
                <div className="company-form__input-container company-form__cover-image">
                  <div className="admin-company-form__error-container" name="hasFifthImage">
                    <label className="company-form__label">Image 4*</label>
                    {errors.hasFifthImage && (
                      <span className="company-form__error">{errors.hasFifthImage}</span>
                    )}
                  </div>
                  <div className="admin-company-form__image-uploader">
                    <ImageUploader
                      key={4}
                      index={4}
                      currentImage={currentCompany !== null ? (
                        currentCompanyImageList[4]
                          ? process.env.REACT_APP_GCS_IMAGE_URL +
                      currentCompanyImageList[4]
                          : undefined
                      )
                        : (
                          images[4]
                            ? process.env.REACT_APP_GCS_IMAGE_URL +
                    images[4]
                            : undefined
                        ) 
                      }
                      removable={true}
                      multiple={false}
                      simplified={true}
                      imageIsPresent={setFifthImageIsPresent}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-company-form__input-container">
              <div className="admin-company-form__error-container" name="hasName">
                {errors.hasName && (
                  <span className="form__error">{errors.hasName}</span>
                )}
              </div>
              <Input
                label="Company Name"
                id="company-name"
                required={true}
                placeholder=""
                name="name"
                setValue={(values.name)}
                onChange={(e) => handleOnChange(e)}
              />
            </div>

            <div className="admin-company-form__input-container">
              <div className="admin-company-form__error-container" name="hasCountry">
                <label className="form__label">Country*</label>
                {errors.hasCountry && (
                  <span className="form__error">{errors.hasCountry}</span>
                )}
              </div>
              <div className="admin-company-form__input-dropdown">
                <CheckedDropdown
                  dropdownList={countryList}
                  singleCheck={true}
                  name="country"
                  onChange={(e) => handleOnChange(e)}
                  searchable={true}
                  checkedValue={
                    values.country
                  }
                  setCurrentChoice={setCurrentCountry}
                />
              </div>
            </div>

            <div className="admin-company-form__input-container">
              <Input
                label="Company Website (No https:// required)"
                id="company-website"
                required={false}
                placeholder=""
                name="websiteUrl"
                setValue={(values.websiteUrl)}
                onChange={(e) => handleOnChange(e)}
                type="url"
              />
            </div>
            
            {AMOUNT_OF_CONTACTS.map((elem, i) => (
              <>
                <div className="admin-company-form__input-container admin-company-form__input-container--group" key={i}>
                  <Input
                    label={"Function " + (i + 1)}
                    id={"company-person-function" + i}
                    required={false}
                    placeholder=""
                    setValue={(contactValues[i].functionName)}
                    name={"functionName"}
                    onChange={(e) => handleOnChangeContact(e, i)}
                  />

                  <Input
                    label={"Megosu Profile " + (i + 1)}
                    id={"company-person-function" + i}
                    required={false}
                    placeholder=""
                    setValue={(contactValues[i].megosuProfileUrl)}
                    name={"megosuProfileUrl"}
                    onChange={(e) => handleOnChangeContact(e, i)}
                  />

                  <Input
                    label={"Email " + (i + 1)}
                    id={"company-person-function" + i}
                    required={false}
                    placeholder=""
                    setValue={(contactValues[i].email)}
                    name={"email"}
                    onChange={(e) => handleOnChangeContact(e, i)}
                  />

                  <Input
                    label={"LinkedIn " + (i + 1)}
                    id={"company-person-function" + i}
                    required={false}
                    placeholder=""
                    setValue={(contactValues[i].linkedInUrl)}
                    name={"linkedInUrl"}
                    onChange={(e) => handleOnChangeContact(e, i)}
                  />
                </div>
              </>
            ))}


            <h4 className="company-form__checkboxes-title">Status</h4>
            {companyStatusOptions.map((elem, i) => (
              <>
                <div className="company-form__checkboxes-container">
                  <Checkbox
                    key={i}
                    id={i}
                    index={i}
                    fieldName={"company-form__status-checkbox"}
                    name={"status"}
                    value={elem}
                    checked={values.status === elem ? true : false}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <span className="company-form__checkboxes-text">{elem}</span>
                </div>
              </>
            ))}
            <h4 className="company-form__checkboxes-title">Company Size</h4>
            {companySizeOptions.map((elem, i) => (
              <>
                <div className="company-form__checkboxes-container">
                  <Checkbox
                    key={i}
                    id={i}
                    index={i}
                    fieldName={"company-form__size-checkbox"}
                    name={"companySize"}
                    value={elem}
                    checked={values.companySize === elem ? true : false}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <span className="company-form__checkboxes-text">{elem}</span>
                </div>
              </>
            ))}
            <h4 className="company-form__checkboxes-title">Payout</h4>
            {companyPayoutOptions.map((elem, i) => (
              <>
                <div className="company-form__checkboxes-container">
                  <Checkbox
                    key={i}
                    id={i}
                    index={i}
                    fieldName={"company-form__payout-checkbox"}
                    name={"payout"}
                    value={elem}
                    checked={values.payout.includes(elem) ? true : false}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <span className="company-form__checkboxes-text">{elem}</span>
                </div>
              </>
            ))}
            <h4 className="company-form__checkboxes-title">Remote / Local</h4>
            {companyWorkEnvironmentOptions.map((elem, i) => (
              <>
                <div className="company-form__checkboxes-container">
                  <Checkbox
                    key={i}
                    id={i}
                    index={i}
                    fieldName={"company-form__work-environment-checkbox"}
                    name={"workEnvironment"}
                    value={elem}
                    checked={values.workEnvironment.includes(elem) ? true : false}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <span className="company-form__checkboxes-text">{elem}</span>
                </div>
              </>
            ))}
            <h4 className="company-form__checkboxes-title">Experience other designers?</h4>
            {companyExperienceOtherDesignersOptions.map((elem, i) => (
              <>
                <div className="company-form__checkboxes-container">
                  <Checkbox
                    key={i}
                    id={i}
                    index={i}
                    fieldName={"company-form__experience-other-designers-checkbox"}
                    name={"experienceOtherDesigners"}
                    value={elem}
                    checked={values.experienceOtherDesigners === elem ? true : false}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <span className="company-form__checkboxes-text">{elem}</span>
                </div>
              </>
            ))}

            {!loading && (
              <>
                <div className="company-form__label-set">
                  {Object.keys(labels).map((elem, i) => (
                    <LabelSet
                      key={i}
                      loading={loading}
                      currentCompany={currentCompany}
                      index={elem}
                      category={labels[elem]}
                      isCompanyLabels={true}
                    />
                  ))}
                </div>
              </>
            )}

            <div className="admin-company-form__input-container">
              <label className="company-form__label">Company Description</label>
              <textarea
                className="company-form__textarea"
                name="description"
                value={values.description}
                maxLength={5000}
                onChange={(e) => handleOnChange(e)}
              />
            </div>

            <div className="admin-company-form__input-container">
              <label className="company-form__label">Company Notes</label>
              <textarea
                className="company-form__textarea"
                name="notes"
                value={values.notes}
                maxLength={5000}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>

          <div className="admin-company-form__button-container">
            {currentCompany !== null ? (
              <>
                <Button 
                  type="pink"
                  title="Save"
                  customStyle={{marginBottom:  "0.625rem", width: "7rem", height: "2rem"}}
                  onClick={() => { onSubmit(); }}
                />
                <Button 
                  type="white"
                  title="Delete"
                  customStyle={{marginBottom:  "0.625rem", marginLeft: "1rem", width: "7rem", height: "2rem"}}
                  onClick={() => { onDelete(); }}
                />
              </>     
            ) : (
              <Button 
                type="pink"
                customClassName="button--pink-form"
                title="Upload"
                onClick={() => { onSubmit(); }}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default AdminCompanyForm;
