import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { compress } from "jpegasus";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import CheckedDropdown from "./CheckedDropdown";
import ImageUploader from "../atoms/ImageUploader";
import Spinner from "../atoms/Spinner";
import PopUp from "../molecules/PopUp";
import Button from "../atoms/Button";
import HorizontalRule from "../atoms/HorizontalRule";

import {
  getCountryList,
  saveProfile,
  updateProfile,
  updateProfileSocials,
} from "../../services/ProfileService";
import { sendAccountVerificationMail } from "../../services/MailService";
import { deleteUser, migrateOAuth2Account, changeEmail } from "../../services/UserService";
import { logout } from "../../services/LoginService";
import { deleteProfileImage, uploadProfileImage } from "../../services/ImageService";
import { createCustomerPortalSession, deleteSubscription, getPaymentStatus, getSubscriptionStatus } from "../../services/CheckoutService";

import { nullFilter } from "../../util/Filters";
import { scrollToFirstError, preventNonNumericalInput, URLRegex, alphabetRegex, instagramRegex, linkedinRegex, facebookRegex, pinterestRegex } from "../../util/Validations";

import {
  experienceList,
  jobTypeList,
  uploaderTypeList,
} from "../../assets/data/DataList";

import "../../styles/components/molecules/AccountSetupForm.scss";
import { getUniversities } from "../../services/SiteService";
import { disable2faForUser, get2faSetupQRCode, verify2faSetup } from "../../services/AuthService";

const socialInitialValues = {
  instagram: "",
  linkedin: "",
  facebook: "",
  pinterest: "",
};

const initialValues = {
  city: "",
  country: { countryId: "", name: ""},
  biography: "",
  siteUrl: "",
  companyName: "",
  jobTitle: "",
  university: "",
  universityGradDate: "",
  experience: "",
  jobAvailability: "",
  type: "",
  userId: "",
  dateOfBirth: "",
  dateOfBirthDD: "",
  dateOfBirthMM: "",
  dateOfBirthYYYY: "",
  hasBirthday: false,
  goal: "",
  firstName: "",
  lastName: "",
  socials: [socialInitialValues],
};

function AccountSetupForm({ title, authUser, onSubmit, userDetails, type, setAllowLeave }) {
  const [profileValues, setProfileValues] = useState(initialValues);
  const [active, setActive] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [socials, setSocials] = useState(false);
  const [submit, setSubmit] = useState(false);
  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [countryListWithId, setCountryListWithId] = useState([]);
  const [experience, setExperiences] = useState([]);
  const [university, setUniversities] = useState([]);
  const [uploaderType, setUploaderTypes] = useState([]);
  const [goals, setGoals] = useState([]);
  const [currentTab, setCurrentTab] = useState("edit-profile");
  const [previousTab, setPreviousTab] = useState(null);
  const[showSettings, setShowSettings] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [paymentPending, setPaymentPending] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [twoFactorAuthCode, setTwoFactorAuthCode] = useState("");
  const [isUsing2fa, setIsUsing2Fa] = useState(false);
  const MOBILE_SCREEN_WIDTH = 768;
  const isMobile = width <= MOBILE_SCREEN_WIDTH;

  const currentYear = new Date().getFullYear();

  const accountSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Required.")
      .matches(alphabetRegex, "Only letters allowed."),
    lastName: Yup.string()
      .required("Required.")
      .matches(alphabetRegex, "Only letters allowed."),
    siteUrl: Yup.string()
      .nullable()
      .default(undefined)
      .transform((value) => value === "" ? null : value)
      .matches(URLRegex, "Link is not valid."),
    dateOfBirthDD: Yup.string()
      .transform((value) => value === "" ? undefined : value)
      .min(1, "Invalid date.")
      .max(31, "Invalid date.")
      .when(["dateOfBirthMM", "dateOfBirthYYYY"], {
        is: (dateOfBirthMM, dateOfBirthYYYY) => dateOfBirthMM || dateOfBirthYYYY,
        then: Yup.string().required("Invalid date.")
      }),
    dateOfBirthMM: Yup.string()
      .transform((value) => value === "" ? undefined : value)
      .min(1, "Invalid date.")
      .max(12, "Invalid date.")
      .when(["dateOfBirthDD", "dateOfBirthYYYY"], {
        is: (dateOfBirthDD, dateOfBirthYYYY) => dateOfBirthDD || dateOfBirthYYYY,
        then: Yup.string().required("Invalid date.")
      }),
    dateOfBirthYYYY: Yup.number()
      .integer("Only numbers are accepted.")
      .typeError("Only numbers are accepted.")
      .min(1900, "Invalid date.")
      .max(currentYear, "Invalid date.")
      .transform((value, originalValue) => typeof originalValue === "string" && originalValue === "" ? null: value)
      .when(["dateOfBirthDD", "dateOfBirthMM"], {
        is: (dateOfBirthDD, dateOfBirthMM) => dateOfBirthDD || dateOfBirthMM,
        then: Yup.number().required("Invalid date.").nullable(),
        otherwise: Yup.number().notRequired().nullable()
      }),
    universityGradDate: Yup.number()
      .nullable()
      .default(undefined)
      .transform((value, originalValue) => typeof originalValue === "string" &&  originalValue === "" ? undefined : value)
      .min(1900, "Invalid date.")
      .max(2100, "Invalid date."),
  }, [["dateOfBirthMM", "dateOfBirthYYYY"], ["dateOfBirthDD", "dateOfBirthYYYY"], ["dateOfBirthDD", "dateOfBirthMM"]]);


  const socialSchema = Yup.object().shape({
    instagram: Yup.string()
      .nullable()
      .default("")
      .transform((value) => checkProtocol(value))
      .matches(instagramRegex, "Link not valid"),
    linkedin: Yup.string()
      .nullable()
      .default("")
      .transform((value) => checkProtocol(value))
      .matches(linkedinRegex, "Link not valid"),
    facebook: Yup.string()
      .nullable()
      .default("")
      .transform((value) => checkProtocol(value))
      .matches(facebookRegex, "Link not valid"),
    pinterest: Yup.string()
      .nullable()
      .default("")
      .transform((value) => checkProtocol(value))
      .matches(pinterestRegex, "Link not valid"),
  });

  const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Field can't be empty."),
    newPassword: Yup.string()
      .required("Field can't be empty.")
      .min(8, "Should contain at least 8 characters.")
      .matches(/\d+/, "Password should contain at least 1 number"),
    confirmPassword: Yup.string()
      .required("Field can't be empty.")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const emailSchema = Yup.object().shape({
    newMail: Yup.string()
      .required("Field can't be empty.")
      .notOneOf([authUser], "New email can't be the same as current email")
      .email("Please fill in a valid email (example@megosu.com)."),
    confirmEmail: Yup.string()
      .required("Field can't be empty.")
      .oneOf([Yup.ref("newMail"), null], "Emails must match"),
    password: Yup.string()
      .required("Field can't be empty.")
  });

  const { register: registerEmail, handleSubmit: handleEmail, formState: emailState, setError: setEmailError } = useForm({
    defaultValues: { newMail: "", confirmEmail: "", password: ""},
    resolver: yupResolver(emailSchema)
  });

  const { register, handleSubmit, formState, reset, setValue, getValues } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      city: "",
      dateOfBirthDD: "",
      dateOfBirthMM: "",
      dateOfBirthYYYY: "",
      siteUrl: ""
    },
    resolver: yupResolver(accountSchema)
  });

  const { register: registerSocial, handleSubmit: handleSocial, formState: socialState, setValue: setSocialValue } = useForm({
    defaultValues: { instagram: "", linkedin: "", facebook: "", pinterest: "" },
    resolver: yupResolver(socialSchema)
  });

  const { register: registerPassword, handleSubmit: handlePassword, formState: passwordState, setError: setPasswordError } = useForm({
    defaultValues: { oldPassword: "", newPassword: "", confirmPassword: "" },
    resolver: yupResolver(passwordSchema)
  });

  const { register: registerSocialDisconnect, handleSubmit: handleSocialDisconnect, formState: socialDisconnectState, setError: setSocialDisconnectError} = useForm({
    defaultValues: { oldPassword: "hallo", newPassword: "", confirmPassword: "" },
    resolver: yupResolver(passwordSchema)
  });

  useEffect(() => {
    if (isMobile) {
      setShowSettings(false);
      if (sessionStorage.getItem("activeSettingsTab") !== null) {
        setCurrentTab(sessionStorage.getItem("activeSettingsTab"));
      } else {
        setCurrentTab("edit-profile");
      }
    }

    getCountryList().then((countries) => {
      setCountryList(countries.map((el) => el.name));
      setCountryListWithId(countries);
    });

    setExperiences(experienceList.map((el) => el));

    getUniversities().then((universities) => {
      const universityList = universities.map((elem) => elem.city + " - " + elem.name);
      universityList.push("None");
      universityList.push("Other University");

      setUniversities(universityList);
    });

    setUploaderTypes(uploaderTypeList.map((el) => el));

    if (type === "edit") {
      getSubscriptionStatus().then((res) => {
        setSubscriptionStatus(res);
      });
    }

    if (submit) {
      scrollToFirstError(errors);
    }

    if (userDetails && !submit) {
      if (userDetails.socials === undefined || userDetails.socials.length === 0) {
        userDetails.socials = [socialInitialValues];
      }
      setSocialValue("instagram", userDetails.socials[0].instagram);
      setSocialValue("linkedin", userDetails.socials[0].linkedin);
      setSocialValue("facebook", userDetails.socials[0].facebook);
      setSocialValue("pinterest", userDetails.socials[0].pinterest);
      setProfileValues(userDetails);
      reset(userDetails);
    }

    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      const userRequests = [];
      setLoading(true);
      // If existing user data update it else create new profile
      if (userDetails) {
        userRequests.push(uploadImage(profileValues.userId));
        userRequests.push(updateProfile(profileValues));
      } else {
        userRequests.push(
          saveProfile(profileValues).then((result) =>
            uploadImage(profileValues.userId)
          )
        );
      }

      Promise.all(userRequests).then(async function () {
        await onSubmit(true);

        // Only sent verification if first time setup
        if (!userDetails) {
          const newUser = {
            firstName: profileValues.firstName,
            lastName: profileValues.lastName,
            email: authUser.email,
            role: authUser.role,
            hasUploaded: (profileValues.projects != null && profileValues.projects.length > 0) ? "yes" : "no",
          };
        
          sendAccountVerificationMail(authUser.email).then((response) => {
            console.log("Email sent!");
          });
        }

        setLoading(false);
        window.location.reload();
      });
    }
  }, [userDetails, errors, dataIsCorrect]);

  useEffect(() => {
    if (submit) {
      return;
    }
    const originalProps = Object.assign({}, profileValues);
    const initialUserValues = Object.assign({}, userDetails);
    const initialValuesWithoutSocials = Object.assign({}, initialValues);

    delete originalProps.socials;
    delete initialUserValues.socials;
    delete initialValuesWithoutSocials.socials;

    if ((title === "Setup Account" && profileValues !== initialValues) || (title === "Edit Account" && JSON.stringify(initialUserValues) !== JSON.stringify(originalProps))) {
      setAllowLeave(false);
    } else {
      setAllowLeave(true);
    }
  }, [profileValues]);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);

      if (window.innerWidth <= MOBILE_SCREEN_WIDTH) {
        setShowSettings(false);
        setCurrentTab("edit-profile");
      } else {
        setShowSettings(true);
      }
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  function handleDelete() {
    deleteSubscription().then((res) => {
      if (res.status === 200 || res.status === 404) {
        deleteProfileImage(profileValues.profileId).then(() => {
          deleteUser().then(() => {
            const deletedUser = {
              email: authUser.email,
            };
            logout();
            history.push("/");
            window.location.reload();
          });
        });
      } else {
        window.alert("Something went wrong whilst trying to cancel your subscription. Please contact us at support@megosu.com for further help.");
      }
    });
  }

  function handleOnSubmit(data, event) {
    event.preventDefault();
    setSubmit(true);
    setAllowLeave(true);

    const jobAvailabilityElem = document.getElementsByName("jobAvailability");
    const jobAvailabilities = [];

    for (let i = 0; i < jobAvailabilityElem.length; i++) {
      if (jobAvailabilityElem[i].checked === true) {
        jobAvailabilities[i] = jobAvailabilityElem[i].value;
      }
    }

    const filteredJobAvailabilities = nullFilter(jobAvailabilities);
    profileValues.jobAvailability = filteredJobAvailabilities.join();
    profileValues.country = country ? { countryId: parseInt(country.countryId), name: country.name, } : null;
    profileValues.userId = authUser.userId;
    profileValues.goal = goals.join(",");
    profileValues.firstName = data.firstName;
    profileValues.lastName = data.lastName;
    profileValues.city = data.city;
    profileValues.url = data.url;
    profileValues.universityGradDate = data.universityGradDate ? data.universityGradDate.toString() : "";

    if (!data.dateOfBirthDD && !data.dateOfBirthMM && !data.dateOfBirthYYYY) {
      profileValues.dateOfBirth = null;
    } else {
      profileValues.dateOfBirth = data.dateOfBirthYYYY + "-" + data.dateOfBirthMM + "-" + data.dateOfBirthDD;
    }
  
    if (profileValues.city && profileValues.city === profileValues.city.toLowerCase()) {
      profileValues.city = profileValues.city.charAt(0).toUpperCase() + profileValues.city.slice(1);
    }

    if (profileValues.firstName && profileValues.firstName === profileValues.firstName.toLowerCase()) {
      profileValues.firstName = profileValues.firstName.charAt(0).toUpperCase() + profileValues.firstName.slice(1);
    }

    if (profileValues.lastName && profileValues.lastName === profileValues.lastName.toLowerCase()) {
      profileValues.lastName = profileValues.lastName.charAt(0).toUpperCase() + profileValues.lastName.slice(1);
    }

    if (profileValues.siteUrl !== "" && !profileValues.siteUrl.match(/^http?:\/\//i) && !profileValues.siteUrl.match(/^https?:\/\//i)) {
      // if the user gave a URL which does not include a protocol, append the http protocol by default
      profileValues.siteUrl = "http://" + profileValues.siteUrl;
    }
    
    if (profileValues.country === null || profileValues.country === "") {
      setErrors({country: "Required."});
      setAllowLeave(false);
      setSubmit(false);
    } else {
      setErrors({});
    }
    setDataIsCorrect(true);
  }

  const onError = () => {
    scrollToFirstError(formState.errors);
  };

  const handleOnChange = (e) => {
    const { name, min, max } = e.target;
    let { value } = e.target;

    if (name === "firstName" || name === "lastName" || name === "city" || name === "siteUrl"|| name === "dateOfBirthDD" || name === "dateOfBirthMM" || name === "dateOfBirthYYYY" || name === "universityGradDate") {
      setValue(name, value);
    }

    if (name === "dateOfBirthDD" || name === "dateOfBirthMM" || name === "dateOfBirthYYYY" || name === "universityGradDate") {
      if (value !== "") { 
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setAllowLeave(false);
        setValue(name, value);
      }
    } 

    setProfileValues({
      ...profileValues,
      [name]: value,
    });
  };

  const handleOnChangeSocials = (socials) => {
    setProfileValues({
      ...profileValues,
      "socials": [
        socials
      ],
    });
  };

  const handleLogOut = () => {
    logout();
    history.push("/");
    window.location.reload();
  };

  async function uploadImage(profileId) {
    const imageInput = $(".image-uploader__form input")[0].files[0];

    if (imageInput instanceof File || imageInput instanceof Blob) {
      // Compress max image size
      const compressedFile = await compress(imageInput, {
        maxHeight: 1000,
        maxWidth: 1000,
        quality: 0.7,
      });
      const formData = new FormData();
      const file = new File([compressedFile], "image");
      formData.append("image", file);

      return uploadProfileImage(formData, profileId).then(() => {
        console.log("image uploaded!");
      });
    }
  }

  const setCurrentCountry = (country) => {
    let choice = null;
    
    countryListWithId.forEach((e) => {
      if (e.name === country) {
        choice = e;
      }
    });
    setCountry(choice ? { countryId: parseInt(choice.countryId), name: choice.name, } : null);
  };

  document
    .querySelectorAll("[name^='dateOfBirthDD'], [name^='dateOfBirthMM']")
    .forEach((elem) => {
      elem.addEventListener("keydown", (e) => {
        if (e.keyCode === 13) {
          let next = elem.nextElementSibling;
          if (elem.attributes.name.value == "dateOfBirthYYYY") {
            return;
          } else if (next === null) {
            next = document.querySelector("[name^='dateOfBirthYYYY']");
          }
          if (next.nodeName === "INPUT") {
            next.focus();
          }
        } else return;
      });
    });

  function changeTab(e, tabName, returnMenu) {
    if (!returnMenu) e.preventDefault();
  
    if (isMobile) {
      if (returnMenu) {
        setShowSettings(true);
        setCurrentTab("settings");
        sessionStorage.removeItem("activeSettingsTab");
      } else {
        setShowSettings(false);
        document.getElementById("settings-menu").classList.add("setting-menu__inactive");
        setCurrentTab(tabName);
        sessionStorage.setItem("activeSettingsTab", tabName);
      }
    } else {
      if (previousTab !== null) {
        previousTab.classList.remove("setting-menu__items-active");
      } else {
        document.getElementById("edit-profile-tab").classList.remove("setting-menu__items-active");
      }

      if (returnMenu) {
        document.getElementById("edit-profile-tab").classList.add("setting-menu__items-active");
        setPreviousTab(document.getElementById("edit-profile-tab"));
      } else {
        e.target.classList.add("setting-menu__items-active");
        setPreviousTab(e.target);
      }  

      setCurrentTab(tabName);
    }

    if (tabName === "two-factor-authentication") {
      get2faSetupQRCode().then((response) => {
        if (response.ok) {
          response.blob().then((image) => {
            var file = window.URL.createObjectURL(image);
            setQrCodeImage(file);
          });
        } else if (response.status === 403) {
          setIsUsing2Fa(true);
        } else {
          console.log("An error occured");
        }
      });
    }
  }

  const handle2faCodeChange = (event) => {
    setTwoFactorAuthCode(event.target.value);
  };

  function handleSocialsSubmit(data, event) {
    event.preventDefault();
    const facebook = data.facebook || "";
    const instagram = data.instagram || "";
    const linkedin = data.linkedin || "";
    const pinterest = data.pinterest || "";
    const socialsInput = [facebook, instagram, linkedin, pinterest];
    const socialsNames = {
      "facebook": facebook,
      "instagram": instagram,
      "linkedin": linkedin,
      "pinterest": pinterest,
    };

    updateProfileSocials(socialsInput).then((response) => {
      if (response !== null) {
        console.log("[API]: Social Updated");
        window.location.reload();
      } 
    });
  }

  const handleChangePassword = (data, event) => {
    event.preventDefault();
    const oldPassword = data.oldPassword;
    const newPassword = data.newPassword;

    changePassword({ oldPassword, newPassword })
      .then((response) => {
        if (response.ok) {
          logout();
          history.push("/login");
        } else {
          if (response.status == 403) {
            setPasswordError("oldPassword", { type: "custom", message: "Password is not correct"});
          }
        }
      })
      .catch((error) => {
        console.log("Request failed" + error);
      });
  };

  const handleChangeEmail = (data, event) => {
    event.preventDefault();
    const newMail = data.newMail;
    const password = data.password;

    changeEmail({ newMail, password })
      .then((response) => {
        if (response.ok) {
          props.setIsEmailChanged(true);
          sendAccountVerificationMail(newMail);
          window.location.reload();
        } else {
          if (response.status === 403) {
            setEmailError("password", { type: "custom", message: "Password is not correct"});
          }
          if (response.status === 409) {
            setEmailError("newMail", { type: "custom", message: "This email is already in use"});
          }
        }
      })
      .catch((error) => {
        console.log("Request failed" + error);
      });
  };

  const handleAccountMigration = (data, event) => {
    event.preventDefault();
    
    const email = authUser.email;
    const password = data.newPassword;
    
    migrateOAuth2Account(email, password).then((res) => {
      if(res.ok) {
        console.log("[API] Migration Succesfull");
        logout();
        history.push("/login");
        window.location.reload();
      } else {
        console.log("[API] Could not migrate");
      }
    });
  };

  const handleVerify2fa = () => {
    verify2faSetup(twoFactorAuthCode).then((res) => {
      if (res.ok) {
        setIsUsing2Fa(true);
        alert("Two Factor Authentication was succesfully enabled.");
      } else {
        alert("The entered code was not valid or expired. Please try again.");
      }
    });
  };

  const handleDisable2fa = () => {
    disable2faForUser(twoFactorAuthCode).then((res) => {
      if (res.ok) {
        setIsUsing2Fa(false);
        alert("Two Factor Authentication was succesfully disabled.");
      } else {
        alert("The entered code was not valid or expired. Please try again.");
      }
    });
  };

  const handleBillingClick = () => {
    getPaymentStatus().then((res) => {
      if (res === undefined) return;

      if (res.status == "unpaid") {
        setPaymentPending(true);
      } else {
        createCustomerPortalSession(document.URL);
      }
    });
  };

  function checkProtocol(link) {
    if (!link) {
      return null;
    }
    return !link.match(/^http?:\/\//i) && !link.match(/^https?:\/\//i) ? ("https://" + link) : link;
  }

  return (
    <>
      {type === "edit" && showSettings ? (
        <div id="settings-menu" className="setting-menu">
          <h2>Settings</h2>
          <div
            id="edit-profile-tab"
            className={isMobile ? "setting-menu__items" : "setting-menu__items setting-menu__items-active"}
            onClick={(e) => changeTab(e, "edit-profile")}
          >
           Edit Profile
          </div>
          <div
            className="setting-menu__items"
            onClick={(e) => changeTab(e, "social-icons")}
          >
        Social Profiles
          </div>
          {subscriptionStatus !== undefined ?
            <div
              className="setting-menu__items"
              onClick={() => handleBillingClick() }
            >
            Subscription &amp; Billing
            </div>
            :
            null
          }
          <>
            {authUser.authType == "database" ?
              <>
                <div
                  className="form__action setting-menu__items"
                  onClick={(e) => changeTab(e, "change-email")}
                >
       Change Email
                </div>
                <div
                  className="form__action setting-menu__items"
                  onClick={(e) => changeTab(e, "change-password")}
                >
       Change Password
                </div>
                <div
                  className="form__action setting-menu__items"
                  onClick={(e) => changeTab(e, "two-factor-authentication")}
                >
       Two Factor Authentication
                </div>
              </>
              :
              <div
                className="form__action setting-menu__items"
                onClick={(e) => changeTab(e, "disconnect-social")}
              >
           Disconnect Social Login
              </div>
            }
          </>
 
          <div className="form__action setting-menu__items" onClick={() => setActive(true)}>
     Delete Account
          </div>
          <div className="form__action setting-menu__items" onClick={handleLogOut}>
     Log Out
          </div>
        </div>
      ) 
        : null}
      <>
        {currentTab == "edit-profile" && (
          <>
            {isMobile ? (
              <div className="form__settings-button">
                <Button
                  type="white"
                  customClassName="button--white-form"
                  title="&#8592;   Settings"
                  onClick={(e) => {
                    changeTab(e, "settings-menu", true);
                  }}
                />
              </div>
            ) : null}
            <div id="form" className={type === "edit" ? "form" : "form form__account-setup"}>
              <form className="form-container" onSubmit={handleSubmit(handleOnSubmit, onError)}>
                <div className="form__title-container">
                  <h1 className="form__title">{title}</h1>
                </div>
                <div className="form__form">
                  <div className="form__contentbox-container">
                    <div className="form__content-container">
                      <div className="form__input-container">
                        <label className="form__label">Email</label>
                        <p className="form__input--email">
                          {authUser && authUser.email}
                        </p>
                      </div>
   
                      <div className="form__name-container">
                        <div className="form__input-container">
                          <div className="form__label-container">
                            <label className="form__label">First name *</label>
                            {formState.errors && formState.errors?.firstName && (
                              <span className="form__error">{formState.errors.firstName.message}</span>
                            )}
                          </div>
                          <input
                            className={formState.errors && formState.errors?.firstName ? "form__input--error" : "form__input"}
                            name="firstName"
                            type="text"
                            maxLength={35}
                            {...register("firstName")}
                            defaultValue={profileValues.firstName}
                            onBlur={() => { handleOnChange({target: {name: "firstName", value: getValues("firstName")}}); }}
                          />
                        </div>
                        <div className="form__input-container">
                          <div className="form__label-container">
                            <label className="form__label">Last name *</label>
                            {formState.errors && formState.errors?.lastName && (
                              <span className="form__error">{formState.errors.lastName.message}</span>
                            )}
                          </div>
                          <input
                            className={formState.errors && formState.errors?.lastName ? "form__input--error" : "form__input"}
                            name="lastName"
                            type="text"
                            maxLength={35}
                            {...register("lastName")}
                            defaultValue={profileValues.lastName}
                            onBlur={() => { handleOnChange({target: {name: "lastName", value: getValues("lastName")}}); }}
                          />
                        </div>
                      </div>
   
                      <div className="form__input-container">
                        <div className="form__label-container">
                          <label className="form__label">Date of Birth</label>
                          {formState.errors && formState.errors?.dateOfBirthDD ? (
                            <span className="form__error">{formState.errors.dateOfBirthDD.message}</span>
                          ) : formState.errors?.dateOfBirthMM ? (
                            <span className="form__error">{formState.errors.dateOfBirthMM.message}</span>
                          ) : formState.errors?.dateOfBirthYYYY ? (
                            <span className="form__error">{formState.errors.dateOfBirthYYYY.message}</span>
                          ) : null}
                          {!formState.errors?.dateOfBirthDD && !formState.errors?.dateOfBirthMM && !formState.errors?.dateOfBirthYYYY && (
                            <label className="form__description-label">
                         e.g. 28-02-1990
                            </label>
                          )}
                        </div>
                        <div className="form__date-container">
                          <div>
                            <input
                              key={profileValues.dateOfBirth + "-dd"}
                              className={
                                formState.errors && formState.errors?.dateOfBirthDD
                                  ? "form__input--error"
                                  : "form__input"
                              }
                              type="number"
                              name="dateOfBirthDD"
                              min="0"
                              max="31"
                              placeholder="dd"
                              {...register("dateOfBirthDD")}
                              defaultValue={profileValues.dateOfBirthDD}
                              onChange={(e) => { handleOnChange(e); }}
                              onKeyPress={(e) => { preventNonNumericalInput(e); }}
                            />
                            <input
                              key={profileValues.dateOfBirth + "-mm"}
                              className={
                                formState.errors && formState.errors?.dateOfBirthMM
                                  ? "form__input--error"
                                  : "form__input"
                              }
                              type="number"
                              min="0"
                              max="12"
                              name="dateOfBirthMM"
                              placeholder="mm"
                              {...register("dateOfBirthMM")}
                              defaultValue={profileValues.dateOfBirthMM}
                              onChange={(e) => { handleOnChange(e); }}
                              onKeyPress={(e) => { preventNonNumericalInput(e); }}
                            />
                          </div>
                          <div>
                            <input
                              key={profileValues.dateOfBirth + "-yyyy"}
                              className={
                                formState.errors && formState.errors?.dateOfBirthYYYY
                                  ? "form__input--error"
                                  : "form__input"
                              }
                              name="dateOfBirthYYYY"
                              type="number"
                              placeholder="yyyy"
                              {...register("dateOfBirthYYYY")}
                              defaultValue={profileValues.dateOfBirthYYYY}
                              min="1900"
                              max={currentYear}
                              onBlur={(e) => { handleOnChange(e); }}
                              onKeyPress={(e) => { preventNonNumericalInput(e); }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
   
                    <div className="form__image-container">
                      <div className="form__input-container">
                        <label className="form__label">Profile Image</label>
                        <ImageUploader
                          index={0}
                          currentImage={
                            profileValues.imageUrl
                              ? process.env.REACT_APP_GCS_IMAGE_URL +
                           profileValues.imageUrl
                              : undefined
                          }
                          name="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__container">
                    <div className="form__input-container">
                      <div className="form__label-container">
                        <label className="form__label">City</label>
                        {formState.errors && formState.errors?.city && (
                          <span className="form__error">{formState.errors.city.message}</span>
                        )}
                      </div>
   
                      <input
                        className={formState.errors && formState.errors?.city ? "form__input--error" : "form__input"}
                        name="city"
                        type="text"
                        maxLength={30}
                        {...register("city")}
                        defaultValue={profileValues.city}
                        onBlur={() => { handleOnChange({target: {name: "city", value: getValues("city")}}); }}
                      />
                    </div>
                  </div>
   
                  <div className="form__input-container">
                    <div className="form__label-container">
                      <label className="form__label">Country *</label>
                      {errors.country && (
                        <span className="form__error">{errors.country}</span>
                      )}
                    </div>
                    <CheckedDropdown
                      dropdownList={countryList}
                      singleCheck={true}
                      name="country"
                      onChange={(e) => handleOnChange(e)}
                      searchable={true}
                      checkedValue={
                        profileValues.country ? profileValues.country.name : null
                      }
                      setCurrentChoice={setCurrentCountry}
                    />
                  </div>
   
                  <div className="form__input-container">
                    <div className="form__label-container">
                      <label className="form__label">Bio</label>
                      <label className="form__description-label">
                   max. 250 characters
                      </label>
                    </div>
                    <textarea
                      name="biography"
                      value={profileValues.biography}
                      maxLength={250}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
   
                  <div className="form__input-container">
                    <div className="form__label-container">
                      <label className="form__label">Website</label>
                      {formState.errors && formState.errors?.siteUrl && (
                        <span className="form__error">{formState.errors.siteUrl.message}</span>
                      )}
                    </div>
                    <input
                      className={formState.errors && formState.errors?.siteUrl ? "form__input--error" : "form__input"}
                      name="siteUrl"
                      placeholder="https://"
                      {...register("siteUrl")}
                      defaultValue={profileValues.siteUrl}
                      onBlur={() => { handleOnChange({target: {name: "siteUrl", value: getValues("siteUrl")}}); }}
                    />
                  </div>
   
                  <div className="form__input-container">
                    <label className="form__label">I&apos;m here to...</label>
                    <div>
                    </div>
                    <CheckedDropdown
                      dropdownList={uploaderType}
                      singleCheck={false}
                      onChange={(e) => handleOnChange(e)}
                      checkedValue={profileValues.goal ? profileValues.goal : null}
                      name="goal"
                      setGoals={setGoals}
                    />
                  </div>
   
                  <h2 className="form__subtitle">
               Business details{" "}
                    <span className="form__subtitle--optional"> - if applicable</span>
                  </h2>
                  <HorizontalRule color="#C1C1C1" />
                  <div className="form__company-input-container">
                    <div className="form__input-container">
                      <label className="form__label">Company Name</label>
                      <input
                        className="form__input"
                        name="companyName"
                        value={profileValues.companyName}
                        onChange={(e) => handleOnChange(e)}
                      />
                    </div>
   
                    <div className="form__input-container">
                      <label className="form__label">Job Title</label>
                      <input
                        className="form__input"
                        name="jobTitle"
                        value={profileValues.jobTitle}
                        onChange={(e) => handleOnChange(e)}
                      />
                    </div>
                  </div>
   
                  <h2 className="form__subtitle">
               Designer details{" "}
                    <span className="form__subtitle--optional"> - if applicable</span>
                  </h2>
                  <HorizontalRule color="#C1C1C1" />
                  <div className="form__university-container">
                    <div className="form__input-container">
                      <div className="form__label-container">
                        <label className="form__label">University</label>
                        {formState.errors && formState.errors?.universityGradDate && (
                          <span className="form__error">{formState.errors.universityGradDate.message}</span>
                        )}
                      </div>
                      <div className="form__university-dropdown">
                        <CheckedDropdown
                          dropdownList={university}
                          singleCheck={true}
                          name="university"
                          searchable={true}
                          matchOnInclude={true}
                          onChange={(e) => handleOnChange(e)}
                          dropdownType="university"
                          checkedValue={profileValues.university}
                        />
                      </div>
                    </div>
                    <div className="form__university-dates">
                      <div className="form__input-container">
                        <label className="form__label">Graduation Year</label>
                        <input
                          type="number"
                          className={
                            formState.errors && formState.errors?.universityGradDate  ? "form__input--error" : "form__input"
                          }
                          name="universityGradDate"
                          placeholder="YYYY"
                          {...register("universityGradDate")}
                          defaultValue={profileValues.universityGradDate}
                          min="1900"
                          max="2100"
                          onBlur={(e) => { handleOnChange(e); }}
                          onKeyPress={(e) => { preventNonNumericalInput(e); }}
                        />
                      </div>
                    </div>
                  </div>
   
                  <div className="form__input-container">
                    <div className="form__label-container">
                      <label className="form__label">Experience Level</label>
                    </div>
                    <CheckedDropdown
                      dropdownList={experience}
                      singleCheck={true}
                      name="experience"
                      onChange={(e) => handleOnChange(e)}
                      checkedValue={profileValues.experience ? profileValues.experience : null}
                    />
                  </div>
   
                  <div className="form__input-container">
                    <div className="form__label-container">
                      <label className="form__label">Job Availability</label>
                    </div>
                    <div className="form__job-container">
                      {jobTypeList.map((elem, i) => (
                        <div key={i} className="form__job-btn-container">
                          <label
                            className="form__job-btn"
                            id={elem["id"]}
                            htmlFor={elem["id"]}
                          >
                            <input
                              key={profileValues.jobAvailability}
                              className="form__job-input"
                              id={elem["id"]}
                              type="checkbox"
                              name="jobAvailability"
                              value={elem}
                              defaultChecked={profileValues.jobAvailability.includes(
                                elem
                              )}
                            />
                            <span className="form__job-label">{elem}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
   
                <div className="form__button-container">
                  {loading ? (
                    <Spinner type="megosu" />
                  ) : (
                    <div className="form__button-container--main">
                      {type !== "setup" && (
                        <Button
                          className="form__submit"
                          type="white"
                          customClassName="button--white-form"
                          title={"Cancel"}
                          onClick={(e) => { history.push("/profile/" + `${profileValues.user.uuid}`); }}
                        />
                      )}
                      <Button
                        customStyle={{padding:  "0.625rem", minWidth: "7.5rem", fontSize: "1rem"}}
                        className="form__submit"
                        type="black"
                        customClassName="button--black-form"
                        title={"Save"}
                        onClick={() => { handleOnSubmit; }}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </>
        )}

        {currentTab == "disconnect-social" && (
          <>
            {isMobile ? (
              <div className="form__settings-button">
                <Button
                  type="white"
                  customClassName="button--white-form"
                  title="&#8592;   Settings"
                  onClick={(e) => {
                    changeTab(e, "settings-menu", true);
                  }}
                />
              </div>
            ) : null}
            <div id="form" className="form" >
              <form onSubmit={handleSocialDisconnect(handleAccountMigration)}>
                <div className="form-container">
                  <div className="form__title-container">
                    <h1 className="form__title">Disconnect Social Login</h1>
                  </div>
                  <div className="form__form">
                    <p className="form__paragraph">
                Your account is connected with Facebook, Google or LinkedIn. Disconnect your social login to setup general login, including a new email & password. You will keep all your projects & information.
                    </p>
                  </div>

                  <div className="form__input-container">
                    <label className="form__label">Email</label>
                    <input
                      value={profileValues.user.email}
                      className={"form__input"}                
                      type="email"
                    />
                  </div>
                  <div className="form__input-container">
                    <div className="form__checked-input-container">
                      <label className="form__label">Password</label>
                      {socialDisconnectState.errors && socialDisconnectState.errors?.newPassword && (
                        <span>{socialDisconnectState.errors.newPassword.message}</span>
                      )}
                    </div>
                    <input
                      className={
                        socialDisconnectState.errors && socialDisconnectState.errors?.newPassword 
                          ? "form__input--error"
                          : "form__input"
                      }                 
                      type="password"
                      {...registerSocialDisconnect("newPassword")}
                    />
                  </div>
                  <div className="form__input-container">
                    <div className="form__checked-input-container">
                      <label className="form__label">Confirm Password</label>
                      {socialDisconnectState.errors && socialDisconnectState.errors?.confirmPassword && (
                        <span>{socialDisconnectState.errors.confirmPassword.message}</span>
                      )}
                    </div>
                    <input
                      className={
                        socialDisconnectState.errors && socialDisconnectState.errors?.confirmPassword 
                          ? "form__input--error"
                          : "form__input"
                      }                 
                      type="password"
                      {...registerSocialDisconnect("confirmPassword")}
                    />
                  </div>
                  <br></br>
                  <Button
                    className="form__submit"
                    type="black"
                    customClassName="button--black-form"
                    title={"Disconnect"}
                    onClick={() => { handleAccountMigration; }}
                  />
                </div>
              </form>
            </div>
          </>
        )}

        
        {currentTab == "two-factor-authentication" && (
          <>
            {isMobile ? (
              <div className="form__settings-button">
                <Button
                  type="white"
                  customClassName="button--white-form"
                  title="&#8592;   Settings"
                  onClick={(e) => {
                    changeTab(e, "settings-menu", true);
                  }}
                />
              </div>
            ) : null}
            {!isUsing2fa ? (
              <div id="form" className="form" >
                <form onSubmit={(e) => { e.preventDefault(); }}>
                  <div className="form-container">
                    <div className="form__title-container">
                      <h1 className="form__title">Manage 2FA</h1>
                    </div>
                    <div className="form__form">
                      <p className="form__paragraph">
                 Your account is not secured with 2FA yet. Two-Factor Authentication (2FA) adds an extra layer of security to your account. 
                 Scan this QR Code to set 2FA up with the Google Authenticator.
                      </p>
                    </div>
 
                    {qrCodeImage && (
                      <img src={qrCodeImage}></img>
                    )}
 
                    <div className="form__input-container">
                      <div className="form__checked-input-container">
                        <label className="form__label">Code</label>
                      </div>
                      <input
                        className={"form__input"}                 
                        type="text"
                        onChange={handle2faCodeChange}
                      />
                    </div>
                 
                    <br></br>
                    <Button
                      type="black"
                      customClassName="button--black-form"
                      title={"Verify code"}
                      onClick={(e) => { e.preventDefault(); handleVerify2fa(); }}
                    />
                  </div>
                </form>
              </div>
            ) : (
              <div id="form" className="form" >
                <form onSubmit={(e) => {e.preventDefault();}}>
                  <div className="form-container">
                    <div className="form__title-container">
                      <h1 className="form__title">Manage 2FA</h1>
                    </div>
                    <div className="form__form">
                      <p className="form__paragraph">
                 Your account is secured with Two-Factor Authentication (2FA). If you would like to disable 2FA, please enter your 2FA code here.
                      </p>
                    </div>
 
                    <div className="form__input-container">
                      <div className="form__checked-input-container">
                        <label className="form__label">Code</label>
                      </div>
                      <input
                        className={"form__input"}                 
                        type="text"
                        customClassName="button--pink-form"
                        onChange={handle2faCodeChange}
                      />
                    </div>
                 
                    <br></br>
                    <Button
                      type="black"
                      customClassName="button--black-form"
                      title={"Disable 2FA"}
                      onClick={(e) => { e.preventDefault(); handleDisable2fa(); }}
                    />
                  </div>
                </form>
              </div>
            )}
          </>
        )}

        {currentTab === "change-email" && (
          <>
            {isMobile ? (
              <div className="form__settings-button">
                <Button
                  type="white"
                  customClassName="button--white-form"
                  title="&#8592;   Settings"
                  onClick={(e) => {
                    changeTab(e, "settings-menu", true);
                  }}
                />
              </div>
            ) : null}
            <div id="form" className="form" >
              <form className="form-container" onSubmit={handleEmail(handleChangeEmail)}>
                <div className="form__title-container">
                  <h1 className="form__title">Change Email</h1>
                </div>
                <div className="form__form">
                  <p className="form__paragraph">
                Your current email address is {authUser.email}. Please enter your new email address and your password.
                  </p>
                </div>
                <div className="form__input-container">
                  <div className="form__checked-input-container">
                    <label className="form__label">New Email</label>
                    {emailState.errors && emailState.errors?.newMail && (
                      <span>{emailState.errors.newMail.message}</span>
                    )}
                  </div>
                  <input
                    className={
                      emailState.errors && emailState.errors?.newMail 
                        ? "form__input--error"
                        : "form__input"
                    }                 
                    type="email"
                    {...registerEmail("newMail")}
                  />
                </div>
                <div className="form__input-container">
                  <div className="form__checked-input-container">
                    <label className="form__label">Confirm New Email</label>
                    {emailState.errors && emailState.errors?.confirmEmail && (
                      <span>{emailState.errors.confirmEmail.message}</span>
                    )}
                  </div>
                  <input
                    className={
                      emailState.errors && emailState.errors?.confirmEmail 
                        ? "form__input--error"
                        : "form__input"
                    }                 
                    type="email"
                    {...registerEmail("confirmEmail")}
                  />
                </div>
                <div className="form__input-container">
                  <div className="form__checked-input-container">
                    <label className="form__label">Password</label>
                    {emailState.errors && emailState.errors?.password && (
                      <span>{emailState.errors.password.message}</span>
                    )}
                  </div>
                  <input
                    className={
                      emailState.errors && emailState.errors?.password 
                        ? "form__input--error"
                        : "form__input"
                    }                 
                    type="password"
                    {...registerEmail("password")}
                  />
                </div>
                <br></br>

                <div>
                  <Button
                    className="form__button"
                    type="white"
                    customClassName="button--white-form"
                    title={"Cancel"}
                    customStyle={{ width: "30%" }}
                    onClick={(e) => { changeTab(e, "edit-profile", true); }}
                  />
                  <Button
                    className="form__button"
                    customStyle={{ marginLeft: "0.625rem", width: "30%" }}
                    type="black"
                    customClassName="button--black-form"
                    title={"Save"}
                    onClick={() => { handleChangeEmail; }}
                  />
                </div>
              </form>
            </div>
          </>
        )}

        {currentTab === "change-password" && (
          <>
            {isMobile ? (
              <div className="form__settings-button">
                <Button
                  type="white"
                  customClassName="button--white-form"
                  title="&#8592;   Settings"
                  onClick={(e) => {
                    changeTab(e, "settings-menu", true);
                  }}
                />
              </div>
            ) : null}
            <div id="form" className="form" >
              <form className="form-container" onSubmit={handlePassword(handleChangePassword)}>
                <div className="form__title-container">
                  <h1 className="form__title">Change Password</h1>
                </div>
                <div className="form__form">
                  <p className="form__paragraph">
                  Please enter your current password and new password.
                  </p>
                </div>

                <div className="form__input-container">
                  <div className="form__checked-input-container">
                    <label className="form__label">Current Password</label>
                    {passwordState.errors && passwordState.errors?.confirmPassword && (
                      <span>{passwordState.errors.confirmPassword.message}</span>
                    )}
                  </div>
                  <input
                    className={
                      passwordState.errors && passwordState.errors?.oldPassword 
                        ? "form__input--error"
                        : "form__input"
                    }
                    type="password"
                    {...registerPassword("oldPassword")}
                  />
                </div>
                <div className="form__input-container">
                  <div className="form__checked-input-container">
                    <label className="form__label">New Password</label>
                    {passwordState.errors && passwordState.errors?.confirmPassword && (
                      <span>{passwordState.errors.confirmPassword.message}</span>
                    )}
                  </div>
                  <input
                    className={
                      passwordState.errors && passwordState.errors?.oldPassword 
                        ? "form__input--error"
                        : "form__input"
                    }                  
                    type="password"
                    placeholder="6+"
                    {...registerPassword("newPassword")}
                  />
                </div>
                <div className="form__input-container">
                  <div className="form__checked-input-container">
                    <label className="form__label">Confirm New Password</label>
                    {passwordState.errors && passwordState.errors?.confirmPassword && (
                      <span>{passwordState.errors.confirmPassword.message}</span>
                    )}
                  </div>
                  <input
                    className={
                      passwordState.errors && passwordState.errors?.oldPassword 
                        ? "form__input--error"
                        : "form__input"
                    }                  
                    type="password"
                    placeholder="6+"
                    {...registerPassword("confirmPassword")}
                  />
                </div>
                <br></br>
              
                <div>
                  <Button
                    className="form__button"
                    type="white"
                    customClassName="button--white-form"
                    title={"Cancel"}
                    customStyle={{ width: "30%" }}
                    onClick={(e) => { changeTab(e, "edit-profile", true); }}
                  />
                  <Button
                    className="form__button"
                    customStyle={{ marginLeft: "0.625rem", width: "30%" }}
                    type="black"
                    customClassName="button--black-form"
                    title={"Save"}
                    onClick={() => { handleChangePassword; }}
                  />
                </div>
              </form>
            </div>
          </>
        )}

        {currentTab === "social-icons" && (
          <>
            {isMobile ? (
              <div className="form__settings-button">
                <Button
                  type="white"
                  customClassName="button--white-form"
                  title="&#8592;   Settings"
                  onClick={(e) => {
                    changeTab(e, "settings-menu", true);
                  }}
                />
              </div>
            ) : null}
            <div id="form" className="form" >
              <div className="form-container">
                <div className="form__title-container">
                  <h1 className="form__title">Social Icons</h1>
                </div>
                <form onSubmit={handleSocial(handleSocialsSubmit)}>
                  <div className="form__input-container">
                    <div className="form__checked-input-container">
                      <label className="form__label">Instagram</label>
                      <h6 className="form__social-icon-boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
                      {socialState.errors && socialState.errors?.instagram && (
                        <span>{socialState.errors.instagram.message}</span>
                      )}
                    </div>
                    <input
                      placeholder="https://"
                      className={socialState.errors && socialState.errors?.instagram && "form__input--error"}
                      type="text"
                      defaultValue={profileValues.socials[0].instagram}
                      {...registerSocial("instagram")}
                    />
                  </div>
                  <div className="form__input-container">
                    <div className="form__checked-input-container">
                      <label className="form__label">LinkedIn</label>
                      <h6 className="form__social-icon-boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
                      {socialState.errors && socialState.errors?.linkedin && (
                        <span>{socialState.errors.linkedin.message}</span>
                      )}
                    </div>                
                    <input
                      placeholder="https://"
                      className={socialState.errors && socialState.errors?.linkedin && "form__input--error"}
                      type="text"
                      {...registerSocial("linkedin")}
                      defaultValue={profileValues.socials[0].linkedin}
                    />
                  </div>
                  <div className="form__input-container">
                    <div className="form__checked-input-container">
                      <label className="form__label">Facebook</label>
                      <h6 className="form__social-icon-boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
                      {socialState.errors && socialState.errors?.facebook && (
                        <span>{socialState.errors.facebook.message}</span>
                      )}
                    </div>               
                    <input
                      placeholder="https://"
                      className={socialState.errors && socialState.errors?.facebook && "form__input--error"}
                      type="text"
                      {...registerSocial("facebook")}
                      defaultValue={profileValues.socials[0].facebook}
                    />
                  </div>

                  <div className="form__input-container">
                    <div className="form__checked-input-container">
                      <label className="form__label">Pinterest</label>
                      <h6 className="form__social-icon-boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
                      {socialState.errors && socialState.errors?.pinterest && (
                        <span>{socialState.errors.pinterest.message}</span>
                      )}
                    </div>               
                    <input
                      placeholder="https://"
                      className={socialState.errors && socialState.errors?.pinterest && "form__input--error"}
                      type="text"
                      name={"pinterest"}
                      {...registerSocial("pinterest")}
                      defaultValue={profileValues.socials[0].pinterest}
                    />
                  </div>
                  <br></br>
              
                  <div>
                    <Button
                      className="form__button"
                      type="white"
                      customClassName="button--white-form"
                      title={"Cancel"}
                      customStyle={{ width: "30%" }}
                      onClick={(e) => { changeTab(e, "edit-profile", true); }}
                    />
                    <Button
                      className="form__button"
                      customStyle={{ marginLeft: "0.625rem", width: "30%" }}
                      type="black"
                      customClassName="button--black-form"
                      title={"Save"}
                      onClick={() => { handleSocialsSubmit; }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </>
    
      <PopUp
        visibility={isEmailChanged}
        handleOnClickClose={() => history.push("/")}
        overlay={true}
        popupTitle="Please go to your mailbox"
        popupText="A verification email will now be sent to your new email address. Click on the verification within 24 hours to login with your updated email address!"
      />

      <PopUp
        visibility={active}
        overlay={true}
        handleOnClickClose={() => setActive(false)}
        popupTitle="Are you sure you want to delete it!?"
        popupText="No data will be saved. Therefore you can not revive your account after it has been deleted."
        firstButtonTitle="Delete"
        firstButtonAction={handleDelete}
        secondButtonTitle="Keep"
        secondButtonAction={() => setActive(false)}
      />

      <PopUp
        visibility={paymentPending}
        overlay={true}
        handleOnClickClose={() => setPaymentPending(false)}
        transition={true}
        popupTitle="Your payment is pending."
        popupText="Payment methods other than creditcards (e.g. SEPA - iDeal) can take up to 14 business days to process your payment. You can already enjoy your paid plan now!"
      />
    </>
  );
}

export default AccountSetupForm;
