import { AuthHeader } from "../util/AuthHeader";

let controller = new AbortController();
let signal = controller.signal;

document.addEventListener("abortFilterFetch", function() {
  if (controller != null) {
    // aborting the current fetch by the filter
    controller.abort();

    // setting up our new abort controller for the next request
    controller = new AbortController();
    signal = controller.signal; 
  }
});

export function saveProject(project) {
  const saveProjectUrl = process.env.REACT_APP_API_BASE_URL + "/projects/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(project),
  };

  return fetch(saveProjectUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjects(page, size, sortingType, loadedProjectIds) {
  page = `?page=${page}`;
  size = `&size=${size}`;

  sortingType = `&sortingType=${sortingType}`;
  loadedProjectIds = `&loadedProjectIds=${loadedProjectIds}`;

  const getProjects = process.env.REACT_APP_API_BASE_URL + `/projects${page}${size}${sortingType}${loadedProjectIds}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  };

  return fetch(getProjects, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjectById(projectId) {
  const getProjectByIdUrl = process.env.REACT_APP_API_BASE_URL + `/projects/${projectId}/project`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };

  return fetch(getProjectByIdUrl, options);
}

export function getProjectOverviewById(projectId) {
  const getProjectByIdUrl = process.env.REACT_APP_API_BASE_URL + `/projects/${projectId}/project-overview`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProjectByIdUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjectDetailsById(projectId) {
  const getProjectByIdUrl = process.env.REACT_APP_API_BASE_URL + `/projects/${projectId}/project-detail`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProjectByIdUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjectsByUploaderId(uploaderId) {
  const getProjectsByUploaderIdUrl = process.env.REACT_APP_API_BASE_URL + `/projects/${uploaderId}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProjectsByUploaderIdUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjectTitleAndCountByProfileId(profileId) {
  const getProjectTitleAndCountByProfileIdUrl = process.env.REACT_APP_API_BASE_URL + `/projects/${profileId}/projectinfo`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProjectTitleAndCountByProfileIdUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getPreviewProjectsByUploaderId(uploaderId, isOwner) {
  const getProjectsByUploaderIdUrl = process.env.REACT_APP_API_BASE_URL + `/projects/preview-projects/${uploaderId}/${isOwner}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProjectsByUploaderIdUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjectDeliverables(projectId) {
  const getProjectById = process.env.REACT_APP_API_BASE_URL + `/projects/${projectId}/deliverables`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProjectById, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function deleteProject(projectId) {
  const deleteProjectUrl = process.env.REACT_APP_API_BASE_URL + `/projects/${projectId}/delete`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(projectId),
  };

  return fetch(deleteProjectUrl, options);
}

export function updateProject(projectId, project) {
  const updateProjectUrl = process.env.REACT_APP_API_BASE_URL + `/projects/${projectId}/update`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(project),
  };

  return fetch(updateProjectUrl, options);
}

export function getFilteredProjects(searchQuery = "", firstIndex, lastIndex, sortingType, loadedProjectIds) {
  if (!searchQuery) {
    firstIndex = `?firstIndex=${firstIndex}`;
  } else {
    firstIndex = `&firstIndex=${firstIndex}`;
  }
  lastIndex = `&lastIndex=${lastIndex}`;

  sortingType = `&sortingType=${sortingType}`;
  loadedProjectIds = `&loadedProjectIds=${loadedProjectIds}`;

  const filteredProjects = process.env.REACT_APP_API_BASE_URL + `/projects/filter${searchQuery}${firstIndex}${lastIndex}${sortingType}${loadedProjectIds}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal
  };

  return fetch(filteredProjects, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.name === "AbortError") {
        console.log("[DOM] Fetch Request Cancelled.");
      } else {
        console.log(err);
      }
    });
}

export function getFilterTypes() {
  const projectTagsUrl = process.env.REACT_APP_API_BASE_URL + "/projects/filtertypes";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(projectTagsUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjectCountries()  { 
  const countryProjectsUrl = process.env.REACT_APP_API_BASE_URL + "/projects/countries";

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(countryProjectsUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getGoalList() {
  const goalListUrl = process.env.REACT_APP_API_BASE_URL + "/projects/goals";

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(goalListUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getDeliverablesList() {
  const deliverableListUrl = process.env.REACT_APP_API_BASE_URL + "/projects/deliverables";

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(deliverableListUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getRelatedProjectsFilter(projectsId, firstIndex, lastindex) {
  firstIndex = `?firstIndex=${firstIndex}`;
  lastindex = `&lastIndex=${lastindex}`;

  const relatedProjects = process.env.REACT_APP_API_BASE_URL + `/projects/related${firstIndex}${lastindex}`;
  
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(projectsId)
  };

  return fetch(relatedProjects, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.name === "AbortError") {
        console.log("Original request cancelled.");
      } else {
        console.log(err);
      }
    });
}

export function getRelatedProjects(projectId) {
  const relatedProjects = process.env.REACT_APP_API_BASE_URL + `/projects/${projectId}/related`;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(relatedProjects, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function archiveProject(projectId, action) {
  const relatedProjects = process.env.REACT_APP_API_BASE_URL + `/projects/archives/${projectId}/${action}`;

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(relatedProjects, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProfileByProjectId(projectId) {
  const getProfileByProjectIdUrl = process.env.REACT_APP_API_BASE_URL + `/projects/${projectId}/profile`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getProfileByProjectIdUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjectsCount(includeArchived) {
  includeArchived = `?includeArchived=${includeArchived}`;

  const getProjectsCount = process.env.REACT_APP_API_BASE_URL + `/projects/count${includeArchived}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  };
    
  return fetch(getProjectsCount, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function updateProjectViews(projectId) {
  const updateViewsUrl = process.env.REACT_APP_API_BASE_URL + `/projects/views/${projectId}/update-count`;
  const options = {
    method: "PUT",
    headers: {
      Authorization: AuthHeader(),
      "Content-Type": "application/json",
    },
  };
    
  return fetch(updateViewsUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getProjectViewsCount(projectId) {
  const getViewsUrl = process.env.REACT_APP_API_BASE_URL + `/projects/views/${projectId}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
      "Content-Type": "application/json",
    },
  };
    
  return fetch(getViewsUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

const exportedFunctions = {
  saveProject,
  getFilteredProjects,
  getProjects,
  getProjectById,
  getProjectsByUploaderId,
  getFilterTypes,
  getProjectCountries,
  getRelatedProjects,
  getProjectsCount,
  updateProjectViews
};

export default exportedFunctions;
