import React, { useEffect, useState, useRef } from "react";
import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill";

import "../../styles/components/molecules/CheckedDropdown.scss";
import Checkbox from "../atoms/Checkbox"; 

function CheckedDropdown({
  dropdownList,
  dropdownCount,
  singleCheck,
  name,
  onChange,
  dropdownType,
  addRemoveFilter,
  checkedValue,
  removeLocation,
  resetAllLocationBoxes,
  onClick,
  searchable,
  matchOnInclude,
  setGoals,
  setDeliverables,
  setCurrentChoice,
  setResetAllLocationBoxes,
  filterCategoryName,
  extraWidth
}) {
  const [click, isClicked] = useState(false);
  const [choice, setChoice] = useState([]);
  const [preselectCheckbox, setPreselectCheckbox] = useState(true);
  const [search, setSearch] = useState(false);
  const [filteredList, setFilteredList] = useState(dropdownList);
  const [input, setInput] = useState("");
  const placeholderText = name !== "country" ? "- Select -" : "- Select country -";
  
  const ref = useRef(null);

  const BLACK = "#292929";
  const GRAY_LIGHT = "#C1C1C1";

  useEffect(() => {
    if (dropdownList.length) {
      if (input !== "") {
        setFilteredList(dropdownList.filter(item => item.includes[input]));
      } else {
        setFilteredList(dropdownList);
      }
    }

    if (preselectCheckbox) {
      if (typeof checkedValue === "string" || (Array.isArray(checkedValue) && preselectCheckbox)) {
        preSelect(checkedValue);
      }
    }

    document.addEventListener("click", handleClickOutside, true);
  }, [removeLocation, resetAllLocationBoxes, dropdownList]);

  useEffect(() => {
    setChoice(choice.filter((elem) => elem !== removeLocation));
  }, [removeLocation]);

  useEffect(() => {
    setChoice([]);
    
    if (setResetAllLocationBoxes !== undefined) {
      setResetAllLocationBoxes(false);
    }
  }, [resetAllLocationBoxes]);
  
  useEffect(() => {
    if (setGoals) {
      setGoals(choice);
    }
    if (setDeliverables) {
      setDeliverables(choice);
    }
  }, [choice]);

  // Handling checkboxes if the dropdown is singlecheck or multiple check.
  function handleCheckbox(elemIndex, elem, name, e) {
    const checkbox = document.getElementsByClassName("checked-dropdown__checkbox-" + elemIndex);
    const checkboxGroup = document.querySelectorAll(`input[name="${name}"]`);

    checkbox.checked = !checkbox.checked;

    if (singleCheck) {
      for (let i = 0; i < checkboxGroup.length; i++) {
        checkboxGroup[i].checked = false;
      }
      if (choice === elem) {
        setChoice([]);
        e.target.value = "";
      } else {
        document.querySelectorAll(`input[value="${elem}"]`)[0].checked = true;
        setChoice(elem);
        setCurrentChoice && setCurrentChoice(elem);
      }
      isClicked(false);
    } else {
      if (choice.find(item => item === elem)) {
        setChoice((choice) => choice.filter(item => item !== elem));
      } else {
        setChoice((choice) => [...choice, elem]);
      }
    }

    setInput("");
    setFilteredList(dropdownList);
    preSelect(checkbox.value);
    setSearch(false);

    if (checkbox.checked) {
      isClicked(false);
    }
  }

  // Check if user clicks outside of the dropdown to close it.
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearch(false);
      isClicked(false);
      setInput("");
    }
  }

  // Preselect checkboxes based on saved data in database.
  function preSelect(selection) {
    if (singleCheck && typeof selection === "string") {
      const country = selection.charAt(0).toUpperCase() + selection.slice(1);
      setChoice(country);
      setCurrentChoice && setCurrentChoice(country);
      const input = document.querySelectorAll("input[value='${selection}']")[0];
      if (input) {
        input.checked = true;
      }
    } else if (!singleCheck && Array.isArray(checkedValue) && checkedValue.length > 0 && selection !== undefined) {
      setChoice(selection.map((a) => { 
        if (a.name === undefined) {
          return a;
        } else {
          return a.name;
        }
      }));

      selection.map((input) => {
        const checkboxes = document.querySelectorAll(`input[value='${input.name === undefined ? input : input.name}']`);

        checkboxes.forEach((checkbox) => {
          checkbox.checked = true;
        });

        setPreselectCheckbox(false);
      });
    } else if (!singleCheck && typeof selection === "string") {
      selection = selection.split(",");
      let checkbox = "";

      setChoice(selection.map((a) => a));

      selection.map((input) => {
        checkbox = document.getElementById(input);

        if (checkbox) {
          checkbox.checked = true;
        }
      });
    }

    singleCheck && isClicked(false);
  }

  // Match search on first letters
  function handleSearch(e) {
    let { value } = e.target;
    value = value.toLowerCase();
    value = value.charAt(0).toUpperCase() + value.slice(1);
    setInput(value);
    setFilteredList(dropdownList.filter(item => item.startsWith(value)));
    onlyCheckedIfChosen();
  }

  // Match search on includes
  function handleSearchIncludes(e) {
    let { value } = e.target;
    value = value.toLowerCase();
    value = value.charAt(0).toUpperCase() + value.slice(1);
    setInput(value);
    setFilteredList(dropdownList.filter(item => item.includes(value)));
    onlyCheckedIfChosen();
  }

  function onlyCheckedIfChosen() {
    filteredList.map(item => {
      const check = document.querySelector(`input[value="${item}"]`);

      if (check) {
        if (singleCheck) {
          check.checked = item !== choice ? false : true;
        } else {
          check.checked = choice.includes(item) ? true : false;
        }
      }
    });
  }

  const dropdownLabelJSX = <label
    className={dropdownType ? "dropdown-label--" + dropdownType + ` ${extraWidth && "dropdown-input--extra-width"}`: "dropdown-label"}
    style={choice.length > 0 ? { color: BLACK } : { color: GRAY_LIGHT }}
    onClick={() => {searchable ? setSearch(true) : null;}}
  >
    {singleCheck ? (
      <>{choice.length > 0 ? choice : placeholderText}</>
    ) : (
      <>{choice.length > 0 ? choice.slice(0, 38).join(", ") : placeholderText}</>
    )}

    <BsFillCaretDownFill className={!click ? "checked-dropdown__caret-icon" : "checked-dropdown__caret-icon checked-dropdown__caret-icon-reverse"} />
  </label>; 


  return (
    <div ref={ref} className="checked-dropdown__container" onClick={onClick}>
      <div
        className="checked-dropdown checked-dropdown"
        data-control="checkbox-dropdown"
        onClick={() => { 
          isClicked((prev) => !prev);
          onlyCheckedIfChosen();
        }}
      >
        {!searchable ? dropdownLabelJSX :
          <>
            {!search ? dropdownLabelJSX :
              <input 
                type="text"
                className={dropdownType ? "dropdown-input--" + dropdownType : "dropdown-input"}
                ref={input => search && input && input.focus()} 
                onFocus={onlyCheckedIfChosen()}
                onChange={(e) => {!matchOnInclude ? handleSearch(e) : handleSearchIncludes(e);}} 
                onKeyUp={(e) => {!matchOnInclude ? handleSearch(e) : handleSearchIncludes(e);}} 
              />
            }
          </>
        }
      </div>
      <div
        className={
          dropdownType
            ? !(filteredList.length === 0) && search ? "checked-dropdown__option-container--" + dropdownType
              : `checked-dropdown__option-container--${dropdownType} checked-dropdown__option-container-unknown`
            : "checked-dropdown__option-container checked-dropdown__option-container-unknown"
        }
        style={click ? { opacity: "1", visibility: "visible", transform: "translateY(0px)" } : { opacity: "0", visibility: "hidden", transform: "translateY(-10px)", transition: "all 0.3s" }}
      >
        {filteredList.map((elem, i) => (
          <label key={i} className="checked-dropdown__option" onChange={onChange} htmlFor={elem}>
            <Checkbox
              id={elem}
              index={i + 1}
              fieldName={"checked-dropdown__checkbox-" + i}
              name={name}
              value={elem}
              onHandle={(e) => {
                handleCheckbox(i, elem, name, e);
                dropdownType === "filter" && addRemoveFilter(elem, filterCategoryName);
              }}
              onChange={onChange}
            />
            <span className="checked-dropdown__option-name">{elem}</span>
            {dropdownCount && (
              <div className="checked-dropdown__amount" key={i}>
                {dropdownCount[i]}
              </div>
            )}
          </label>
        ))}
        {filteredList.length === 0 && search && (
          <label className="checked-dropdown__unknown">No results found.</label>
        )}
      </div>
    </div>
  );
}

export default CheckedDropdown;