import lukasBazle from "../../assets/images/subscription/review1.jpg";
import felixGrimm from "../../assets/images/howItWorks/Felix Grimm .jpg";
import minwookPaeng from "../../assets/images/howItWorks/Minwook Paeng.jpg";
import saifFaisal from "../../assets/images/howItWorks/Saif Faisal .jpg";
import hugoPaternostre from "../../assets/images/howItWorks/Hugo Paternostre .jpg";
import vincentZigarelli from "../../assets/images/howItWorks/Vincent Zigarelli.jpg";
import ginevraGrisotto from "../../assets/images/howItWorks/Ginevra Grisotto.jpg";
import eikeVoss from "../../assets/images/howItWorks/Eike Voss .jpg";
import mitrabindaBehera from "../../assets/images/howItWorks/Mitrabinda Bahere.jpg";
import jacobSouthfield from "../../assets/images/howItWorks/Jacob Southfield .jpg";
import ruthWassermann from "../../assets/images/howItWorks/Ruth.jpg";
import yuYingTosca from "../../assets/images/howItWorks/Yu-Ying.jpg";
import mahafrinRustomjee from "../../assets/images/howItWorks/Mahafrin-Rustomjee.jpg";
import bingVanDerLande from "../../assets/images/howItWorks/Bing.jpg";
import cinziaFerrari from "../../assets/images/howItWorks/Cinzia Ferrari.jpg";
import profilePlaceholder from "../../assets/images/ProfilePlaceholder.png";
import megosuLogo from "../../assets/images/MegosuBlack.png";

import moooiHeader from "../../assets/images/successStories/Lukas-Bazle/Megosu-Lukas Bazle-8.jpg";
import moooiLogo from "../../assets/images/successStories/Lukas-Bazle/Moooi-Logo.png";
import firstRegularMoooiMedia from "../../assets/images/successStories/Lukas-Bazle/Megosu - Lukas Bazle - 7.mp4";
import secondRegularMoooiMedia from "../../assets/images/successStories/Lukas-Bazle/Megosu - Lukas Bazle - 5.jpg";
import thirdRegularMoooiMedia from "../../assets/images/successStories/Lukas-Bazle/Megosu - Lukas Bazle - 6.mp4";
import fifthSmallBazleImage from "../../assets/images/successStories/Lukas-Bazle/Megosu - Lukas Bazle - 2.jpg";
import sixthSmallBazleImage from "../../assets/images/successStories/Lukas-Bazle/Megosu - Lukas Bazle - 3.jpg";
import seventhSmallBazleImage from "../../assets/images/successStories/Lukas-Bazle/Megosu - Lukas Bazle - 1.jpg";
import eigthSmallBazleImage from "../../assets/images/successStories/Lukas-Bazle/Megosu - Lukas Bazle - 4.jpg";

import madeHeader from "../../assets/images/successStories/Made.com/Megosu - Made.com - 1.jpg";
import madeLogo from "../../assets/images/successStories/Made.com/Made.com - Logo.png";
import firstSmallMadeImage from "../../assets/images/successStories/Made.com/Megosu - Made.com - 4.jpg";
import secondSmallMadeImage from "../../assets/images/successStories/Made.com/Megosu - Made.com - 5.jpg";
import thirdSmallMadeImage from "../../assets/images/successStories/Made.com/Megosu - Made.com - 6.jpg";
import fourthSmallMadeImage from "../../assets/images/successStories/Made.com/Megosu - Made.com - 7.jpg";
import firstRegularMadeMedia from "../../assets/images/successStories/Made.com/Megosu - Made.com - 2.jpg";
import secondRegularMadeMedia from "../../assets/images/successStories/Made.com/Megosu - Made.com - 3.mp4";
import madeStory from "../../assets/images/successStories/Animaties/Megosu-Animation-Made.com.mp4";
import moooiStory from "../../assets/images/successStories/Animaties/Megosu-Animation-Moooi.mp4";
import madeStoryStatic from "../../assets/images/successStories/Made.com/Megosu-Made.com-GIF.png";
import mooiStoryStatic from "../../assets/images/successStories/Lukas-Bazle/Megosu---Lukas-Bazle---Moooi---GIF.png";

import kartentHeader from "../../assets/images/successStories/Kartent/Megosu - KarTent - 1.jpg";
import kartentLogo from "../../assets/images/successStories/Kartent/KarTent - Logo.png";
import firstRegularKartentMedia from "../../assets/images/successStories/Kartent/Megosu - KarTent - 3.jpg";
import secondRegularKartentMedia from "../../assets/images/successStories/Kartent/Megosu - KarTent - 2.m4v";
import kartentStory from "../../assets/images/successStories/Animaties/Megosu-Animation-Kartent.mp4";
import kartentStoryStatic from "../../assets/images/successStories/Kartent/Megosu-KarTent-Gif.png";

export const designerProfiles = [
  {
    name: "Joris",
    videoPath: "about-us/joris-video.mp4",
  },
  {
    name: "Marieke",
    videoPath: "about-us/marieke-video.mp4",
  },
  {
    name: "Silvan",
    videoPath: "about-us/silvan-video.mp4"
  }

];

export const businessTypeList = [
  "Design Studio",
  "Education",
  "Entrepreneur (independent)",
  "Fashion Company",
  "Furniture Company",
  "Manufacturer",
  "Media",
  "Research",
  "Supplier",
  "Tech Company",
];
export const countList = [
  "1-5",
  "6-10",
  "11-25",
  "26-50",
  "51-100",
  "101-250",
  "250+",
];

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua & Deps",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Rep",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo (Democratic Rep)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland (Republic)",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar, (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "St Kitts & Nevis",
  "St Lucia",
  "Saint Vincent & the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Other country",
];

export const designersList = ["Aesthetics", "Craftsman"];
export const experienceList = ["Student", "Junior", "Mid level", "Senior"];
export const jobTypeList = ["Internship", "Freelance", "Parttime", "Fulltime"];
export const uploaderTypeList = ["Find inspiration", "Upload my project", "Seek designers for work"];

export const companySizeList = ["Small", "Medium", "Large"];
export const payoutTypes = ["Royalties", "Buyout"];

export const freeFeatures = [
  "Upload projects",
  "Save projects",
  "Access private community"
];
export const proFeatures = [
  "Everything from Free Plan",
  "Boost exposure on your projects",
  "Link your website & webshop",
  "Add extra images & video",
  "Use all pro filters on explore",
  "Add 3D Viewer of project",
  "See who saved your project"
];
export const businessFeatures = [
  "Everything from Pro Plan",
  "Advanced recruiting filters",
  "Access project rating",
  "Monthly curated projects",
  "Priority support",
  "Onboarding call",
];
export const pitchFeatures = [
  "Everything from Pro Plan",
  "Explore 400+ companies",
  "Unlimited pitching",
  "Contact the right person in 1 click",
  "Access all filters & files"
];

export const partnerFeatures = [
  "Access pool of top designers",
  "Receive pitches",
  "Curation & consultancy",
  "Recruitment"
];

export const freePrices = ["0", "0"];
export const proPrices = ["6", "4"];
export const pitchPrices = ["9", "6"];

export const marketOptions = [
  "Yes, I'm looking for a partner",
  "No, it's unavailable for now",
];

export const companyStatusOptions = [
  "A. Official Partner (Purple) - Open for new designers + Confirmed contact person + Stripe subscription",
  "B. Potential Partner (Orange) - Unknown",
  "C. No Partner (Grey) - Not open for new designers"
];
export const companyStatusExplanations = [
  {
    statusName: "Official Partner",
    title: "This company is an official partner.",
    firstText: "Megosu will propose all pitches to the company on the first of day of each month. Hereafter the response time usually takes 4-6 weeks, if the company is interested.",
    subtitle: "If the company is not interested:",
    secondText: "You won't receive a response",
    thirdTitle: "",
    thirdText: ""
  },
  {
    statusName: "Potential Partner",
    title: "This company is not an official partner, yet.",
    firstText: "",
    subtitle: "But you can pitch to this company.",
    secondText: "Before we pitch your project, we first have to arrange a partnership. We'll save your pitch until we've found the right contactperson within the company. We ensure that the company agrees to our Terms of Use, so you can pitch your project safely. All in all, this process might take some months.",
    thirdTitle: "Bèta Phase:",
    thirdText: "During the starting phase of the pitch feature, you may expect a longer response time since many companies ain’t official partners yet."
  },
  {
    statusName: "No Partner",
    title: "This company is currently not interested in pitches.",
    firstText: "They informed us that they usually stick with their inhouse design department. It’s always worth trying to pitch your designs nonetheless, but we recommend you to pitch your work to other companies as well.",
    subtitle: "",
    secondText: "",
    thirdTitle: "",
    thirdText: ""
  }
];

export const companySizeOptions = [
  "Small 1-50",
  "Medium 51-200",
  "Large 200+"
];
export const companyPayoutOptions = [
  "Unknown",
  "Royalties",
  "Buyout"
];
export const companyWorkEnvironmentOptions = [
  "Unknown",
  "Remote Friendly",
  "At work"
];
export const companyExperienceOtherDesignersOptions = [
  "Unknown",
  "Yes",
  "No"
];

export const brands = [
  {
    name: "Artifort",
    desktopImageUrl: "brands/artifort/Artifort%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/artifort/Artifort%20-%20Mobile.jpeg",
    logoImageUrl: "brands/artifort/Artifort.003.jpeg" 
  },
  {
    name: "Brabantia",
    desktopImageUrl: "brands/brabantia/Brabantia%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/brabantia/Brabantia%20-%20Mobile.jpeg",
    logoImageUrl: "brands/brabantia/Brabantia.009.jpeg" 
  },
  {
    name: "Ferm Living",
    desktopImageUrl: "brands/ferm-living/Ferm%20Living%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/ferm-living/Ferm%20Living%20-%20Mobile.jpeg",
    logoImageUrl: "brands/ferm-living/Ferm%20Living.007.jpeg" 
  },
  {
    name: "Kikkerland",
    desktopImageUrl: "brands/kikkerland/Kikkerland%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/kikkerland/Kikkerland%20-%20Mobile.jpeg",
    logoImageUrl: "brands/kikkerland/Kikkerland.001.jpeg" 
  },
  {
    name: "Moooi",
    desktopImageUrl: "brands/moooi/Moooi%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/moooi/Moooi%20-%20Mobile.jpeg",
    logoImageUrl: "brands/moooi/Moooi.008.jpeg" 
  },
  {
    name: "Occony",
    desktopImageUrl: "brands/occony/Occony%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/occony/Occony%20-%20Mobile.jpeg",
    logoImageUrl: "brands/occony/Occony.006.jpeg" 
  },
  {
    name: "Samsung",
    desktopImageUrl: "brands/samsung/Samsung%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/samsung/Samsung%20-%20Mobile.jpeg",
    logoImageUrl: "brands/samsung/Samsung.002.jpeg" 
  },
  {
    name: "Stelton",
    desktopImageUrl: "brands/stelton/Stelton%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/stelton/Stelton%20-%20Mobile.jpeg",
    logoImageUrl: "brands/stelton/Stelton.004.jpeg" 
  },
  {
    name: "Weltevree",
    desktopImageUrl: "brands/weltevree/Weltevree%20-%20Desktop.jpeg",
    mobileImageUrl: "brands/weltevree/Weltevree%20-%20Mobile.jpeg",
    logoImageUrl: "brands/weltevree/Weltevree.005.jpeg" 
  }
];

export const testimonials = [
  {
    text: `“Design-brand 'Moooi' discovered my work as soon as it was published via
      Megosu. Now it's part of their collection.”`,
    source: lukasBazle,
    designerName: "Lukas Bazle",
    jobTitle: "In-House Designer at IKEA"
  },
  {
    text: "“The idea to build a platform to bring market and designers together on a way as easy as this one is simply brilliant!”",
    source: felixGrimm,
    designerName: "Felix Grimm",
    jobTitle: "Hochschule Coburg"
  },
  {
    text: "“A very welcome move and a great resource for young designers and manufactures alike.”",
    source: saifFaisal,
    designerName: "Saif Faisal",
    jobTitle: "Independent Designer"
  },
  {
    text: "“My project went viral- more than 50 posts have been featured so far, including Reuters, New York Post & Dezeen. This is all due to your team's support and world best design platform.”",
    source: minwookPaeng,
    designerName: "Minwook Paeng",
    jobTitle: "MSc. Imperial College London"
  },
  {
    text: "“What you guys do is great and has exposed me to so many great designers around the world- I'm excited to be a part of it.”",
    source: vincentZigarelli,
    designerName: "Vincent Zigarelli",
    jobTitle: "Industrial Designer - DiveDesign"
  },
  {
    text: "“I joined the Megosu community in April 2021 and since then I noticed nothing but improvements and great works from young designers from all over the world.”",
    source: ginevraGrisotto,
    designerName: "Ginevra Grisotto",
    jobTitle: "Politecnico di Torino"
  },
  {
    text: "“It is a very nice idea you had actually to make brands and designs meet up together.”",
    source: hugoPaternostre,
    designerName: "Hugo Paternostre",
    jobTitle: "Founder - Inoo"
  },
  {
    text: "“I got to say, it's really fantastic how you guys are supporting young designers like me.”",
    source: eikeVoss,
    designerName: "Eike Voss",
    jobTitle: "Independent Designer"
  },
  {
    text: "“I wanted to publish my work and as an avid follower of Megosu, I think it is the best platform to showcase my work!”",
    source: mitrabindaBehera,
    designerName: "Mitrabinda Behera",
    jobTitle: "National Institute of Design"
  },
];

export const testimonialsMobile = [
  {
    text: "“The idea to build a platform to bring market and designers together on a way as easy as this one is simply brilliant!”",
    source: felixGrimm,
    designerName: "Felix Grimm",
    jobTitle: "Hochschule Coburg"
  },
  {
    text: "“A very welcome move and a great resource for young designers and manufactures alike.”",
    source: saifFaisal,
    designerName: "Saif Faisal",
    jobTitle: "Independent Designer"
  },
  {
    text: "“It is a very nice idea you had actually to make brands and designs meet up together.”",
    source: hugoPaternostre,
    designerName: "Hugo Paternostre",
    jobTitle: "Founder - Inoo"
  },
  {
    text: "“You guys have really helped make a huge difference to so many young designers!”",
    source: jacobSouthfield,
    designerName: "Jacob Southfield",
    jobTitle: "Graduated Designer"
  },
];

export const testimonialsTablet = [
  {
    text: `“Design-brand 'Moooi' discovered my work as soon as it was published via
      Megosu. Now it's part of their collection.”`,
    source: lukasBazle,
    designerName: "Lukas Bazle",
    jobTitle: "In-House Designer at IKEA"
  },
  {
    text: "“The idea to build a platform to bring market and designers together on a way as easy as this one is simply brilliant!”",
    source: felixGrimm,
    designerName: "Felix Grimm",
    jobTitle: "Hochschule Coburg"
  },
  {
    text: "“A very welcome move and a great resource for young designers and manufactures alike.”",
    source: saifFaisal,
    designerName: "Saif Faisal",
    jobTitle: "Independent Designer"
  },
  {
    text: "“What you guys do is great and has exposed me to so many great designers around the world- I'm excited to be a part of it.”",
    source: vincentZigarelli,
    designerName: "Vincent Zigarelli",
    jobTitle: "Industrial Designer - DiveDesign"
  },
  {
    text: "“My project went viral- more than 50 posts have been featured so far, including Reuters, New York Post & Dezeen. This is all due to your team's support and world best design platform.”",
    source: minwookPaeng,
    designerName: "Minwook Paeng",
    jobTitle: "MSc. Imperial College London"
  },
  {
    text: "“I joined the Megosu community in April 2021 and since then I noticed nothing but improvements and great works from young designers from all over the world.”",
    source: ginevraGrisotto,
    designerName: "Ginevra Grisotto",
    jobTitle: "Politecnico di Torino"
  },
  {
    text: "“It is a very nice idea you had actually to make brands and designs meet up together.”",
    source: hugoPaternostre,
    designerName: "Hugo Paternostre",
    jobTitle: "Founder - Inoo"
  },
  {
    text: "“I got to say, it's really fantastic how you guys are supporting young designers like me.”",
    source: eikeVoss,
    designerName: "Eike Voss",
    jobTitle: "Independent Designer"
  }
];


export const businessTestimonials = [
  {
    text: "\"We love to help designers turn their ideas into reality over here at Josmo.\"",
    source: mahafrinRustomjee,
    designerName: "Mahafrin Rustomjee",
    jobTitle: "Creative Manager - Josmo"
  },
  {
    text: "\"We can always find cool designs on Megosu!\"",
    source: yuYingTosca,
    designerName: "Yu-Ying Tosca",
    jobTitle: "Founder - Yusca Studio"
  },
  {
    text: "\"Via Megosu we managed to curate 300+ designs and eventually launched over 10 projects into the market.\"",
    source: ruthWassermann,
    designerName: "Ruth Wassermann",
    jobTitle: "Design Director - MADE.COM"
  },
  {
    text: "\"I just wanted to say that you've created such a lovely community! Thank you for letting us be part of it! It was a pleasure to work with you :)\"",
    source: profilePlaceholder,
    designerName: "Maria Zimnoch",
    jobTitle: "Marketing & Media - Wooden story"
  },
  {
    text: "\"Great talent is hard to find, so we're extremely happy with the two talented product designers we hired through Megosu.\"",
    source: bingVanDerLande,
    designerName: "Bing van der Lande",
    jobTitle: "Founder - Object Embassy"
  },
  {
    text: "\"My experience with Megosu was great! It's an incredible community of talents. I recommend it to all designers that would like to connect with alike professionals.\"",
    source: cinziaFerrari,
    designerName: "Cinzia Ferrari",
    jobTitle: "Designer - Flashtalking"
  }
];

export const businessTestimonialsMobile = [
  {
    text: "\"We love to help designers turn their ideas into reality over here at Josmo.\"",
    source: mahafrinRustomjee,
    designerName: "Mahafrin Rustomjee",
    jobTitle: "Creative Manager - Josmo"
  },
  {
    text: "\"We can always find cool designs on Megosu!\"",
    source: yuYingTosca,
    designerName: "Yu-Ying Tosca",
    jobTitle: "Founder - Yusca Studio"
  },
  {
    text: "\"My experience with Megosu was great! It's an incredible community of talents. I recommend it to all designers that would like to connect with alike professionals.\"",
    source: cinziaFerrari,
    designerName: "Cinzia Ferrari",
    jobTitle: "Designer - Flashtalking"
  }
];

export const advertisementLabels = [
  "None",
  "Pro",
  "Pitch",
  "Ad"
];
  
export const successStories = {
  moooi: {
    headerImage: moooiHeader,
    headerCaption: "now in production at ",
    companyName: "Moooi",
    firstInfoHeaderText: "Lukas Bazle",
    secondInfoHeaderText: "",
    firstInfoHeaderImage: null,
    secondInfoHeaderImage: moooiLogo,
    infoTextHTML: `<p class="success-story__info__text--bold">It was early 2019 </p>when we stumbled across a great project of Lukas Bazle, the Hocker stool. We had contact with Lukas and afterwards shared it on our social media. Nothing extraordinary happened. We stayed in touch and on the 5th of March, 2020 we came across Lukas’ new lamp project ‘Méduse’. We were blown away by the movements and its aesthetic: 
    <p class="success-story__info__text--cursive">We just knew the design had potential.</p>
    `,
    infoUnderTitle: "“Little did we know that it would turn out to be an all-time favorite”",
    firstSmallImages: false,
    secondSmallImages: true,
    firstSmallImage: null,
    secondSmallImage: null,
    thirdSmallImage: null,
    fourthSmallImage: null,
    fifthSmallImage: fifthSmallBazleImage,
    sixthSmallImage: sixthSmallBazleImage,
    seventhSmallImage: seventhSmallBazleImage,
    eigthSmallImage: eigthSmallBazleImage,
    firstSmallImageCaption: "",
    secondSmallImageCaption: "",
    thirdSmallImageCaption: "",
    fourthSmallImageCaption: "",
    fifthSmallImageCaption: "",
    sixthSmallImageCaption: "",
    seventhSmallImageCaption: "",
    eigthSmallImageCaption: "",
    regularVideos: "13",
    autoPlayVideos: "1",
    loopVideos: "1",
    firstRegular: firstRegularMoooiMedia,
    secondRegular: secondRegularMoooiMedia,
    thirdRegular: thirdRegularMoooiMedia,
    firstRegularCaption: "",
    secondRegularCaption: "Designer Lukas Bazle",
    thirdRegularCaption: "",
    firstRegularTextHTML: `
    We got back in touch. Lukas was happy about the opportunity of having Méduse shared via our social media. He actually explained: ‘Right now I'm looking for a manufacturer, so maybe this can be helpful.’
    <br/><br/>
    Two weeks later, on the 20st of March we shared his project. We had high hopes, but little did we know that it would turn out to be an all-time favorite of the public.
    <br/><br/>
    <p class="success-story__regular-container__text--bold">It’s been viewed by 2.6 million people</p> and saved by 33.000+ designlovers.
    `,
    secondRegularTextHTML: `
    Lukas told us a couple days after:
    <br/><br/>
    <p class="success-story__regular-container__text--cursive">“The reactions to Méduse were beyond amazing. <span class="success-story__regular-container__text--bold">It looks like I'll find a producer after all.</span> I'm already talking to a few. Frankly, I already reached out to some of the companies a few months ago (before Méduse was featured), but never heard back until now - so you can see how these emails nearly always get lost”</p>
    <br/><br/>
    A couple of months went by and Lukas got back to us. He asked us to be discrete about the exciting news:
    `,
    thirdRegularTextHTML: `
    Roughly 1,5 years after we featured the design on our social media the news came that it’s officialy out for sale at <a class="success-story__regular-container__text__link" href="https://www.moooi.com">Moooi</a>. Till today Méduse remains one of our personal favorite designs. We’re thrilled we could have been a part in bringing it to life.
    `,
    middleTitleHTML: "“Marcel Wanders has seen the lamp at your account and Moooi will distribute it”",
    preButtonQuestion: "The story you've always dreamed about?",
    firstButtonTitle: "Upload your designs",
    secondButtonTitle: "",
    firstButtonAction: "upload-project",
    secondButtonAction: "",
    underButtonText: "Maybe you'll be next.",
    firstOtherStory: madeStory,
    secondOtherStory: kartentStory,
    firstOtherStoryStatic: madeStoryStatic,
    secondOtherStoryStatic: kartentStoryStatic,
    firstOtherStoryCaption: "MADE.com",
    secondOtherStoryCaption:"Kartent",
  },
  made: {
    headerImage: madeHeader,
    headerCaption: "One of the collections of TalentLAB by ",
    companyName: "MADE.com",
    firstInfoHeaderImage: megosuLogo,
    secondInfoHeaderImage: madeLogo,
    infoTextHTML: `
    <p class="success-story__info__text--bold">When we first met </p>
    MADE.com back in the day (2017) it was still relatively unknown. Much has changed. Nowadays it’s one of the biggest brands in the furniture design industry. As we got a big design audience they’ve asked us to promote their TalentLAB initiative: 
    <p class="success-story__info__text--cursive">‘To bring design of upcoming designers to life’.</p>
    `,
    infoUnderTitle: "300+ designs curated for MADE.com",
    firstSmallImages: true,
    secondSmallImages: false,
    firstSmallImage: firstSmallMadeImage,
    secondSmallImage: secondSmallMadeImage,
    thirdSmallImage: thirdSmallMadeImage,
    fourthSmallImage: fourthSmallMadeImage,
    fifthSmallImage: null,
    sixthSmallImage: null,
    seventhSmallImage: null,
    eigthSmallImage: null,
    firstSmallImageCaption: "by Frederick Eksteen",
    secondSmallImageCaption: "by François Bazin",
    thirdSmallImageCaption: "by Nicolas Jacoby",
    fourthSmallImageCaption: "by Silvan Glorie",
    fifthSmallImageCaption: "",
    sixthSmallImageCaption: "",
    seventhSmallImageCaption: "",
    eigthSmallImageCaption: "",
    regularVideos: "2",
    autoPlayVideos: "",
    loopVideos: "",
    firstRegular: firstRegularMadeMedia,
    secondRegular: secondRegularMadeMedia,
    thirdRegular: null,
    firstRegularCaption: "",
    secondRegularCaption: "",
    thirdRegularCaption: "",
    firstRegularTextHTML: `
    The idea is simple. 
    <p class="success-story__regular-container__text--bold">Designers could send in any product design they’ve got </p>
    - MADE.com curates- and the successful matches are crowdfunded and bought by MADE’s customers.
    <br/><br/>
    What MADE did with TalentLAB is well aligned with Megosu’s mission- so it was an absolute honor working closely together with their team. 
    `,
    secondRegularTextHTML: `
    Ruth Wasserman- former Director of Design:
    <br/><br/>
    <p class="success-story__regular-container__text--big">“Via Megosu we managed to curate 300+ designs and eventually launched over 10 projects into the market.”</p>
    `,
    thirdRegularTextHTML: "",
    middleTitleHTML: `
    The designers that managed to pass the curation are now earning royalties. They’ve been exhibited at various designevents and are being sold in <a class="success-story__title__link">MADE’</a> stores across Europe.
    `,
    preButtonQuestion: "Working for a brand and looking for new designs?",
    firstButtonTitle: "Contact us",
    secondButtonTitle: "Business Plan",
    firstButtonAction: "contact",
    secondButtonAction: "pricing",
    underButtonText: "",
    firstOtherStory: moooiStory,
    firstOtherStoryStatic: mooiStoryStatic,
    secondOtherStory: kartentStory,
    secondOtherStoryStatic: kartentStoryStatic,
    firstOtherStoryCaption: "Moooi",
    secondOtherStoryCaption:"Kartent",
  },
  kartent: {
    headerImage: kartentHeader,
    headerCaption: "Now in production at ",
    companyName: "Kartent",
    firstInfoHeaderText: "Skai India",
    secondInfoHeaderText: "",
    firstInfoHeaderImage: null,
    secondInfoHeaderImage: kartentLogo,
    infoTextHTML: `
    <p class="success-story__info__text--bold">What started as </p>
    a company that tackled the problem of festivals- has grown out in a renowned brand that’s famous for 
    <p class="success-story__info__text--cursive">everything that’s got to do with cardboard. </p>
    Kartent’s first product was a tent made out of cardboard, and soon after a wide variety of products followed: beds, garbage bins, lights, toys and many more.
    `,
    infoUnderTitle: "“Within three weeks the product was already put into the shop”",
    firstSmallImages: false,
    secondSmallImages: false,
    firstSmallImage: null,
    secondSmallImage: null,
    thirdSmallImage: null,
    fourthSmallImage: null,
    fifthSmallImage: null,
    sixthSmallImage: null,
    seventhSmallImage: null,
    eigthSmallImage: null,
    firstSmallImageCaption: "",
    secondSmallImageCaption: "",
    thirdSmallImageCaption: "",
    fourthSmallImageCaption: "",
    fifthSmallImageCaption: "",
    sixthSmallImageCaption: "",
    seventhSmallImageCaption: "",
    eigthSmallImageCaption: "",
    regularVideos: "2",
    autoPlayVideos: "",
    loopVideos: "",
    firstRegular: firstRegularKartentMedia,
    secondRegular: secondRegularKartentMedia,
    thirdRegular: null,
    firstRegularCaption: "",
    secondRegularCaption: "",
    thirdRegularCaption: "",
    firstRegularTextHTML: `
    We met Jan Portheine, the founder of Kartent, in 2020. 
    <p class="success-story__regular-container__text--bold">Jan brought us in to scout amongst our Megosu designers to expand Kartent’s range of products. </p>
    One of the products that was put into market was the painting easel by Shwetha Iyengar & Jaymin Panchasara from India.
    `,
    secondRegularTextHTML: `
    What we loved about this partnership was the speed of the process, things all rolled out smoothly. 
    <p class="success-story__regular-container__text--bold">Within three weeks the product was already put into the shop- </p>
    which was also managable because of the relative simple production method and the DIY approach to ship the painting easel flatpacked. Do you like to start painting? Buy yours at 
    <a class="success-story__regular-container__text__link" href="https://shop.kartent.com">Kartent</a>.
    `,
    thirdRegularTextHTML: "",
    middleTitleHTML: "",
    preButtonQuestion: "Wanna be the next success story?",
    firstButtonTitle: "Upload your designs",
    secondButtonTitle: "",
    firstButtonAction: "upload-project",
    secondButtonAction: "",
    underButtonText: "",
    firstOtherStory: moooiStory,
    firstOtherStoryStatic: mooiStoryStatic,
    secondOtherStory: madeStory,
    secondOtherStoryStatic: madeStoryStatic,
    firstOtherStoryCaption: "Moooi",
    secondOtherStoryCaption:"MADE.com",
  },
  emptyObject: {
    headerImage: null,
    headerCaption: "",
    companyName: "",
    firstInfoHeaderText: "",
    secondInfoHeaderText: "",
    firstInfoHeaderImage: null,
    secondInfoHeaderImage: null,
    infoTextHTML: "",
    infoUnderTitle: "",
    firstSmallImages: false,
    secondSmallImages: false,
    firstSmallImage: null,
    secondSmallImage: null,
    thirdSmallImage: null,
    fourthSmallImage: null,
    fifthSmallImage: null,
    sixthSmallImage: null,
    seventhSmallImage: null,
    eigthSmallImage: null,
    firstSmallImageCaption: "",
    secondSmallImageCaption: "",
    thirdSmallImageCaption: "",
    fourthSmallImageCaption: "",
    fifthSmallImageCaption: "",
    sixthSmallImageCaption: "",
    seventhSmallImageCaption: "",
    eigthSmallImageCaption: "",
    regularVideos: "",
    autoPlayVideos: "",
    loopVideos: "",
    firstRegular: null,
    secondRegular: null,
    thirdRegular: null,
    firstRegularCaption: "",
    secondRegularCaption: "",
    thirdRegularCaption: "",
    firstRegularTextHTML: "",
    secondRegularTextHTML: "",
    thirdRegularTextHTML: "",
    middleTitleHTML: "",
    preButtonQuestion: "",
    firstButtonTitle: "",
    secondButtonTitle: "",
    firstButtonAction: "",
    secondButtonAction: "",
    underButtonText: "",
    firstOtherStory: null,
    secondOtherStory: null,
    firstOtherStoryStatic: null,
    secondOtherStoryStatic: null,
    firstOtherStoryCaption: "",
    secondOtherStoryCaption:"",
  },
};


export default {
  designerProfiles,
  businessTypeList,
  countList,
  countryList,
  designersList,
  experienceList,
  jobTypeList,
  uploaderTypeList,
  freeFeatures,
  proFeatures,
  businessFeatures,
  marketOptions,
  freePrices,
  proPrices,
  pitchFeatures,
  partnerFeatures,
  advertisementLabels
};
