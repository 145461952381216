import { Link } from "react-router-dom";

import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";

import "../styles/pages/PrivacyPolicyPage.scss";

import FeatureImage from "../assets/images/Policy.jpg";

const PrivacyPolicyPage = () => {
  return (
    <div className="policy">
      <DocumentHead
        title="Privacy Policy | Megosu"
        metaDescription="In this privacy statement we will explain what information we process from you if we have, 
        want to have or have had a business relationship with you. 
        We will also tell you why and for how long we process your data and what your rights are with regard to each processing."
        showChat={true}
      />
      <h1 className="policy__title">Privacy Policy</h1>
      <div className="policy__feature-image-container">
        <img
          className="policy__feature-image"
          src={FeatureImage}
          alt="partners"
        />
        <p className="policy__feature-image-copyright">
          © Nathan Cussol & Baptiste Cotten
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title--spacing">1. Introduction</h2>
        <p className="policy__container-text">
          In this privacy statement we will explain what information we process
          from you if we have, want to have or have had a business relationship
          with you. We will also tell you why and for how long we process your
          data and what your rights are with regard to each processing. In
          addition, we will also provide information about the parties we work
          with and about our security policy. We believe it is important that
          our services are reliable and transparent. We therefore treat your
          personal data with discretion and care and ensure that any processing
          of your data complies with the applicable laws and regulations.
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          2. Responsible party for processing personal data
        </h2>
        <p className="policy__container-text">
          Company: StudentDesign V.O.F.
          <br />
          Adress: Mt. Lincolnweg 22 15F
          <br />
          Postal Code: 1033 SN
          <br />
          Place: Amsterdam
          <br />
          Tel: +31681378296
          <br />
          Email: info@megosu.com
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">3. Privacy Manager</h2>
        <p className="policy__container-text">
          We have appointed a Privacy Manager. This is not a ‘data protection
          officer’ as referred to in Article 37 of the GDPR. However, it is
          someone who ensures that your data is processed in accordance with the
          law. The activities of the Privacy Manager include monitoring and
          dealing with questions and complaints. The contact details of our
          Privacy Manager are: Guido Lok, info@megosu.com, 0681378296.
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          4. The personal data we process
        </h2>
        <p className="policy__container-text">
          Depending on your relationship with our organization and the purpose
          for which we process data, we note (always some of) the following
          data:
        </p>
        <ul>
          <li className="policy__list-item--ordered">First name and surname</li>
          <li className="policy__list-item--ordered">Date of birth </li>
          <li className="policy__list-item--ordered">
            Reason why you are here ‘I’m here to...’
          </li>
          <li className="policy__list-item--ordered">Address details </li>
          <li className="policy__list-item--ordered">E-mail address</li>
          <li className="policy__list-item--ordered">Job details</li>
          <li className="policy__list-item--ordered">
            Data on education and courses
          </li>
          <li className="policy__list-item--ordered">Team Members</li>
          <li className="policy__list-item--ordered">
            Information about your activities on our website
          </li>
          <li className="policy__list-item--ordered">Website URL</li>
          <li className="policy__list-item--ordered">Project information</li>
          <li className="policy__list-item--ordered">Project pictures</li>
          <li className="policy__list-item--ordered">Project video </li>
          <p className="policy__container-text--spacing">
            As well as in specific cases the following special and/or sensitive
            personal data:
          </p>
          <li className="policy__list-item--ordered">Photograph</li>
          <li className="policy__list-item--ordered">Biography</li>
        </ul>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          5. For what purpose and on what basis we process personal data
        </h2>
        <p className="policy__container-text">
          We process your personal data for the following purposes:
        </p>
        <ul>
          <li className="policy__list-item--ordered">
            Keeping financial records
          </li>
          <li className="policy__list-item--ordered">
            Sending newsletters or mailing
          </li>
          <li className="policy__list-item--ordered">
            Carrying out access control
          </li>
          <li className="policy__list-item--ordered">
            Performing a visitor registration{" "}
          </li>
          <li className="policy__list-item--ordered">
            Website visitor monitoring
          </li>
        </ul>
        <p className="policy__container-text--spacing">
          The processing grounds are those referred to in Article 6 and/or 9 of
          the GDPR (consent, agreement or legitimate interest) ( (unambiguous)
          consent, agreement or legitimate interest).
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          6. Automated decision-making
        </h2>
        <p className="policy__container-text">
          We do not make decisions on the basis of automated processing on
          matters that may have (significant) consequences for individuals.
          These decisions are made by computer programs or systems, without a
          human being (for example, one of our employees) being involved.
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          7. How long we retain personal data
        </h2>
        <p className="policy__container-text">
          In general, we will not retain your data any longer than necessary for
          the purposes for which we have collected your data. The retention
          period may vary from case to case. For example, we may need to retain
          a number of documents for a minimum period of time for tax or
          administrative purposes. In such cases, we will only retain data that
          is necessary to comply with our legal obligations. After the legal
          retention period, we will delete or anonymize your data.
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          8. Sharing personal data with third parties
        </h2>
        <p className="policy__container-text">
          We do not share your personal data with third parties, unless this is
          done in the context of executing an agreement with you and in order to
          comply with any legal obligation. To the extent that these third
          parties are to be regarded as processors, we have entered into a
          processing agreement with these third parties in which, among other
          things, security, confidentiality and your rights are arranged. We
          remain responsible for these processing operations.
        </p>
        <p className="policy__container-text--spacing">
          We engage third parties in the manner and on the grounds described
          above for the following categories of processing:
        </p>
        <ul>
          <li className="policy__list-item--ordered">
            Processing and execution of orders
          </li>
          <li className="policy__list-item--ordered">
            Keeping a financial administration
          </li>
          <li className="policy__list-item--ordered">
            Sending newsletters or mailing
          </li>
          <li className="policy__list-item--ordered">
            Carrying out access control
          </li>
          <li className="policy__list-item--ordered">
            Performing a visitor registration{" "}
          </li>
          <li className="policy__list-item--ordered">
            Website visit monitoring{" "}
          </li>
        </ul>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          9. Cookies, or similar techniques, that we use
        </h2>
        <p className="policy__container-text">
          We use cookies on our website. This is a small text file that is
          stored int eh browser of your computer, tablet or smartphone the first
          time you visit our website.
        </p>
        <p className="policy__container-text--spacing">
          We use the following cookies:
        </p>
        <ul>
          <li className="policy__list-item">
            Cookies with a purely technical functionality. These ensure that the
            website works properly and that, for example, your preferred
            settings are remembered. These cookies are also used to make the
            website work properly and to optimize it.
          </li>
          <li className="policy__list-item">
            Cookies that keep track of your surfing behaviour so that we can
            offer customized content and advertisements. When you visited our
            website, we already informed you about these cookies and asked
            permission to place them.
          </li>
          <li className="policy__list-item">
            Cookies placed by third parties. These are, for example, advertisers
            and/or social media companies.
          </li>
        </ul>
        <p className="policy__container-text--spacing">
          You can unsubscribe from cookies by setting your internet browser in
          such a way that it no longer stored cookies. In addition, you can also
          delete all information previously stored via your browser settings.
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          10. Your rights (inspection, modification, etc.)
        </h2>
        <p className="policy__container-text">
          Your rights with regard to the processing of your data are explained
          below
        </p>
        <ul>
          <li className="policy__list-item">
            Right of inspection. You have the right to access your own data.
            This includes the questions on what the purpose of processing is, to
            which parties the data is provided and what the retention periods
            are.
          </li>
          <li className="policy__list-item">
            Right to rectification. You can ask us to rectify your data
            immediately. You also have the right to complete incomplete data,
            for example by sending us an email.
          </li>
          <li className="policy__list-item">
            ‘Right to be forgotten’. You have the ‘right to be forgotten.’ Upon
            request, we will erase your data without unreasonable delay.
            However, we may not always delete all of your data. Sometimes the
            processing is still necessary, for example, to comply with legal
            obligations or to make, exercise or substantiate claims.
          </li>
          <li className="policy__list-item">
            Right to restriction of processing. In principle, you have the right
            to have the processing of your data restricted, for example if you
            believe that your data is incorrect or the processing of the data is
            unnecessary.
          </li>
          <li className="policy__list-item">
            Notification of rectification, erasure or restriction. Unless it is
            impossible or involves a disproportionate effort, we will notify the
            recipients of your data of any rectification, deletion or
            restriction of the processing.
          </li>
          <li className="policy__list-item">
            Right to data portability. You have the right to transfer data. This
            means that you can make a request to receive your data. You can then
            save this data for personal reuse. You only have this right in
            relation to data that you have provided us yourself and if the
            processing is based on consent or an agreement to which you are a
            party.
          </li>
          <li className="policy__list-item">
            Withdrawal of consent. If the data processing is based on consent,
            you have the right to revoke that consent at any time. However, the
            processing of your data for the period prior to withdrawal remains
            lawful.
          </li>
          <li className="policy__list-item">
            Right to object. In principle, you have the right to object to the
            processing of your data. After your objection we will in principle
            stop the processing of your data.
          </li>
          <li className="policy__list-item">
            Complaint to the Dutch Data Protection Authority or to the court. If
            you are of the opinion that the processing of your data violates the
            law you can contact our Privacy Manager, but you also have the right
            to lodge a complaint with the Personal Data Authority (AP) or go to
            court.
          </li>
          <li className="policy__list-item">
            Restrictions on your rights. Sometimes we may restrict your rights,
            for example in the context of the prevention, investigation,
            detection and prosecution of criminal offenses, such as fraud.
          </li>
        </ul>
        <p className="policy__container-text--spacing">
          Any request can be addressed to our Privacy Manager. To make sure that
          a request has been made by you, we ask you to enclose a copy of your
          ID with the request. In this copy, make your MRZ (machine readable
          zone, the strip with the numbers at the bottom of the passport),
          passport number and citizen service number (burgerservicenummer)
          black. This is to protect your privacy.
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          11. How we secure personal data
        </h2>
        <p className="policy__container-text">
          We take the protection of your data seriously and take appropriate
          measure to prevent misuse, loss, unauthorized access, unwanted
          disclosure and unauthorized modification. If you have the impression
          that your information is not properly secured or there are indications
          of misuse, please contact our privacy manager.
        </p>
      </div>

      <div className="policy__container">
        <h2 className="policy__container-title">
          12. How we secure personal data
        </h2>
        <p className="policy__container-text">
          We may amend this statement if developments give cause to do so, for
          example in the case of new forms of processing. The most current
          privacy statement can be found on our website. We recommend that you
          consult this privacy and cookie statement regularly so that you are
          kept informed of the changes.
        </p>
      </div>

      <p className="policy__container-text--spacing">
        Latest edit: 6th of January 2022
      </p>

      <h2 className="policy__subtitle--spacing">Got more questions?</h2>

      <Link to={"/faq"}>
        <Button
          className="policy__contact-button"
          type="black"
          title="Get Support"
        ></Button>
      </Link>
    </div>
  );
};

export default PrivacyPolicyPage;
