import { AuthHeader } from "../util/AuthHeader";

export function getUnverifiedProjects() {
  const getUnverifiedProjectsUrl = process.env.REACT_APP_API_BASE_URL + "/projects/unverified";
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getUnverifiedProjectsUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
}

export function verifyProject(isVerified, projectId) {
  const verifyProjectUrl = process.env.REACT_APP_API_BASE_URL + "/projects/" + projectId;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "text/plain",
      Authorization: AuthHeader(),
    },
    body: isVerified,
  };

  return fetch(verifyProjectUrl, options).then((response) => {
    if (response.ok) {
      return response;
    }
  });
}

export function sendFeedback(
  type,
  recipient,
  recipientName,
  projectId,
  projectName,
  projectImage,
  subject,
  feedback,
  firstNameSender,
  lastNameSender,
  emailSender,
  rating,
  ratingBoosted
) {
  const feedbackMailUrl = process.env.REACT_APP_API_BASE_URL + "/mails/send/feedback/" + type;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify({
      recipient: recipient,
      recipientName: recipientName,
      subject: subject,
      message: feedback,
      emailSender: emailSender,
      firstNameSender: firstNameSender,
      lastNameSender: lastNameSender,
      projectImage: projectImage,
      projectId: projectId,
      rating: rating,
      ratingBoosted: ratingBoosted
    }),
  };

  return fetch(feedbackMailUrl, options).then((response) => {
    if (response.ok) {
      return response;
    }
  });
}

export function saveHeader(header) {
  const headerSaveUrl =
    process.env.REACT_APP_API_BASE_URL + "/headers/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(header),
  };

  return fetch(headerSaveUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}


export function updateHeader(id, header) {
  const headerUpdateUrl =
    process.env.REACT_APP_API_BASE_URL + `/headers/${id}/update`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(header),
  };

  return fetch(headerUpdateUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function deleteHeader(id) {
  const deleteHeaderUrl = process.env.REACT_APP_API_BASE_URL + `/headers/${id}/delete`;

  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
    },
  };
      
  return fetch(deleteHeaderUrl, options).then((response) => {
    if (response.ok) {
      console.log("Header removed");
    }
  });
}

export function saveCompany(company) {
  const saveCompanyUrl = process.env.REACT_APP_API_BASE_URL + "/companies/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(company),
  };
  
  return fetch(saveCompanyUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}