import React, { useEffect, useState } from "react";

import Button from "../atoms/Button";
import CheckedDropdown from "./CheckedDropdown";
import Input from "../atoms/Input";

import { updateAdvertisement, deleteAdvertisement, saveAdvertisement } from "../../services/AdvertisementService";
import { uploadAdvertisementVisual } from "../../services/ImageService";

import { advertisementValidation } from "../../util/Validations";
import { advertisementLabels } from "../../assets/data/DataList";

import "../../styles/components/molecules/AdminAdvertisementForm.scss";

const initialValues = {
  position: "",
  title: "",
  url: "",
  label: ""
};

function AdminAdvertisementForm({ currentAdvertisement, advertisementPositions }) {
  const [values, setValues] = useState(initialValues);
  const [advertisement, setAdvertisement] = useState(null);
  const [visualInput, setVisualInput] = useState(null);
  const [errors, setErrors] = useState({});
  const [advertisementPositionOptions, setAdvertisementPositionOptions] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);

  useEffect(() => {
    setLabelOptions(advertisementLabels);

    // Fill advertisement options list with the available position options (open position(s) and last position)
    if (advertisementPositions.length !== 0) {
      const availablePositions = [];
      let temp = 1;
      // Loop over all current occupied advertisement positions to find the open position(s) if any
      for (let i = 0; i < advertisementPositions.length; i++) {
        if (i + temp !== advertisementPositions[i]) {
          availablePositions.push((i + temp).toString()); // Convert to String for CheckedDropdown functionality
          // Increment temp by 1 to keep track of the already available positions
          temp++;
        }
      }
      // Add last position+1 to the array
      availablePositions.push((advertisementPositions[advertisementPositions.length - 1] + 1).toString());
      // Add current position to the advertisement position dropdown options
      if (currentAdvertisement !== null) {
        availablePositions.push(currentAdvertisement.position.toString());
      }
      availablePositions.sort();
      setAdvertisementPositionOptions(availablePositions);
    } else {
      advertisementPositionOptions.push("1");
    }
    
    if (currentAdvertisement !== null) {
      setAdvertisement(currentAdvertisement);
      // Set the values to the current advertisement
      values.position = currentAdvertisement.position;
      values.title = currentAdvertisement.title;
      values.url = currentAdvertisement.url;
      values.label = currentAdvertisement.label;
    } else {
      setValues(initialValues);
    }
  }, [currentAdvertisement]);

  function handleOnChange(e) {
    var { name, value } = e.target;

    switch (name) {
      default:
        setValues({
          ...values,
          [name]: value,
        });
        break;
    }
  }

  const onSubmit = () => { 
    // Error handling
    let editing = false;
    if (currentAdvertisement !== null) {
      editing = true;
    }
    const errorsValidation = advertisementValidation(values, visualInput, editing);
    if (Object.keys(errorsValidation).length === 0) {
      if (currentAdvertisement == null) {
        if (values.position === "") {
          // Set the position to the next advertisement position as default
          values.position = advertisementPositionOptions[0];
        }

        saveAdvertisement(values).then((advertisement) => {
          setValues(advertisement);
          uploadVisual(advertisement.id, visualInput).then((res) => {
            window.alert("Banner was saved succesfully!");
          });
        });
      } else {
        values.id = currentAdvertisement.id;
        // If no new file is selected, visualInput is null, so keep the old visual
        if (visualInput === null) {
          values.visual = currentAdvertisement.visual;
        }

        updateAdvertisement(currentAdvertisement.id, values).then((advertisement) => {  
          // If no new file is selected, visualInput is null, so keep the old visual
          if (visualInput !== null) {
            uploadVisual(currentAdvertisement.id, visualInput);
          }
          window.alert("Banner was saved succesfully!");
        });        
      }
      window.scrollTo(0, 0);
    } else {
      setErrors(advertisementValidation(values));
    }
  };

  async function uploadVisual(advertisementId, visual) {
    if (visual instanceof File || visual instanceof Blob) {
      const formData = new FormData();
      const file = new File([visual], "visual");
      formData.append("visual", file);

      return uploadAdvertisementVisual(formData, advertisementId).then(() => {
        console.log("image uploaded!");
      });
    }
  }

  const onDelete = () => {
    if (confirm("Are you sure you want to delete this banner?")) {
      deleteAdvertisement(currentAdvertisement.id).then((res) => {
        window.alert("Banner was deleted succesfully!");
        sessionStorage.setItem("adminTab", "Banner Card");
        window.location.reload();
      });       
    }
  };

  const onCancel = () => {
    sessionStorage.setItem("adminTab", "Banner Card");
    window.location.reload();
  };

  const handleFileInput = (event) => {
    setVisualInput(event.target.files[0]);
  };
  
  return (
    <div>
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <div className="admin-advertisement-form__container">
          <div className="admin-advertisement-form__content-container">
            <div className="admin-advertisement-form__header-container">
              <h3 className="admin-advertisement-form__header">Banner Card</h3>
            </div>

            <div className="admin-advertisement-form__input-container">
              <div className="admin-advertisement-form__error-container" name="hasVideo">
                {errors.hasVideo && (
                  <span className="form__error">{errors.hasVideo}</span>
                )}
              </div>
              <Input
                label={currentAdvertisement ? "Choose a file to replace the current file (optional)" : "Video (MP4)"}
                id="banner-visual"
                type="file"
                accept="video/mp4"
                required={currentAdvertisement ? false : true}
                placeholder=""
                name="visual"
                onInput={(e) => {  handleFileInput(e, "file"); }}
              />
              {currentAdvertisement !== null && (
                <>
                  <h4 className="admin-advertisement-form__current-file-title">Current file:</h4>
                  <video 
                    className="admin-advertisement-form__visual" 
                    src={process.env.REACT_APP_GCS_IMAGE_URL + currentAdvertisement.visual + "#t=0.001"}
                    type="video/mp4"
                    playsInline 
                    muted="muted"
                    autoPlay="autoPlay"
                    loop="loop"
                  />
                </>
              )}
            </div>

            <div className="admin-advertisement-form__input-container">
              <div className="admin-advertisement-form__error-container" name="hasTitle">
                {errors.hasTitle && (
                  <span className="form__error">{errors.hasTitle}</span>
                )}
              </div>
              <Input
                label="Title"
                id="banner-title"
                required={true}
                placeholder=""
                name="title"
                setValue={(currentAdvertisement ? values.title : null)}
                onChange={(e) => handleOnChange(e)}
              />
            </div>

            <div className="admin-advertisement-form__input-container">
              <div className="admin-advertisement-form__error-container" name="hasUrl">
                {errors.hasUrl && (
                  <span className="form__error">{errors.hasUrl}</span>
                )}
              </div>
              <Input
                label="URL (No https:// required. Example: www.megosu.com)"
                id="banner-url"
                required={true}
                placeholder=""
                name="url"
                setValue={(currentAdvertisement ? values.url : null)}
                onChange={(e) => handleOnChange(e)}
              />
            </div>

            <div className="admin-advertisement-form__input-container">
              <div className="admin-advertisement-form__error-container" name="hasCountry">
                <label className="form__label">Label*</label>
                {errors.hasLabel && (
                  <span className="form__error">{errors.hasLabel}</span>
                )}
              </div>
              <div className="admin-advertisement-form__input-dropdown">
                <CheckedDropdown
                  dropdownList={labelOptions}
                  singleCheck={true}
                  name="label"
                  onChange={(e) => handleOnChange(e)}
                  searchable={false}
                  checkedValue={currentAdvertisement && values.label.toString()}
                />
              </div>
            </div>
            <div className="admin-advertisement-form__input-container">
              <div className="admin-advertisement-form__error-container" name="hasPosition">
                <label className="form__label">Position (Optional, default: current position or last position with new upload)</label>
              </div>
              <div className="admin-advertisement-form__input-dropdown">
                <CheckedDropdown
                  dropdownList={advertisementPositionOptions}
                  singleCheck={true}
                  name="position"
                  onChange={(e) => handleOnChange(e)}
                  searchable={false}
                  checkedValue={currentAdvertisement && values.position.toString()}
                />
              </div>
            </div>
          </div>

          <div className="admin-advertisement-form__button-container">
            {currentAdvertisement !== null ? (
              <>
                <Button 
                  type="white"
                  title="Delete"
                  customClassName="admin-advertisement-form__button button--white-form"
                  onClick={() => { onDelete(); }}
                />
                <Button 
                  type="black"
                  title="Cancel"
                  customClassName=
                    "admin-advertisement-form__button button--black-form admin-advertisement-form__button--margin-left"
                  onClick={() => { onCancel(); }}
                />
                <Button 
                  type="pink"
                  title="Save"
                  customClassName=
                    "admin-advertisement-form__button button--pink-form admin-advertisement-form__button--margin-left"
                  onClick={() => { onSubmit(); }}
                />
              </>     
            ) : (
              <Button 
                type="pink"
                customClassName="button--pink-form"
                title="Upload"
                onClick={() => { onSubmit(); }}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default AdminAdvertisementForm;
