import { Link } from "react-router-dom";
import { useEffect } from "react";

import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";

import "../styles/pages/GuidelinesPage.scss";

import FeatureImage from "../assets/images/Guidelines.jpg";

import scrollTrigger from "../util/ScrollReveal";

const GuidelinesPage = () => {
  const sendEmail = () => {
    window.location = "mailto:partners@megosu.com";
  };

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);

  return (
    <div className="guidelines">
      <DocumentHead
        title="Community Guidelines | Megosu"
        metaDescription="You pledge to be an upstanding member of the Megosu community. Please follow our 8 Rules. "
      />
      <h1 className="guidelines__title reveal">Community Guidelines</h1>
      <div className="guidelines__feature-image-container reveal">
        <img
          className="guidelines__feature-image"
          src={FeatureImage}
          alt="partners"
        />
        <p className="guidelines__feature-image-copyright">
          © Cornelius Richter
        </p>
      </div>

      <h2 className="guidelines__subtitle reveal">
        You pledge to be an upstanding member of the Megosu community. Please
        follow our 8 Rules.
      </h2>

      <div className="guidelines__container reveal">
        <h2 className="guidelines__container-title">
          1. I will upload only work that i’ve created
        </h2>
        <p className="guidelines__container-text">
          Share your own work on Megosu.
        </p>
        <ul>
          <li className="guidelines__list-item">Don’t post other’s work.</li>
          <li className="guidelines__list-item">
            Don’t take credit for other’s work.
          </li>
          <li className="guidelines__list-item">
            Don’t post copyrighted or trademarked content.
          </li>
          <li className="guidelines__list-item">
            Don&apos;t plagiarize other’s work. A remix/rebound is ok. An exact
            copy/replica is not.
          </li>
        </ul>
        <p className="guidelines__container-text--spacing">
          If your shot violates these guidelines, it will be removed from Megosu
          and your account may be suspended.
        </p>
      </div>

      <div className="guidelines__container reveal">
        <h2 className="guidelines__container-title">
          2. I will upload design work
        </h2>
        <p className="guidelines__container-text">
          Megosu is a place to upload what you’re currently working on and
          portfolio quality work. As a Megosu member, you should be thoughtful
          about the design work you upload.
        </p>
        <ul>
          <li className="guidelines__list-item">
            Don’t share content that you would share on a video-sharing
            platform—including promotional videos, commercials, and tutorials.
            Video uploads should be used for animation, 3D explorations, process
            shots and design work of this nature.
          </li>
          <li className="guidelines__list-item">
            If you’re using audio to enhance your shot, please use it
            thoughtfully. Make sure you have permission before using audio
            that’s copyrighted or stick to audio that’s under creative commons.
          </li>
        </ul>
        <p className="guidelines__container-text--spacing">
          If your shot violates these guidelines, it will be removed from
          Megosu.
        </p>
      </div>

      <div className="guidelines__container reveal">
        <h2 className="guidelines__container-title">
          3. I won’t post inappropriate content
        </h2>
        <p className="guidelines__container-text">
          Megosu aspires to be a welcoming and inclusive community. Please don’t
          post content that is:
        </p>
        <ul>
          <li className="guidelines__list-item">Illegal</li>
          <li className="guidelines__list-item">
            Don&apos;t post other&apos;s work.
          </li>
          <li className="guidelines__list-item">
            Pornographic, mature in content or overly sexual [1]
          </li>
          <li className="guidelines__list-item">
            Racist, sexist, or otherwise offensive
          </li>
          <li className="guidelines__list-item">
            Shockingly graphic, grotesque, or obscene
          </li>
          <li className="guidelines__list-item">
            Inflammatory (e.g. name-calling, preaching, ranting, stirring up
            controversy, or venting frustrations)
          </li>
        </ul>
        <p className="guidelines__container-text--spacing">
          [1] We realize that nudity can be a gray area. We aim to keep Megosu
          PG-13 and shots violating this guideline will be removed at Megosu’s
          discretion.
        </p>
        <p className="guidelines__container-text--spacing">
          If you post inappropriate content, it may be removed by Megosu per our{" "}
          <Link to={"/terms"}>Terms of Service. </Link>
          Megosu has final say over whether content is appropriate.
        </p>
        <p className="guidelines__container-text--spacing">
          If you notice a Megosu member posting inappropriate content, report it
          by <a href="mailto:support@megosu.com"> contacting us</a>.
        </p>
      </div>

      <div className="guidelines__container reveal">
        <h2 className="guidelines__container-title">
          4. I will follow the following guidelines for my profile
        </h2>
        <ul>
          <li className="guidelines__list-item">
            Do not replace your name with an email address, website url, or
            keywords to attempt to appear higher in search results.
          </li>
          <ul>
            <li className="guidelines__list-item--spacing">Do: Dan Thrones</li>
            <li className="guidelines__list-item--spacing">
              Don’t: Dan Thornett &#8226; Logos &#8226; Branding &#8226; Graphic
              Design
            </li>
          </ul>
          <li className="guidelines__list-item">
            Do not include an email address or website url in the masthead
            description or image on your profile (including the use of special
            characters)
          </li>
          <li className="guidelines__list-item">
            Do not post known false information including onsite statistics,
            follower counts or other misleading information
          </li>
        </ul>
        <p className="guidelines__container-text--spacing">
          If you post inappropriate content, it may be removed by Megosu per our{" "}
          <Link to={"/terms"}>Terms of Service</Link>. Megosu has final say over
          whether content is appropriate.
        </p>
      </div>

      <div className="guidelines__container reveal">
        <h2 className="guidelines__container-title">
          5. I won’t spam other Megosu Members
        </h2>
        <p className="guidelines__container-text">
          Megosu provides many features that allow users to interact with
          one-another, including messages. Please don’t use these tools
          indiscriminately to spam or send unsolicited messages to other
          members. If you’re caught spamming other Megosu members, your account
          may be suspended or removed.
        </p>
        <p className="guidelines__container-text--spacing">
          What do we consider spam?
        </p>
        <ul>
          <li className="guidelines__list-item">
            Using likes and other disingenuous social gestures to draw attention
            to your account
          </li>
          <li className="guidelines__list-item">
            Misusing site features and functionality in a way that isn’t in line
            with their core purpose for personal gain
          </li>
        </ul>
      </div>

      <div className="guidelines__container reveal">
        <h2 className="guidelines__container-title">
          6. I will give due credit
        </h2>
        <p className="guidelines__container-text">
          If your work is inspired by other work on Megosu, make sure to give
          credit. You can do this by mentioning the Megosu member. Know that
          work that’s inspired by others may be interpreted as stolen.
        </p>
        <p className="guidelines__container-text--spacing">
          If you’re the owner of work that has been posted on Megosu without
          your consent, please report it by
          <a href="mailto:support@megosu.com"> contacting us</a>.
        </p>
        <p className="guidelines__container-text--spacing">
          Please link back to Megosu when posting Megosu content elsewhere.
        </p>
      </div>

      <div className="guidelines__container">
        <h2 className="guidelines__container-title">
          7. I will be constructive in my feedback
        </h2>
        <p className="guidelines__container-text">
          When providing criticism or feedback to other Megosu members—be
          helpful, not mean.
        </p>
      </div>

      <div className="guidelines__container reveal">
        <h2 className="guidelines__container-title">
          8. I will use messages to contact Megosu members about serious work
          inquires only
        </h2>
        <p className="guidelines__container-text">
          When sending a message to a designer, please follow these guidelines:
        </p>
        <ul>
          <li className="guidelines__list-item">
            Your message must be a work inquiry regarding a design job or
            project or any other opportunity.
          </li>
          <li className="guidelines__list-item">
            Please personalize the messages you send—don’t send boilerplate
            messages. They tend to feel impersonal and lack the detail necessary
            to be appealing. This means you should use their name, provide a
            brief description of the work or job for which you’re hiring and any
            details you find compelling about the designer’s work on Megosu.
          </li>
          <li className="guidelines__list-item">
            Please make sure the designer you’re contacting is a good fit for
            your job or project as indicated by the work, bio and skills on
            their profile. Many designers also declare the type work in which
            they’re interested on the message form—so make sure your message
            conforms with their stated preferences.
          </li>
          <li className="guidelines__list-item">
            Have a specific job or project in mind when contacting a designer.
            Don’t ask if someone is generally looking for new opportunities or
            if anyone they know is looking for new opportunities.
          </li>
        </ul>
        <p className="guidelines__container-text--spacing">
          These Guidelines were heavily inspired by the incredible team at
          Dribbble.
        </p>
      </div>

      <h2 className="guidelines__subtitle--spacing">
        Do you pledge to abide by these rules?
      </h2>

      <Link to={"/pricing"}>
        <Button
          customClassName="guidelines__button button--black"
          type="black"
          title="Join Megosu"
        ></Button>
      </Link>
    </div>
  );
};

export default GuidelinesPage;
