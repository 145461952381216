/** 
 * ? Functions to reveal elements on scroll
 * ? How to use 
    * * Add the classname reveal to an element
    * * Animate the reveal and active class in CSS
    * * Call scrollTrigger(".reveal") in component

    * IntersectionObserver accepts options as second argument
    * Example: add active class once bigger part of element is visible
    * scrollTrigger("reveal", {
    *   rootMargin: "-200px"
    * })
*/

// Intersection Observer is a JavaScript API which asynchronously observes changes in the intersection of an element
// This is more performant than watching for scroll events

function addObserver(el){
  // Check if `IntersectionObserver` is supported
  if (!("IntersectionObserver" in window)) {
    // Simple fallback
    // The animation/callback will be called immediately so
    // the scroll animation won't happen on unsupported browsers
    if (options.cb) {
      options.cb(el);
    } else {
      entry.target.classList.add("active");
    }
    // We don't need to execute the rest of the code
    return;
  }

  // Create a new IntersectionObserver instance
  const observer = new IntersectionObserver((entries, observer) => { // This takes a callback function that receives two arguments: the elements list and the observer instance.
    entries.forEach(entry => {
      // `entry.isIntersecting` will be true if the element is visible
      if (entry.isIntersecting || Math.round(entry.intersectionRatio) === 1) {
        entry.target.classList.add("active");
        // We are removing the observer from the element after adding the active class
        observer.unobserve(entry.target);
      }
    });
  });
  // Adding the observer to the element
  observer.observe(el);
}

function scrollTrigger(selector) {
  // Select elements from parameter selector
  let els = document.querySelectorAll(selector);

  // Convert nodeList to an array
  els = Array.from(els);

  els.forEach(el => {
    // attach the IntersectionObserver to the element
    addObserver(el);
  });
}

export default scrollTrigger;