import { AuthHeader } from "../util/AuthHeader";

export function saveComment(profileId, projectId, content) {
  const saveCommentUrl = process.env.REACT_APP_API_BASE_URL + "/projects/comment";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify({ "profile": {"profileId" : profileId}, "project" : {"projectId": projectId }, "content": content }),
  };

  return fetch(saveCommentUrl, options).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
}

export function getCommentsByProjectId(projectId, fromIndex, size) {
  const getCommentsByProjectIdUrl = process.env.REACT_APP_API_BASE_URL + "/projects/" + projectId + `/comments?page=${fromIndex}&size=${size}`;

  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getCommentsByProjectIdUrl, options)
    .then((data) => {
      return data;
    });
}

export function removeComment(id) {
  const removeFavoriteUrl = process.env.REACT_APP_API_BASE_URL + `/projects/comment/${id}/delete`;
  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
      "Content-Type": "application/json",
    },
  };

  return fetch(removeFavoriteUrl, options).then((response) => {
    if (response.ok) {
      return response.text();
    }
  });
}