import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CryptoJS from "crypto-js";

import { AiOutlineEye } from "@react-icons/all-files/ai/AiOutlineEye";
import { AiOutlineEyeInvisible } from "@react-icons/all-files/ai/AiOutlineEyeInvisible";

import Button from "../atoms/Button";
import Checkbox from "../atoms/Checkbox";

import { register as registration } from "../../services/UserService";
import { getProvidersUrls } from "../../util/OAuth2";

import { PROVIDER, PROVIDER_ICON } from "../../constants/auth";

import "../../styles/components/molecules/RegistrationForm.scss";

function Registration() {
  const [existingEmail, setExistingEmail] = useState("");
  const [invisible, setInvisible] = useState(true);
  const [checked, isChecked] = useState(false);
  const [checkedError, setCheckedError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const providerUrls = getProvidersUrls();

  const registerSchema = Yup.object().shape({
    email: Yup.string()
      .required("Required.")
      .email("Please fill in a valid email (example@megosu.com)."),
    password: Yup.string()
      .required("Required.")
      .min(8, "Should contain at least 8 characters.")
      .matches(/\d+/, "Password should contain at least 1 number"),
    checkbox: Yup.bool().oneOf([true], "Checking the box is required."),
  });

  const { control, register, handleSubmit, formState, getValues } = useForm({
    defaultValues: { email: "", password: "", checkbox: false},
    resolver: yupResolver(registerSchema)
  });

  const handleCheckbox = () => {
    const checkbox = document.getElementsByClassName("registration__checkbox");

    isChecked(!checked);
    checkbox.checked = !checked;
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    const email = data.email;
    const password = CryptoJS.AES.encrypt(JSON.stringify(data.password), CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_SK), {iv: CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_IV)}).toString();

    if (checked) {
      const user = {
        email: email,
        password: password,
        toggle: false,
      };

      // Temporarily store credentials for auto-login process
      sessionStorage.setItem(
        "credentials",
        JSON.stringify({ email, password })
      );

      registration(user)
        .then((response) => {
          if (response.ok) {
            sessionStorage.setItem("previousURL", history.location.pathname);
            history.push({
              pathname: "/set-up",
              state: { email: email },
            });
            window.location.reload();
          } else if (response.status === 400) {
            console.log("Request failed! http error 400");
            setExistingEmail("Email already exists!");
            setInvisible(false);
          }
        })
        .catch((error) => {
          console.log("Request failed! " + error);
        });
    } else {
      console.log("Error. Privacy terms has to be checked!");
    }
  };

  const handleOAuth2Click = (providerUrl, event) => {
    event.preventDefault();
    window.location.replace(`${providerUrl}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="registration-form">
        <div className="registration-form__input-container">
          <div className="registration-form__error-container">
            <label className="registration-form__label">Email</label>
            {formState.errors && formState.errors?.email && (
              <div className="form-error">{formState.errors.email.message}</div>
            )}
            <div className={invisible ? "invisible" : "form-error"}>
              {existingEmail}
            </div>
          </div>
          <input
            className={
              formState.errors && formState.errors?.email
                ? "input-error login-form__input"
                : "login-form__input"
            }
            name="email"
            type="text"
            placeholder="example@email.com"
            {...register("email")}
          />
        </div>

        <div className="registration-form__input-container">
          <div className="registration-form__error-container">
            <label className="registration-form__label">Password</label>
            {formState.errors && formState.errors?.password && (
              <div className="form-error">{formState.errors.password.message}</div>
            )}
          </div>

          <div className="registration-form__input-field">
            <input
              className={
                formState.errors && formState.errors?.password
                  ? "input-error login-form-input"
                  : "login-form__input"
              }
              name="password"
              placeholder="*******"
              type={showPassword ? "text" : "password"}
              {...register("password")}
            />
            <AiOutlineEye 
              className={`registration-form__input-field-icon ${showPassword && "registration-form__input-field-icon--hide"}`} 
              onClick={() => { setShowPassword(!showPassword); }}
            />
            <AiOutlineEyeInvisible 
              className={`registration-form__input-field-icon ${!showPassword && "registration-form__input-field-icon--hide"}`} 
              onClick={() => { setShowPassword(!showPassword); }}
            />
          </div>
        </div>

        <div className="registration-form__terms">
          {formState.errors && formState.errors?.checkbox && (
            <div className="form-error form-error--flicker">Checking the box is required.</div>
          )}
          {checkedError && (
            <div className="form-error form-error--flicker">Checking the box is required.</div>
          )}
          <div className="registration-form__terms-container">
            <Controller 
              name="checkbox"
              control={control}
              render={({ field }) => { return (
                <Checkbox 
                  {...field} 
                  name="checkbox"
                  fieldName={"registration-form__checkbox"} 
                  onHandle={(e) => handleCheckbox(e) }
                />
              );
              }}
            />
            <label className="registration-form__terms-label">
              <p className="registration-form__terms-text">
                {" "}
                      Agree with our
                <a
                  className="registration-form__policy-link"
                  href="/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                        Terms
                </a>
                      and
                <a
                  className="registration-form__policy-link"
                  href="/policy"
                  target="_blank"
                  rel="noreferrer"
                >
                        Privacy Policy.
                </a>
              </p>
            </label>
          </div>
        </div>

        <div className="registration-form__button-container">
          <Button
            type="pink"
            customClassName="button--pink-form"
            title="Sign Up"
            onClick={() => onSubmit}
          />
        </div>
        <h3 className="registration-form__social-login"><span>Or continue with</span></h3>
        <div className="registration-form__social-grid-container">
          {Object.keys(PROVIDER).map((provider, i) => (
            <a key={i} onClick={(event) => { 
              if (getValues("checkbox")) {
                handleOAuth2Click(`${providerUrls[provider]}`, event); 
              } else {
                setCheckedError(true);
              }}}>
              <img src={PROVIDER_ICON[provider]}></img>
            </a>
          ))}
        </div>
      </div>
    </form>
  );
}

export default Registration;
