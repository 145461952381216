import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { getLinkedInInformation, loginOAuth2Social } from "../services/LoginService";
import { getValidatedWithFacebookUser, getValidatedWithGoogleUser, queryToObject } from "../util/OAuth2";

import { PROVIDER } from "../constants/auth";

import Spinner from "../components/atoms/Spinner";

const OAuthLoadingPage = ({ processOAuthParams }) => {
  const history = useHistory();

  useEffect(() => {
    const queryString = window.location.search;
    
    if (queryString) {
      const { code, provider: lowProvider } = queryToObject(queryString);
      const provider = lowProvider && PROVIDER[lowProvider.toUpperCase()];

      if (provider) {
        try {
          if (provider === "GOOGLE") {
            getValidatedWithGoogleUser(code, process.env.REACT_APP_OAUTH2_URL + "/oauth?provider=google").then((res) => {
              loginOAuth2Social(res, "google", res.email).then((res) => {
                setTimeout(() => {
                  // wait for tokens to be stored before pushing to new location
                  if (res.status !== 303 && res.status !== 412) {
                    history.push("/");
                    window.location.reload();
                  }
                }, 500);
              });
            });
          } else if (provider === "FACEBOOK") {
            getValidatedWithFacebookUser(code, process.env.REACT_APP_OAUTH2_URL + "/oauth?provider=facebook").then((res) => {
              loginOAuth2Social(res, "facebook", res.email).then((res) => {
                setTimeout(() => {
                  if (res.status !== 303 && res.status !== 412) {
                    history.push("/");
                    window.location.reload();
                  }
                }, 500);
              });
            });
          } else if (provider === "LINKEDIN") {
            // authenticate LinkedIn User through Backend
            const body = new URLSearchParams({
              grant_type: "authorization_code",
              code: code,
              redirect_uri: process.env.REACT_APP_OAUTH2_URL + "/oauth?provider=linkedin",
              client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
              client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET
            });
          
            getLinkedInInformation(body).then(async (res) => {                
              loginOAuth2Social(res, "linkedin", res.email).then((res) => {
                setTimeout(() => {
                  if (res.status !== 303 && res.status !== 412) {
                    history.push("/");
                    window.location.reload();
                  }
                }, 1000);
              });
            });
          } else {
            history.push("/");
          }
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      history.push("/");
    }
  
  }, [processOAuthParams]);
  
  return (
    <>
      <br></br>
      <Spinner type="megosu"/>
      <br></br>
      <h3 style={{"text-align": "center"}}>Loading your profile ...</h3>
    </>
  );
};

export default OAuthLoadingPage;