import React, { useEffect, useState } from "react";
import $ from "jquery";
import { FiPaperclip } from "@react-icons/all-files/fi/FiPaperclip";
import { compress } from "jpegasus";

import Button from "../atoms/Button";
import ImageUploader from "../atoms/ImageUploader";
import Input from "../atoms/Input";
import CheckedDropdown from "./CheckedDropdown";

import { uploadSiteFile } from "../../services/ImageService";
import { deleteSiteFile, getToolCategories, saveSiteFile } from "../../services/SiteService";

import "../../styles/components/molecules/AdminFileForm.scss";

const initialValues = {
  title: "",
  availability: "",
  webUrl: "",
  categories: [],
};

const labels = ["Free", "Pro", "Pitch"];

function AdminFileForm(
  { 
    currentFile
  }) {

  const [values, setValues] = useState(initialValues);
  const [previewFile, setPreviewFile] = useState([]);
  const [invalidImage, setInvalidimage] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [labelOptions, setLabelOptions] = useState([]);
  const [isFileTool, setIsFileTool] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryObjects, setCategoryObjects] = useState([]);
  const [objectCategories, setObjectCategories] = useState([]);

  useEffect(() => {    
    if (currentFile) {
      setValues(currentFile);
      setFileName(currentFile.fileUrl);
    }

    getToolCategories().then((res) => {
      setCategoryOptions(res.map((item => item.name)));
      setCategoryObjects(res);
    });

    setLabelOptions(labels);
  }, []);

  function handleOnChange(e) {
    const { name, value } = e.target;
    
    switch (name) {
      default:
        setValues({
          ...values,
          [name]: value,
        });
        break;
    }
  }

  // Convert data URL to file
  function dataURLtoFile(dataurl, filename) {
    // Using a regex to extract the data values into an array so we can convert it into a file
    // fields extract include datat type, params etc.
    const arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]);
    let n = bstr.length; 
    const u8arr = new Uint8Array(n);
    
    // Adding the UTF-16 code units of the characters to our 8 bit array
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    // Create file from array
    return new File([u8arr], filename, {type:mime});
  }

  const handleFileInput = (event) => {
    const inputFile = event.target.files[0];

    if (inputFile) {
      event.target.isRemove = false;
      const reader = new FileReader();

      reader.onload = function (e) {  
        const uploadFile = e.target.result;
        const allowedMimes = ["data:application/pdf"];
        const checkType = uploadFile.split("data/").pop().split(";")[0];
        const maxMb = 100;
        const mb = inputFile.size / 1024 / 1024;

        if (!uploadFile) {
          setInvalidimage("Please select a file");
        } else if (!allowedMimes.includes(checkType)) {
          setInvalidimage("Please select a file of type: .pdf");
        } else if (mb > maxMb) {
          setInvalidimage(`File size (${mb.toFixed(2)}MB) is too big, please select a file with a maximum file size of ${maxMb}MB.`);
        }

        setPreviewFile({name: inputFile.name, URI: uploadFile, type: "file"});
      };

      setFileName(inputFile.name);
      reader.readAsDataURL(inputFile);
    }
  };

  async function uploadImagesAndFile(fileId) {
    const imageFormInput = $(".image-uploader__input");
    const formData = new FormData();

    if (imageFormInput[0].files != null && imageFormInput[0].files.length >= 1 &&
        imageFormInput[1].files != null && imageFormInput[1].files.length >= 1) { 

      const compressedVisual1 = await compress(imageFormInput[0].files[0], {
        maxHeight: 1200,
        maxWidth: 1200,
        quality: 0.90,
      });
    
      const visual1File = new File([compressedVisual1], "cover-1");
      formData.append("cover-1", visual1File);

      const compressedVisual2 = await compress(imageFormInput[1].files[0], {
        maxHeight: 1200,
        maxWidth: 1200,
        quality: 0.90,
      });
    
      const visual2File = new File([compressedVisual2], "cover-2");
      formData.append("cover-2", visual2File);

      if (previewFile !== undefined && isFileTool) {
        const file = dataURLtoFile(previewFile.URI, "filename." + previewFile.URI.substring(previewFile.URI.indexOf("/") + 1, previewFile.URI.indexOf(";")));
  
        const actualFile = new File([file], previewFile.name);
        formData.append("file", actualFile);
      }
      
      uploadSiteFile(fileId, formData);
    } else {
      alert("No new images and files found. If you want to update any images or files, please upload all visuals and the file again. "  + 
      "Proceeding with updating file information...");
    }
  }

  function saveFile() {
    values.categories = objectCategories.map((elem) => categoryObjects.filter((category) => category.name === elem)[0]);
    saveSiteFile(values).then((res) => {
      uploadImagesAndFile(res.id).then((res) => {
        alert("[OK] - File saved");
      });
    });
  }

  function deleteFile() {
    if (currentFile == null) return;
    
    deleteSiteFile(currentFile.id).then((res) => {
      alert("[OK] - File deleted");
      window.location.reload();
    });
  }

  return (
    <div className="admin-file-form">
      <div className="admin-file-form__container">
        <h2>Tool</h2>  
        <div className="admin-file-form__uploaders-container">
          <div className="admin-file-form__image-uploader">
            <h6 className="admin-file-form__image-title">Cover Visual 1</h6>
            <ImageUploader
              key={0}
              index={0}
              currentImage={currentFile !== null ? currentFile.firstCoverUrl : undefined}             
              removable={true}
              multiple={false}
              simplified={true}
            />
          </div>

          <div className="admin-file-form__image-uploader">
            <h6 className="admin-file-form__image-title">Cover Visual 2</h6>
            <ImageUploader
              key={1}
              index={1}
              currentImage={currentFile !== null ? currentFile.secondCoverUrl : undefined}             
              removable={true}
              multiple={false}
              simplified={true}
            />
          </div>
        </div>

        {invalidImage && (
          <div className="admin-file-form__error">{invalidImage}</div>
        )}

        <div className="admin-file-form__input-container">
          <label className="admin-file-form__input-label">Type</label>
          <div className="admin-file-form__input-container-buttons">
            <Button onClick={() => { setIsFileTool(true); }} title="File" type={isFileTool ? "black" : "white"}/>
            <Button onClick={() => { setIsFileTool(false); }} title="URL" type={!isFileTool ? "black" : "white"}/>
          </div>
        </div>

        <div className="admin-file-form__input-container">
          {isFileTool ? (
            <>
              <label className="admin-file-form__input-label">File*</label>
              <input 
                id="attachmentInput"
                type="file" 
                name="upload" 
                required
                className="admin-file-form__input"
                accept=".pdf"
                onInput={(e) => {  handleFileInput(e); }}
              />
              {fileName ? (
                <label className="admin-file-form__input-text" htmlFor="attachmentInput"><FiPaperclip/>{fileName}</label>
              ) : (
                <label className="admin-file-form__input-text" htmlFor="attachmentInput"><FiPaperclip/> Choose File</label>
              )}
            </>
          ) : (
            <Input
              label="URL"
              type="url"
              id="file-weburl"
              required={true}
              placeholder=""
              name="webUrl"
              setValue={(values.webUrl)}
              onChange={(e) => handleOnChange(e)}
            />
          )}
        </div>

        <div className="admin-file-form__input-container">
          <Input
            label="Title"
            id="file-name"
            required={true}
            placeholder=""
            name="title"
            setValue={(values.title)}
            onChange={(e) => handleOnChange(e)}
          />
        </div>

        <div className="admin-file-form__input-container">
          <label className="admin-file-form__input-label">Availability*</label>
          <div>
            <CheckedDropdown
              dropdownList={labelOptions}
              singleCheck={true}
              name="availability"
              onChange={(e) => handleOnChange(e)}
              searchable={false}
              checkedValue={
                values.availability.toString()
              }
            />
          </div>
        </div>

        <div className="admin-file-form__input-container">
          <label className="admin-file-form__input-label">Categories*</label>
          <div>
            <CheckedDropdown
              dropdownList={categoryOptions !== null && categoryOptions}
              singleCheck={false}
              name="categories"
              onChange={(e) => handleOnChange(e)}
              searchable={false}
              checkedValue={values.categories}
              setGoals={setObjectCategories}
            />
          </div>
        </div>

        <div className="admin-file-form__button-container">
          <Button onClick={() => { deleteFile(); }} title="Delete" type="black"/>
          <Button onClick={() => { saveFile(); }} title="Save" type="pink"/>
        </div>
      </div>
    </div>
  );
}

export default AdminFileForm;
