import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import PopUp from "../components/molecules/PopUp";
import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";

import { resetPassword } from "../services/UserService";
import { logout } from "../services/LoginService";

import "../styles/pages/ResetPasswordPage.scss";

const ResetPasswordPage = () => {
  const [passwordResetConfirm, setPasswordResetConfirm] = useState(false);

  const passwordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Field can't be empty.")
      .min(8, "Should contain at least 8 characters.")
      .matches(/\d+/, "Password should contain at least 1 number"),
    confirmPassword: Yup.string()
      .required("Field can't be empty.")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const { register, handleSubmit, formState } = useForm({
    defaultValues: { password: "", confirmPassword: "" },
    resolver: yupResolver(passwordSchema)
  });

  const history = useHistory();
  const token = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      history.push("/");
    }
  }, []);

  const handleResetPassword = (data, event) => {
    event.preventDefault();
    const newPassword = data.password;

    resetPassword(token, newPassword)
      .then((response) => {
        if (response.ok) {
          logout();
          history.push("/login").then(() => {
            setPasswordResetConfirm(true);
          });
          //TODO create popup thats shows after relocating to login + Send verify email again
          alert("password has been reset");
        }
      })
      .catch(() => console.log("Can not reset password"));
  };

  return (
    <div className="reset-password__container">
      <DocumentHead
        title="Reset Password | Megosu"
        metaDescription="Reset your password here."
      />
      <form className="reset-password" onSubmit={handleSubmit(handleResetPassword)}>
        <div className="reset-password__form-container">
          <h4 className="reset-password__title">Creating your new password</h4>
          <p>Fill in your new password and confirm.</p>
          <div className="reset-password__input-container">
            <div className="reset-password__error-container">
              <label className="reset-password__label">Password</label>
              {formState.errors && formState.errors?.password && (
                <span>{formState.errors?.password.message}</span>
              )}
            </div>
            <input
              className={
                formState.errors && formState.errors?.password
                  ? "reset-password__input--error"
                  : "reset-password__input"
              }
              type="password"
              placeholder="********"
              {...register("password")}
            />
          </div>
          <div className="reset-password__input-container">
            <div className="reset-password__error-container">
              <label className="reset-password__label">Confirm Password</label>
              {formState.errors && formState.errors?.confirmPassword && (
                <span>{formState.errors?.confirmPassword.message}</span>
              )}
            </div>
            <input
              className={
                formState.errors && formState.errors?.confirmPassword
                  ? "reset-password__input--error"
                  : "reset-password__input"
              }
              type="password"
              placeholder="********"
              {...register("confirmPassword")}
            />
          </div>
          <div className="reset-password__button-container">
            <Button type="pink" customClassName="button--pink-form" title="Send" onClick={() => { handleResetPassword; }} />
          </div>
        </div>
      </form>
      <PopUp visibility={passwordResetConfirm} popupTitle="Confirmed." />
    </div>
  );
};

export default ResetPasswordPage;
