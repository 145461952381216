import { AuthHeader, AuthHeaderRefresh } from "./AuthHeader";

let refreshTokenPromise;
let credentialsPromiseResolve, credentialsPromiseReject;

const credentialsPromise = new Promise(function (resolve, reject) {
  credentialsPromiseResolve = resolve;
  credentialsPromiseReject = reject;
});

export function getRefreshToken() {
  const refreshTokenUrl = process.env.REACT_APP_API_BASE_URL + "/token";
  const options = {
    method: "POST",
    headers: {
      Authorization: AuthHeaderRefresh(),
    },
  };

  return originalFetch(refreshTokenUrl, options);
}

//Override original fetch methode
const originalFetch = fetch;
fetch = function () {
  const self = this;
  const args = arguments;
  return originalFetch.apply(self, args).then(async function (data) {
    // request for new access token with original fetch if status is 401
    if (data.status === 401) {
      refreshTokenPromise = getRefreshToken().then((token) => {
        return token;
      });

      return refreshTokenPromise.then(async (res) => {
        //If request for refresh token is denied return user to login page
        if (
          (res.status === 401 || res.status === 403) &&
          window.location.pathname !== "/login" && window.location.pathname !== "/set-up"
        ) {
          localStorage.removeItem("currentUser");
          localStorage.removeItem("refreshToken");
          window.location.assign("/login");
          return {};
        }
        //Update user credentials in local storage
        if (!res.bodyUsed) {
          const tokens = JSON.parse(await res.text());
          localStorage.setItem("currentUser", tokens["access_token"]);
          localStorage.setItem("refreshToken", tokens["refresh_token"]);
          credentialsPromiseResolve();
        }

        // Wait for new credentials to be set before retrying requests.
        await credentialsPromise;

        //Modify header with updated access key
        args[1]["headers"]["Authorization"] = AuthHeader();

        //Redo request with updated credentials
        return originalFetch(args[0], args[1]);
      });
    } else {
      return data;
    }
  });
};
