import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { SiTiktok } from "@react-icons/all-files/si/SiTiktok";

import megosu from "../../assets/images/MegosuOutlinetext.svg";

import PopUp from "../molecules/PopUp";

import { getCurrentUser } from "../../services/AuthService";

import "../../styles/components/organisms/Footer.scss";

const Footer = ({ user }) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState("");
  const [role, setRole] = useState("");
  const [id, setID] = useState("");
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 

  const sendEmail = () => {
    window.location = "mailto:partners@megosu.com";
  };

  useEffect(() => {
    const email = getCurrentUser();
    setCurrentUser(email);
  }, []);

  useEffect(() => {
    if (user) {
      setRole(user.role.name);
      setID(user.uuid);
    }
  }, [user]);

  const homePageScrollHandler = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      sessionStorage.removeItem("scrollPosition");
    }
  };

  const scrollToTopHandler = () => {
    if (currentUser) {
      window.scrollTo(0, 0);
      history.push("/profile/" + id);
    } else {
      setSignUpPopUpVisible(true);
    }
  };

  const navigateToSaves = () => {
    if (currentUser) {
      window.scrollTo(0, 0);
      history.push({pathname: "/profile/" + id, state: { saves: true }});
    } else {
      setSignUpPopUpVisible(true);
    }
  };

  const navigateToChat = () => {
    if (currentUser) {
      window.scrollTo(0, 0);
      history.push("/chat");
    } else {
      setSignUpPopUpVisible(true);
    }
  };

  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__list-container--logo">
          <img src={megosu} alt="megosu logo"></img>
          <p>
            Connecting most refreshing independent product designers worldwide.
          </p>
          <ul className="footer__list">
            <li className="footer__list-item">
              <a
                href="https://www.instagram.com/megosu/"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to the Instagram of Megosu"
              >
                <FaInstagram />
              </a>
            </li>
            <li className="footer__list-item">
              <a
                href="https://www.linkedin.com/company/megosu"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to the LinkedIn of Megosu"
              >
                <FaLinkedin />
              </a>
            </li>
            <li className="footer__list-item">
              <a
                href="https://www.facebook.com/studentdesign.megosu"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to the Facebook of Megosu"
              >
                <FaFacebookSquare />
              </a>
            </li>
            <li className="footer__list-item">
              <a
                href="https://www.youtube.com/channel/UC4Ux-It5gws5GYMd3Y_R4gg"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to the YouTube Channel of Megosu"
              >
                <FaYoutube />
              </a>
            </li>
            <li className="footer__list-item">
              <a
                href="https://www.tiktok.com/@megosuu"
                target="_blank"
                rel="noreferrer"
                aria-label="Go to the TikTok of Megosu"
              >
                <SiTiktok />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__row-container">
          <div className="footer__list-container">
            <ul className="footer__list">
              <li className="footer__list-item">
                <p>Menu</p>
              </li>
              <li className="footer__list-item">
                <Link to="/" onClick={homePageScrollHandler}>Explore</Link>
              </li>
              <li className="footer__list-item">
                <Link to="/pitch">Pitch Designs</Link>
              </li>
              <li className="footer__list-item">
                {role ? (
                  <a
                    href="https://community.megosu.com/users/sign_in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Community
                  </a>
                ) : (
                  <span onClick={() => { setSignUpPopUpVisible(true); }}>
                    Community
                  </span> 
                )}
              </li>
              <li className="footer__list-item">
                <Link to="/pricing">Pricing</Link>
              </li>
            </ul>
          </div>
          <div className="footer__list-container">
            <ul className="footer__list">
              <li className="footer__list-item">
                <p>Members</p>
              </li>
              <li className="footer__list-item">
                <Link to="#" onClick={scrollToTopHandler}>Profile</Link>
              </li>
              <li className="footer__list-item">
                <Link to="#" onClick={navigateToSaves}>Saves</Link>
              </li>
              <li className="footer__list-item">
                <Link to="#" onClick={scrollToTopHandler}>Uploads</Link>
              </li>
              <li className="footer__list-item">
                <Link to="#" onClick={navigateToChat}>Chats</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__row-container">
          <div className="footer__list-container">
            <ul className="footer__list">
              <li className="footer__list-item">
                <p>Collaboration</p>
              </li>
              <li className="footer__list-item">
                <Link to="/testimonials">Testimonials</Link>
              </li>
              <li className="footer__list-item">
                <Link to="/success-stories/moooi">Success Stories</Link>
              </li>
              <li className="footer__list-item">
                <Link to="/partners">Partners</Link>
              </li>
              <li className="footer__list-item">
                <Link to="#" onClick={() => sendEmail()}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer__list-container">
            <ul className="footer__list">
              <li className="footer__list-item">
                <p>Company</p>
              </li>
              <li className="footer__list-item">
                <Link to="/about">About</Link>
              </li>
              <li className="footer__list-item">
                <Link to="/faq">FAQ</Link>
              </li>
              <li className="footer__list-item">
                <Link to="/guidelines">Guidelines</Link>
              </li>
              <li className="footer__list-item">
                <Link to="/terms">Terms &amp;</Link>
                <Link to="/policy"> Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__bottom-bar">
        <p>&#169; 2022 Powered by Megosu. All Rights Reserved.</p>
        <p>Made with love for design from Amsterdam</p>
        <p>16.000.000 monthly views on our socials</p>
      </div>

      <PopUp 
        visibility={signUpPopUpVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => setSignUpPopUpVisible(false)}
        popupType="sign-up" 
      />

      <PopUp 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />
    </div>
  );
};

export default Footer;
