import "../../styles/components/molecules/SubscriptionTestimonials.scss";

import review1 from "../../assets/images/subscription/review1.jpg";
import review2 from "../../assets/images/subscription/review2.jpg";
import review3 from "../../assets/images/subscription/review3.jpg";

const SubscriptionTestimonials = ({ reveal }) => {
  return (
    <div className={`subscription-testimonials ${reveal && "reveal"}`}>
      <h3 className="subscription-testimonials__review-title">What others are thinking</h3>
      <div>
        <div className="subscription-testimonials__review-card">
          <h4 className="subscription-testimonials__review-text">
            “Design-brand &apos;Moooi&apos; discovered my work as soon as it was published via
            Megosu. Now it&apos;s part of their collection.”
          </h4>
          <div>
            <img className="subscription-testimonials__review-image" src={review1} />
            <div className="subscription-testimonials__review-job-container">
              <h5 className="subscription-testimonials__review-name">Lukas Bazle</h5>
              <p className="subscription-testimonials__review-job-title">In-House Designer at IKEA</p>
            </div>
          </div>
        </div>

        <div className="subscription-testimonials__review-card">
          <h4 className="subscription-testimonials__review-text">
            “Via Megosu we managed to curate 300+ designs and eventually launched over 10 projects
            into the market.”
          </h4>
          <div>
            <img className="subscription-testimonials__review-image" src={review2}></img>
            <div className="subscription-testimonials__review-job-container">
              <h5 className="subscription-testimonials__review-name">Ruth Wassermann</h5>
              <p className="subscription-testimonials__review-job-title">Design Director - ex MADE.COM</p>
            </div>
          </div>
        </div>

        <div className="subscription-testimonials__review-card">
          <h4 className="subscription-testimonials__review-text">
            “Great talent is hard to find, so we&apos;re extremely happy with the two talented
            product designers we hired through Megosu.”
          </h4>
          <div>
            <img className="subscription-testimonials__review-image" src={review3}></img>
            <div className="subscription-testimonials__review-job-container">
              <h5 className="subscription-testimonials__review-name">Bing van der Lande </h5>
              <p className="subscription-testimonials__review-job-title">Founder Object Embassy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTestimonials;
