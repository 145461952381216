import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import tipsAndContractsPdf from "../assets/files/Tips & Contracts - Megosu.pdf";

const PdfRedirectPage = () => { 
  const history = useHistory();

  useEffect(() => {
    const pdfName = new URLSearchParams(window.location.search).get("name");
    
    switch (pdfName) {
      case "tips and contracts" :
        location.replace(tipsAndContractsPdf);
        break;
      default :
        history.push("/");
    }
  }, []);

  return (
    <div></div>
  );  
};

export default PdfRedirectPage;
