import React, { useEffect, useState } from "react";
import { TweenMax } from "gsap/TweenMax";
import $ from "jquery";

import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";

import Button from "../atoms/Button";

import { getHeadersByType } from "../../services/HeaderService";
import { getCurrentUser } from "../../services/AuthService";

import "../../styles/components/molecules/DynamicSlideshow.scss";

function DynamicSlideshow({
  isMobile
}) {
  const [headers, setHeaders] = useState([]);
  const [showHeaders, setShowHeaders] = useState(false);
  const [userIsLoggedIn] = useState(getCurrentUser() ? true : false); 

  useEffect(() => {
    if (sessionStorage.getItem("showHeader") !== undefined) {
      if (sessionStorage.getItem("showHeader") === "false") {
        setShowHeaders(false);
        return;
      }
    }

    // load the dynamic headers
    getHeadersByType(userIsLoggedIn).then((res) => {
      setHeaders(res);
      setShowHeaders(true);
      window.scrollTo(0, 0);
    });
  }, []);

  var slideshowDuration = 8000;
  var slideshow = $(".slideshow__main-content .slideshow");
  
  function slideshowSwitch(slideshow, index, auto) {
    if (slideshow.data("wait")) return;
  
    // find our elements
    var slides = slideshow.find(".slide");
    var pages = slideshow.find(".slideshow__pagination");
    var activeSlide = slides.filter(".is-active");
    var activeSlideImage = activeSlide.find(".slide__image-container");
    var newSlide = slides.eq(index);
    var newSlideImage = newSlide.find(".slide__image-container");
    var newSlideContent = newSlide.find(".slide__content");
    var newSlideElements = newSlide.find(".slide__caption > *");

    if (newSlide.is(activeSlide)) return;
  
    newSlide.addClass("is-new");
    var timeout = slideshow.data("timeout");
    clearTimeout(timeout);

    slideshow.data("wait", true);
    var transition = slideshow.attr("data-transition");

    if(transition == "fade"){
      newSlide.css({
        display: "block",
        zIndex: 2
      });

      newSlideImage.css({
        opacity: 0
      });
  
      TweenMax.to(newSlideImage, 1, {
        alpha: 1,
        onComplete: function() {
          // make the new slide the active one
          newSlide.addClass("is-active").removeClass("is-new");
          activeSlide.removeClass("is-active");
          newSlide.css({display: "", zIndex: ""});
          newSlideImage.css({opacity: ""});

          slideshow.find(".slideshow__pagination").trigger("check");
          slideshow.data("wait", false);

          if (auto) {
            timeout = setTimeout(function() {
              slideshowNext(slideshow, false, true);
            }, slideshowDuration);
            slideshow.data("timeout", timeout);
          }
        }
      });
    } else {
      let newSlideRight;
      let newSlideLeft;
      let newSlideImageRight;
      let newSlideImageLeft;
      let newSlideImageToRight;
      let newSlideImageToLeft;
      let newSlideContentLeft;
      let newSlideContentRight;
      let activeSlideImageLeft;

      if (newSlide.index() > activeSlide.index()) {
        newSlideRight = 0;
        newSlideLeft = "auto";
        newSlideImageRight =- slideshow.width() / 8;
        newSlideImageLeft = "auto";
        newSlideImageToRight = 0;
        newSlideImageToLeft = "auto";
        newSlideContentLeft = "auto";
        newSlideContentRight = 0;
        activeSlideImageLeft =- slideshow.width() / 4;
      } else {
        newSlideRight = "";
        newSlideLeft = 0;
        newSlideImageRight = "auto";
        newSlideImageLeft =- slideshow.width() / 8;
        newSlideImageToRight = "";
        newSlideImageToLeft = 0;
        newSlideContentLeft = 0;
        newSlideContentRight = "auto";
        activeSlideImageLeft = slideshow.width() / 4;
      }
  
      // update the css of the new visisble elements
      newSlide.css({
        display: "block",
        width: 0,
        right: newSlideRight,
        left: newSlideLeft,
        zIndex: 2
      });
  
      newSlideImage.css({
        width: slideshow.width(),
        right: newSlideImageRight,
        left: newSlideImageLeft
      });
  
      newSlideContent.css({
        width: slideshow.width(),
        left: newSlideContentLeft,
        right: newSlideContentRight,
        top: 0
      });
  
      activeSlideImage.css({
        left: 0
      });
  
      // move the old slide out of screen
      TweenMax.set(newSlideElements, {y: 20, force3D: true});
      TweenMax.to(activeSlideImage, 1, {
        left: activeSlideImageLeft,
        ease: Power3.easeInOut
      });
  
      // move new slide into the screen
      TweenMax.to(newSlide, 1, {
        width:slideshow.width(),
        ease:Power3.easeInOut
      });
  
      TweenMax.to(newSlideImage, 1, {
        right: newSlideImageToRight,
        left: newSlideImageToLeft,
        ease: Power3.easeInOut
      });
  
      // move elements of new slide also into the screen
      TweenMax.staggerFromTo(newSlideElements, 0.8, {alpha: 0, y: 0, x: 100}, {alpha: 1, y:0, x: 0, ease: Power3.easeOut, force3D: true, delay: 0.4}, 0.1, function() {
        newSlide.addClass("is-active").removeClass("is-new");
        activeSlide.removeClass("is-active");

        newSlide.css({
          display: "",
          width: "",
          left: "",
          zIndex: ""
        });
  
        newSlideImage.css({
          width: "",
          right: "",
          left: ""
        });
  
        newSlideContent.css({
          width: "",
          left: ""
        });
  
        newSlideElements.css({
          opacity: "",
          transform: ""
        });
  
        activeSlideImage.css({
          left: ""
        });
  
        // trigger the pagination to update
        slideshow.find(".slideshow__pagination").trigger("check");
        slideshow.data("wait", false);

        if (auto) {
          timeout = setTimeout(function() {
            slideshowNext(slideshow, false, true);
          }, slideshowDuration);
          
          slideshow.data("timeout", timeout);
        }
      });
    }
  }
  
  function slideshowNext(slideshow, previous, auto){
    var slides = slideshow.find(".slide");
    var activeSlide = slides.filter(".is-active");

    var newSlide = null;
    if (previous) {
      newSlide = activeSlide.prev(".slide");

      if (newSlide.length == 0) {
        // there is no new previous slide, our last is the next again
        newSlide = slides.last();
      }
    } else {
      newSlide = activeSlide.next(".slide");

      if (newSlide.length == 0) {
        // there is no new slide, our next is the first again
        newSlide = slides.filter(".slide").first();
      }
    }
  
    slideshowSwitch(slideshow, newSlide.index(), auto);
  }
  
  function homeSlideshowParallax() {
    var scrollTop = $(window).scrollTop();

    if(scrollTop > windowHeight) return;

    var inner = slideshow.find(".slideshow__inner");
    var newHeight = windowHeight - (scrollTop / 2);
    var newTop = scrollTop * 0.8;
  
    inner.css({ transform:"translateY(" + newTop + "px )", height: newHeight});
  }
  
  $(function() {
    $(".slide").addClass("is-loaded");
  
    $(".slideshow .arrows .arrow").on("click", function() {
      // get the next slide
      slideshowNext($(this).closest(".slideshow"), $(this).hasClass("prev"));
    });
  
    $(".slideshow .slideshow__pagination .slideshow__pagination-item").on("click", function() {
      // switch the slide
      slideshowSwitch($(this).closest(".slideshow"), $(this).index());
    });
  
    $(".slideshow .slideshow__pagination").on("check", function() {
      // keep pagination up to date with current slide
      var slideshow = $(this).closest(".slideshow");
      var pages = $(this).find(".slideshow__pagination-item");
      var index = slideshow.find(".slides .is-active").index();

      pages.removeClass("is-active");
      pages.eq(index).addClass("is-active");
    });
  
    var timeout = setTimeout(function() {
      slideshowNext(slideshow, false, true);
    }, slideshowDuration);
  
    slideshow.data("timeout", timeout);
  });
  
  if ($(".slideshow__main-content .slideshow").length > 1) {
    $(window).on("scroll", homeSlideshowParallax);
  }

  const hideHeader = () => {
    setShowHeaders(false);
    sessionStorage.setItem("showHeader", false);
  };

  return (
    <>
      {showHeaders && (
        <div className="slideshow__main-content">
          <section className="slideshow">
            <div className="slideshow__inner">
              <div className="slides">
                {headers && (
                  <>
                    {headers.map((elem, i) => (
                      <div key={i} className={`slide ${i == 0 && "is-active"}`}>
                        <div className="slide__content">
                          <div className="slide__caption">
                            <h1 className="slide__title">{elem.title}</h1>
                            <div className="slide__text">
                              <p>{elem.subtitle}</p>
                            </div>
                            <Button type="black" className="form__submit" customClassName="slide__banner-btn" onClick={() => { window.location.replace(elem.buttonUrl); }} title={elem.buttonText}/>
                            <VscChromeClose
                              className="slide__close-icon"
                              onClick={() => { hideHeader(); }}
                            />
                          </div>
                        </div>
                        <div className="slide__image-container">
                          <img src={isMobile ? process.env.REACT_APP_GCS_IMAGE_URL + elem.mobileImageUrl : process.env.REACT_APP_GCS_IMAGE_URL + elem.desktopImageUrl} alt="header image" className="slide__image" />
                        </div>
                      </div>
                    ))}
                  </>
                )}
  
              </div>
              {!isMobile && (
                <>
                  <div className="slideshow__pagination">
                    {headers.map((elem, i) => (
                      <div key={i} className={`slideshow__pagination-item ${i == 0 && "is-active"}`}>
                        <span className="icon">{i}</span>
                      </div>
                    ))}
                  </div> 
                </>
              )} 
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default DynamicSlideshow;
