import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { BsImageFill } from "@react-icons/all-files/bs/BsImageFill";

import ProfileCard from "../components/molecules/ProfileCard";
import ProjectCard from "../components/molecules/ProjectCard";
import Button from "../components/atoms/Button";
import Spinner from "../components/atoms/Spinner";
import DocumentHead from "../components/atoms/DocumentHead";
import PopUp from "../components/molecules/PopUp";

import { getCurrentUser } from "../services/AuthService";
import { getUserPreviewById } from "../services/UserService";
import { getPreviewProjectsByUploaderId } from "../services/ProjectService";
import { getNotifications } from "../services/ChatService";
import { getFavoriteProjectsByProfileId, saveFavorite } from "../services/FavoriteService";

import popupProImage from "../assets/images/popupImages/transitions/popupTransitionPro.m4v";
import popupFreeImage from "../assets/images/popupImages/transitions/popupTransitionFree.m4v";
import preTransitionPopupFreeImage from "../assets/images/popupImages/PopupProAccount.png";
import preTransitionPopupProImage from "../assets/images/popupImages/PopupFreeAccount.png";

import scrollTrigger  from "../util/ScrollReveal";

import "../styles/pages/ProfilePage.scss";

const ProfilePage = ({ currentUser }) => {
  const FAVORITES_PER_PAGE = 18;

  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);

  const [favorites, setFavorites] = useState([]);
  const [currentUserFavorites, setCurrentUserFavorites] = useState([]);
  const [items, setItems] = useState([]);
  const [isCurrentUserProfile, setIsCurrentUserProfile] = useState(false);
  const [proUpgradePopup, setProUpgradePopup] = useState(false);
  const [uploadUpgradePopup, setUploadUpgradePopup] = useState(false);
  const [activeTab, setActiveTab] = useState("projectsTab");
  const [previousTab, setPreviousTab] = useState("");
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [endOfFavorites, setEndOfFavorites] = useState(false);
  const [lastFavoriteIndex, setLastFavoriteIndex] = useState(0);
  const [saveUpgradePopup, setSaveUpgradePopup] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);

  const saves = history.location.state ? history.location.state.saves : false;
  const savesTab = useRef(null);

  useEffect(() => {
    const email = getCurrentUser();

    if (!email) {
      if (!id) {
        history.push("/sign-up");
      } else {
        setLoading(true);
        setIsCurrentUserProfile(false);
        getUserPreviewById(id).then((user) => {
          if (user) {
            setUser(user);
            setRole(user.role.name);

            getPreviewProjectsByUploaderId(user.profileId, false).then((response) => {
              setItems(response.reverse());
            }); 

            getFavoriteProjectsByProfileId(user.profileId, 0, FAVORITES_PER_PAGE).then((response) => {
              setFavorites(response);
            });

            setLoading(false);
          }
        });
      }
    } else {
      if (currentUser) {
        if (currentUser.uuid == id || currentUser.userId == id) {        
          setLoading(true);
          setIsCurrentUserProfile(true);
          setLoadingProfile(true);

          getUserPreviewById(id).then((user) => { 
            setUser(currentUser);
            setRole(user.role.name);

            getNotifications(currentUser.userId).then((notifications) => {
              if (notifications) {
                const notifcationIds = [];
    
                for (let i=0; i<notifications.length; i++) {
                  notifcationIds.push(notifications[i].id);
                }
    
                setNotifications(notifcationIds);
                setLoadingProfile(false);
              }
            });
            
            getPreviewProjectsByUploaderId(currentUser.profile.profileId, true).then((response) => {
              setItems(response.reverse());

              if (response.filter((elem) => elem.archived === false).length >= 4 && user.role.name == "free-user") {
                sessionStorage.setItem("uploadLimitReached",  true);
              }
            });
  
            getFavoriteProjectsByProfileId(currentUser.profile.profileId, 0, FAVORITES_PER_PAGE).then((response) => {
              setFavorites(response);
              setLastFavoriteIndex(response.length);
              if (response.length < FAVORITES_PER_PAGE) setEndOfFavorites(true);
  
              setCurrentUserFavorites(JSON.parse(localStorage.getItem("favorites")));
              setLoading(false);
            });
          });
        } else {
          setLoading(true);
          setLoadingProfile(true);
          setIsCurrentUserProfile(false);
          getUserPreviewById(id).then((user) => {
            if (user) {
              setUser(user);
              setRole(user.role.name);
              setLoadingProfile(false);

              getPreviewProjectsByUploaderId(user.profileId, false).then((response) => {
                setItems(response.reverse());
                setLoading(false);
              });

              getFavoriteProjectsByProfileId(user.profileId, 0, FAVORITES_PER_PAGE).then((response) => {
                setFavorites(response);
                setLastFavoriteIndex(response.length);
                if (response.length < FAVORITES_PER_PAGE) setEndOfFavorites(true);

                setCurrentUserFavorites(JSON.parse(localStorage.getItem("favorites")));
                setLoading(false);
              });
            }
          });
        }
      } 
    }
  }, [location, currentUser]);

  useEffect(() => {
    if (saves && isCurrentUserProfile && savesTab.current) {
      savesTab.current.click();
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, [200]);
    }
  }, [savesTab, isCurrentUserProfile]);

  useEffect(() => {
    if (items.length > 0) {
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, [200]);
    }
  }, [items]);

  useEffect(() => {
    if (favorites.length > 0) {
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, [200]);
    }
  }, [favorites]);

  useEffect(() => {
    // check if sessionStorage has a current tab
    const sessionItem = sessionStorage.getItem("currentProfileTab");
    if (sessionItem !== null) {
      const currentTab = JSON.parse(sessionItem).tab;
      const profileId = JSON.parse(sessionItem).id;

      if (profileId === id) {
        changeTab(currentTab);
      } else {
        changeTab("projectsTab");
      }
    } else {
      changeTab("projectsTab");
    }
  }, []);

  function storeFavorites(projectId) {
    if (currentUser !== undefined && currentUser !== null) {
      setLoading(true);
      let favoriteList = [];
      const favoriteItems = localStorage.getItem("favorites");

      // Use list from local storage if it exists
      if (favoriteItems != null) {
        favoriteList = JSON.parse(favoriteItems);
      }

      // Add new favorite project to list and local storage
      if (currentUserFavorites.find(item => item === projectId) == null) {
        favoriteList.push(projectId);
      
        saveFavorite(currentUser.profile.profileId, projectId).then((response) => {

          if (isCurrentUserProfile) {
            getFavoriteProjectsByProfileId(currentUser.profile.profileId).then((response) => {
              setCurrentUserFavorites(favoriteList);

              if (isCurrentUserProfile) {
                setFavorites(response);
              }

              localStorage.setItem("favorites", JSON.stringify(favoriteList));

              getPreviewProjectsByUploaderId(user.profileId, true).then((response) => {
                setItems(response);
                setLoading(false);
              });
            });
          } else {
            localStorage.setItem("favorites", JSON.stringify(favoriteList));

            getPreviewProjectsByUploaderId(user.profileId, false).then((response) => {
              setItems(response);
              setLoading(false);
            });

          }
        });
        
      } else {
        setLoading(true);
        favoriteList.splice(favoriteList.indexOf(projectId), 1);

        if (isCurrentUserProfile) {
          getFavoriteProjectsByProfileId(currentUser.profile.profileId).then((response) => {
            const filteredList = response.filter(item => item.project.projectId !== projectId);

            localStorage.setItem("favorites", JSON.stringify(favoriteList));

            setCurrentUserFavorites(favoriteList);
            setFavorites(filteredList);


            getPreviewProjectsByUploaderId(user.profileId, true).then((response) => {
              setItems(response);
              setLoading(false);
            });

          });
        } else {
          localStorage.setItem("favorites", JSON.stringify(favoriteList));
          setCurrentUserFavorites(favoriteList);
        }
      }
    }
  }

  function handleOnClick() {
    if (items.filter((elem) => elem.archived === false).length >= 5 && role === "free-user") {
      setUploadUpgradePopup(true);
    } else {
      history.push({
        pathname: "/upload-project",
      });
    }
  }

  function detectMobile() {
    return window.innerWidth <= 800 && window.innerHeight <= 1000;
  }

  function changeTab(tabName) {
    setPreviousTab(activeTab);
    setActiveTab(tabName);
    sessionStorage.setItem("currentProfileTab", JSON.stringify({"tab": tabName, "id": id}));
  }

  function fetchMoreFavorites() {
    setScrollLoading(true);
    
    if (currentUser && currentUser.uuid == id || currentUser.userId == id) {
      getFavoriteProjectsByProfileId(currentUser.profile.profileId, 0, lastFavoriteIndex + FAVORITES_PER_PAGE).then((response) => {
        setFavorites(response);
        setCurrentUserFavorites(response);
        setLastFavoriteIndex(response.length);
        setScrollLoading(false);

        if (response.length < FAVORITES_PER_PAGE) setEndOfFavorites(true);
      });
      
    } else {
      getFavoriteProjectsByProfileId(user.profile.profileId, 0, lastFavoriteIndex + FAVORITES_PER_PAGE).then((response) => {
        setFavorites(response);
        setLastFavoriteIndex(response.length);
        setScrollLoading(false);

        if (response.length < FAVORITES_PER_PAGE) setEndOfFavorites(true);
      });
    }
  }

  return (
    <div className="profile">
      {isCurrentUserProfile ? 
        <DocumentHead
          title="Your Profile on Megosu"
          metaDescription="View your profile."
        /> :
        null
      }
      {!loadingProfile ? (
        <>
          {user && (
            <ProfileCard user={user} currentUser={currentUser} isCurrentUserProfile={isCurrentUserProfile} /> 
          )}

          <div className="profile__project-container">
            <div className="profile__container">
              <div className="profile__content-container">
                <div className="profile__title-container">
                  <>
                    <button 
                      id="projectsTab" 
                      onClick={event => { changeTab("projectsTab"); setTimeout(() => { scrollTrigger(".reveal"); }, [200]); }} 
                      className={`${activeTab === "projectsTab" ? "profile__title--active" :  ""} profile__title profile__title--first-element`}
                    >
                  Projects
                    </button>

                    {!isCurrentUserProfile && favorites.length === 0 ?
                      null
                      :
                      <button ref={savesTab} onClick={ event => { changeTab("saves"); setTimeout(() => { scrollTrigger(".reveal"); }, [200]); }} className={`${activeTab === "saves" ? "profile__title--active" :  ""} profile__title`}>
                    Saves
                      </button>
                    }
                
                    {isCurrentUserProfile && (
                      <>
                        <div className="profile__chat-container">
                          {notifications.length > 0 && (
                            <span className="profile__chat-notification"/>
                          )}
                          <button
                            id="chatTab" 
                            onClick={() => { history.push({ pathname: "/chat" }); }} 
                            className={!(notifications.length > 0) ? "profile__title profile__title-chat" : "profile__title profile__title--active"}>
                      Chats
                          </button>
                        </div>
                      </>
                    )}

                    {isCurrentUserProfile && role === "free-user" && detectMobile() === false &&
                  <Button type="pink" title="Boost Projects" onClick={ () => history.push("/pricing")}/>
                    }
                  </>
                </div>

                {activeTab === "projectsTab" ?
                  <>
                    {!loading && !isCurrentUserProfile && items.length == 0 && <h1 className="profile__error-message">This user has no uploads yet...</h1>}
                    <div className="profile__card-container">
                      {loading ?
                        <Spinner type="megosu" />
                        :
                        <>
                          {items.map((elem, i) => (
                            <ProjectCard
                              key={`project-${i}`}
                              parent="profile-projects"
                              projectId={elem.projectId}
                              title={elem.title}
                              imageUrl={elem.imageUrl}
                              index={i}
                              boosted={role === "business-user" || role === "pro-user" || role === "pitch-user"}
                              onClick={(projectId) => storeFavorites(projectId)}
                              uuid={elem.uuid}
                              ownProjects={true}
                              user={currentUser}
                              manualFavoriteSaveFunction={true}
                              favoriteCount={elem.favoriteCount}
                              ownerProfileId={elem.userUUID}
                              archived={elem.archived}
                            />
                          ))}

                          {isCurrentUserProfile ? (
                            <div className="profile__btn-container">
                              <div className="profile__upload-btn" onClick={handleOnClick}>
                                <BsImageFill className="profile__upload-btn-icon" />
                                {items.length >= 1 ? (
                                  <p>Upload more projects</p>
                                ) : (
                                  <p>Upload your first project</p>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </>}
                    </div></>
                  :
                  <div>
                    {favorites && favorites.length >= 1 ? (
                      <>
                        <InfiniteScroll
                          dataLength={favorites.length}
                          next={fetchMoreFavorites}
                          hasMore={currentUser ? !endOfFavorites : favorites.length < FAVORITES_PER_PAGE}
                          style={{ padding: "0 3rem", overflowY: "hidden", display: "grid" }}
                          scrollThreshold={0.5}
                        >
                          <div className="profile__content-container">
                            <div className="profile__card-container">
                              {favorites &&
                          favorites.map((elem, i) => (
                            <ProjectCard
                              key={`project-${i}`}
                              parent="profile"
                              projectId={elem.project.projectId}
                              title={elem.project.title}
                              imageUrl={elem.project.imageUrl}
                              boosted={elem.project.boosted}
                              userImageUrl={elem.project.userImageUrl}
                              designerName={elem.project.designerName}
                              index={i}
                              onClick={(projectId) => storeFavorites(projectId)}
                              uuid={elem.project.uuid}
                              user={currentUser}
                              manualFavoriteSaveFunction={true}
                              favoriteCount={elem.project.favoriteCount}
                              ownerProfileId={elem.project.userUUID}
                            />
                          ))}
                            </div>
                          </div>
                        </InfiniteScroll>

                        {scrollLoading && (
                          <Spinner type="megosu" />
                        )}
                      </>
                    )
                      :
                      <>
                        {isCurrentUserProfile &&
                          <h2 className={"profile__error-message"}>Whoops! You haven’t saved any projects
                            yet…&nbsp;
                          <a className={"profile__link"}
                            onClick={ () => {history.push({pathname: "/"});}}
                          >
                              Start exploring
                          </a>
                            &nbsp;and collect your favorite designs here!</h2>
                        }
                      </>
                    }
                  </div>
                }
              </div>

            </div>
          </div>
        </>
      ) : (
        <div className="profile__spinner-container">
          <Spinner type="megosu" />
        </div>      
      )}
      
      <PopUp
        visibility={proUpgradePopup}
        overlay={true}
        handleOnClickClose={() => setProUpgradePopup(false)}
        src={popupProImage}
        preTransitionImage={preTransitionPopupProImage}
        transition={true}
        popupTitle="Unlock this feature!"
        popupText="Projects are boosted, unlimited uploads, access to the private community & get in touch with clients."
        firstButtonTitle="Go Pro"
        firstButtonType="black"
        firstButtonAction={ () => {
          history.push({ pathname: "/pricing" });
          setProUpgradePopup(false);
        }}
      />

      <PopUp
        visibility={saveUpgradePopup}
        overlay={true}
        handleOnClickClose={() => setSaveUpgradePopup(false)}
        src={popupFreeImage}
        preTransitionImage={preTransitionPopupFreeImage}
        transition={true}
        popupTitle="Start your account \n to save this project."
        firstButtonTitle="Sign up for free"
        firstButtonType="black"
        firstButtonAction={() => history.push({ pathname: "/sign-up" })}
      />

      <PopUp
        visibility={uploadUpgradePopup}
        overlay={true}
        handleOnClickClose={() => setUploadUpgradePopup(false)}
        src={popupProImage}
        preTransitionImage={preTransitionPopupProImage}
        transition={true}
        popupTitle="Unlock more uploads!"
        popupText="Congrats! You’ve uploaded 5 projects. Go Pro to upload as many projects as you like."
        firstButtonTitle="Go Pro"
        firstButtonType="black"
        firstButtonAction={() => {
          history.push({ pathname: "/pricing" });
          setUploadUpgradePopup(false);
        }}
      />
    </div>
  );
};

export default ProfilePage;
