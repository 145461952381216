import { AuthHeader } from "../util/AuthHeader";

const imageUrl = process.env.REACT_APP_API_BASE_URL + "/images";

export function uploadProjectImage(file, projectId, inputIndex, compressedFile) {
  const uploadImageUrl =
    imageUrl +
    "/projects/upload" +
    `?projectId=${projectId}&inputIndex=${inputIndex}`;

  const options = {
    method: "POST",
    body: file, compressedFile,
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(uploadImageUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image upload response");
    }
  });
}

export function uploadCompanyImage(file, companyId, inputIndex, compressedFile) {
  const uploadImageUrl =
  imageUrl +
  "/companies/upload" +
  `?companyId=${companyId}&inputIndex=${inputIndex}`;

  const options = {
    method: "POST",
    body: file, compressedFile,
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(uploadImageUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image upload response");
    }
  });
}

export function uploadHeader(headerId, index, type, desktopImage, mobileImage, fileType) {
  const uploadImageUrl =
    imageUrl +
    `/headers/upload?headerId=${headerId}&index=${index}&type=${type}&fileType=${fileType}`;

  const options = {
    method: "POST",
    body: desktopImage, mobileImage,
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(uploadImageUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image upload response");
    }
  });
}

export function deleteProjectImage(imagePath) {
  const deleteImageUrl = imageUrl + `/projects?imagePath=${imagePath}`;

  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(deleteImageUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image deleted");
    }
  });
}

export function backUpProjectImages(projectId) {
  const deleteImageUrl = imageUrl + "/projects/" + projectId;

  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(deleteImageUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image deleted");
    }
  });
}

export function uploadProfileImage(file, userId) {
  const uploadImageUrl = imageUrl + "/profile/upload" + `?id=${userId}`;

  const options = {
    method: "POST",
    body: file,
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(uploadImageUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image upload response", response);
    }
  });
}

export function uploadAdvertisementVisual(file, advertisementId) {
  const uploadVisualUrl = imageUrl + "/ads/upload" + `?id=${advertisementId}`;

  const options = {
    method: "POST",
    body: file,
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(uploadVisualUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image upload response", response);
    }
  });
}

export function deleteProfileImage(profileId) {
  const deleteImageUrl = imageUrl + "/profiles/" + profileId + "/image";

  const options = {
    method: "DELETE",
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(deleteImageUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image deleted");
    }
  });
}

export function uploadChatMessageAttachment(file, chatRoomId, chatMessageId, index) {
  const uploadImageUrl = imageUrl + "/chat/upload" + `?id=${chatRoomId}&chatMessageId=${chatMessageId}&inputIndex=${index}`;

  const options = {
    method: "POST",
    body: file,
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(uploadImageUrl, options).then((response) => {
    if (response.ok) {
      console.log("Image upload response", response);
    }
  });
}

export function downloadChatAttachment(resourceUrl) {
  const downloadAttachmentUrl = imageUrl + `/chat/download?resourceUrl=${resourceUrl}`;

  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(downloadAttachmentUrl, options).then((response) => {
    if (response.ok) {
      return response.blob();
    } 
  }).catch((err) => {
    console.log("Download failed!");
  });
}

export function uploadSiteFile(siteFileId, files) {
  const uploadSiteFileUrl =
    imageUrl +
    `/files/upload?id=${siteFileId}`;

  const options = {
    method: "POST",
    body: files,
    headers: {
      Authorization: AuthHeader(),
    }
  };

  return fetch(uploadSiteFileUrl, options).then((response) => {
    if (response.ok) {
      console.log("[200 - OK] Files upload response: ", response);
    }
  });
}

export default { uploadProjectImage, uploadProfileImage, deleteProjectImage, deleteProfileImage, uploadAdvertisementVisual };