import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function DocumentHead({title, metaDescription, showChat, chatTimeout}) {

  useEffect(() => {
    var timeout = 0;

    if (chatTimeout !== undefined) {
      timeout = chatTimeout;
    }

    setTimeout(() => {
      const chat = document.getElementById("tidio-chat");
    
      if (chat === null) return;

      if (!showChat || showChat === undefined) {
        chat.style.display = "none";
      } else {
        chat.style.display = "block";
      }
    }, timeout);
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {metaDescription != "" && ( 
          <meta name="description" content={metaDescription}/> 
        )}

        {/* optional meta tags */}
        <meta charSet="utf-8" />
      </Helmet>
    </>
  );

}

export default DocumentHead;