import React from "react";
import DocumentHead from "../components/atoms/DocumentHead";

import "../styles/pages/NotFoundPage.scss";

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <DocumentHead
        title="Page Not Found | Megosu"
        metaDescription=""
      />
      <div className="not-found__container">
        <h2>404 Not Found</h2>
      </div>
    </div>
  );
};

export default NotFoundPage;
