import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaPinterest } from "@react-icons/all-files/fa/FaPinterest";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { VscGlobe } from "@react-icons/all-files/vsc/VscGlobe"; 
import { IoLocationSharp } from "@react-icons/all-files/io5/IoLocationSharp";

import profilePlaceHolder from "../../assets/images/ProfilePlaceholder.png";
import popupProImage from "../../assets/images/popupImages/transitions/popupTransitionPro.m4v";
import preTransitionPopupProImage from "../../../src/assets/images/popupImages/PopupProAccount.png";

import Button from "../atoms/Button";
import PopUp from "./PopUp";
import DocumentHead from "../atoms/DocumentHead";

import { getChats } from "../../services/ChatService";
import { getProfileById } from "../../services/ProfileService";
import { getCurrentUser } from "../../services/AuthService";

import "../../styles/components/molecules/ProfileCard.scss";

const socialInitialValues = [{
  instagram: "",
  linkedin: "",
  facebook: "",
  pinterest: "",
}];

const initialValuesProfile = {
  firstName: "",
  lastName: "",
  city: "",
  country: "",
  biography: "",
  university: "",
  universityStartDate: "",
  universityEndDate: "",
  experience: "",
  jobAvailability: "",
  email: "",
  siteUrl: "",
  imageUrl: profilePlaceHolder,
  socials: socialInitialValues,
};

function ProfileCard({ user, currentUser, isCurrentUserProfile }) {
  const history = useHistory();
  const [valuesProfile, setValuesProfile] = useState(initialValuesProfile);
  const [upgradePopUpVisible, setUpgradePopUpVisible] = useState(false);
  const [contactPopUpVisible, setContactPopUpVisible] = useState(false);
  const [contactInfo, setContactInfo] = useState({});
  const [role, setRole] = useState("");
  const [chatExists, setChatExists] = useState(false);
  const [goToChat, setGoToChat] = useState();
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(getCurrentUser() ? true : false); 
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 

  const profileImageRef = useRef(null);

  useEffect(() => {
    if (!isCurrentUserProfile) {
      getProfileById(user.profileId).then((res) => {
        currentUserProfile(res);
      });
    } else {
      currentUserProfile(user.profile);
    }
  }, [user, currentUser]);

  useEffect(() => {
    if (currentUser && user) {
      getChats(currentUser.userId).then((chats) => {
        if (chats) {
          for (const chat of chats) {
            if (chat.senderId == currentUser.userId && chat.recipientId == user.userId) {
            // user has chat with designer
              setChatExists(true);
              setGoToChat(chat.chatRoomId);
              return;
            }
          }
        }
      });
    }
  }, [currentUser, user]);

  useEffect(() => {
    if (valuesProfile.imageUrl) {
      if (!valuesProfile.imageUrl.includes("ProfilePlaceholder")) {
        profileImageRef.current.src = process.env.REACT_APP_GCS_IMAGE_URL + valuesProfile.imageUrl;
      }
    }
  }, [valuesProfile]);

  function currentUserProfile(profile) {
    if (profile !== null) {
      if (profile.socials === undefined || profile.socials.length === 0) {
        profile.socials = socialInitialValues;
      }
      setValuesProfile(profile);
      setRole(user.role.name);

      setContactInfo({
        designerId: user.userId,
        designerFirstName: profile.firstName,
        designerLastName: profile.lastName,
        designerMail: user.email,
        designerRole: user.role.name,
        designerAvatar: profile.imageUrl
          ? process.env.REACT_APP_GCS_IMAGE_URL + profile.imageUrl
          : null,
      });

      if (valuesProfile.imageUrl) {
        if (!valuesProfile.imageUrl.includes("ProfilePlaceholder")) {
          document.getElementById("profile-image").src = process.env.REACT_APP_GCS_IMAGE_URL + valuesProfile.imageUrl;
        }
      }
    }
  }

  function handleContactClick() {
    if (userIsLoggedIn) {
      if (chatExists) {
        sessionStorage.setItem("goToChat", goToChat);
        history.push("/chat");
      } else {
        setContactPopUpVisible(true);
      }
    } else {
      setSignUpPopUpVisible(true);
    }
  }

  const sendMailToUser = () => {
    window.location = "mailto:" + user.email;
  };

  return (
    <div className="profile-card">
      {!isCurrentUserProfile &&
      <DocumentHead
        title={valuesProfile.firstName + "'s profile on Megosu"}
        metaDescription="Upload your designs."
      />
      }
      <div className="profile-card__container">
        <div className="profile-card__content-container">
          <div className="profile-card__img-wrapper">
            <img
              src={profilePlaceHolder}
              className="profile-card__img"
              alt="profile-card"
              id="profile-image"
              ref={profileImageRef}
            />
          </div>
          <div className="profile-card__name-container">
            <h5 className="profile-card__name">
              {valuesProfile.firstName} {valuesProfile.lastName}&nbsp;
            </h5>
            {contactInfo.designerRole == "pro-user" || contactInfo.designerRole == "business-user" || contactInfo.designerRole === "pitch-user" 
              ? 
              <h6 className="profile-card__boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
              :
              null
            }
          </div>
          <span className="profile-card__location">
            <a href={`https://maps.google.com/?q=${valuesProfile.city != "" ? valuesProfile.city + ", " : ""} ${valuesProfile.country.name}`} target="_blank" rel="noreferrer">
              <IoLocationSharp/>
            </a>
            {valuesProfile.city != "" ? valuesProfile.city + ", " : ""}{valuesProfile.country.name}
          </span>
        </div>

        {valuesProfile.biography && (
          <span className="profile-card__biography">
            {valuesProfile.biography}
          </span>
        )}

        {valuesProfile.jobTitle && (
          <div className="profile-card__experience-container">
            <h6 className="profile-card__title">Work</h6>
            <span className="profile-card__experience">
              {valuesProfile.jobTitle}
              <br />
              {valuesProfile.companyName}
            </span>
          </div>
        )}

        {valuesProfile.university && valuesProfile.university !== "None" && (
          <div className="profile-card__education-container">
            <h6 className="profile-card__title">Education</h6>
            <span className="profile-card__education">
              {valuesProfile.university !== "Other University" ? valuesProfile.university.split("-").splice(1).join("-") : valuesProfile.university}
              <br />
              {valuesProfile.universityGradDate &&
                "Graduation year " + valuesProfile.universityGradDate}
            </span>
          </div>
        )}

        {valuesProfile.experience && (
          <div className="profile-card__experience-container">
            <h6 className="profile-card__title">Experience Level</h6>
            <span className="profile-card__experience">
              {valuesProfile.experience}
            </span>
          </div>
        )}

        {valuesProfile.jobAvailability && (
          <div className="profile-card__job-container">
            <h6 className="profile-card__title">Job Availability</h6>
            <span className="profile-card__job">
              {valuesProfile.jobAvailability.replaceAll(",", ", ")}
            </span>
          </div>
        )}

        <div className="profile-card__button-container">
          {isCurrentUserProfile && (
            <>
              {role && (role !== "pro-user" && role !== "business-user" && role !== "pitch-user") && (
                <Button
                  title="Upgrade Profile"
                  type="pink"
                  onClick={() => { history.push("/pricing"); }}
                />
              )}
              <Button
                title="Edit Profile"
                onClick={() => { history.push("/settings"); }}
                type="white"
              />
            </>
          )}

        </div>

        <div className="profile-card__button-container">
          {(isCurrentUserProfile && valuesProfile.socials[0].instagram == "" && valuesProfile.socials[0].linkedin == "" && valuesProfile.socials[0].facebook == ""
              && valuesProfile.socials[0].pinterest == "" && (role === "pro-user" || role === "business-user" || role === "pitch-user")) ? (
              <Link className="profile-card__add-socials-btn" to="/settings">Add social icons</Link>
            ) : (
              null
            )
          }
          {!isCurrentUserProfile &&  (
            <>
              <Button
                title="Contact"
                type="black"
                onClick={() => { handleContactClick(); }}
              />
            </>
          )}
        </div>
        {(role === "pro-user" || role === "business-user" || role === "pitch-user") ? 
          <>
            <ul className="profile-card__list">
              {valuesProfile.siteUrl != "" && (
                <li className="profile-card__list-item">
                  <a
                    href={valuesProfile.siteUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <VscGlobe />
                  </a>
                </li>
              )}
              {(valuesProfile.socials[0].instagram !== "") && (
                <li className="profile-card__list-item">
                  <a
                    href={valuesProfile.socials[0].instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram />
                  </a>
                </li>
              )}
              {(valuesProfile.socials[0].linkedin !== "") && (
                <li className="profile-card__list-item">
                  <a
                    href={valuesProfile.socials[0].linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin />
                  </a>
                </li>
              )}
              {(valuesProfile.socials[0].facebook !== "") && (
                <li className="profile-card__list-item">
                  <a
                    href={valuesProfile.socials[0].facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebookSquare />
                  </a>
                </li>
              )}
              {(valuesProfile.socials[0].pinterest !== "") && (
                <li className="profile-card__list-item">
                  <a
                    href={valuesProfile.socials[0].pinterest}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaPinterest />
                  </a>
                </li>
              )}
            </ul>
          </>
          : null
        }

        <PopUp
          visibility={upgradePopUpVisible}
          overlay={true}
          handleOnClickClose={() => setUpgradePopUpVisible(false)}
          src={popupProImage}
          preTransitionImage={preTransitionPopupProImage}
          transition={true}
          popupTitle="Unlock this feature!"
          popupText="Projects are boosted, ask feedback, access to the private community & get in touch with clients."
          firstButtonTitle="Go Pro"
          firstButtonType="black"
          firstButtonAction={() => { history.push({ pathname: "/pricing" }); }}
        />

        <PopUp
          visibility={contactPopUpVisible}
          handleOnClickClose={() => { setContactPopUpVisible(false); }}
          overlay={true}
          popupType="contact"
          props={contactInfo}
          firstButtonAction={() => { setChatExists(true); }}
        />

        <PopUp 
          visibility={signUpPopUpVisible}
          overlay={true}
          firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
          handleOnClickClose={() => setSignUpPopUpVisible(false)}
          popupType="sign-up" 
        />

        <PopUp 
          visibility={loginPopupVisible}
          overlay={true}
          firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
          handleOnClickClose={() => { setLoginPopupVisible(false); }}
          popupType="login" 
        />
      </div>
    </div>
  );
}

export default ProfileCard;
