import React, { useEffect, useState } from "react";

import Input from "../atoms/Input";
import Button from "../atoms/Button";

import { deleteUniversity, saveUniversity } from "../../services/SiteService";

import "../../styles/components/molecules/AdminUniversityForm.scss";

function AdminUniversityForm({
  currentUniversity
}) {

  const initialValues = {
    name: "",
    city: "",
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {    
    if (currentUniversity) {
      setValues(currentUniversity);
    }
  }, []);

  function handleOnChange(e) {
    const { name, value } = e.target;
    
    switch (name) {
      default:
        setValues({
          ...values,
          [name]: value,
        });
        break;
    }
  }

  function processSaveUniversity() {
    saveUniversity(values).then((res) => {
      if (res === null) {
        alert("Oops, something went wrong whilst trying to save the university");
      } else {
        alert("[OK] - University Saved");
      }
    });
  }

  function processDeleteUniversity() {
    if (currentUniversity == null) return;
    
    deleteUniversity(currentUniversity.universityId).then((res) => {
      if (res.ok) {
        alert("[OK] - University deleted");
        window.location.reload();
      } else {
        alert("[ERR] - Something went wrong.");
      }
    });
  }

  return (
    <div className="admin-university">
      <div className="admin-university__container">
        <h2>{currentUniversity ? currentUniversity.name : (values.name === "" ? "New University" : values.name)}</h2>

        <div className="admin-university__input-container">
          <Input
            label="Name"
            id="university-name"
            required={true}
            placeholder=""
            name="name"
            setValue={(values.name)}
            onChange={(e) => handleOnChange(e)}
          />
        </div>

        <div className="admin-university__input-container">
          <Input
            label="City"
            id="university-city"
            required={true}
            placeholder=""
            name="city"
            setValue={(values.city)}
            onChange={(e) => handleOnChange(e)}
          />
        </div>

        <div className="admin-university__button-container">
          <Button onClick={() => { processDeleteUniversity(); }} title="Delete" type="black"/>
          <Button onClick={() => { processSaveUniversity(); }} title="Save" type="pink"/>
        </div>
      </div>
    </div>
  );
}

export default AdminUniversityForm;