import React, { useEffect, useLayoutEffect, useState } from "react";
import $ from "jquery";

import "../../styles/components/molecules/LabelSet.scss";

function LabelSet({ index, category, currentProject, currentCompany, isCompanyLabels }) {
  const [currentTags, setCurrentTags] = useState([]);
  const MAX_SELECTED_TAGS = 2;

  useEffect(() => {
    if (currentProject) {
      const tags = [
        currentProject.material,
        currentProject.product,
        currentProject.production,
        currentProject.space,
        currentProject.topic,
      ];

      tags.map((item) => {
        return item.map((tag) => setCurrentTags((tags) => [...tags, tag]));
      });
    }
    
    if (currentCompany) {
      const tags = [
        currentCompany.material,
        currentCompany.product,
        currentCompany.space,
      ];

      tags.map((item) => {
        return item.map((tag) => setCurrentTags((tags) => [...tags, tag]));
      });
    }
  }, []);

  // Todo: look for alternative of useLayoutEffect when setting tags to active
  useLayoutEffect(() => {
    currentTags.map((item) => {
      const activeTags = document.querySelectorAll(`input[value='${item.id}']`);
      activeTags.forEach((input) => {
        input.checked = true;
      });
    });
  }, [currentTags]);

  function handleOnClick(index, e) {
    window["labelset-" + index] = $(`.labelset-${index}__input`);

    if (window["labelset-" + index].filter(":checked").length > MAX_SELECTED_TAGS && !isCompanyLabels) {
      e.preventDefault();
      e.target.checked = false;
    }
  }

  return (
    <div className="label-set">
      <div className="label-set__title-container">
        <span className="label-set__title">{index}</span>
        {!isCompanyLabels && ( 
          <span className="label-set__subtitle">max. {MAX_SELECTED_TAGS}</span>
        )}
      </div>
      <div id={`labelset-${index}`} className="label-set__labels-container">
        {category.map((elem, i) => (
          <div key={i} className="label-set__label-container">
            <label
              id={elem["id"]}
              htmlFor={elem["id"]}
              className="label-set__label"
            >
              <input
                id={elem["name"]}
                type="checkbox"
                className={`labelset__input labelset-${index}__input`}
                name={index}
                value={elem["id"]}
                onClick={(e) => {
                  handleOnClick(index, e);
                }}
              />
              <span className="label-set__label-name">{elem["name"]}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LabelSet;
