import React from "react";
import { Link } from "react-router-dom";

import LoginForm from "../components/molecules/LoginForm";
import DocumentHead from "../components/atoms/DocumentHead";

import "../styles/pages/LoginPage.scss";

const LoginPage = () => {
  return (
    <>
      <DocumentHead
        title="Log In | Megosu"
        metaDescription="Log in to Megosu with your account."
      />
      <div className="login__container">
        <div className="login">
          <div className="login__form-container">
            <span className="login__registration-link">
            Don&apos;t have an account? &nbsp;
              <Link to="/sign-up">Sign Up</Link>
            </span>
            <h4 className="login__title">Log In</h4>
            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
