import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill";

import readmore from "../assets/files/Read More - Megosu.pdf";
import tipsAndContractsPDF from "../assets/files/Tips & Contracts - Megosu.pdf";

import popupImagePremium from "../assets/images/popupImages/transitions/popupTransitionPremium.m4v";
import preTransitionPopupPremiumImage from "../assets/images/popupImages/PopupPremiumAccount.png";

import scrollTrigger from "../util/ScrollReveal";

import Spinner from "../components/atoms/Spinner";
import Button from "../components/atoms/Button";
import PopUp from "../components/molecules/PopUp";
import CompanyCard from "../components/molecules/CompanyCard";
import CompanyFilter from "../components/molecules/CompanyFilter";
import DocumentHead from "../components/atoms/DocumentHead";

import { getCompanies, getCompanyById, getFilteredCompanies } from "../services/CompanyService";
import { getPreviewProjectsByUploaderId } from "../services/ProjectService";
import { getCurrentUser, getCurrentUserByEmail } from "../services/AuthService";

import { 
  GCS_ASSET_PICTORIGHT_FONDS_LOGO, GCS_ASSET_PITCH_COVER_1, GCS_ASSET_PITCH_COVER_2, GCS_ASSET_PITCH_COVER_3, GCS_ASSET_PITCH_COVER_4, GCS_ASSET_PITCH_COVER_BECOME_A_PARTNER, GCS_ASSET_PITCH_P1,
  GCS_ASSET_PITCH_P2, GCS_ASSET_PITCH_P3, GCS_ASSET_PITCH_P4 
} from "../constants/gcs-assets-paths";

import "../styles/pages/PitchPage.scss";

const PitchPage = () => {
  const history = useHistory();
  const location = useLocation();
  const COMPANIES_PER_PAGE = 20;
  const sortingFields = ["Sort", "Newest", "A-Z"];
  const BECOME_PARTNER_ROW_INDEX = 6;
  
  const [selectedSortingField, setSelectedSortingField] = useState(sortingFields[0]);
  const [sortFilter, setSortFilter] = useState(false);
  const [reversedIcon, setReversedIcon] = useState(false);
  const [indexOfFirstCompany, setIndexOfFirstCompany] = useState(0);
  const [indexOfLastCompany, setIndexOfLastCompany] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [loadedCompanyIds, setLoadedCompanyIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [results, setResults] = useState("");
  const [user, setUser] = useState(null);
  const [endOfCompanies, setEndOfCompanies] = useState(false);
  const [query, setQuery] = useState("");
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false);
  const [noProjectsPopupVisble, setNoProjectsPopupVisible] = useState(false);
  const [upgradePopup, setUpgradePopup] = useState(false);
  const [uploadedProjectsPopup, setUploadedProjectsPopup] = useState(false);
  const [unlockAllCompaniesPopup, setUnlockAllCompaniesPopup] = useState(false);
  const [contactCompanyPopup, setContactCompanyPopup] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);
  const [projects, setProjects] = useState([]);

  const readMoreNewLine = (width < 1200 && width > 438);
  const isMobile = width <= 1200;
  const tenCompaniesScrollPosition = 3200;
  const twentyCompaniesScrollPosition = 5700;

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (companies.length > 0) {
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, 500);
    } 

    if (companies.length < 5 && window.scrollY >= tenCompaniesScrollPosition) {
      setNoProjectsPopupVisible(false);
    }
  }, [companies]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [projects, user]);

  useEffect(() => {
    const currentSort = sessionStorage.getItem("companiesSort");
    if (currentSort !== null) {
      setSelectedSortingField(currentSort);
      fetchMoreData(currentSort);
    } else {
      setSelectedSortingField(sortingFields[0]);
      fetchMoreData(sortingFields[0]);
    }
  }, []);

  useEffect(() => {
    const currentUser = getCurrentUser();

    if (!currentUser && window.scrollY >= tenCompaniesScrollPosition) {
      setNoProjectsPopupVisible(true);
      return;
    }

    if (currentUser) {
      getCurrentUserByEmail(currentUser).then((user) => {
        setUser(user);

        getPreviewProjectsByUploaderId(user.profile.profileId, true).then((res) => {
          setProjects(res);
        });
      });
    }
  }, []);

  function fetchMoreData(sortingField) {
    if (sortingField) {
      setLoading(true);
      setEndOfCompanies(false);
    }
    setScrollLoading(true);
    // Get the first and last index of the companies that are requested
    const loadedCompanies = parseInt(handleAmountOfCompanies());
    const firstIndex = (sortingField) ? 0 : indexOfLastCompany;
    const lastIndex = (loadedCompanyIds != 0) ? loadedCompanies : ((sortingField) ? COMPANIES_PER_PAGE : indexOfLastCompany + COMPANIES_PER_PAGE);

    // Get sorting type
    const sortingType =  (sortingField) ? sortingField : selectedSortingField;

    // If there is an filter used
    if (query != "" || loadedCompanies != 0 || window.location.search !== "") return;

    const page = Math.floor(firstIndex / COMPANIES_PER_PAGE);
    
    getCompanies(page, COMPANIES_PER_PAGE, sortingType, loadedCompanyIds).then((companiesList) => {
      if (companiesList === undefined) return;

      if (companiesList && companiesList.length > 0) {
        if (companiesList.length < COMPANIES_PER_PAGE) {
          setEndOfCompanies(true);
        } 
        setIndexOfLastCompany(lastIndex);
        setCompanies((sortingField) ? companiesList : companies.concat(companiesList));
        setResults(companiesList[0].amountOfCompanies);

        setScrollLoading(false);
        setLoading(false);
      } else {
        setEndOfCompanies(true);
        setScrollLoading(false);
      }
    });
  }

  const handleSelectSort = (field) => {
    if (field === "Newest" || field === "A-Z") {
      if (!user) {
        setSignUpPopUpVisible(true);
        return;
      } else if (user.role.name !== "pitch-user") {
        setUpgradePopup(true);
        return;
      }
    }

    if (field === selectedSortingField) {
      sessionStorage.setItem("companiesSort", "Sort");
      setSelectedSortingField("Sort");
      fetchMoreData("Sort");
    } else {
      sessionStorage.setItem("companiesSort", field);
      setSelectedSortingField(field);
      fetchMoreData(field);
    }
  };

  const handleAmountOfCompanies = () => {
    const amountOfCompanies = sessionStorage.getItem("amountOfCompanies");

    if (amountOfCompanies) {
      setIndexOfLastCompany(amountOfCompanies);
      sessionStorage.removeItem("amountOfCompanies");
      return amountOfCompanies;
    }

    return 0;
  };

  const handleScroll = () => {
    if (projects && projects.length === 0 && user) {
      if (sessionStorage.getItem("scrollPosition") < window.scrollY && window.scrollY >= tenCompaniesScrollPosition) {
        window.removeEventListener("scroll", handleScroll);
        setNoProjectsPopupVisible(true);
      } 
    } else if (projects && projects.length > 1 && user) {
      if (user.role.name !== "pitch-user" && sessionStorage.getItem("scrollPosition") < window.scrollY 
          && window.scrollY >= twentyCompaniesScrollPosition) {
        setUnlockAllCompaniesPopup(true);
      }
    } 

    sessionStorage.setItem("scrollPosition", window.scrollY);
  };
  
  function handleNoProjects() {
    if (!user) {
      history.push("/sign-up");
    } else {
      history.push("/upload-project");
    }
  }
  
  function openContactPopup(companyId) {
    if (!user) {
      setSignUpPopUpVisible(true); 
    } else if (projects.length === 0) {
      setUploadedProjectsPopup(true);
    } else {
      getCompanyById(companyId).then((res) => {
        setSelectedCompany(res);
        setContactCompanyPopup(true);
      });
    }
  }

  return (
    <div className="pitch">
      <DocumentHead
        title="Pitch your designs | Megosu"
        metaDescription="Our mission is to unleash the potential of great product design through collaboration with design brands."
        showChat={false}
      />
      <div className="pitch__explanation">
        <h2 className="pitch__explanation-title">Propose your designs to your favourite companies.</h2>
        <p className="pitch__explanation-subtitle">
          <span className="pitch__social-menu-boosted" onClick={() => { window.open(readmore, "_blank"); }}>Beta</span>
          Pitch within seconds to the right person at 400+ companies,
          {!readMoreNewLine && (
            <>
              <a className="pitch__explanation-readmore-subtitle-space" onClick={() => { window.open(readmore, "_blank"); }}> </a>
              <a className="pitch__explanation-readmore-subtitle" onClick={() => { window.open(readmore, "_blank"); }}>read more.</a>
            </>               
          )}
        </p>
        {readMoreNewLine && (
          <a className="pitch__explanation-readmore-subtitle" onClick={() => { window.open(readmore, "_blank"); }}>read more</a>
        )}

        <div className="pitch__info-container pitch__image-step-container reveal">
          <div className="pitch__image-step-container-steps">
            <div className="pitch__container pitch__step-container">
              <figure className="pitch__step-image-container">
                <div className="pitch__step-image-wrapper">
                  <video
                    onMouseOver={(event) => { event.target.play(); }}
                    onMouseOut={(event) => { event.target.pause(); }}
                    src={GCS_ASSET_PITCH_P1 + "#t=0.001"} 
                    poster={GCS_ASSET_PITCH_COVER_1}
                    muted="muted"
                    loop
                    type="video/mp4"
                    playsInline
                  />
                </div>
              </figure>
              <p onClick={() => { handleNoProjects(); }} className="pitch__explanation-text pitch__explanation-text--hover">1. Upload your designs</p>
            </div>
            <div className="pitch__container pitch__step-container">
              <figure className="pitch__step-image-container">
                <div className="pitch__step-image-wrapper">
                  <video
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    src={GCS_ASSET_PITCH_P2 + "#t=0.001"}
                    poster={GCS_ASSET_PITCH_COVER_2}
                    muted="muted"
                    loop
                    type="video/mp4"
                    playsInline
                  />
                </div>
              </figure>
              <p className="pitch__explanation-text">2. Search the companies that match</p>
            </div>
          </div>
          <div className="pitch__image-step-container-steps">
            <div className="pitch__container pitch__step-container">
              <figure className="pitch__step-image-container">
                <div className="pitch__step-image-wrapper">
                  <video
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    src={GCS_ASSET_PITCH_P3 + "#t=0.001"} 
                    poster={GCS_ASSET_PITCH_COVER_3}
                    muted="muted"
                    loop
                    type="video/mp4"
                    playsInline
                  />
                </div>
              </figure>
              <p className="pitch__explanation-text">3. Propose your design</p>
            </div>
            <div className="pitch__container pitch__step-container">
              <figure className="pitch__step-image-container">
                <div className="pitch__step-image-wrapper">
                  <video
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    src={GCS_ASSET_PITCH_P4 + "#t=0.001"} 
                    poster={GCS_ASSET_PITCH_COVER_4}
                    muted="muted"
                    loop
                    type="video/mp4"
                    playsInline
                  />
                </div>
              </figure>
              <p className="pitch__explanation-text">4. The companies review your design and reach out if mutual interest arises</p>
              <a className="pitch__explanation-readmore" onClick={() => { window.open(readmore, "_blank"); }}>Read more</a>
            </div>
          </div>
        </div>
      </div>

      <div className="pitch__companies">
        <div className="pitch__companies-title-row">
          <div className="pitch__companies-title-container">
            <h3>Companies</h3>
          </div>
        </div>

        <div className="pitch__companies-filter">
          <CompanyFilter 
            setLoading={setLoading}
            setIndexOfFirstCompany={setIndexOfFirstCompany}
            setIndexOfLastCompany={setIndexOfLastCompany}
            setQuery={setQuery}
            setCompanies={setCompanies}
            loading={loading}
            results={results}
            setResults={setResults}
            setFirstIndex={setIndexOfFirstCompany}
            setLastIndex={setIndexOfLastCompany}
            setEndOfCompanies={setEndOfCompanies}
            tag={location.state && location.state.tag}
            filterCategory={location.state && location.state.filterCategory}
            role={user && user.role.name}
            isMobile={isMobile}
            selectedSortingField={selectedSortingField}
          />
        </div>

        <div
          className="pitch__sorting"
          onMouseEnter={() => { setReversedIcon(true); setSortFilter(true); }}
          onMouseLeave={() => { setReversedIcon(false); setSortFilter(false); }}
          onClick={() => { setSortFilter(!sortFilter); setReversedIcon(!reversedIcon); }}
        >
          <h3 
            className="pitch__sorting-title"
            onMouseEnter={() => { setReversedIcon(true); setSortFilter(true); }}
            onMouseLeave={() => { setReversedIcon(false); setSortFilter(false); }}
            onClick={() => { setSortFilter(!sortFilter); setReversedIcon(!reversedIcon); }}
          >
            {selectedSortingField}
            <BsFillCaretDownFill className={`pitch__sorting-title-icon ${reversedIcon && "pitch__sorting-title-icon--reversed"}`} />
          </h3>
          <ul
            className={`pitch__sorting-dropdown ${sortFilter && "pitch__sorting-dropdown--active"}`}
            onMouseEnter={() => { setReversedIcon(true); }}
            onMouseLeave={() => { setReversedIcon(false); }}
          >
            {sortingFields.map((field) => (
              <li
                className={`pitch__sorting-dropdown__item ${selectedSortingField === field && "pitch__sorting-dropdown__item--active"}`}
                key={field}
                onClick={() => { handleSelectSort(field); }}
              >
                {(field == "Sort") ? "Relevance" : field}
                {(field === "Newest" || field === "A-Z") &&
                <h6 className="pitch__social-menu-boosted" onClick={() => { history.push("/pricing"); }}>Pitch</h6>
                }
              </li>
            ))}
          </ul>
        </div>
      </div>

      {companies && !loading ? (
        <>
          <InfiniteScroll
            dataLength={companies.length}
            next={fetchMoreData}
            hasMore={user ? !endOfCompanies : (companies.length < COMPANIES_PER_PAGE)}
            style={{ padding: "0 3rem", overflow: "hidden", display: "grid" }}
            scrollThreshold={0.5}
            endMessage={
              <>
                {!user ? (
                  <div
                    className="pitch__filter-ending-message"
                    style={
                      results === 0 ? { display: "none" } : { display: "block" }
                    }
                  >
                    <p>
                      <a onClick={() => { setLoginPopupVisible(true); }}>Log In</a> or{" "}
                      <a onClick={() => { setSignUpPopUpVisible(true); }}>Sign Up</a> to your free account to
                      view all companies.
                    </p>
                    <Button onClick={() => { setSignUpPopUpVisible(true); }} type="black" title="Show more companies"/>
                  </div>
                ) : (
                  <div className="pitch__filter-ending-message" style={results === 0 ? { display: "none" } : { display: "flex" }}>
                    <div className="pitch__filter-ending-message-block">
                      <p>
                      You have reached the end.
                      </p>
                      <Button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} type="black" title="Go back to start"/>
                    </div>

                    {!isMobile && (
                      <div className="pitch__filter-sponsor-message">
                        <p>This Pitch Feature is supported by</p>
                        <img className="pitch__filter-sponsor-message-image" src={GCS_ASSET_PICTORIGHT_FONDS_LOGO}></img>
                      </div>
                    )}
                  </div> 
                )}
              </>
            }
          >
            <div className="pitch__container">      
              <div
                className="pitch__content-container-companies"
                style={
                  results === 0 ? { display: "flex" } : { display: "grid" }
                }
              >
                {results >= 1 ? (
                  <>
                    {companies.map((elem, i) => (
                      <>
                        {(i >= results && results <= BECOME_PARTNER_ROW_INDEX) || i === BECOME_PARTNER_ROW_INDEX ? ( 
                          <div className="pitch__partner">  
                            <div className="pitch__partner-container">
                              <div className="pitch__partner-content-container">
                                <div className="pitch__partner-wrapper-container">
                                  <div className="pitch__partner-text-container">
                                    <h3 className="pitch__partner-title">Want to become a partner?</h3>
                                    <p className="pitch__partner-subtext">
                                    Become a partner, receive pitches and scroll through thousands of designs. 
                                    The Megosu team also helps you with personal curation, consultation and recruitment.
                                    </p>
                                    <Button type="white" title="Become a partner" onClick={() => { window.open("mailto:partners@megosu.com"); }} customClassName="pitch__partner-button--less-width"/>
                                  </div>

                                  <div className="pitch__partner-image-container">
                                    <figure className="pitch__partner-image-figure" onClick={() => { window.open("mailto:partners@megosu.com"); }}>
                                      <div className="pitch__partner-image">
                                        <img src={GCS_ASSET_PITCH_COVER_BECOME_A_PARTNER} alt="people reading books"/>
                                      </div>
                                      <figcaption>&copy; Hayo Gebauer</figcaption>
                                    </figure>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <CompanyCard
                          key={`company-${i}`}
                          index={i}
                          companyId={elem.id}
                          name={elem.name}
                          country={elem.country}
                          imageUrl={elem.imageUrl}
                          handleScroll={handleScroll}
                          onClick={() => { openContactPopup(elem.id); }}
                        />         
                      </>       
                    ))}
                  </>
                ) : (
                  <h2 className="pitch__content-container-noresults">
                    Oops! There were no companies found with your criteria.
                  </h2>
                )}
              </div>
            </div>
          </InfiniteScroll>
          {loading && (
            <Spinner type="megosu" />
          )}

          <PopUp
            type="no-projects-pitch" 
            visibility={noProjectsPopupVisble}
            overlay={true}
            handleOnClickClose={() => { setNoProjectsPopupVisible(false); }}
            popupTitle="Oops..."
            popupText="To pitch your designs to 400+ companies, you have to upload your work first."
            firstButtonTitle="Upload"
            firstButtonAction={() => { handleNoProjects(); }}
            firstButtonType="black"
          />

          <PopUp 
            visibility={signUpPopUpVisible}
            overlay={true}
            firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
            handleOnClickClose={() => setSignUpPopUpVisible(false)}
            popupType="sign-up" 
          />

          <PopUp 
            visibility={contactCompanyPopup}
            overlay={true}
            transition={true}
            handleOnClickClose={() => setContactCompanyPopup(false)}
            popupType="contact-company" 
            data={selectedCompany}
            firstButtonAction={() => { window.open(tipsAndContractsPDF, "_blank"); }}
            firstButtonTitle="Tips & Contracts"
          />

          <PopUp 
            visibility={loginPopupVisible}
            overlay={true}
            firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
            handleOnClickClose={() => { setLoginPopupVisible(false); }}
            popupType="login" 
          />

          <PopUp
            visibility={upgradePopup}
            overlay={true}
            handleOnClickClose={() => setUpgradePopup(false)}
            src={popupImagePremium}
            preTransitionImage={preTransitionPopupPremiumImage}
            transition={true}
            popupTitle="Stay one step ahead! 💪"
            popupText="Use advanced filters, pitch more designs and boost your design career with ease."
            firstButtonTitle="Upgrade"
            firstButtonAction={() => { history.push("/pricing"); }}
            firstButtonType="black"
          />

          <PopUp
            visibility={unlockAllCompaniesPopup}
            overlay={true}
            handleOnClickClose={() => { setUnlockAllCompaniesPopup(false); window.scrollTo({ top: 0, behavior: "smooth" }); }}
            src={popupImagePremium}
            preTransitionImage={preTransitionPopupPremiumImage}
            transition={true}
            popupTitle="Unlock all companies"
            popupText="Get access to all 400+ companies and filters."
            firstButtonTitle="Upgrade"
            firstButtonAction={() => { history.push("/pricing"); }}
            firstButtonType="black"
          />


          <PopUp
            visibility={uploadedProjectsPopup}
            overlay={true}
            handleOnClickClose={() => setUploadedProjectsPopup(false)}
            popupTitle="Oops..."
            popupTextLeft="To pitch your designs to 400+ companies, you have to upload your work first."
            firstButtonTitle="Upload"
            firstButtonType="black"
            firstButtonAction={() => {
              history.push({ pathname: "/upload-project" });
              setUploadedProjectsPopup(false);
            }}
          />
        </>
      ) : (
        <div className="home__spinner-container">
          <Spinner type="megosu" />
        </div>
      )}
    
    </div>
  );
};

export default PitchPage;
