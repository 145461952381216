import React, { useEffect, useState, useRef } from "react";

import Button from "../atoms/Button";

import "../../styles/components/molecules/CompanyCard.scss";

import placeholder from "../../assets/images/ProjectPlaceholder.jpeg";

function CompanyCard({
  index,
  companyId,
  name,
  country,
  handleScroll,
  imageUrl,
  onClick
}) {
  const [companyImages, setCompanyImages] = useState("");
  const [originalImages, setOriginalImages] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const TWO_IMAGE_SCREEN_WIDTH = 530;
  const THREE_IMAGE_SCREEN_WIDTH = 690;
  const FOUR_IMAGE_SCREEN_WIDTH = 900;
  const twoImages = width >= TWO_IMAGE_SCREEN_WIDTH;
  const threeImages = width >= THREE_IMAGE_SCREEN_WIDTH;
  const fourImages = width >= FOUR_IMAGE_SCREEN_WIDTH;

  const imageRef = useRef(null);
  
  useEffect(() => {
    if (imageUrl) {
      const companyImageList = imageUrl.map((a) => a.compressedUrl); 
      companyImageList.sort();
      setCompanyImages(companyImageList);
      imageRef.current.src = process.env.REACT_APP_GCS_IMAGE_URL + companyImageList[0];
      
      const originalImageList = imageUrl.map((a) => a.url);
      originalImageList.sort();
      setOriginalImages(originalImageList);
    }
  }, [companyId]);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  
  return (
    <div>
      <div className={"company-card__content-container"}> 
        <div className="company-card__info-container company-card__image-company-container reveal">
          <div className="company-card__image-company-container-images">
            <div className="company-card__logo-container">
              <figure className="company-card__company-image-container">
                <div className="company-card__company-image-wrapper-logo">
                  <img
                    src={
                      imageUrl
                        ? process.env.REACT_APP_GCS_IMAGE_URL + companyImages[0]
                        : placeholder
                    }
                    alt="company-card"
                    id={"company-image-"+ companyId}
                    ref={imageRef}
                  />
                </div>
              </figure>

              <p className="company-card__company-name">{name}</p>
              <p className="company-card__company-country">{country}</p>
              <div className="company-card__pitch-button-container">
                <Button
                  customClassName={"company-card__pitch-button"}
                  type="white"
                  title="Contact"
                  onClick={onClick}
                />        
              </div>
            </div>

            <div className="company-card__image-container">
              <figure className="company-card__company-image-container">
                <div className="company-card__company-image-wrapper">
                  <img src={
                    companyImages
                      ? process.env.REACT_APP_GCS_IMAGE_URL + companyImages[1]
                      : placeholder
                  }/>
                </div>
              </figure>
            </div>
            {twoImages && (
              <div className="company-card__image-container">
                <figure className="company-card__company-image-container">
                  <div className="company-card__company-image-wrapper">
                    <img src={
                      companyImages
                        ? process.env.REACT_APP_GCS_IMAGE_URL + companyImages[2]
                        : placeholder
                    }/>
                  </div>
                </figure>
              </div>
            )}
            {threeImages && (
              <div className="company-card__image-container">
                <figure className="company-card__company-image-container">
                  <div className="company-card__company-image-wrapper">
                    <img src={
                      companyImages
                        ? process.env.REACT_APP_GCS_IMAGE_URL + companyImages[3]
                        : placeholder
                    }/>
                  </div>
                </figure>
              </div>
            )}
            {fourImages && (
              <div className="company-card__image-container">
                <figure className="company-card__company-image-container">
                  <div className="company-card__company-image-wrapper">
                    <img src={
                      companyImages
                        ? process.env.REACT_APP_GCS_IMAGE_URL + companyImages[4]
                        : placeholder
                    }/>                    
                  </div>
                </figure>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyCard;
