import React, { useState, useEffect } from "react";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";

import Button from "../atoms/Button";
import FormPopUp from "./FormPopup";
import UserDetailCard from "../molecules/UserDetailCard";
import Spinner from "../atoms/Spinner";

import "../../styles/components/molecules/PopUp.scss";

import FacebookLogo from "../../assets/images/socials/facebook_med.png";
import TwitterLogo from "../../assets/images/socials/twitter-logo-png-twitter-logo-vector-png-clipart-library-518.png";
import LinkedInLogo from "../../assets/images/socials/free-linkedin-icon-130-thumb.png";
import PinterestLogo from "../../assets/images/socials/Pinterest-logo.png";
import firstTestimonialPhoto from "../../assets/images/howItWorks/ElenaKayser.png";
import secondTestimonialPhoto from "../../assets/images/howItWorks/NicolasWijnstekers.png";
import thirdTestimonialPhoto from "../../assets/images/howItWorks/LuizeMihalova.png";
import mailIcon from "../../assets/images/icons/mail_icon.png";
import megosuIcon from "../../assets/images/icons/megosu_icon.png";
import linkedInIcon from "../../assets/images/icons/linkedin_icon.png";


function Popup({
  type,
  visibility,
  handleOnClickClose,
  overlay,
  src,
  popupTitle,
  popupSubtitle,
  popupText,
  popupTextLeft,
  popupTextLight,
  firstButtonTitle,
  firstButtonAction,
  firstButtonType,
  secondButtonTitle,
  secondButtonAction,
  secondButtonType,
  popupType,
  props,
  handleOnChangeAccountSetup,
  profileValues,
  role,
  savedProjectUsers,
  savedProjectUsersLoading,
  projectTitle,
  projectId,
  projectImageUrl,
  projectShareUrl,
  transition,
  preTransitionImage,
  data
}) {
  const [popupTransition, setPopupTransition] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;
  const shareText = "Look at this exciting project I found on Megosu ✨";
  const UrlLength = 500;
  
  useEffect(() => {
    if (visibility) {
      setTimeout(() => {
        setPopupTransition(true);
      }, 50);
    }
  }, [visibility]);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function copyTextToClipBoard() {
    // Get the text field 
    var copyText = document.getElementById("social-url-container");
  
    // Select the text field 
    copyText.select();
    copyText.setSelectionRange(0, UrlLength); // For mobile devices 
  
    // Copy the text inside the text field 
    navigator.clipboard.writeText(copyText.value);
  }

  function openWebLink(url) {
    if (url.includes("https://")) { 
      window.open(url, "_blank"); 
    } else {
      window.open("https://" + url, "_blank");
    }
  }

  function presetEmail(mail) {
    var email = mail;
    var subject = "My design for [COMPANY NAME]";
    var emailBody = "- - - THIS IS AN EXAMPLE TEXT - - - %0D%0A %0D%0A %0D%0A" +
    "Dear [NAME / COMPANY NAME], %0D%0A %0D%0A" +
    "I would like to present my project [PROJECT NAME] in the hopes to produce it at [COMPANY NAME] I’m an all-time fan of [COMPANY NAME] and believe that my project shares similarities with your daring collection and items such as [PRODUCT NAME FROM COMPANY]. Here’s a link to the project: [URL MEGOSU PROJECT] %0D%0A %0D%0A" +
    "[IMAGE PROJECT] %0D%0A %0D%0A" + 
    "Let me know if I can send you more details or if you have any questions at the moment. I would love to elaborate this via a call anytime soon. %0D%0A %0D%0A" +
    "Kindest Regards, %0D%0A" +
    "[YOUR NAME] %0D%0A %0D%0A %0D%0A" + 
    "[YOUR CONTACT INFO] %0D%0A"; 
    return "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
  }

  return (
    <>
      {type === "small" ? (
        <div className="popup--small"></div>
      ) : (
        <>
          {visibility && (
            <div className="popup">
              <div
                className={`${
                  overlay ? "popup__container--overlay" : "popup__container"
                }`}
              >
                <div 
                  className={`popup__elements-container 
                    ${popupType === "showSavedProjectsUsers" && "popup__elements-container--less-width"} 
                    ${popupTransition ? popupType !== "showSavedProjectsUsers" ? "popup__elements-container-visible" : "popup__elements-container--less-width-visible" : ""}
                    ${(popupType === "sign-up" || popupType === "login") && "popup__elements-container--less-height"}
                    ${(popupType === "community" || popupType === "contact-company") && "popup__elements-container--more-width"}`}
                  onClick={(e) => { e.stopPropagation(); }}
                >
                  <VscChromeClose
                    className="popup__close-icon"
                    onClick={() => { handleOnClickClose(); setPopupTransition(false); }}
                  />
                  {popupType === "showSavedProjectsUsers" ? (
                    <div className="popup-form--less-width popup-form__user-details-grid">
                      <div className="popup-form__user-details-column">
                        <h1 className="popup-form__user-details-title">Views</h1>
                        <hr className="popup-form__user-details-break-line"/>
                        <p className="popup-form__user-details-subtitle">{data}</p>

                        <h1 className="popup-form__user-details-title">Saves</h1>
                        <hr className="popup-form__user-details-break-line"/>
                        <p className="popup-form__user-details-subtitle">{savedProjectUsers.length}</p>
                      </div>

                      <div className="popup-form__user-details-column">
                        <h1 className="popup-form__user-details-title">{popupTitle}</h1>
                        <hr className="popup-form__user-details-break-line"/>

                        {!savedProjectUsersLoading ? (
                          <>
                            <ul className={`${role === "free-user" ?
                              "popup-form__user-details-list--no-scroll" : "popup-form__user-details-list"}`}
                            >
                              {savedProjectUsers && savedProjectUsers.map((user) => (
                                <li className="popup-form__user-details-container" key={user.profileId}>
                                  <UserDetailCard
                                    name={user.firstName + " " + user.lastName}
                                    city={user.city}
                                    country={user.country.name}
                                    profileImage={user.imageUrl}
                                    role={user.user.role.name}
                                    uuid={user.user.uuid} 
                                    type="popup-content"
                                  />
                                </li>
                              ))}
                            </ul>

                            {firstButtonTitle && role === "free-user" ? (
                              <>
                                <div className="popup-form__user-details-fade-out">
                                  <h1 className="popup-form__user-details-text">Upgrade your account to see everyone that saved your project.</h1>

                                  <div className="popup-form__button-container" style={{ width: "auto" }}>
                                    <Button
                                      type={firstButtonType ? firstButtonType : "white"}
                                      title={firstButtonTitle}
                                      onClick={firstButtonAction} />
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </>
                        )
                          :
                          (
                            <Spinner type="megosu" />
                          )}
                      </div>
                    </div>
                  ) : popupType === "share" ? (
                    <div>
                      <h1 className="popup-form__subtitle">{popupTitle}</h1>  
                      <div className="popup-form__social-grid-container">   
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${"https://www.megosu.com/project-details/" + projectId}&quote=${shareText}`} target="_blank" rel="noopener noreferrer">
                          <img src={FacebookLogo}>
                          </img>
                        </a>         
                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${"https://www.megosu.com/project-details/" + projectId}&title=${projectTitle}on Megosu&summary=${shareText}&source=Megosu`} target="_blank" rel="noopener noreferrer">
                          <img src={LinkedInLogo}></img>
                        </a>    
                        <a href={`https://twitter.com/intent/tweet?text=${shareText}&url=${"https://www.megosu.com/project-details/" + projectId}`} target="_blank" rel="noopener noreferrer">
                          <img src={TwitterLogo}></img>
                        </a>    
                        <a href={`https://pinterest.com/pin/create/button/?url=${"https://www.megosu.com/project-details/" + projectId}&media=${projectImageUrl}&description=${shareText}`} target="_blank" rel="noopener noreferrer">
                          <img src={PinterestLogo}></img>
                        </a>
                      </div>
                      <h6 className="popup-form__social-copy-link">Or copy link</h6>  
                      <input className="popup-form__social-url-container" id="social-url-container" value={projectShareUrl ? projectShareUrl : window.location}></input>
                      <Button
                        type="black"
                        title="Copy"
                        onClick={(e) => { copyTextToClipBoard(); }}
                        customStyle={{ marginLeft: "0.625rem", width: "20%", padding: "0.5rem"}}
                      />
                    </div>
                  ) : popupType === "community" ? (
                    <div className="popup-community">
                      <div className="popup-community__container">
                        <div className="popup-community__testimonial">
                          <img className="popup-community__user-image" src={firstTestimonialPhoto}></img>
                          <div className="popup-community__testimonial-text-block">
                            <h5 className="popup-community__name-title">Elena Kayser - Germany</h5>
                            <p className="popup-community__paragraph">
                            &quot;This is exactly the kind of platform I&apos;m missing right now. Thank you for 
                            facilitating such an exchange.&quot;
                            </p>
                          </div>
                        </div>

                        <div className="popup-community__testimonial">
                          <img className="popup-community__user-image" src={secondTestimonialPhoto}></img>
                          <div className="popup-community__testimonial-text-block">
                            <h5 className="popup-community__name-title">Nicolas Wijnstekers - Ireland</h5>
                            <p className="popup-community__paragraph">
                            &quot;It&apos;s great to see Megosu providing a platform like this and I&apos;m really happy to be
                            a part of it!&quot;
                            </p>
                          </div>
                        </div>

                        <div className="popup-community__testimonial">
                          <img className="popup-community__user-image" src={thirdTestimonialPhoto}></img>
                          <div className="popup-community__testimonial-text-block">
                            <h5 className="popup-community__name-title">Luïze Mihalova - Latvia</h5>
                            <p className="popup-community__paragraph">
                            &quot;I have the feeling I&apos;m sharing thoughts with friends... And, visiting this website
                            is starting to become a daily ritual! {":)"}&quot;
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="popup-community__containers">
                        <div className="popup-community__divider"></div>
                      </div>

                      <div className="popup-community__container">
                        <h2 className="popup-community__join-title">Join our community</h2>
                        <div className="popup-community__list-container">
                          <ul className="popup-community__list">
                            <li>Discuss numerous design topics.</li>
                            <li>Receive feedback on your portfolio.</li>
                            <li>Free Social Media course {"(worth 90€)"}.</li>
                            <li>Be connected with designers worldwide.</li>
                          </ul>
                        </div>

                        <Button
                          customClassName={"popup-community__pro-button"}
                          type={firstButtonType ? firstButtonType : "white"}
                          title={firstButtonTitle}
                          onClick={firstButtonAction}
                        />
                        <p className="popup-community__pro-price">Just 4€/month</p>
                      </div>
                    </div>
                  ) : popupType === "contact-company" ? (
                    <div className="popup-company-contact">
                      <div className="popup-company-contact__container">
                        <div className="popup-company-contact__title-container">
                          <h3 className="popup-company-contact__title">Contact {data.name}</h3>
                          <p className="popup-company-contact__date">
                            <>
                              {"Latest update on " + new Intl.DateTimeFormat("en-GB", { 
                                month: "long", 
                                day: "2-digit",
                                year: "numeric", 
                              }).format(new Date(data.updatedAt))}
                            </>
                          </p>
                        </div>

                        {data.websiteUrl && (
                          <div className="popup-company-contact__subtitle-container">
                            <p className="popup-company-contact__subtitle" onClick={() => { openWebLink(data.websiteUrl); }}>&#8594; {data.websiteUrl}</p>
                          </div>
                        )}

                        {data.contacts.length === 0 && !data.notes && (
                          <p className="popup-company-contact__notes">
                            No information available at this time.
                          </p>
                        )}

                        {data.contacts.length > 0 && (
                          <div className="popup-company-contact__content-container">
                            <h6 className="popup-company-contact__content-title">Contact Persons</h6>
                        
                            <div className="popup-company-contact__inputs-container">
                              {data.contacts.map((elem, i) => (
                                (
                                  <div className="popup-company-contact__person-box" key={i}>
                                    <div className="popup-company-contact__icons-container">
                                      {elem.megosuProfileUrl !== "" && ( 
                                        <img className="popup-company-contact__input-image" onClick={() => { window.open(elem.megosuProfileUrl, "_blank"); }} src={megosuIcon}></img>
                                      )}

                                      {elem.linkedInUrl !== "" && (
                                        <img className="popup-company-contact__input-image" onClick={() => { window.open(elem.linkedInUrl, "_blank"); }} src={linkedInIcon}></img>
                                      )}

                                      {elem.email !== "" && (
                                        <img className="popup-company-contact__input-image" onClick={() => { window.open(presetEmail(elem.email), "_blank"); }}  src={mailIcon}></img>
                                      )}
                                    </div>
                                    <div className="popup-company-contact__input-container">
                                      <p className="popup-company-contact__input-text">{elem.functionName}</p>
                                    </div>
                                  </div>
                                )
                              ))}
                            </div>
                          </div>
                        )}
                        
                        {data.notes && (
                          <div className="popup-company-contact__content-container">
                            <h6 className="popup-company-contact__content-title">Company Notes</h6>
                            <p className="popup-company-contact__notes">
                              {data.notes}
                            </p>
                          </div>
                        )}
                        
                        <div className="popup-company-contact__button-container">
                          <Button
                            customClassName={"popup-company-contact__button"}
                            type="white"
                            title={firstButtonTitle}
                            onClick={firstButtonAction}
                          />
                        </div>
                      </div>
                    </div>
                  ) : popupType ? (
                    <FormPopUp
                      popupType={popupType}
                      props={props}
                      handleOnClickClose={handleOnClickClose}
                      profileValues={profileValues}
                      handleOnChangeAccountSetup={handleOnChangeAccountSetup}
                      firstButtonAction={firstButtonAction}
                      secondButtonAction={secondButtonAction}
                    />
                  ) : (
                    <div className="popup-form">
                      {src ? !transition ? (
                        <img
                          src={src}
                          className="popup-form__feature-image"
                          alt={src}
                        />
                      ) : (
                        <div>
                          <img
                            src={preTransitionImage}
                            className={isVideoLoaded ? "popup-form__feature-image popup-form__feature-image--hidden" : "popup-form__feature-image"}
                          />
                          {!isMobile && (
                            <video 
                              loop 
                              autoPlay
                              className={isVideoLoaded ? "popup-form__feature-image" : "popup-form__feature-image popup-form__feature-image--hidden"}
                              onLoadedData={() => { !isMobile && setIsVideoLoaded(true); }}
                            >
                              <source src={src} type="video/mp4" />
                            </video>
                          )}
                        </div>
                      ): null}
                      <h1 className={"popup-form__title"}>{popupTitle}</h1>
                      {popupSubtitle && (
                        <h2 className="popup-form__subtitle">{popupSubtitle}</h2>
                      )}
                      {popupText && (
                        <p className={"popup-form__text"}>{popupText}</p>
                      )}
                      {popupTextLeft && (
                        <p className="popup-form__text-left">{popupTextLeft}</p>
                      )}
                      {popupTextLight && (
                        <p className="popup-form__text-light">{popupTextLight}</p>
                      )}
                      {firstButtonTitle ? (
                        <div className="popup-form__button-container">
                          <Button
                            type={firstButtonType ? firstButtonType : "white"}
                            title={firstButtonTitle}
                            onClick={firstButtonAction}
                          />
                          {secondButtonTitle ? (
                            <Button
                              type={secondButtonType ? secondButtonType : "black"}
                              title={secondButtonTitle}
                              onClick={secondButtonAction}
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Popup;
