import React, { useEffect, useState } from "react";
import $ from "jquery";

import Button from "../atoms/Button";
import ImageUploader from "../atoms/ImageUploader";

import { uploadHeader } from "../../services/ImageService";
import { deleteHeader, saveHeader, updateHeader } from "../../services/AdminService";

import "../../styles/components/molecules/AdminHeaderForm.scss";

const initialValues = {
  title: "",
  subtitle: "",
  buttonUrl: "",
  buttonText: ""
};

function AdminHeaderForm(
  { 
    number,
    header,
    type
  }) {

  const [values, setValues] = useState(initialValues);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (!editing) {
      if (header != undefined) {
        setValues(header);
      } else {
        setValues(initialValues);
      }
    }
  });

  function handleOnChange(e) {
    setEditing(true);
    const { name, value } = e.target;

    switch (name) {
      default:
        setValues({
          ...values,
          [name]: value,
        });
        break;
    }
  }

  async function uploadImage(headerId) {
    const imageInput = $(`.image-uploader__form .image-uploader__input-${number + 1}`)[0].files[0];
    const mobileImageInput = $(`.image-uploader__form .image-uploader__input-${number + 1}`)[1].files[0];

    if (imageInput instanceof File || imageInput instanceof Blob) {

      const formData = new FormData();
      const file = new File([imageInput], "desktop");
      formData.append("desktopImage", file);

      const compressedData = new FormData();
      const smallImageFile = new File([mobileImageInput], "mobileimage");
      formData.append("mobileImage", smallImageFile);

      return uploadHeader(headerId, number, type, formData, compressedData, imageInput.type).then(() => {
        console.log("image uploaded!");
      });
    }
  }

  const onSubmit = () => {
    if (header == null) {
      values.type = type;
      saveHeader(values).then((res) => {
        setValues(res);

        uploadImage(res.id);

        window.alert("Header was saved succesfully!");
      });
    } else {
      values.id = header.id;
      updateHeader(header.id, values).then((res) => {
        setValues(res);

        uploadImage(res.id);
        
        window.alert("Header was updated succesfully!");
      });
    }

    window.scrollTo(0, 0);
  };

  const removeHeader = () => {
    if (header == null) {
      return;
    } else {
      deleteHeader(header.id);
      window.location.reload();
    }
  };

  return (
    <div>
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <h3 className="admin-header-form__title">Header {number}</h3>
        <div className="admin-header-form__container">
          <div className="admin-header-form__content-container">
            <h4 className="admin-header-form__sub-title">Upload Images</h4>
            <div className="admin-header-form__image-uploader">
              <h4 className="admin-header-form__mid-title">Desktop</h4>
              {header != null ? (
                <ImageUploader
                  key={0}
                  index={number + 1}
                  removable={true}
                  usesCropper={false}
                  multiple={false}
                  currentImage={
                    header.desktopImageUrl
                      ? process.env.REACT_APP_GCS_IMAGE_URL +
                      header.desktopImageUrl
                      : undefined
                  }
                  allowWebP={true}
                />
              ) : (
                <ImageUploader
                  key={0}
                  index={number + 1}
                  removable={true}
                  usesCropper={true}
                  multiple={false}
                  allowWebP={true}
                />
              )}
            </div>
            <div className="admin-header-form__image-uploader admin-header-form__image-uploader--mobile">
              <h4 className="admin-header-form__mid-title">Mobile</h4>
              {header != null ? (
                <ImageUploader
                  key={1}
                  index={number + 1}
                  removable={true}
                  usesCropper={false}
                  multiple={false}
                  currentImage={
                    header.mobileImageUrl
                      ? process.env.REACT_APP_GCS_IMAGE_URL +
                      header.mobileImageUrl
                      : undefined
                  }
                  allowWebP={true}
                />
              ) : (
                <ImageUploader
                  key={1}
                  index={number + 1}
                  removable={true}
                  usesCropper={true}
                  multiple={false}     
                  allowWebP={true}             
                />
              )}
            </div>

            <div className="admin-header-form__input-container">
              <label>Header Title:</label>
              <input
                id="header-title"
                className="admin-header-form__input"
                name="title"
                defaultValue={header == null ? "" : header.title}
                onChange={(e) => handleOnChange(e)}
              />
            </div>

            <div className="admin-header-form__input-container">
              <label>Header Subtitle:</label>
              <input
                id="header-subtitle"
                className="admin-header-form__input"
                name="subtitle"
                maxLength={200}
                defaultValue={header == null ? "" : header.subtitle}
                onChange={(e) => handleOnChange(e)}
              />
            </div>

            <div className="admin-header-form__input-container">
              <label>Button Link:</label>
              <input
                id="header-button-url"
                className="admin-header-form__input"
                name="buttonUrl"
                maxLength={200}
                defaultValue={header == null ? "" : header.buttonUrl}
                onChange={(e) => handleOnChange(e)}
              />
            </div>

            <div className="admin-header-form__input-container">
              <label>Button Text:</label>
              <input
                id="header-button-text"
                className="admin-header-form__input"
                name="buttonText"
                maxLength={200}
                defaultValue={header == null ? "" : header.buttonText}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>
          <div className="admin-header-form__button-container">
            <Button 
              type="black"
              customClassName="button--black-form"
              title="Save"
              onClick={() => { onSubmit(); }}
            />
            <Button 
              type="white"
              customClassName="button--white-form"
              title="Delete"
              onClick={() => { removeHeader(); }}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AdminHeaderForm;
