import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import popupProImage from "../assets/images/popupImages/transitions/popupTransitionPro.m4v";
import preTransitionPopupProImage from "../assets/images/popupImages/PopupProAccount.png";

import Popup from "../components/molecules/PopUp";
import FileCard from "../components/molecules/FileCard";
import DocumentHead from "../components/atoms/DocumentHead";

import { getSiteFiles, getToolCategories } from "../services/SiteService";
import { getCurrentUser } from "../services/AuthService";
import { getUser } from "../services/UserService";
import scrollTrigger from "../util/ScrollReveal";

import "../styles/pages/FilesPage.scss";
import { HashLink } from "react-router-hash-link";

const FilesPage = () => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const [upgradePopup, setUpgradePopup] = useState(false);
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false);
  const [toolCategories, setToolCategories] = useState([]);
  const [labelsFixed, setLabelsFixed] = useState(false);
  const [categorizedTools, setCategorizedTools] = useState([]);
  const [uncategorizedTools, setUnCategorizedTools] = useState([]);

  const LABELS_STICK_POSITION = 200;

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);

  useEffect(() => {
    if (!currentUser) {
      const email = getCurrentUser();
  
      if (email) {
        getUser(email).then((user) => {
          if (user) {
            setCurrentUser(user);
          }
        });
      }
    }

    getToolCategories().then((categories) => {
      setToolCategories(categories);

      getSiteFiles().then((files) => {
        const categorized = categories.map((elem) => {
          return {category: elem, files: []};
        });

        categorized.forEach((categoryObject) => {
          files.forEach((file) => {
            if (file.categories.length > 0) {
              const fileCategories = file.categories;
              fileCategories.forEach((fileCategory) => {
                if (fileCategory.name === categoryObject.category.name) {
                  categoryObject.files.push(file);
                }
              });
            }
          });
        });

        setCategorizedTools(categorized.filter((categoryObj) => categoryObj.files.length > 0));

        const uncategorized = files.filter((file) => file.categories.length === 0);
        setUnCategorizedTools(uncategorized);
      });
    });

    window.addEventListener("scroll", handlePageScroll);

    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, []);

  const handlePageScroll = () => {
    if (window.scrollY > LABELS_STICK_POSITION) {
      setLabelsFixed(true);
    } else {
      setLabelsFixed(false);
    }
  };

  const scrollWithOffset = (name, yOffset) => {
    const yCoordinate = document.getElementById(name).getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <div className="files reveal">
        <DocumentHead
          title="Tools | Megosu"
          metaDescription="The leading and most inspiring platform for talented product designers worldwide."
        />
        <div className="files__content">
          <div className="files__title-container">
            <h2 className="files__title">Tools, Templates, Tips & Tricks</h2>
            <p className="files__subtitle">Discover highly useful Design Resources & Tools</p>
            <p className="files__intro">
            Over the years we&apos;ve learned quite the few things about design. Whether it&apos;s about tools or
            contracts- <br className="files__line-break"/> or just plain and simple inspiration. Now it&apos;s time to share some of our learnings.
            </p>
          </div>
        </div>

        <div className={`files__categories ${labelsFixed && "files__categories--backdrop"}`}>
          <div className="files__categories-set">
            <div className="files__categories-labels-container">
              {toolCategories.map((elem, i) => (
                <div key={elem.id} className="files__categories-label-container">
                  <HashLink smooth={true} to={`#${elem.name}`} scroll={() => { scrollWithOffset(elem.name, -250); }}> 
                    <label
                      htmlFor={elem["id"]}
                      className="files__categories-label"
                    >
                      <input
                        type="checkbox"
                        className={`labelset__input labelset-${i}__input`}
                        name={i}
                        value={elem["id"]}
                      />
                      <span className="files__categories-label-name">{elem["name"]}</span>
                    </label>
                  </HashLink>
                </div>
              ))}
            </div>
          </div>   
        </div>

        <div className="files__container reveal">
          {categorizedTools && categorizedTools.map((elem, i) => (
            <>
              <h1 key={i}>{elem.category.name}</h1>

              <div id={elem.category.name} className="files__container-grid">
                {elem.files.map((file) => (
                  <FileCard 
                    key={file.id} 
                    currentUser={currentUser} 
                    file={file} 
                    setUpgradePopup={setUpgradePopup}
                    setSignUpPopup={setSignUpPopUpVisible}
                  />
                ))}
              </div>
            </>
          ))
          }

          {uncategorizedTools.length > 0 && (
            <>
              <h1>Other Tools</h1>
              <div className="files__container-grid">
                {uncategorizedTools.map((elem) => (
                  <>
                    <FileCard 
                      key={elem.id} 
                      currentUser={currentUser} 
                      file={elem} 
                      setUpgradePopup={setUpgradePopup}
                      setSignUpPopup={setSignUpPopUpVisible}
                    />
                  </>
                ))
                } 
              </div>
            </>
          )}
        </div>
      </div>

      <Popup
        visibility={upgradePopup}
        overlay={true}
        handleOnClickClose={() => setUpgradePopup(false)}
        src={popupProImage}
        preTransitionImage={preTransitionPopupProImage}
        transition={true}
        popupTitle="Upgrade to unlock this file."
        firstButtonTitle="Upgrade"
        firstButtonAction={() => { history.push("/pricing"); }}
        firstButtonType="black"
      />

      <Popup 
        visibility={signUpPopUpVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => setSignUpPopUpVisible(false)}
        popupType="sign-up" 
      />

      <Popup 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />
    </>
  );
};

export default FilesPage;
