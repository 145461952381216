import { AuthHeader } from "../util/AuthHeader";
import CryptoJS from "crypto-js";

export function register(user) {
  const postUserUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/save";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(user),
  };

  return fetch(postUserUrl, options);
}

export async function getUser(email) {
  const getUserUrl = process.env.REACT_APP_API_BASE_URL + "/users/" + email;

  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return await fetch(getUserUrl, options)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    });
}

export async function resetPassword(token, newPassword) {
  newPassword = CryptoJS.AES.encrypt(JSON.stringify(newPassword), CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_SK), {iv: CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_IV)}).toString();

  const resetPasswordUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/reset-password?token=" + token;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: newPassword,
  };
  return await fetch(resetPasswordUrl, options);
}

export function changePassword(passwords) {
  const changePasswordUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/change-password";
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(passwords),
  };
  return fetch(changePasswordUrl, options);
}

export function changeEmail(newMail, password) {
  const changeEmailUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/change-email";

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(newMail, password),
  };

  return fetch(changeEmailUrl, options);
}

export function deleteUser() {
  const deleteUserUrl = process.env.REACT_APP_API_BASE_URL + "/users/delete";
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
  };
  return fetch(deleteUserUrl, options);
}

export function getUserByCustomerId(customerId) {
  const getUserByCustomerIdUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/customer/" + customerId;
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getUserByCustomerIdUrl, options)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function getUserById(id) {
  const getUserByIdUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/" + id + "/user";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getUserByIdUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      return data;
    });
}

export function getUserPreviewById(id) {
  const getUserByIdUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/" + id + "/preview";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(getUserByIdUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return null;
      }
    })
    .then((data) => {
      return data;
    });
}


export function getUserId(email) {
  const getUserUrl = process.env.REACT_APP_API_BASE_URL + "/users/" + email;
  const options = {
    method: "GET",
    headers: {
      Authorization: AuthHeader(),
    },
  };

  return fetch(getUserUrl, options)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        console.log("Requested failed!");
      }
    })
    .then((data) => {
      return data;
    });
}

export function verifyUser(token) {
  const getUserByIdUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/verify?token=" + token;
  const options = {
    method: "PUT",
  };

  return fetch(getUserByIdUrl, options);
}

export function migrateOAuth2Account(newMail, password) {
  const changeAccountUrl =
    process.env.REACT_APP_API_BASE_URL + "/users/migrate-oauth2";

  password = CryptoJS.AES.encrypt(JSON.stringify(password), CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_SK), {iv: CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_IV)}).toString();
  
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify({newMail: newMail, password: password}),
  };

  return fetch(changeAccountUrl, options);
}

const exportedFunctions = {
  register,
  getUser,
  changePassword,
  changeEmail,
  getUserByCustomerId,
  getUserId,
  getUserById
};

export default exportedFunctions;
