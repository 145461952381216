export function AuthHeader() {
  const currentUser = localStorage.getItem("currentUser");

  if (currentUser) {
    return `Bearer ${currentUser}`;
  }
}

export function AuthHeaderRefresh() {
  const refreshToken = localStorage.getItem("refreshToken");

  if (refreshToken) {
    return `Bearer ${refreshToken}`;
  }
}

const exportedFunctions = { AuthHeader, AuthHeaderRefresh };

export default exportedFunctions;
