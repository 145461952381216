import React, { useEffect, useState } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FaArrowCircleRight } from "@react-icons/all-files/fa/FaArrowCircleRight";

import Button from "../components/atoms/Button";
import HowItWorksTestimonials from "../components/molecules/HowItWorksTestimonials";
import SubscriptionForm from "../components/molecules/SubscriptionForm";
import Popup from "../components/molecules/PopUp";
import DocumentHead from "../components/atoms/DocumentHead";

import customerPopupImage from "../assets/images/popupImages/transitions/popupTransitionUpgrade.m4v";
import preTransitionCustomerPopupImage from "../assets/images/popupImages/UpgradeProPopup.png";
import howItWorksHeader from "../assets/images/howItWorks/Megosu---Thomas-Misse.jpg";
import moooi from "../assets/images/howItWorks/Megosu - Moooi.jpg";
import luisGimeno from "../assets/images/howItWorks/Megosu - Luis Gimeno.jpeg";
import falstaff from "../assets/images/howItWorks/Megosu---Falstaff.jpg";
import megosuCommunity from "../assets/images/howItWorks/Megosu-Community.jpg";
import f1 from "../assets/images/howItWorks/f1.jpg";
import f2 from "../assets/images/howItWorks/f2.jpg";
import f3 from "../assets/images/howItWorks/f3.jpg";
import f4 from "../assets/images/howItWorks/f4.jpg";
import f5 from "../assets/images/howItWorks/f5.jpg";
import f6 from "../assets/images/howItWorks/f6.jpg";
import businessHeader from "../assets/images/howItWorks/Megosu-Original.jpg";
import kiddo from "../assets/images/howItWorks/Megosu-Compressed-.jpg";
import natachaSacha from "../assets/images/howItWorks/natachaetsachaa-Original.jpg";
import booster from "../assets/images/howItWorks/Renata-Souza-1.jpg";
import tobiasTrubenbacher from "../assets/images/howItWorks/Tobias-Truebenbacher.jpg";
import { testimonials, testimonialsMobile, testimonialsTablet, businessTestimonials, businessTestimonialsMobile } from "../assets/data/DataList";

import scrollTrigger from "../util/ScrollReveal";

import "../styles/pages/HowItWorksPage.scss";

const HowItWorksPage = ({ currentUser }) => {
  const [customerPopup, setCustomersPopup] = useState(false);
  const [communityPopup, setCommunityPopup] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const isTablet = width <= 1024;
  const history = useHistory();

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);


  const scrollWithOffset = (el, yOffset) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const sendEmail = () => {
    window.location = "mailto:partners@megosu.com";
  };

  const privateCommunity = () => {
    if (currentUser) {
      if (currentUser.role.name === "free-user") {
        setCommunityPopup(true);
      } else {
        window.open("https://community.megosu.com/users/sign_in", "_blank");
      }
    } else {
      history.push("/login");
    }
  };

  return (
    <div className="how-it-works">
      <DocumentHead
        title="How it works | Megosu"
        showChat={true}
      />
      <div
        style={{
          backgroundImage: `url(${howItWorksHeader})`
        }}
        className="how-it-works__header-image reveal"
      >
        <p className="how-it-works__title">How it works</p>
        <div className="how-it-works__container how-it-works__navigation">
          <div className="how-it-works__navigation-container">
            <FaArrowCircleRight />
            <HashLink smooth to="#for-designers" scroll={el => scrollWithOffset(el, -85)}>
              <p className="how-it-works__item">For designers</p>
            </HashLink>
          </div>
          <div className="how-it-works__navigation-container">
            <FaArrowCircleRight />
            <HashLink smooth to="#for-brands-overview" scroll={el => scrollWithOffset(el, -85)}>
              <p className="how-it-works__item">For brands</p>
            </HashLink>
          </div>
        </div>
      </div>

      <div id="for-designers" className="how-it-works__for reveal">
        <p className="how-it-works__for-title">For designers</p>
        <div className="how-it-works__info-container how-it-works__steps">
          <div className="how-it-works__container how-it-works__step-align">
            <HashLink smooth to="#product-to-market" scroll={el => scrollWithOffset(el, -125)}>
              <p className="how-it-works__step">1. Product to Market</p>
            </HashLink>
            <HashLink smooth to="#find-a-job" scroll={el => scrollWithOffset(el, -125)}>
              <p className="how-it-works__step">2. Find a Job</p>
            </HashLink>
          </div>
          <div className="how-it-works__container how-it-works__step-align">
            <HashLink smooth to="#sell-your-work" scroll={el => scrollWithOffset(el, -125)}>
              <p className="how-it-works__step">3. Sell your work</p>
            </HashLink>
            <HashLink smooth to="#community-network" scroll={el => scrollWithOffset(el, -125)}>
              <p className="how-it-works__step">4. Community network</p>
            </HashLink>
          </div>
        </div>
      </div>

      <div className="how-it-works__info-container how-it-works__first-step reveal" id="product-to-market">
        <figure className="how-it-works__feature-image-container">
          <div className="how-it-works__feature-image-wrapper">
            <img src={moooi}/>
            <figcaption>Lukas Bazle, now in production at <div className="how-it-works__inline-bold">Moooi.</div> </figcaption>
          </div>
        </figure>
        <div className="how-it-works__first-step-text">
          <p className="how-it-works__step-title">1. Product to Market</p>
          <p className="how-it-works__step-text how-it-works__product-text">Are you looking to get your designs produced in close collaboration with acknowledged design companies?</p>
          <br/>
          <p className="how-it-works__product-text">It&apos;s easy:</p>
          <p className="how-it-works__market-step"><div className="how-it-works__inline-bold">1.</div> Upload your designs- the more the merrier.</p>
          <p className="how-it-works__market-step"><div className="how-it-works__inline-bold">2.</div> Promote yourself and wait for companies to get in touch with you.</p>
          <p className="how-it-works__market-step"><div className="how-it-works__inline-bold">3.</div> Settle an agreement for the partnership.</p>
          <p className="how-it-works__market-step"><div className="how-it-works__inline-bold">4.</div> Work towards production to market the product.</p>
          <div className="how-it-works__button-container">
            <NavLink to="/upload-project" className="how-it-works__button-link">
              <Button type="black" title="Upload" />
            </NavLink>
            <p className="how-it-works__button-text">or</p>
            <NavLink to="/success-stories/moooi" className="how-it-works__button-link">
              <Button type="white" title="Success stories" />
            </NavLink>
          </div>
        </div>
      </div>

      <div className="how-it-works__info-container how-it-works__image-step-container reveal">
        <div className="how-it-works__image-step-container-steps">
          <div className="how-it-works__container how-it-works__step-container">
            <p className="how-it-works__image-title">1. Upload</p>
            <figure className="how-it-works__step-image-container">
              <div className="how-it-works__step-image-wrapper">
                <img src={f1}/>
              </div>
            </figure>
            <p className="how-it-works__step-image-text how-it-works__small">The original design of Lukas Bazle.</p>
          </div>
          <div className="how-it-works__container how-it-works__step-container">
            <p className="how-it-works__image-title">2. Connect</p>
            <figure className="how-it-works__step-image-container">
              <div className="how-it-works__step-image-wrapper">
                <img src={f2}/>
              </div>
            </figure>
            <p className="how-it-works__step-image-text">Lukas Bazle was contacted by several interested brands.</p>
          </div>
        </div>
        <div className="how-it-works__image-step-container-steps">
          <div className="how-it-works__container how-it-works__step-container">
            <p className="how-it-works__image-title">3. Setup Partnership</p>
            <figure className="how-it-works__step-image-container">
              <div className="how-it-works__step-image-wrapper">
                <img src={f4}/>
              </div>
            </figure>
            <p className="how-it-works__step-image-text">Lukas altered the design to make it more fitting for <div className="how-it-works__inline-bold">Moooi.</div></p>
          </div>
          <div className="how-it-works__container how-it-works__step-container">
            <p className="how-it-works__image-title">4. Bring it to market</p>
            <figure className="how-it-works__step-image-container">
              <div className="how-it-works__step-image-wrapper">
                <img src={f6}/>
              </div>
            </figure>
            <p className="how-it-works__step-image-text">The finalized version is now for sale at <div className="how-it-works__inline-bold">Moooi.</div></p>
          </div>
        </div>
      </div>

      <div className="how-it-works__info-container how-it-works__step-2 reveal" id="find-a-job">
        <div className="how-it-works__step-2-text">
          {!isMobile && <p className="how-it-works__step-2-designer-title">More design dreams we help fulfill.</p>}
          <div>
            <p className="how-it-works__step-title">2. Find a job</p>
            <p className="how-it-works__step-text">Are you looking for an open vacancy? We got you covered. With 25+ jobs added every month you&apos;ll definitely come across your dream job.</p>
            <a href="https://jobs.megosu.com/">
              <Button type="white" title="Browse our job board"/>
            </a>
          </div>
        </div>
        <figure className="how-it-works__feature-image-container">
          <div className="how-it-works__feature-image-wrapper">
            <img src={luisGimeno}/>
            <figcaption>Luis found a job at <div className="how-it-works__inline-bold">Object Embassy</div> </figcaption>
          </div>
        </figure>
        {isMobile && (<p className="how-it-works__step-2-designer-title">More design dreams we help fulfill</p>)}
      </div>

      <div className="how-it-works__info-container how-it-works__step-3 reveal" id="sell-your-work">
        <figure className="how-it-works__feature-image-container">
          <div className="how-it-works__feature-image-wrapper">
            <img src={falstaff}/>
            <figcaption>Leandra Eibl co-designed this chair for <div className="how-it-works__inline-bold">Goods and Bads</div> </figcaption>
          </div>
        </figure>
        <div className="how-it-works__step-3-text">
          <p className="how-it-works__step-title">3. Sell your work</p>
          <p className="how-it-works__step-text">Whether you&apos;re looking for a place to sell your studio, work or designs that are in production with brands- Megosu offers you an outlet to meet designloving consumers.</p>
          <Button type="white" title="Find customers" onClick={() => { setCustomersPopup(true); }}/>
        </div>
      </div>

      <div className="how-it-works__info-container how-it-works__step-4 reveal" id="community-network">
        <div className="how-it-works__step-4-text">
          <p className="how-it-works__step-title">4. Community network</p>
          <p className="how-it-works__step-text">In our private community you&apos;ll connect with like-minded designers to establish a network, talk about design topics and receive feedback on your projects. Get access to exclusive guides to learn more about design and take that extra step to grow as a designer.</p>
          <Button type="white" title="Join private community" onClick={() => { privateCommunity(); }}/>
        </div>
        <figure className="how-it-works__feature-image-container">
          <div className="how-it-works__feature-image-wrapper">
            <img src={megosuCommunity}/>
            <figcaption>Some of the members of our private community </figcaption>
          </div>
        </figure>
      </div>
      {!isMobile
        ? !isTablet
          ? <HowItWorksTestimonials isTablet={isTablet} title="Curious to know what people got to say?" testimonials={testimonials}/>
          : <HowItWorksTestimonials isTablet={isTablet} title="Curious to know what people got to say?" testimonials={testimonialsTablet} />
        : <HowItWorksTestimonials isTablet={isTablet} title="Curious to know what people got to say?" testimonials={testimonialsMobile} />
      }

      <p id="for-brands"/>
      <div
        style={{
          backgroundImage: `url(${businessHeader})`
        }}
        className="how-it-works__header-image how-it-works__business-header-image reveal"
      >
        <p className="how-it-works__title how-it-works__business-title">Find the best design projects worldwide!</p>
        <Link to="/">
          <Button type="black" title="Start exploring"/>
        </Link>
      </div>

      <div className="how-it-works__for reveal" id="for-brands-overview">
        <p className="how-it-works__for-title">For brands</p>
        <div className="how-it-works__info-container how-it-works__steps">
          <div className="how-it-works__container how-it-works__step-align">
            <HashLink smooth to="#find-products" scroll={el => scrollWithOffset(el, -125)}>
              <p className="how-it-works__step">1. Find products</p>
            </HashLink>
            <HashLink smooth to="#recruit-designers" scroll={el => scrollWithOffset(el, -125)}>
              <p className="how-it-works__step">2. Recruit designers</p>
            </HashLink>
          </div>
          <div className="how-it-works__container how-it-works__step-align">
            <HashLink smooth to="#inspiration-booster" scroll={el => scrollWithOffset(el, -125)}>
              <p className="how-it-works__step">3. Inspiration booster</p>
            </HashLink>
            <HashLink smooth to="#design-challenges" scroll={el => scrollWithOffset(el, -125)}>
              <p className="how-it-works__step">4. Design challenges</p>
            </HashLink>
          </div>
        </div>
      </div>

      <div className="how-it-works__info-container how-it-works__first-step reveal" id="product-to-market">
        <figure className="how-it-works__feature-image-container">
          <div className="how-it-works__feature-image-wrapper">
            <img src={kiddo}/>
            <figcaption>Shwetha Iyengar &amp; Jaymin Panchasara designed this painting easel. </figcaption>
          </div>
        </figure>
        <div className="how-it-works__first-step-text">
          <p className="how-it-works__step-title">1. Find products</p>
          <p className="how-it-works__step-text how-it-works__product-text">Designers look for opportunties to get their designs produced while companies are always on the look-out for innovative designs. So why not bring them together in one place?</p>
          <br/>
          <p className="how-it-works__product-text">It&apos;s easy:</p>
          <p className="how-it-works__market-step"><div className="how-it-works__inline-bold">1</div>. Start filtering to find your perfect match.</p>
          <p className="how-it-works__market-step"><div className="how-it-works__inline-bold">2.</div> Contact designers and see if there&apos;s mutual interest.</p>
          <p className="how-it-works__market-step"><div className="how-it-works__inline-bold">3.</div> Setup a partnership according to your own terms.</p>
          <p className="how-it-works__market-step"><div className="how-it-works__inline-bold">4.</div> Work towards production to market the product!</p>
          <div className="how-it-works__button-container">
            <NavLink to="/success-stories/moooi" className="how-it-works__button-link">
              <Button type="black" title="Success stories" />
            </NavLink>
            <p className="how-it-works__button-text">or</p>
            <a href="mailto:info@megosu.com" className="how-it-works__button-link">
              <Button type="white" title="Contact us" />
            </a>
          </div>
        </div>
      </div>

      <div className="how-it-works__info-container how-it-works__image-step-container reveal">
        <div className="how-it-works__image-step-container-steps">
          <div className="how-it-works__container how-it-works__step-container">
            <p className="how-it-works__image-title">1. Search &amp; Filter</p>
            <figure className="how-it-works__step-image-container">
              <div className="how-it-works__step-image-wrapper">
                <img src={f5}/>
              </div>
            </figure>
            <p className="how-it-works__step-image-text">Filter on a variety of subjects; e.g. type of product, material, room.</p>
          </div>
          <div className="how-it-works__container how-it-works__step-container">
            <p className="how-it-works__image-title">2. Connect</p>
            <figure className="how-it-works__step-image-container">
              <div className="how-it-works__step-image-wrapper">
                <img src={f2}/>
              </div>
            </figure>
            <p className="how-it-works__step-image-text">Found your perfect project? Start a chat and connect.</p>
          </div>
        </div>
        <div className="how-it-works__image-step-container-steps">
          <div className="how-it-works__container how-it-works__step-container">
            <p className="how-it-works__image-title">3. Setup Partnership</p>
            <figure className="how-it-works__step-image-container">
              <div className="how-it-works__step-image-wrapper">
                <img src={f4}/>
              </div>
            </figure>
            <p className="how-it-works__step-image-text">Partner up under your own terms &amp; conditions- do it designer friendly.</p>
          </div>
          <div className="how-it-works__container how-it-works__step-container">
            <p className="how-it-works__image-title">4. Bring it to market</p>
            <figure className="how-it-works__step-image-container">
              <div className="how-it-works__step-image-wrapper">
                <img src={f3}/>
              </div>
            </figure>
            <p className="how-it-works__step-image-text">One of our success stories, now for sale at <div className="how-it-works__inline-bold">Kartent.</div></p>
          </div>
        </div>
      </div>

      <div className="how-it-works__info-container how-it-works__step-2 reveal" id="recruit-designers">
        <div className="how-it-works__container how-it-works__step-2-text">
          {!isMobile && (<p className="how-it-works__step-2-business-title">More we can help you with</p>)}
          <div>
            <p className="how-it-works__step-title">2. Recruit designers</p>
            <p className="how-it-works__step-text">Are you looking for a designer to join your team? Recruit them yourself by using our filters. Browse on location, experience or availability to meet the designers that fit your profile. Or do you have an open job vacancy ready? Place it on our job board.</p>
            <div className="how-it-works__button-container how-it-works__button-container-step-2">
              <NavLink to="/" className="how-it-works__button-link">
                <Button type="black" title="Use business filters"/>
              </NavLink>
              <p className="how-it-works__button-text">or</p>
              <a href="mailto:info@megosu.com" className="how-it-works__button-link">
                <Button type="white" title="Contact us" />
              </a>
            </div>
          </div>
        </div>
        <figure className="how-it-works__feature-image-container">
          <div className="how-it-works__feature-image-wrapper">
            <img src={natachaSacha}/>
            <figcaption>Natacha.Sacha. is a design duo from Paris </figcaption>
          </div>
        </figure>
        {isMobile && (<p className="how-it-works__step-2-business-title">More we can help you with</p>)}
      </div>

      <div className="how-it-works__info-container how-it-works__step-3 reveal" id="inspiration-booster">
        <figure className="how-it-works__feature-image-container">
          <div className="how-it-works__feature-image-wrapper">
            <img src={booster}/>
            <figcaption>Renata Souza makes life easier for children with diabetes </figcaption>
          </div>
        </figure>
        <div className="how-it-works__step-3-text">
          <p className="how-it-works__step-title">3. Inspiration booster</p>
          <p className="how-it-works__step-text">Sometimes you&apos;re just scrolling around- not particulary with any goal in mind. With hundreds of designs on Megosu, you&apos;ll always find ideas to spark inspiration. Contact designers to ask them for consultancy or setup a meeting with designers to inspire your team with new design stories.</p>
          <Link to="/">
            <Button type="white" title="Give me a shot of inspiration"/>
          </Link>
        </div>
      </div>


      <div className="how-it-works__info-container how-it-works__step-4 reveal" id="design-challenges">
        <div className="how-it-works__step-4-text">
          <p className="how-it-works__step-title">4. Design challenges</p>
          <p className="how-it-works__step-text">With years of experience- and hosting multiple challenges- we know how to organize a contest. We&apos;ve worked with the likes of Braun, HermanMiller and Wacom amongst others. Be our next partner.</p>
          <Link to="#" onClick={() => sendEmail()}>
            <Button type="white" title="Contact us"/>
          </Link>
        </div>
        <figure className="how-it-works__feature-image-container">
          <div className="how-it-works__feature-image-wrapper">
            <img src={tobiasTrubenbacher}/>
            <figcaption>Tobias Trübenbacher was one of the winners of the <div className="how-it-works__inline-bold">BraunPrize</div> </figcaption>
          </div>
        </figure>
      </div>


      <div className="how-it-works__pick-a-plan reveal">
        {!isMobile
          ? <HowItWorksTestimonials isTablet={isTablet} title="What other businesses say" testimonials={businessTestimonials} />
          : <HowItWorksTestimonials isTablet={isTablet} title="What other businesses say" testimonials={businessTestimonialsMobile} />
        }
        <h2 id="pickaplan" className="how-it-works__pick-a-plan-title">Pick a Plan</h2>
        <SubscriptionForm />
      </div>

      <Popup
        visibility={customerPopup}
        handleOnClickClose={() => setCustomersPopup(false)}
        overlay={true}
        src={customerPopupImage}
        preTransitionImage={preTransitionCustomerPopupImage}
        transition={true}
        popupTitle="Find Customers!"
        popupText="Link your webshop to your upload or edit existing uploads under 'edit project'."
        firstButtonType="black"
        firstButtonTitle="Upload"
        firstButtonAction={() => { history.push("/upload-project"); }}
      />

      <Popup
        visibility={communityPopup}
        handleOnClickClose={() => setCommunityPopup(false)}
        popupType="community"
        overlay={true}
        firstButtonType="black"
        firstButtonTitle="Go Pro"
        firstButtonAction={() => { setCommunityPopup(false); history.push("/pricing"); }}
      />
    </div>
  );
};

export default HowItWorksPage;
