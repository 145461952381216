import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { BsSearch } from "@react-icons/all-files/bs/BsSearch";
import { BsArrowCounterclockwise } from "@react-icons/all-files/bs/BsArrowCounterclockwise";
import { AiOutlineClose } from "@react-icons/all-files/ai/AiOutlineClose";

import DesignerFilter from "./DesignerFilter";
import ProjectFilter from "./ProjectFilter";

import { getFilteredProjects, getRelatedProjectsFilter, getFilterTypes } from "../../services/ProjectService.js";
import { filterQueryBuilder } from "../../util/Filters";

import "../../styles/components/molecules/Filter.scss";

function Filter({
  currentUser,
  role,
  setProjects,
  loadedProjectIds,
  setLoadedProjectIds,
  setItems,
  loading,
  setLoading,
  setIndexOfFirstProject,
  setIndexOfLastProject,
  results,
  setResults,
  tag,
  filterCategory,
  setOtherProjects,
  setQuery,
  setFirstIndex,
  setLastIndex,
  projectsPerPage,
  setEndOfProjects,
  setEndOfRelatedProjects,
  setScrollLoadingRelated,
  selectedSortingField
}) {
  const initialSelectedTagValues = {
    material: [],
    product: [],
    production: [],
    space: [],
    topic: [],
    experience: [],
    jobAvailability: [],
    location: [],
    title: [],
    projectAvailability: [],
    goal: [],
    deliverable: [],
  };

  const fields = ["material", "product", "production", "space", "topic"];

  const [designerFilter, setDesignerFilter] = useState(false);
  const [projectFilter, setProjectFilter] = useState(false);
  const [filtersActiveStatus, setFiltersActiveStatus] = useState(false);
  const [selectedTags, setSelectedTags] = useState(initialSelectedTagValues);

  const [removeLocation, setRemoveLocation] = useState("");
  const [resetAllCheckBoxes, setResetAllCheckBoxes] = useState(false);
  const [preselectLocation, setPreselectLocation] = useState([]);
  const [removeGoal, setRemoveGoal] = useState("");
  const [preselectGoal, setPreselectGoal] = useState([]);
  const [removeDeliverable, setRemoveDeliverable] = useState("");
  const [preselectDeliverable, setPreselectDeliverable] = useState([]);

  const [loadingFilter, setLoadingFilter] = useState(false);

  const history = useHistory();
  const MAX_PROJECTS_AMOUNT = 40;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams !== {}) {
      const tagValues = Object.keys(initialSelectedTagValues);
      for (const [key, value] of urlSearchParams) {
        tagValues.map(item => {
          if (item === key) {
            if (item === "location") {
              const splitCountries = value.split(",");
              for (let i = 0; i < splitCountries.length; i++) {
                const countryObject = { name: splitCountries[i] };
                setPreselectLocation((item) => [...item, countryObject]);
              }
            } else if (item === "goal") {
              const splitGoals = value.split(",");
              setPreselectGoal(splitGoals);
            } else if (item === "deliverable") {
              const splitDeliverables = value.split(",");
              setPreselectDeliverable(splitDeliverables);
            }

            addRemoveFilter(value, key, true);
          }
        });
      }
    }

    if (tag && filterCategory) {
      addRemoveFilter(tag, filterCategory, false);
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (event.target[0].value !== "") {
      addRemoveFilter(event.target[0].value, "title", true);
    }

    event.target.reset();
  };

  const addRemoveFilter = async (tag, filterCategory, searchOverwrite) => {
    if (loading === true) {
      // if our filter was still loading whilst the function was called,
      // cancel the current fetch request by firing an event which is listened to by the DOM through the ProjectService
      const evt = new Event("abortFilterFetch");
      document.dispatchEvent(evt);
      setLoadingFilter(false);
    }
    
    setLoading(true);
    setLoadingFilter(true);
    setIndexOfFirstProject(0);
    setIndexOfLastProject(MAX_PROJECTS_AMOUNT);

    if (filterCategory === "projectAvailability") {
      if (!searchOverwrite) {
        selectedTags[filterCategory] === false
          ? (selectedTags[filterCategory] = true)
          : (selectedTags[filterCategory] = true);
      }
    } else if (filterCategory === "reset") {
      setResetAllCheckBoxes(true);
      setFiltersActiveStatus(false);
      setSelectedTags(initialSelectedTagValues);

    } else {
      const selectedTagsCopy = { ...selectedTags };
      const indexOfSelectedFilter = tag !== "" && selectedTagsCopy[filterCategory].indexOf(tag);

      if (indexOfSelectedFilter > -1 || tag === "") {
        if (!searchOverwrite && tag !== "" && filterCategory !== "location" && filterCategory !== "deliverable" && filterCategory !== "goal") {
          selectedTagsCopy[filterCategory].splice(indexOfSelectedFilter, 1);
        } else if (filterCategory === "location" || filterCategory === "deliverable" || filterCategory === "goal") {
          switch (filterCategory) {
            case "location": 
              setRemoveLocation(tag); 
              break;
            case "deliverable":
              setRemoveDeliverable(tag);
              break;
            case "goal":
              setRemoveGoal(tag);
              break;
          }

          selectedTagsCopy[filterCategory].splice(indexOfSelectedFilter, 1);
        } else {
          selectedTagsCopy[filterCategory].splice(0, selectedTagsCopy[filterCategory].length);
        }
      } else {
        if (tag.includes(",")) {
          const splitValue = tag.split(",");
          for (let i = 0; i < splitValue.length; i++) {
            selectedTagsCopy[filterCategory].push(splitValue[i]);
          }
        } else {
          selectedTagsCopy[filterCategory].push(tag);
        }
      }

      setSelectedTags(selectedTagsCopy);
      setFiltersActiveStatus(!Object.values(selectedTagsCopy).every(({ length }) => length ? false : true));
    }

    const queryUrl = filterCategory === "reset" ? filterQueryBuilder(initialSelectedTagValues) : filterQueryBuilder(selectedTags);
    const resultList = [];

    await getFilteredProjects(queryUrl, 0, projectsPerPage, selectedSortingField, loadedProjectIds).then((filteredProjectsList) => {
      if (filteredProjectsList) {
        filteredProjectsList.forEach(project => {
          resultList.push(project.projectId);
        });

        if (selectedSortingField == "Random") {
          const ids = filteredProjectsList.map(project => project.projectId);
  
          setLoadedProjectIds(ids);
        }
        
        setQuery(queryUrl);
        setFirstIndex(0);
        setLastIndex(projectsPerPage);
        setIndexOfLastProject(projectsPerPage);
        
        if (filteredProjectsList.length < projectsPerPage) {
          setEndOfProjects(true);
        } else {
          setEndOfProjects(false);
        }
        setProjects(filteredProjectsList);
        setResults(filteredProjectsList.length === 0 ? 0 : filteredProjectsList[0].amountOfProjects);
        setLoading(false);
        setLoadingFilter(false);
      }
    });
    setScrollLoadingRelated(true);
    getRelatedProjectsFilter(resultList, 0, projectsPerPage).then((list) => {
      if (list) {
        if (list.length < projectsPerPage) setEndOfRelatedProjects(true);
        setOtherProjects(list);
        setScrollLoadingRelated(false);
      }
    });

    history.push({
      pathname: "/",
      search: "?" + new URLSearchParams(queryUrl),
    });
  };

  return (
    <div className="filter">
      <div className="filter__container">
        <div className="filter__search-status">
          <p>
            {(filtersActiveStatus && !loading) && `${results} projects with...`}
          </p>
          <p>
            {Object.keys(selectedTags).map((category, idx) => {
              return Object.values(selectedTags[category]).map((tag, idx) => {
                return (
                  <span key={`tag-${tag}-${idx}`}>
                    {tag === "Other" ? tag + " " + category + "s" : tag}&nbsp;
                    <AiOutlineClose
                      className="filter__remove-icon"
                      onClick={() => {
                        addRemoveFilter(tag, category);
                      }}
                    />
                    &nbsp;
                  </span>
                );
              });
            })}
          </p>
        </div>

        <div className="filter-reset" onClick={(e) => { addRemoveFilter("", "reset"); }}>
          <label>
            <BsArrowCounterclockwise />
          </label>
        </div>

        <div className="filter__searchbar-container">
          <form onSubmit={onSubmit} className="filter__searchbar-form">
            <BsSearch className="filter__searchbar-icon" />
            <input
              type="search"
              placeholder="Search"
              minLength={1}
              maxLength={30}
              className="filter__searchbar-input"
              enterkeyhint="search"
            />
          </form>
        </div>

        <div className="filter-unordered-list">
          <ProjectFilter
            designerFilter={designerFilter}
            setDesignerFilter={setDesignerFilter}
            projectFilter={projectFilter}
            setProjectFilter={setProjectFilter}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            addRemoveFilter={addRemoveFilter}
          />
        </div>

        <div className="filter__dropdown-container">
          <DesignerFilter
            currentUser={currentUser}
            role={role}
            designerFilter={designerFilter}
            setDesignerFilter={setDesignerFilter}
            projectFilter={projectFilter}
            setProjectFilter={setProjectFilter}
            addRemoveFilter={addRemoveFilter}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            removeLocation={removeLocation}
            setRemoveLocation={setRemoveLocation}
            resetAllCheckBoxes={resetAllCheckBoxes}
            setResetAllCheckBoxes={setResetAllCheckBoxes}
            preselectLocation={preselectLocation}
            removeGoal={removeGoal}
            setRemoveGoal={setRemoveGoal}
            preSelectGoals={preselectGoal}
            removeDeliverable={removeDeliverable}
            setRemoveDeliverable={setRemoveDeliverable}
            preSelectDeliverables={preselectDeliverable}
          />
          <ProjectFilter
            designerFilter={designerFilter}
            setDesignerFilter={setDesignerFilter}
            projectFilter={projectFilter}
            setProjectFilter={setProjectFilter}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            addRemoveFilter={addRemoveFilter}
            filterType="mobile"
          />
        </div>
      </div>
    </div>
  );
}

export default Filter;
