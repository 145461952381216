import React from "react";
import { Link, useHistory } from "react-router-dom";

import { IoLocationSharp } from "@react-icons/all-files/io5/IoLocationSharp";

import profilePlaceholder from "../../assets/images/ProfilePlaceholder.png";

import "../../styles/components/molecules/UserDetailCard.scss";

function UserDetailCard({
  name,
  city,
  country,
  profileImage,
  role,
  uuid,
  type
}) {

  const history = useHistory();
    
  return (
    <div className="user-detail-card">
      <div className="user-details-card__info-container">

        <div className="user-details-card__user-info-container">
          <Link className="header__user-image" to="#" onClick={() => (uuid) && history.push("/profile/" + uuid)} aria-label="Go to user profile">
            <div
              className="header__user-image-container--user-detail-card"
              style={{
                backgroundImage: profileImage ? `url(${process.env.REACT_APP_GCS_IMAGE_URL + profileImage})` : `url(${profilePlaceholder})`
              }}
            />
          </Link>
        </div>

        <div className="user-details-card__user-info-container">
          <div className="user-details-card__header">
            <h5
              className={`user-details-card__title ${type !== "popup-content" && "user-details-card__title--dynamic-color"}`}
              onClick={() => (uuid) && history.push("/profile/" + uuid)}
            >
              {name}&nbsp;
            </h5>
            {role === "pro-user" || role === "business-user" || role === "pitch-user"
              ? 
              <h6 className="user-details-card__boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
              :
              null
            }
          </div>
          <span className={`user-details-card__info-location ${type !== "popup-content" && "user-details-card__info-location--dynamic-color"}`}>
            <a href={`https://maps.google.com/?q=${city != "" ? city + ", " : ""} ${country}`} target="_blank" rel="noreferrer" aria-label="Show location">
              <IoLocationSharp/>
            </a>
            {city != "" ? city + ", " : ""}{country}
          </span>
        </div>

      </div>
    </div>
  );
}

export default UserDetailCard;