import React, { useEffect, useState, Suspense } from "react";
import { lazy } from "@loadable/component";

import { useHistory, useLocation, Link } from "react-router-dom";
import { Honeybadger } from "@honeybadger-io/react";
const InfiniteScroll = lazy(() => import("react-infinite-scroll-component"));

import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";

import "../styles/pages/HomePage.scss";

import popupImagePremium from "../assets/images/popupImages/transitions/popupTransitionPremium.m4v";
import popupImagePro from "../assets/images/popupImages/transitions/popupTransitionPro.m4v";
import popupImageFree from "../assets/images/popupImages/transitions/popupTransitionUpgrade.m4v";
import preTransitionPopupPremiumImage from "../assets/images/popupImages/PopupPremiumAccount.png";
import preTransitionPopupProImage from "../assets/images/popupImages/PopupProAccount.png";
import preTransitionPopupFreeImage from "../assets/images/popupImages/UpgradeFreePopUp.png";
import popupProImage from "../assets/images/popupImages/transitions/popupTransitionPro.m4v";

import Filter from "../components/molecules/Filter";
import Spinner from "../components/atoms/Spinner";
import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";

const ProjectCard = lazy(() => import("../components/molecules/ProjectCard"));
const PopUp = lazy(() => import("../components/molecules/PopUp"));
const Notification = lazy(() => import("../components/molecules/Notification"));
import DynamicSlideshow from "../components/molecules/DynamicSlideshow";

import { getCurrentUser, updateAccessToken } from "../services/AuthService";
import {getFilteredProjects, getProjects, getRelatedProjectsFilter, getProjectsCount } from "../services/ProjectService";
import { sendBuddyInvitation } from "../services/MailService";
import { getUser, verifyUser } from "../services/UserService";
import { loginOAuth2Social } from "../services/LoginService";
import { getFavoritesInLocalStorage } from "../services/FavoriteService";
import { getPaymentStatus } from "../services/CheckoutService";
import { getAdvertisements } from "../services/AdvertisementService";

import { amountOfUploadsCheck } from "../util/PopUpChecks";
import scrollTrigger from "../util/ScrollReveal";

const HomePage = ({ currentUser }) => {
  const PROJECTS_PER_PAGE = 39;
  const BANNER_AMOUNT = 5;
  const sortingFields = ["Sort", "Relevance", "ForSale", "Random", "Popular", "Newest", "Availability"];
  const MOBILE_SCREEN_WIDTH = 768;

  const history = useHistory();
  const location = useLocation();
  const [projects, setProjects] = useState([]);
  const [loadedProjectIds, setLoadedProjectIds] = useState([]);
  const [items, setItems] = useState([]);
  const [indexOfFirstProject, setIndexOfFirstProject] = useState(0);
  const [indexOfLastProject, setIndexOfLastProject] = useState(0);
  const [indexOfLastRelatedProject, setIndexOfLastRelatedProject] = useState(0);
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [scrollLoadingRelated, setScrollLoadingRelated] = useState(false);
  const [results, setResults] = useState("");
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("");
  const [firstLogin, setFirstLogin] = useState(true);
  const [firstUpload, setFirstUpload] = useState(0);
  const [firstUploadName, setFirstUploadName] = useState("");
  const [fiveUploads, setFiveUploads] = useState(0);
  const [threeUploads, setThreeUploads] = useState(0);
  const [paymentConfirmation, setPaymentConfirmation] = useState(null);
  const [randomNumber, setRandomNumber] = useState("");
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const [query, setQuery] = useState("");
  const [endOfProjects, setEndOfProjects] = useState(false);
  const [endOfRelatedProjects, setEndOfRelatedProjects] = useState(false);
  const [otherProjects, setOtherProjects] = useState([]);
  const [selectedSortingField, setSelectedSortingField] = useState(sortingFields[0]);
  const [upgradePopUpVisible, setUpgradePopUpVisible] = useState(false);
  const [pendingPaymentPopup, setPendingPaymentPopup] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(getCurrentUser() ? true : false); 
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false); 
  const [reversedIcon, setReversedIcon] = useState(false);
  const [sortFilter, setSortFilter] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);
  const [randomizedAdPositions, setRandomizedAdPositions] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= MOBILE_SCREEN_WIDTH;

  let scrollTimeout;
  
  let favoriteList = [];

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(async () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const userAgentIsTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (userAgentIsTablet) {
      setIsTablet(true);
    }

    const token = new URLSearchParams(window.location.search).get("token");
    const paymentConfirmationType = new URLSearchParams(window.location.search).get("paymentConfirmation");

    if (paymentConfirmationType) {
      if (paymentConfirmationType == "pro-user" || paymentConfirmationType == "business-user" || paymentConfirmationType == "pitch-user") {
        getPaymentStatus().then((res) => {
          if (res === undefined) return;

          if (res.status == "unpaid") {
            setPendingPaymentPopup(true);
          } else if (res.status == "paid") {
            setPaymentConfirmation(paymentConfirmationType);
          }
        });
      } else {
        setPaymentConfirmation(paymentConfirmationType);
      }

      await updateAccessToken("/");
      history.push({
        path: "/",
      });
    }

    if (token) {
      verifyUser(token);
      history.push("/");
    }

    const oauth2Token = new URLSearchParams(window.location.search).get("oauth2Token");
    const oauth2Email = new URLSearchParams(window.location.search).get("oauth2Email");

    if (oauth2Token) {
      await loginOAuth2Social(oauth2Token, oauth2Email).then(async (loginResponse) => {
        await getUser(oauth2Email)
          .then(async (user) => {
            if (loginResponse.ok) {
              getFavoritesInLocalStorage(user.profile.profileId).catch((e) => {
                console.log("Error occured when retrieving user favorites.");
              });
              if (user.role === "admin") {
                history.push({
                  pathname: "/admin",
                });
              } else {
                history.push({
                  pathname: "/",
                });
              }
            }
            window.location.reload();
          })
          .catch((e) => {
            console.log("Error occured when retrieving user! " + e);
          });
      });
    }

    if (!location.state && window.location.search == "") {
      // Check if sessionStorage has a sorting field
      const currentSort = sessionStorage.getItem("exploreSort");
      if (currentSort !== null) {
        setSelectedSortingField(currentSort);
        fetchMoreData(currentSort);
      } else {
        setSelectedSortingField(sortingFields[0]);
        fetchMoreData(sortingFields[0]);
      }
    }

    setFirstLogin(localStorage.getItem("emailVerified"));
    setRandomNumber(Math.floor(Math.random() * BANNER_AMOUNT));

    setTimeout(() => {
      setFiveUploads(parseInt(localStorage.getItem("fiveUploads")));
      setThreeUploads(parseInt(localStorage.getItem("threeUploads")));
      setFirstUpload(parseInt(localStorage.getItem("firstUpload")));
      setFirstUploadName(sessionStorage.getItem("firstUploadTitle"));
    }, 10);

    const popStateEventListener = () => {
      if (document.URL.includes("project-details")) {
        sessionStorage.setItem("scrollPosition", scrollYPosition);
      }
    };
  
    const scrollEventListener = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setScrollYPosition(window.pageYOffset);
      }, 150);
    };
    
    window.addEventListener("popstate", popStateEventListener);
    window.addEventListener("scroll", scrollEventListener);

    return () => { 
      window.removeEventListener("popstate", popStateEventListener);
      window.removeEventListener("scroll", scrollEventListener);
    };
  }, []);

  useEffect(() => {
    if (projects.length > 0) {
      setTimeout(() => {
        scrollTrigger(".reveal");
      }, 500);
    }
  }, [projects, otherProjects]);

  useEffect(() => {
    if (currentUser && !user) {
      setUser(currentUser);
      setRole(currentUser.role.name);
      amountOfUploadsCheck();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser != null) {
      Honeybadger.setContext({
        user_id: currentUser.userId,
        user_email: currentUser.email,
        role: currentUser.role.name
      });
    }
  }, [currentUser]);
  
  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getProjectsCount(true).then((res) => {
      getAdvertisements(true).then((advertisementsList) => {
        setAdvertisements(advertisementsList);
        ramdomizeAdvertisementPositions(advertisementsList, res);
      });
    });
  }, []);

  function ramdomizeAdvertisementPositions(advertisementsList, projectsLength) {
    // Check if advertisementsList exists to prevent an error
    if (advertisementsList && advertisementsList != undefined) {
      const randomizedIndexPerAdvertisement = [];

      // Calculate random position between adIndex
      for (let i = 0; i < advertisementsList.length; i++) {
        // Randomize between first 40 projects, then 40-80, 80-120, etc...
        // +1 to make sure that the correct advertisement is shown / projectsLength-1 because it cannot appear in last position
        const startNumber =  ((i) * PROJECTS_PER_PAGE) + 1;
        const endNumber = Math.min((i + 1) * PROJECTS_PER_PAGE, projectsLength - 1);
        const randomIndex = randomIntBetween(startNumber, endNumber);
        randomizedIndexPerAdvertisement.push(randomIndex);
      }

      setRandomizedAdPositions(randomizedIndexPerAdvertisement);
    }
  }

  function randomIntBetween(min, max) { 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const getAdvertisementIndex = (projectsIndex) => {
    return Math.floor(projectsIndex / (PROJECTS_PER_PAGE + 1));
  };

  function openWebLink(url) {
    if (url.includes("https://")) { 
      window.open(url, "_blank"); 
    } else {
      window.open("https://" + url, "_blank");
    }
  }

  function fetchMoreData(sortingField) {
    if (sortingField) {
      setLoading(true);
      setEndOfProjects(false);

      if (sortingField != "Random" && sortingField != "ForSale") {
        setLoadedProjectIds([]);
      }
    }
    setScrollLoading(true);
    // Get the first and last index of the projects that are requested
    // If sortingType is changed first and last index will be resetted
    const loadedProjects = parseInt(handleAmountOfProjects());
    const firstIndex = (sortingField) ? 0 : indexOfLastProject;
    const lastIndex = (loadedProjects != 0) ? loadedProjects : ((sortingField) ? PROJECTS_PER_PAGE : indexOfLastProject + PROJECTS_PER_PAGE);

    // Get sorting type
    const sortingType =  (sortingField) ? sortingField : selectedSortingField;

    // If there is an filter used
    if (query != "" || loadedProjects != 0) {
      getFilteredProjects(query, firstIndex, lastIndex, sortingType, loadedProjectIds).then((filteredProjectsList) => {
        if (filteredProjectsList) {
          if (filteredProjectsList.length < PROJECTS_PER_PAGE) setEndOfProjects(true);

          if (
            (sortingField && (sortingField == "Random" || sortingField == "ForSale")) || 
            (!sortingField && (selectedSortingField == "Random" || selectedSortingField == "ForSale"))
          ) {
            const ids = filteredProjectsList.map(project => project.projectId);
    
            setLoadedProjectIds((sortingField) ? ids : loadedProjectIds.concat(ids));
          }
          
          setIndexOfLastProject(lastIndex);
          // If sortingType is changed projects are replaced by the new projects
          setProjects((sortingField) ? filteredProjectsList : projects.concat(filteredProjectsList));
          setResults(filteredProjectsList[0].amountOfProjects);
          setScrollLoading(false);
          setLoading(false);
          handleScrollPosition();
        }
      });

      return;
    }

    const page = Math.floor(firstIndex / PROJECTS_PER_PAGE);
    
    // getProjects is called when no filter is used
    getProjects(page, PROJECTS_PER_PAGE, sortingType, loadedProjectIds).then((projectsList) => {
      if (projectsList && projectsList.length > 0) {
        if (projectsList.length < PROJECTS_PER_PAGE) setEndOfProjects(true);

        if (
          (sortingField && (sortingField == "Random" || sortingField == "ForSale")) || 
          (!sortingField && (selectedSortingField == "Random" || selectedSortingField == "ForSale"))
        ) {
          const ids = projectsList.map(project => project.projectId);
  
          setLoadedProjectIds((sortingField) ? ids : loadedProjectIds.concat(ids));
        }
          
        setIndexOfLastProject(lastIndex);
        setProjects((sortingField) ? projectsList : projects.concat(projectsList));
        setResults(projectsList[0].amountOfProjects);
        setScrollLoading(false);
        setLoading(false);
      } else {
        setEndOfProjects(true);
        setScrollLoading(false);
      }
    });
  }

  function fetchMoreDataRelatedProjects() {
    setScrollLoadingRelated(true);
    const lastIndex = indexOfLastRelatedProject + PROJECTS_PER_PAGE;
    const firstIndex = lastIndex - PROJECTS_PER_PAGE;
    const projectsId = [];

    projects.forEach(project => {
      projectsId.push(project.projectId);
    });

    getRelatedProjectsFilter(projectsId, firstIndex, lastIndex).then((list) => {
      if (list) {
        if (list.length < PROJECTS_PER_PAGE) { 
          setEndOfRelatedProjects(true); 
        }
        
        setIndexOfLastRelatedProject(lastIndex);
        setOtherProjects(otherProjects.concat(list));
        setScrollLoadingRelated(false);
        setScrollLoading(false);
      }
    });
  }

  function storeFavorites(projectId) {
    const favoriteItems = localStorage.getItem("favorites");

    // Use list from local storage if it exists
    if (favoriteItems != null) {
      favoriteList = JSON.parse(favoriteItems);
    }

    // Add new favorite project to list and local storage
    if (favoriteList.indexOf(projectId) === -1) {
      favoriteList.push(projectId);
    } else {
      favoriteList.splice(favoriteList.indexOf(projectId), 1);
    }

    localStorage.setItem("favorites", JSON.stringify(favoriteList));
  }

  const removeVerificationPopup = () => {
    localStorage.removeItem("emailVerified");
    setFirstLogin(false);
  };

  function inviteBuddy(data, event) {
    event.preventDefault();
    sendBuddyInvitation(data.email);
    closeLocalStoragePopUp("threeUploads");
  }

  function acceptProOffer(event) {
    event.preventDefault();
    closeLocalStoragePopUp("fiveUploads");
    history.push("/pricing");
  }

  const closeLocalStoragePopUp = (localStorageItem) => {
    localStorage.setItem(localStorageItem, 1);

    if (localStorageItem === "threeUploads") {
      setThreeUploads(1);
    } else if (localStorageItem === "fiveUploads") {
      setFiveUploads(1);
    } else if (localStorageItem === "firstUpload") {
      setFirstUpload(1);
    }
  };

  const handleAmountOfProjects = () => {
    const amountofProjects = sessionStorage.getItem("amountOfProjects");

    if (amountofProjects) {
      setIndexOfLastProject(amountofProjects);
      sessionStorage.removeItem("amountOfProjects");
      return amountofProjects;
    }

    return 0;
  };

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");

    if (scrollPosition) {
      setTimeout(() => {
        window.scrollTo({top: scrollPosition, behavior: "smooth"});
      }, 1000);

      sessionStorage.removeItem("scrollPosition");
    }
  };

  const handleScroll = () => {
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
    sessionStorage.setItem("amountOfProjects", indexOfLastProject);
  };

  const handleSelectSort = (field) => {
    if (field === "Newest" || field === "Availability") {
      if (role === "") {
        setSignUpPopUpVisible(true);
        return;
      } else if (role === "free-user") {
        setUpgradePopUpVisible(true);
        return;
      }
    }

    if (field === selectedSortingField) {
      sessionStorage.setItem("exploreSort", "Sort");
      setSelectedSortingField("Sort");
      fetchMoreData("Sort");
    } else {
      sessionStorage.setItem("exploreSort", field);
      setSelectedSortingField(field);
      fetchMoreData(field);
    }
  };

  return (
    <div className="home">
      <DocumentHead
        showChat={false}
        chatTimeout={500}
      />

      <DynamicSlideshow isMobile={isMobile}></DynamicSlideshow>  

      <Suspense fallback={<Spinner type="megosu"></Spinner>}>
        <>
          <Filter
            currentUser={currentUser}
            role={role && role}
            setProjects={setProjects}
            loadedProjectIds={loadedProjectIds}
            setLoadedProjectIds={setLoadedProjectIds}
            setItems={setItems}
            loading={loading}
            setLoading={setLoading}
            setIndexOfFirstProject={setIndexOfFirstProject}
            setIndexOfLastProject={setIndexOfLastProject}
            results={results}
            setResults={setResults}
            tag={location.state && location.state.tag}
            filterCategory={location.state && location.state.filterCategory}
            setOtherProjects={setOtherProjects}
            setQuery={setQuery}
            setFirstIndex={setIndexOfFirstProject}
            setLastIndex={setIndexOfLastProject}
            setEndOfProjects={setEndOfProjects}
            setEndOfRelatedProjects={setEndOfRelatedProjects}
            projectsPerPage={PROJECTS_PER_PAGE}
            setScrollLoadingRelated={setScrollLoadingRelated}
            selectedSortingField={selectedSortingField}
          />

          <div 
            className="home__sorting"
            onMouseEnter={() => { setReversedIcon(true); }}
            onMouseLeave={() => { setReversedIcon(false); }}
            onClick={() => { setSortFilter(!sortFilter); }}
          >
            <h3 className="home__sorting-title">
              {selectedSortingField === "ForSale" ? "To Buy" : selectedSortingField}
              <BsFillCaretDownFill className={`home__sorting-title-icon ${reversedIcon && "home__sorting-title-icon--reversed"} ${sortFilter && "home__sorting-title-icon--reversed-mobile"}`} />
            </h3>

            <ul 
              className={`home__sorting-dropdown ${sortFilter && "home__sorting-dropdown--active"}`}
              onMouseEnter={() => { setReversedIcon(true); }}
              onMouseLeave={() => { setReversedIcon(false); }}
            >
              {sortingFields.filter((field) => field !== "Sort").map((field) => (            
                <li 
                  className={`home__sorting-dropdown__item ${selectedSortingField === field && "home__sorting-dropdown__item--active"}`}
                  key={field}
                  onClick={() => { handleSelectSort(field); }}
                >
                  {(field == "ForSale") ? "To Buy" : field}
                  {(field === "Newest" || field === "Availability") && 
                <h6 className="home__social-menu-boosted" onClick={() => { history.push("/pricing"); }}>Pro</h6>
                  }
                </li>
              ))}
            </ul>
          </div>

          {projects && !loading && items ? (
            <>
              <InfiniteScroll
                dataLength={projects.length}
                next={fetchMoreData}
                hasMore={currentUser ? !endOfProjects : (projects.length < PROJECTS_PER_PAGE && !endOfProjects)}
                style={{ padding: "0 3rem", overflowY: "hidden", display: "grid" }}
                scrollThreshold={0.5}
                endMessage={
                  <>
                    {!currentUser && !window.location.search ? (
                      <div
                        className="home__filter-ending-message"
                        style={
                          results === 0 ? { display: "none" } : { display: "block" }
                        }
                      >
                        <p>
                          <a onClick={() => { setLoginPopupVisible(true); }}>Log In</a> or{" "}
                          <a onClick={() => { setSignUpPopUpVisible(true); }}>Sign Up</a> to your free account to
                      view all projects.
                        </p>
                        <Button onClick={() => { setSignUpPopUpVisible(true); }} type="black" title="Show more projects"/>
                      </div>
                    ) : currentUser ? (
                      <div className="home__filter-ending-message" style={results === 0 ? { display: "none" } : { display: "block" }}>
                        <p>
                      You have reached the end.
                        </p>
                        <Button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} type="black" title="Use Other Filters"/>
                      </div> 
                    ) : null}
                  </>
                }
              >
                <div className="home__container">
              
                  <div
                    className="home__content-container-projects"
                    style={
                      results === 0 ? { display: "flex" } : { display: "grid" }
                    }
                  >
                    {results >= 1 ? (
                      projects.map((elem, i) => (
                        <>
                          {randomizedAdPositions && randomizedAdPositions.includes(i) && (
                            <>
                              {advertisements && ( 
                                <>
                                  <div className="home__advertisement">
                                    <div onClick={() => { openWebLink((advertisements[getAdvertisementIndex(i)].url)); }} className="home__advertisement-visual-container">
                                      <video
                                        muted="muted"
                                        autoPlay="autoPlay"
                                        loop="loop"
                                        type="video/mp4"
                                        playsInline 
                                        className="home__advertisement-visual"
                                        src={process.env.REACT_APP_GCS_IMAGE_URL + advertisements[getAdvertisementIndex(i)].visual + "#t=0.001"}
                                      />
                                    </div>
                                    <div className="home__advertisement-description-container">
                                      <div className="home__advertisement-text-container">
                                        <a className="home__advertisement-text-link">
                                          <h5 
                                            className="home__advertisement-text-title" 
                                            onClick={() => { openWebLink((advertisements[getAdvertisementIndex(i)].url)); }}
                                          >
                                            {advertisements[getAdvertisementIndex(i)].title}<br/>
                                          </h5>
                                        </a>
                                        <div className="home__advertisement-attributes-container">
                                          {advertisements[getAdvertisementIndex(i)].label !== "None" && advertisements[getAdvertisementIndex(i)].label !== "" && (
                                            <h6 className="home__advertisement-label" onClick={() => { history.push("/pricing"); }}>{advertisements[getAdvertisementIndex(i)].label}</h6> 
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          <ProjectCard
                            key={`project-${i}`}
                            parent="home"
                            index={i}
                            projectId={elem.projectId}
                            title={elem.title}
                            boosted={elem.boosted}
                            selectedSortingField={selectedSortingField}
                            forSale={elem.forSale}
                            imageUrl={elem.imageUrl}
                            userImageUrl={elem.userImageUrl}
                            designerName={elem.designerName}
                            onClick={(projectId) => storeFavorites(projectId)}
                            handleScroll={handleScroll}
                            uuid={elem.uuid}
                            user={user}
                            favoriteCount={elem.favoriteCount}
                            ownerProfileId={elem.userUUID}
                            marketUrl={elem.marketUrl}
                          />
                        </>
                      ))
                    ) : (
                      <h2 className="home__content-container-noresults">
                    Oops! There were no projects found with your criteria.
                      </h2>
                    )}
                  </div>
                </div>
              </InfiniteScroll>
          
              {window.location.search
                ?
                <div>
                  <InfiniteScroll
                    dataLength={otherProjects.length}
                    next={fetchMoreDataRelatedProjects}
                    hasMore={currentUser ? !endOfRelatedProjects : otherProjects.length < PROJECTS_PER_PAGE}
                    style={{ padding: "0 3rem", overflowY: "hidden", display: "grid" }}
                  >

                    {!currentUser && (
                      <div
                        className="home__filter-ending-message"
                        style={
                          results === 0 ? { display: "none" } : { display: "block" }
                        }
                      >
                        <p>
                          <Link to="/login">Log In</Link> or{" "}
                          <Link to="/sign-up">Sign Up</Link> to your free account to
                            view all projects.
                        </p>
                        <Button onClick={() => history.push("/login")} type="black" title="Show more projects"/>
                      </div>
                    )}
                
                    <div className="home__container home__container-other-projects">
                      <div className="home__content-container-results">
                        <h2 className="home__related-results-title">Related projects</h2>
                      </div>
                      <div
                        className="home__content-container-projects"
                        style={
                          otherProjects === 0 ? { display: "flex" } : { display: "grid" }
                        }
                      >
                        {otherProjects.length >= 1 ? (
                          otherProjects.map((elem, i) => (
                            <ProjectCard
                              key={`project-${i}`}
                              parent="home"
                              projectId={elem.projectId}
                              title={elem.title}
                              boosted={elem.boosted}
                              imageUrl={elem.imageUrl}
                              userImageUrl={elem.userImageUrl}
                              designerName={elem.designerName}
                              onClick={(projectId) => storeFavorites(projectId)}
                              handleScroll={handleScroll}
                              uuid={elem.uuid}
                              user={user}
                              favoriteCount={elem.favoriteCount}
                              ownerProfileId={elem.userUUID}
                            />
                          ))
                        ) 
                          : 
                          (
                            !scrollLoadingRelated &&
                      <h2 className="home__content-container-noresults">
                        Oops! There were no projects found with your criteria.
                      </h2>
                          )}
                      </div>
                    </div>
                  </InfiniteScroll>
                </div>
                :
                null
              }

              {
                scrollLoadingRelated &&
                <Spinner type="megosu" />
              }
            </>
          ) : (
            <div className="home__spinner-container">
              <Spinner type="megosu" />
            </div>
          )}
        </>
      </Suspense>

      {firstLogin && currentUser && (
        <Suspense fallback={null}>
          <Notification
            position={"rightBottom"}
            onRemove={() => { removeVerificationPopup(); }}
            hasButton={false}
            title={"Welcome to Megosu!"}
            subtitle={"A verification email has been sent to your email address. Please verify within 24 hours."}
          />
        </Suspense>
      )}

      {firstUpload === 0 && currentUser && user && (
        <Suspense fallback={null}>
          <Notification
            position={"rightBottom"}
            onRemove={() => { closeLocalStoragePopUp("firstUpload"); }}
            hasButton={false}
            title={"Hey " + user.profile.firstName + "! 👋"}
            subtitle={`We just wanted to congratulate you on your first upload ${firstUploadName}. We're looking forward to seeing more of your work soon!`}
          />
        </Suspense>
      )}

      {fiveUploads === 0 && currentUser && (
        <Suspense fallback={null}>
          <Notification
            position="rightBottom"
            onRemove={() => { closeLocalStoragePopUp("fiveUploads"); }}
            onClickButton={(event) => { acceptProOffer(event); }}
            buttonTitle="Go Pro"
            buttonType="pink"
            title="Surprise! 🎉"
            subtitle="You've uploaded 5 incredible projects, which is why we would like to offer you a 50% discount code to become a Pro Member. Use the code"
            subtitleHighlight="5TIMES"
            subtitleHighlightStyleType="discount-code"
            subtitleContinuation="and sign up for our Pro Features."
            customButtonStyle={{padding: "0.5rem 2rem 0.5rem 2rem"}}
            hasButton={true}
          />
        </Suspense>
      )}

      {threeUploads === 0 && currentUser && user && (
        <Suspense fallback={null}>
          <Notification
            type="form"
            position="rightBottom"
            onSubmit={inviteBuddy}
            onRemove={() =>  { closeLocalStoragePopUp("threeUploads"); }}
            onClickButton={() => { inviteBuddy; }}
            buttonTitle="Send invitation"
            buttonType="pink"
            title={"Hey " + user.profile.firstName + "! 💪"}
            subtitle="As one of our frequent uploaders we're sure you've got some design friends that might be interested in joining Megosu too. 
              We won't spam this person, we'll just send one invitation!"
            formLabel="Invite a design buddy:"
            formPlaceholder="example@megosu.com"
            formName="email"
            hasButton={true}
          />
        </Suspense>
      )}

      <Suspense fallback={null}>
        <PopUp
          visibility={paymentConfirmation === "free-user"}
          overlay={true}
          handleOnClickClose={() => setPaymentConfirmation(false)}
          src={popupImageFree}
          preTransitionImage={preTransitionPopupFreeImage}
          popupTitle="Your free plan has been confirmed!"
          popupText="Create a portfolio, save projects & contact designers for your next project."
          firstButtonTitle="Proceed"
          firstButtonAction={() => setPaymentConfirmation(false)}
          firstButtonType="black"
        />
      </Suspense>

      <Suspense fallback={null}>
        <PopUp
          visibility={paymentConfirmation === "pro-user"}
          overlay={true}
          handleOnClickClose={() => setPaymentConfirmation(false)}
          src={popupImagePro}
          preTransitionImage={preTransitionPopupProImage}
          transition={true}
          popupTitle="Congrats, you're Pro!"
          popupText="Projects are boosted, ask feedback, access to the private community & get in touch with clients."
          firstButtonTitle="Proceed"
          firstButtonAction={() => setPaymentConfirmation(false)}
          firstButtonType="black"
        />
      </Suspense>

      <Suspense fallback={null}>
        <PopUp
          visibility={paymentConfirmation === "pitch-user"}
          overlay={true}
          handleOnClickClose={() => setPaymentConfirmation(false)}
          src={popupImagePro}
          preTransitionImage={preTransitionPopupProImage}
          transition={true}
          popupTitle="Congrats, you're Pitch Member!"
          popupText="Pitch safely without a hassle, expand your company-network, start partnerships, projects are boosted & access to the private community."
          firstButtonTitle="Proceed"
          firstButtonAction={() => setPaymentConfirmation(false)}
          firstButtonType="black"
        />
      </Suspense>

      <Suspense fallback={null}>
        <PopUp
          visibility={paymentConfirmation === "business-user"}
          overlay={true}
          handleOnClickClose={() => setPaymentConfirmation(false)}
          src={popupImagePremium}
          preTransitionImage={preTransitionPopupPremiumImage}
          transition={true}
          popupTitle="Great! You have a Business Plan!"
          popupText="Use advanced filters, show project availability & start hiring your next designer. "
          firstButtonTitle="Proceed"
          firstButtonAction={() => setPaymentConfirmation(false)}
          firstButtonType="black"
        />
      </Suspense>

      <Suspense fallback={null}>
        <PopUp
          visibility={pendingPaymentPopup}
          overlay={true}
          handleOnClickClose={() => setPendingPaymentPopup(false)}
          transition={true}
          popupTitle="Your payment is pending."
          popupText="Payment methods other than creditcards (e.g. SEPA - iDeal) can take up to 14 business days to process your payment. You can already enjoy your paid plan now!"
          firstButtonTitle="Proceed"
          firstButtonAction={() => setPendingPaymentPopup(false)}
          firstButtonType="black"
        />
      </Suspense>

      <Suspense fallback={null}>
        <PopUp 
          visibility={signUpPopUpVisible}
          overlay={true}
          firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
          handleOnClickClose={() => setSignUpPopUpVisible(false)}
          popupType="sign-up" 
        />
      </Suspense>

      <Suspense fallback={null}>
        <PopUp 
          visibility={loginPopupVisible}
          overlay={true}
          firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
          handleOnClickClose={() => { setLoginPopupVisible(false); }}
          popupType="login" 
        />
      </Suspense>

      <Suspense fallback={null}>
        <PopUp
          visibility={upgradePopUpVisible}
          overlay={true}
          handleOnClickClose={() => setUpgradePopUpVisible(false)}
          src={popupProImage}
          preTransitionImage={preTransitionPopupProImage}
          transition={true}
          popupTitle="Unlock this feature!"
          popupText="All sorting tools, pro filters, projects are boosted, unlimited uploads & link your webshop."
          firstButtonTitle="Go Pro"
          firstButtonType="black"
          firstButtonAction={() => history.push({ pathname: "/pricing" })}
        />
      </Suspense>
    </div>
  );
};

export default HomePage;
