import CryptoJS from "crypto-js";
import { AuthHeader } from "../util/AuthHeader";

export function login(username, password) {
  password = CryptoJS.AES.encrypt(JSON.stringify(password), CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_SK), {iv: CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_IV)}).toString();
  
  const loginUrl = process.env.REACT_APP_API_BASE_URL + "/login";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  };

  return fetch(loginUrl, options).then(async (data) => {
    if (data.status === 200) {
      data.text().then((res) => {
        const tokens = JSON.parse(res);
        localStorage.setItem("currentUser", tokens["access_token"]);
        localStorage.setItem("refreshToken", tokens["refresh_token"]);
      });
    } else if (data.status === 303) {
      sessionStorage.setItem("credentials", JSON.stringify({ "email": username, "password": password }));
      window.location.pathname = "/set-up";
    } else if (data.status === 202) {
      sessionStorage.setItem("credentials", JSON.stringify({ "email": username, "password": password }));
    }
    return data;
  });
}

export function loginVerify2fa(credentials, code) {
  const verify2faUrl = process.env.REACT_APP_API_BASE_URL + "/2fa/login/verify";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username: credentials.email, password: credentials.password, twoFactorAuthCode: code }),
  };

  return fetch(verify2faUrl, options).then(async (data) => {
    if (data.status === 200) {
      data.text().then((res) => {
        const tokens = JSON.parse(res);
        localStorage.setItem("currentUser", tokens["access_token"]);
        localStorage.setItem("refreshToken", tokens["refresh_token"]);

        sessionStorage.removeItem("credentials");
      });
    }
    return data;
  });
}

export function loginOAuth(state, redirectUri, clientId, responseType) {
  const loginUrl =
    process.env.REACT_APP_API_BASE_URL +
    "/authorize" +
    "?state=" + state +
    "&redirect_uri=" + redirectUri +
    "&client_id=" + clientId +
    "&response_type=" + responseType;

  const options = {
    method: "POST",
    headers: {
      Authorization: AuthHeader(),
    },
  };
  fetch(loginUrl, options)
    .then((response) => {
      if (response.status === 303) {
        response.text().then((res) => {
          window.location.href = res.replaceAll("\"", "");
        });
      }
      else {
        window.location.href = "/pricing";
      }
    })
    .catch(function (err) {
      console.info(err + " url: " + loginUrl);
    });
}

export function loginOAuth2Social(userData, provider, email) {
  const loginUrl = process.env.REACT_APP_API_BASE_URL + "/login/oauth2?provider=" + provider;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  };

  return fetch(loginUrl, options).then(async (data) => {
    if (data.ok) {
      data.text().then((res) => {
        const tokens = JSON.parse(res);
        localStorage.setItem("currentUser", tokens["access_token"]);
        localStorage.setItem("refreshToken", tokens["refresh_token"]);
      });
    } else if (data.status === 303 || data.status === 412) {
      const tempPassword = CryptoJS.AES.encrypt(JSON.stringify("changeit"), CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_SK), {iv: CryptoJS.enc.Base64.parse(process.env.REACT_APP_PASS_IV)}).toString();

      sessionStorage.setItem("credentials", JSON.stringify({"email": email, "password": tempPassword}));
      window.location.pathname = "/set-up";   
    } 
    return data;
  });
}

export function getLinkedInInformation(body) {
  const loginUrl =
  process.env.REACT_APP_API_BASE_URL +
  "/authorize/linkedin/profile";

  const options = {
    method: "POST",
    body: body
  };

  return fetch(loginUrl, options)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
}

export function logout() {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("favorites");
  sessionStorage.removeItem("selectedChat");
}

const exportedFunctions = { login, logout };

export default exportedFunctions;
