import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { RouterPrompt } from "../util/RouterPrompt";

import UploadProjectForm from "../components/molecules/UploadProjectForm";
import PostUploadProjectForm from "../components/molecules/PostUploadProjectForm";
import PopUp from "../components/molecules/PopUp";
import DocumentHead from "../components/atoms/DocumentHead";

import "../styles/pages/EditProjectPage.scss";

import { getProjectById } from "../services/ProjectService";

const EditProjectPage = () => {
  const history = useHistory();
  const [proceed, hasProceeded] = useState(false);
  const [project, setProject] = useState(null);
  const [upload, hasUploaded] = useState(false);
  const [currentProject, setCurrentProject] = useState(history.location.state);
  const [allowLeave, setAllowLeave] = useState(true);
  const [imageFiles, setImageFiles] = useState([]);
  const { id } = useParams();

  useEffect(() => { 
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!currentProject) {
      getProjectById(id).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setCurrentProject(data);
          });
        } else {
          history.push("/404");
        } 
      });
    }
  }, [currentProject]);

  return (
    <div className="edit-project">
      <DocumentHead
        title={"Edit your Project on Megosu"}
        metaDescription="Edit your project."
      />
      {currentProject && (
        <>
          <UploadProjectForm
            proceed={proceed}
            hasProceeded={hasProceeded}
            setProject={(e) => setProject(e)}
            currentProject={currentProject}
            setAllowLeave={setAllowLeave}
            setImageFiles={setImageFiles}
          />
          <PostUploadProjectForm
            proceed={proceed}
            hasProceeded={hasProceeded}
            project={project}
            proceededFromEditPage={true}
            currentProject={currentProject}
            onUpload={hasUploaded}
            setAllowLeave={setAllowLeave}
            imageFiles={imageFiles}
          />
          <RouterPrompt
            when={!allowLeave}
            title="Are you sure you want to leave this page?"
            text="No data will be saved. Therefore the changes you made to your project will be lost."
            cancelText="Cancel"
            confirmText="Confirm"
          />
        </>
      )}
    </div>
  );
};
export default EditProjectPage;
