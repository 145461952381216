import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { BsFillCaretDownFill } from "@react-icons/all-files/bs/BsFillCaretDownFill";
import { BsFilter } from "@react-icons/all-files/bs/BsFilter";
import { VscGlobe } from "@react-icons/all-files/vsc/VscGlobe";

import Toggle from "../atoms/Toggle";
import CheckedDropdown from "./CheckedDropdown";
import PopUp from "./PopUp";

import { experienceList, jobTypeList } from "../../assets/data/DataList";
import { getDeliverablesList, getGoalList, getProjectCountries } from "../../services/ProjectService";

import popupImagePremium from "../../assets/images/popupImages/transitions/popupTransitionPremium.m4v";
import preTransitionPopupPremiumImage from "../../assets/images/popupImages/PopupPremiumAccount.png";

import "../../styles/components/molecules/DesignerFilter.scss";

function DesignerFilter({
  role,
  designerFilter,
  setDesignerFilter,
  projectFilter,
  setProjectFilter,
  addRemoveFilter,
  selectedTags,
  removeLocation,
  setRemoveLocation,
  resetAllCheckBoxes,
  preselectLocation,
  removeGoal,
  setRemoveGoal,
  preSelectGoals,
  removeDeliverable,
  setRemoveDeliverable,
  setResetAllCheckBoxes,
  preSelectDeliverables
}) {
  const history = useHistory();
  const [experience] = useState(experienceList);
  const [jobType] = useState(jobTypeList);
  const [upgradePopup, setUpgradePopup] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [reversedIcon, setReversedIcon] = useState(false);
  const [signUpPopUpVisible, setSignUpPopUpVisible] = useState(false);
  const [loginPopupVisible, setLoginPopupVisible] = useState(false);
  const [projectGoals, setProjectGoals] = useState([]);
  const [deliverablesList, setDeliverablesList] = useState([]);

  const filterRef = useRef(null);

  useEffect(() => { 
    if (role === "business-user" || role === "pitch-user" || role === "pro-user") {
      getProjectCountries().then(projects => { 
        if (projects) { 
          const countryNames = Object.keys(projects).map((key) => {
            return projects[key].name;
          });

          setCountryList(countryNames);
        }
      });

      getGoalList().then((res) => {
        setProjectGoals(res.map((elem) => elem.name));
      });

      getDeliverablesList().then((list) => {
        setDeliverablesList(list.map((elem) => elem.name));
      });
    }
  }, [role]);

  function toggleAvailability(e) {
    if (e === false) {
      addRemoveFilter("", "reset");
    } else {
      addRemoveFilter("", "projectAvailability", false);
    }
  }

  function signUpOrUpgradePopup(role) {
    if (role === "") {
      setSignUpPopUpVisible(true);
      return true;
    } else if ((role !== "business-user" && role !== "pitch-user" && role !== "pro-user")) {
      setUpgradePopup(true);
      return true;
    }

    return false;
  }

  return (
    <>
      <div
        className="designer-filter"
        onClick={() => {
          setDesignerFilter(!designerFilter);
          setProjectFilter(false);
        }}
        onMouseEnter={() => { setReversedIcon(true); !designerFilter && filterRef?.current && filterRef?.current.click(); }}
        onMouseLeave={() => { setReversedIcon(false); }}
        ref={filterRef}
      >
        <div
          className={`${
            projectFilter && "designer-filter__btn--disabled"
          } designer-filter__btn`}
        >
          <BsFilter className="designer-filter__bsfilter-icon" />
          Pro Filter
          <BsFillCaretDownFill 
            className={`${designerFilter && "designer-filter__bsx-icon--disabled"} designer-filter__bsx-icon ${reversedIcon && "designer-filter__bsx-icon--reversed"} ${designerFilter && "designer-filter__bsx-icon--reversed-mobile"}`}
          />
        </div>
      </div>
      <div
        className={`designer-filter__dropdown-content ${designerFilter && "designer-filter__dropdown-content--active"}`}
        onMouseEnter={() => { setReversedIcon(true); }}
        onMouseLeave={() => { setReversedIcon(false); }}
      >
        <div className="designer-filter__dropdown-category">
          <p>Experience</p>
          <div className="designer-filter__dropdown-options">
            <ul>
              <li
                style={{
                  fontWeight:
                    selectedTags.experience.length >= 1 ? "300" : "400",
                }}
                onClick={() => { !signUpOrUpgradePopup(role) ? selectedTags.experience.length >= 1 && addRemoveFilter("", "experience") : null; }}
              >
                All
              </li>
              {experience.map((item, i) => (
                <li
                  key={`filter-experience-${i}`}
                  onClick={() => { !signUpOrUpgradePopup(role) ? addRemoveFilter(item, "experience") : null; }}
                >
                  <span
                    style={{
                      fontWeight:
                        selectedTags.experience.includes(item) && "400",
                    }}
                  >
                    {item}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="designer-filter__dropdown-category">
          <p>Job Availability</p>
          <div className="designer-filter__dropdown-options">
            <ul>
              <li
                style={{
                  fontWeight:
                    selectedTags.jobAvailability.length >= 1 ? "300" : "400",
                }}
                onClick={() => { !signUpOrUpgradePopup(role) ? selectedTags.jobAvailability.length >= 1 && addRemoveFilter("", "jobAvailability") : null; }}
              >
                All
              </li>
              {jobType.map((item, i) => (
                <li
                  key={`filter-job-${i}`}
                  onClick={() => { !signUpOrUpgradePopup(role) ? addRemoveFilter(item, "jobAvailability") : null; }}
                >
                  <span
                    style={{
                      fontWeight:
                        selectedTags.jobAvailability.includes(item) && "400",
                    }}
                  >
                    {item}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="designer-filter__dropdown-category">
          <p>Project Goal</p>
          <div className="designer-filter__dropdown-options">
            <CheckedDropdown
              onClick={() => { signUpOrUpgradePopup(role) ? setReversedIcon(false) : null; }}
              dropdownList={projectGoals !== [] && projectGoals}
              name="goal"
              className="projectgoalsdropdown"
              dropdownType="filter"
              filterCategoryName="goal"
              checkedValue={preSelectGoals}
              searchable={false}
              addRemoveFilter={addRemoveFilter}
              removeLocation={removeGoal}
              setRemoveLocation={setRemoveGoal}
              resetAllLocationBoxes={resetAllCheckBoxes}
              setResetAllLocationBoxes={setResetAllCheckBoxes}
              extraWidth={true}
              singleCheck={false}
            />
          </div>
        </div>

        <div className="designer-filter__dropdown-category">
          <p>Project Deliverables</p>
          <div className="designer-filter__dropdown-options">
            <CheckedDropdown
              onClick={() => { signUpOrUpgradePopup(role) ? setReversedIcon(false) : null; }}
              dropdownList={deliverablesList !== [] && deliverablesList}
              name="deliverable"
              className="designer-filter__project-deliverables"
              dropdownType="filter"
              filterCategoryName="deliverable"
              checkedValue={preSelectDeliverables}
              searchable={false}
              addRemoveFilter={addRemoveFilter}
              removeLocation={removeDeliverable}
              setRemoveLocation={setRemoveDeliverable}
              resetAllLocationBoxes={resetAllCheckBoxes}
              setResetAllLocationBoxes={setResetAllCheckBoxes}
              extraWidth={true}
              singleCheck={false}
            />
          </div>
        </div>

        <div className="designer-filter__dropdown-category">
          <p>Project Availability</p>
          <div className="designer-filter__dropdown-options">
            <Toggle
              isChecked={(e) => { !signUpOrUpgradePopup(role) ? toggleAvailability(e) : null; }}
              value="projectAvailability"
              role={role}
            />
            <span>Available to adopt</span>
            <div className="designer-filter__dropdown-tooltip">
              <p>i</p>
              <div className="designer-filter__dropdown-tooltip-content">
                <p>
                  You can use this filtering to search for products that have
                  not yet found a partner (e.g. company to produce their
                  design). This way you can easily find products to partner
                  with.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="designer-filter__dropdown-category">
          <p>Location</p>
          <div className="designer-filter__dropdown-options">
            <VscGlobe className="designer-filter__globe-icon" />
            <CheckedDropdown
              onClick={() => { signUpOrUpgradePopup(role) ? setReversedIcon(false) : null; }}
              dropdownList={countryList !== [] && countryList}
              name="country"
              className="countrydropdown"
              dropdownType="filter"
              filterCategoryName="location"
              checkedValue={preselectLocation}
              searchable={true}
              addRemoveFilter={addRemoveFilter}
              removeLocation={removeLocation}
              setRemoveLocation={setRemoveLocation}
              resetAllLocationBoxes={resetAllCheckBoxes}
              setResetAllLocationBoxes={setResetAllCheckBoxes}
              extraWidth={false}
            />
          </div>
        </div>
      </div>
      <PopUp
        visibility={upgradePopup}
        overlay={true}
        handleOnClickClose={() => setUpgradePopup(false)}
        src={popupImagePremium}
        preTransitionImage={preTransitionPopupPremiumImage}
        transition={true}
        popupTitle="Stay one step ahead!"
        popupText="Use advanced pro filters, show project availability & access a pool of top designers."
        firstButtonTitle="Become a pro"
        firstButtonAction={() => { history.push("/pricing"); }}
        firstButtonType="black"
      />
      <PopUp 
        visibility={signUpPopUpVisible}
        overlay={true}
        firstButtonAction={() => { setSignUpPopUpVisible(false); setLoginPopupVisible(true); }}
        handleOnClickClose={() => setSignUpPopUpVisible(false)}
        popupType="sign-up" 
      />
      <PopUp 
        visibility={loginPopupVisible}
        overlay={true}
        firstButtonAction={() => { setLoginPopupVisible(false); setSignUpPopUpVisible(true); }}
        handleOnClickClose={() => { setLoginPopupVisible(false); }}
        popupType="login" 
      />
    </>
  );
}

export default DesignerFilter;