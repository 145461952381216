export function projectValidation(values, charactersAmount) {
  const errors = {};

  if (!values.hasFirstCoverImage) {
    errors.hasFirstCover = "Required.";
  }

  if (!values.hasSecondCoverImage) {
    errors.hasSecondCover = "Required.";
  }

  if (values.marketAvailability === "") {
    errors.marketAvailability = "Please select Yes or No.";
  }

  if (!values.description) {
    errors.description = "Required.";
  } else if (charactersAmount < 200 || charactersAmount > 5000) {
    errors.description = "Should be between 200 and 5000 characters";
  }

  return errors;
}

export function adminFeedbackValidation(adminFeedback) {
  const errors = {};

  if (!adminFeedback) {
    errors.textArea = "Feedback can not be empty when enabled.";
  } else if (adminFeedback.length >= 500) {
    errors.textArea = "Feedback can not be more than 500 characters";
  }

  return errors;
}

// Alphabet and whitespaces
export const alphabetRegex = new RegExp(/^[a-zA-Z\s]+$/);

// Alphabet, numbers, whitespaces and dashes
export const alphanumericRegex = new RegExp(/^[a-zA-Z0-9-\s]+$/);

export const instagramRegex = new RegExp(/(?:(?:https):\/\/)?(?:www.)?(?:instagram.com)/igm);
export const linkedinRegex = new RegExp(/(?:(?:https):\/\/)?(?:www.|nl.)?(?:linkedin.com)/igm);
export const facebookRegex = new RegExp(/(?:(?:https):\/\/)?(?:www.)?(?:facebook.com)/igm);
export const pinterestRegex = new RegExp(/(?:(?:https):\/\/)?(?:www.|nl.)?(?:pinterest.com)/igm);

export const URLRegex = new RegExp(
  /* eslint-disable no-useless-escape */
  "(https?:\/\/" + // protocol, match characters 'https' zero or one time, match ':' once and '/' twice
    "[a-z\WæøåA-Z\Wæøå0-9][a-z\WæøåA-Z\Wæøå0-9-]+[a-z\WæøåA-Z\Wæøå0-9]\." + //domain name, matches 3-unlimited alphanumeric character and one .
    "[^\s]{2,}" + // top level domain, match character 2-unlimited times
    // OR
    "|www\." + // world wide web, matches www.
    "[a-z\WæøåA-Z\Wæøå0-9][a-z\WæøåA-Z0-9-]+[a-z\WæøåA-Z\Wæøå0-9]\." + //domain name
    "[^\s]{2,})"  // top level domain
);

export const projectURLRegex = new RegExp(
  [
    /(https?:\/\/)www.(youtube.com\/watch[?]v=([a-zA-Z0-9_-]{11}))|/,
    /(https?:\/\/)youtu.(be\/[a-zA-Z0-9_-]{11})|/,
    /https?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/,
  ]
    .map(function (r) {
      return r.source;
    })
    .join("")
);

export function scrollToFirstError(errors) {
  const err = Object.keys(errors);
  // If there are errors find the element of the first error
  if (err.length) { 
    let input = document.querySelector(`input[name=${err[0]}]`);

    if (!input && err[0].includes("Cover")) {
      input = document.querySelector(`div[name=${err[0]}]`);
    }
    
    // If the element exists scroll to its position and focus on the element
    if (input) {
      var rect = input.getBoundingClientRect();
      window.scrollTo(rect.left, rect.top);
      input.focus();
    }
  }
}

// To prevent Firefox from being able to enter symbols in a number input
export function preventNonNumericalInput(e) {
  e = e || window.event;
  var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);

  // Match a single character present in the list [0-9] between one and unlimited times
  if (!charStr.match(/^[0-9,.]+$/))
    e.preventDefault();
}

export function companyValidation(values) {
  const errors = {};

  if (values.name === "") {
    errors.hasName = "Required.";
  }

  if (values.country === "") {
    errors.hasCountry = "Required.";
  }

  if (!values.hasFirstImage) {
    errors.hasFirstImage = "Required.";
  }

  if (!values.hasSecondImage) {
    errors.hasSecondImage = "Required.";
  }

  if (!values.hasThirdImage) {
    errors.hasThirdImage = "Required.";
  }

  if (!values.hasFourthImage) {
    errors.hasFourthImage = "Required.";
  }

  if (!values.hasFifthImage) {
    errors.hasFifthImage = "Required.";
  }
  
  return errors;
}

export function advertisementValidation(values, visual, editing) {
  const errors = {};

  // If the advertisement is edited, the visual from the old advertisement can be used, so ignore this check then
  if (!visual && !editing) {
    errors.hasVideo = "Required.";
  }  

  if (values.title === "") {
    errors.hasTitle = "Required.";
  }

  if (values.url === "") {
    errors.hasUrl = "Required.";
  }

  if (values.label === "") {
    errors.hasLabel = "Required.";
  }
  
  return errors;
}

const exportedFunctions = {
  projectValidation,
  adminFeedbackValidation,
  companyValidation,
  advertisementValidation
};

export default exportedFunctions;
