import { AuthHeader } from "../util/AuthHeader";

const controller = new AbortController();
const signal = controller.signal;

export function getCompanies(page, size, sortingType, loadedCompanyIds) {
  page = `?page=${page}`;
  size = `&size=${size}`;
  sortingType = `&sortingType=${sortingType}`;
  loadedCompanyIds = `&loadedCompanyIds=${loadedCompanyIds}`;
 
  const getCompanies = process.env.REACT_APP_API_BASE_URL + `/companies/preview${page}${size}${sortingType}${loadedCompanyIds}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  };
    
  return fetch(getCompanies, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getCompanyCountries() { 
  const countryCompaniesUrl = process.env.REACT_APP_API_BASE_URL + "/companies/countries";

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(countryCompaniesUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getAllSortedCompanies(sorted) {
  sorted = `?sorted=${sorted}`;

  const getAllSortedCompanies = process.env.REACT_APP_API_BASE_URL + `/companies${sorted}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal,
  };
    
  return fetch(getAllSortedCompanies, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function updateCompany(companyId, company) {
  const updateCompanyUrl = process.env.REACT_APP_API_BASE_URL + `/companies/${companyId}/update`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(company),
  };

  return fetch(updateCompanyUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getCompanyById(companyId) {
  const getCompanyUrl = process.env.REACT_APP_API_BASE_URL + `/companies/${companyId}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    }
  };

  return fetch(getCompanyUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function saveCompanyContacts(companyId, contacts) {
  const saveContactsUrl = process.env.REACT_APP_API_BASE_URL + `/companies/${companyId}/contacts/save`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(contacts),
  };

  return fetch(saveContactsUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function deleteCompany(companyId) {
  const deleteCompanyUrl = process.env.REACT_APP_API_BASE_URL + `/companies/${companyId}/delete`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(companyId),
  };

  return fetch(deleteCompanyUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getFilteredCompanies(searchQuery = "", firstIndex, lastIndex, sortingType) {
  if (!searchQuery) {
    firstIndex = `?firstIndex=${firstIndex}`;
  } else {
    firstIndex = `&firstIndex=${firstIndex}`;
  }
  lastIndex = `&lastIndex=${lastIndex}`;
  sortingType = `&sortingType=${sortingType}`;

  const filteredCompanies = process.env.REACT_APP_API_BASE_URL + `/companies/preview/filter${searchQuery}${firstIndex}${lastIndex}${sortingType}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    signal: signal
  };

  return fetch(filteredCompanies, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Request failed!");
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.name === "AbortError") {
        console.log("[DOM] Fetch Request Cancelled.");
      } else {
        console.log(err);
      }
    });
}

export function getCompanyFilterTypes() {
  const companyTagsUrl = process.env.REACT_APP_API_BASE_URL + "/companies/filtertypes";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(companyTagsUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

const exportedFunctions = {
  getCompanies,
  getAllSortedCompanies,
  updateCompany,
  deleteCompany,
  getCompanyFilterTypes,
  getFilteredCompanies
};
export default exportedFunctions;
