import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import $ from "jquery";

import DocumentHead from "../components/atoms/DocumentHead";
import SuccessStory from "../components/molecules/SuccessStory";

import "../styles/pages/SuccessStoriesPage.scss";

import { successStories } from "../assets/data/DataList";

import scrollTrigger from "../util/ScrollReveal";

const SuccessPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [currentStory, setCurrentStory] = useState(successStories.moooi);

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);

  useEffect(() => {
    if (currentStory === "Moooi") {
      history.push("/success-stories/moooi");
      $(".active").removeClass("active");
      window.scrollTo({top: 0, behavior: "smooth"});
      setTimeout(() => {
        setCurrentStory(successStories.moooi);
        scrollTrigger(".reveal");

        const videos = document.getElementsByTagName("video");

        for (let i=0; i < videos.length; i++) {
          videos[i].load();
        }
      }, 500);
    } else if (currentStory === "MADE.com") {
      history.push("/success-stories/made");
      $(".active").removeClass("active");
      window.scrollTo({top: 0, behavior: "smooth"});
      setTimeout(() => {
        setCurrentStory(successStories.made);
        scrollTrigger(".reveal");

        const videos = document.getElementsByTagName("video");

        for (let i=0; i < videos.length; i++) {
          videos[i].load();
        }
      }, 500);
    } else if (currentStory === "Kartent") {
      history.push("/success-stories/kartent");
      $(".active").removeClass("active");
      window.scrollTo({top: 0, behavior: "smooth"});
      
      setTimeout(() => {
        setCurrentStory(successStories.kartent);
        scrollTrigger(".reveal");

        const videos = document.getElementsByTagName("video");

        for (let i=0; i < videos.length; i++) {
          videos[i].load();
        }
      }, 500);
    }
  }, [currentStory]);

  useEffect(() => {
    //when a location changes, show the correct success story
    const newSuccesStory = location.pathname.split("/")[2];
    
    switch (newSuccesStory) {
      case "made":
        setCurrentStory(successStories.made);
        break;
      case "kartent":
        setCurrentStory(successStories.kartent);
        break;
      default:
        setCurrentStory(successStories.moooi);
    }
  }, [location]);

  return (
    <div className="success reveal">
      <DocumentHead
        title="Success Stories | Megosu"
        metaDescription="Success Stories"
      />
      <h1 className="success__title">Success Stories</h1>
      <SuccessStory 
        headerImage={currentStory.headerImage} 
        headerCaption={currentStory.headerCaption} 
        companyName={currentStory.companyName} 
        firstInfoHeaderText={currentStory.firstInfoHeaderText} 
        secondInfoHeaderText={currentStory.secondInfoHeaderText} 
        firstInfoHeaderImage={currentStory.firstInfoHeaderImage} 
        secondInfoHeaderImage={currentStory.secondInfoHeaderImage} 
        infoTextHTML={currentStory.infoTextHTML} 
        infoUnderTitle={currentStory.infoUnderTitle} 
        firstSmallImages={currentStory.firstSmallImages} 
        secondSmallImages={currentStory.secondSmallImages} 
        firstSmallImage={currentStory.firstSmallImage} 
        secondSmallImage={currentStory.secondSmallImage} 
        thirdSmallImage={currentStory.thirdSmallImage} 
        fourthSmallImage={currentStory.fourthSmallImage} 
        fifthSmallImage={currentStory.fifthSmallImage} 
        sixthSmallImage={currentStory.sixthSmallImage} 
        seventhSmallImage={currentStory.seventhSmallImage} 
        eigthSmallImage={currentStory.eigthSmallImage} 
        firstSmallImageCaption={currentStory.firstSmallImageCaption} 
        secondSmallImageCaption={currentStory.secondSmallImageCaption} 
        thirdSmallImageCaption={currentStory.thirdSmallImageCaption} 
        fourthSmallImageCaption={currentStory.fourthSmallImageCaption} 
        fifthSmallImageCaption={currentStory.fifthSmallImageCaption} 
        sixthSmallImageCaption={currentStory.sixthSmallImageCaption} 
        seventhSmallImageCaption={currentStory.seventhSmallImageCaption} 
        eigthSmallImageCaption={currentStory.eigthSmallImageCaption} 
        regularVideos={currentStory.regularVideos} 
        autoPlayVideos={currentStory.autoPlayVideos}
        loopVideos={currentStory.loopVideos}
        firstRegular={currentStory.firstRegular} 
        secondRegular={currentStory.secondRegular} 
        thirdRegular={currentStory.thirdRegular} 
        firstRegularCaption={currentStory.firstRegularCaption} 
        secondRegularCaption={currentStory.secondRegularCaption} 
        thirdRegularCaption={currentStory.thirdRegularCaption} 
        firstRegularTextHTML={currentStory.firstRegularTextHTML} 
        secondRegularTextHTML={currentStory.secondRegularTextHTML} 
        thirdRegularTextHTML={currentStory.thirdRegularTextHTML} 
        middleTitleHTML={currentStory.middleTitleHTML} 
        preButtonQuestion={currentStory.preButtonQuestion} 
        firstButtonTitle={currentStory.firstButtonTitle} 
        secondButtonTitle={currentStory.secondButtonTitle} 
        firstButtonAction={currentStory.firstButtonAction} 
        secondButtonAction={currentStory.secondButtonAction} 
        underButtonText={currentStory.underButtonText} 
        firstOtherStory={currentStory.firstOtherStory}
        firstOtherStoryStatic={currentStory.firstOtherStoryStatic} 
        secondOtherStory={currentStory.secondOtherStory} 
        secondOtherStoryStatic={currentStory.secondOtherStoryStatic} 
        firstOtherStoryCaption={currentStory.firstOtherStoryCaption} 
        secondOtherStoryCaption={currentStory.secondOtherStoryCaption} 
        setCurrentStory={setCurrentStory}
      />
      
    </div>
  );
};

export default SuccessPage;