import { AuthHeader } from "../util/AuthHeader";

export function send(message) {
  const sendMailUrl = process.env.REACT_APP_API_BASE_URL + "/mails/send";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(message),
  };

  return fetch(sendMailUrl, options);
}

export function sendAccountVerificationMail(recipient) {
  const verifyAccountUrl =
    process.env.REACT_APP_API_BASE_URL + "/mails/send/verify-account";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify({
      recipient: recipient,
    }),
  };

  return fetch(verifyAccountUrl, options);
}

export async function sendResetPasswordMail(email) {
  const sendEmailUrl =
    process.env.REACT_APP_API_BASE_URL + "/mails/send/reset-password";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ recipient: email }),
  };
  return await fetch(sendEmailUrl, options);
}

export function sendDesigner(message) {
  const sendMailUrl = process.env.REACT_APP_API_BASE_URL + "/mails/send/designer";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(message),
  };

  return fetch(sendMailUrl, options);
}

export function sendBuddyInvitation(message) {
  const sendMailUrl = process.env.REACT_APP_API_BASE_URL + "/mails/send/invite-buddy";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: message,
  };

  return fetch(sendMailUrl, options);
}

export function sendPitcherBuddyInvitationReward(message) {
  const sendMailUrl = process.env.REACT_APP_API_BASE_URL + "/mails/send/pitcher-invite-reward";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: message,
  };

  return fetch(sendMailUrl, options);
}

export function sendTeamMateInvitation(message) {
  const sendMailUrl = process.env.REACT_APP_API_BASE_URL + "/mails/send/invite-team-member";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: message,
  };

  return fetch(sendMailUrl, options);
}

export function sendProject(message) {
  const sendMailUrl =
    process.env.REACT_APP_API_BASE_URL + "/mails/send/project";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: AuthHeader(),
    },
    body: JSON.stringify(message),
  };

  return fetch(sendMailUrl, options);
}

const exportedFunctions = {
  send,
  sendDesigner,
  sendProject,
  sendResetPasswordMail,
};

export default exportedFunctions;
