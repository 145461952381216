import { getProjectTitleAndCountByProfileId } from "../services/ProjectService";
import { getCurrentUser, getCurrentUserByEmail } from "../services/AuthService";

export function amountOfUploadsCheck(user) {
  if (user) {
    getProjectTitleAndCountByProfileId(user.profile.profileId).then((res) => {
      if (res) {
        const firstProjectTitle = res[0];
        const count = res[1];

        if (count === 1 && !localStorage.getItem("firstUpload")) {
          localStorage.setItem("firstUpload", 0);
          sessionStorage.setItem("firstUploadTitle", firstProjectTitle);
        }

        if (count === 3 && !localStorage.getItem("threeUploads")) {
          localStorage.setItem("threeUploads", 0);
        }
        if (count === 5 && !localStorage.getItem("fiveUploads") && user.role.name !== "pro-user" && user.role.name !== "business-user" && user.role.name !== "pitch-user") {
          localStorage.setItem("fiveUploads", 0);
        }
      }
    });
  } else {
    const currentUser = getCurrentUser();
    getCurrentUserByEmail(currentUser).then((user) => {
      if (user) {
        getProjectTitleAndCountByProfileId(user.profile.profileId).then((res) => {
          if (res) {
            const firstProjectTitle = res[0];
            const count = res[1];

            if (count === 1 && !localStorage.getItem("firstUpload")) {
              localStorage.setItem("firstUpload", 0);
              sessionStorage.setItem("firstUploadTitle", firstProjectTitle);
            }

            if (count === 3 && !localStorage.getItem("threeUploads")) {
              localStorage.setItem("threeUploads", 0);
            }
            if (count === 5 && !localStorage.getItem("fiveUploads") && user.role.name !== "pro-user" && user.role.name !== "business-user" && user.role.name !== "pitch-user") {
              localStorage.setItem("fiveUploads", 0);
            }
          }
        });
      }
    });
  }
}

const exportedFunctions = {
  amountOfUploadsCheck
};

export default exportedFunctions;