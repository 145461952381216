import { useEffect } from "react";

import Button from "../components/atoms/Button";
import DocumentHead from "../components/atoms/DocumentHead";

import "../styles/pages/PartnersPage.scss";

import FeatureImage from "../assets/images/PartnersFeature.jpg";
import Partners from "../components/molecules/Partners";

import scrollTrigger from "../util/ScrollReveal";

const PartnersPage = () => {
  const sendEmail = () => {
    window.location = "mailto:partners@megosu.com";
  };

  useEffect(() => {
    setTimeout(() => {
      scrollTrigger(".reveal");
    }, 500);
  }, []);

  return (
    <div className="partners">
      <DocumentHead
        title="Partners | Megosu"
        metaDescription="We're always open to seek new oppurtunies with partners. Below you can see an overview of some of the partners we have worked with.
         Together we have managed to inspire millions of design-lovers and we launched many projects into the market. You could be next!"
      />
      <h1 className="partners__title reveal">Partners</h1>
      <div className="partners__feature-image-container">
        <img
          className="partners__feature-image"
          src={FeatureImage}
          alt="partners"
        />
      </div>
      <h2 className="partners__subtitle reveal">Let&apos;s talk</h2>
      <p className="partners__description-text reveal">
        We&apos;re always open to seek new oppurtunies with partners. Below you
        can see an overview of some of the partners we have worked with.
        Together we have managed to inspire millions of design-lovers and we
        launched many projects into the market. You could be next! So please
        don’t hesitate to send a request to partners@megosu.com. You can also
        contact our team if you would like to have multiple accounts or
        exclusive features for your company or university and get exclusive
        deals. We&apos;re excited to discuss the options.
      </p>

      <Button
        customClassName="partners__contact-button reveal button--black"
        type="black"
        title="Contact"
        onClick={() => sendEmail()}
      />

      <Partners reveal={true} />
    </div>
  );
};

export default PartnersPage;
