import React from "react";
import PropTypes from "prop-types";

import "../../styles/components/atoms/Button.scss";

function Button({ onClick, type, customStyle, index, title, disabled, customClassName }) {
  return (
    <>
      {type === "black" && (
        <button
          className={`button ${(!customClassName || (customClassName && !customClassName.includes("-form"))) && "button--black"} ${customClassName && customClassName}`}
          onClick={(e) => onClick && onClick(e)}
          style={customStyle}
          disabled={disabled}
        >
          {title}
        </button>
      )}
      {type === "pink" && (
        <button
          className={`button ${(!customClassName || (customClassName && !customClassName.includes("-form"))) && "button--pink"} ${customClassName && customClassName}`}
          onClick={(e) => onClick && onClick(e)}
          style={customStyle}
          disabled={disabled}
        >
          {title}
        </button>
      )}
      {type === "grey" && (
        <button
          className="button button--grey"
          onClick={(e) => onClick && onClick(e)}
          style={customStyle}
          disabled={disabled}
        >
          {title}
        </button>
      )}
      {type === "outline" && (
        <button
          className={
            index
              ? "button button--active button--white-outline"
              : "button button--inactive button--white-outline"
          }
          onClick={(e) => onClick && onClick(e)}
          style={customStyle}
          disabled={disabled}
        >
          {title}
        </button>
      )}
      {type === "white" && (
        <button
          className={`button ${(!customClassName || (customClassName && !customClassName.includes("-form"))) && "button--white"} ${customClassName && customClassName}`}
          onClick={(e) => onClick && onClick(e)}
          style={customStyle}
          disabled={disabled}
        >
          {title}
        </button>
      )}
    </>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
